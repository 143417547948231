/**
 * Функция позволяет получить время скипа в секундах
 * из VAST файла в котором время может быть в следующих форматах:
 * mm:ss
 * hh:mm:ss
 * hh:mm:ss.zzz
 *
 * @param {String} time
 *
 * @return {Number}
 */
export default function getSkipTime(time) {
  if (!time) return null;

  time = time.split(".")[0];
  time = time.split(":").map(Number);

  let seconds = 0;

  if (time.length === 3) {
    seconds += time[0] * 60 * 60;
    seconds += time[1] * 60;
    seconds += time[2];
  } else if (time.length === 2) {
    seconds += time[0] * 60;
    seconds += time[1];
  }

  return seconds;
}
