import React from "react";
import PropTypes from "prop-types";
import Grid from "./Grid";
import Carousel from "./Carousel";
import ReactGA from "react-ga4";
import HeaderMoreLink from "arena/components/HeaderMoreLink";
import HomepageTitle from "arena/components/HomepageTitle";
import { useQuery } from "@apollo/client";
import LoadMore from "core/components/LoadMore";

import { ArenaPostsQuery } from "arena/queries";

const components = {
  CAROUSEL: Carousel,
  GRID: Grid,
};

/**
 * Список в стиле полки контента
 */
export default function ArenaPosts({
  title,
  view,
  variant,
  limit = 12,
  tagIds,
  rightholderIds,
  loadMore,
  itemsPerRow,
}) {
  const variables = {
    rowsPerPage: limit,
    tagIds,
  };

  if (rightholderIds?.length) {
    variables.rightholderIds = rightholderIds;
  }

  const { data, loading, fetchMore } = useQuery(ArenaPostsQuery, {
    variables,
  });

  const count = data?.posts?.count || 0;
  const nodes = data?.posts?.nodes || [];

  if (!count) return null;

  let moreLink = null;

  if (!loadMore) {
    if (count > limit) {
      const searchParams = new URLSearchParams();
      if (tagIds) {
        tagIds.forEach((tagId) => {
          searchParams.append("tagId", tagId);
        });
      }
      moreLink = `/posts?${searchParams.toString()}`;
    }
  }

  const hasMore = nodes.length < count;
  const showLoadMore = loadMore && view === "GRID" && hasMore && !loading;
  const nextPage = Math.ceil(nodes.length / limit);

  const onLoadMore = () => {
    ReactGA.event({
      category: "User",
      action: "Load more posts",
      label: nextPage + " page",
    });

    fetchMore({
      variables: {
        page: nextPage,
      },
    });
  };

  const Component = components[view];

  const sm = Math.max(12 / itemsPerRow, 4);
  const md = Math.max(12 / itemsPerRow, 3);
  const lg = 12 / itemsPerRow;
  const xl = lg;

  return (
    <>
      {title && (
        <HomepageTitle
          title={title}
          action={moreLink && <HeaderMoreLink url={moreLink} />}
        />
      )}

      <Component
        items={nodes}
        variant={variant}
        xs={12}
        sm={sm}
        md={md}
        lg={lg}
        xl={xl}
      />

      {showLoadMore && <LoadMore key={nextPage} onLoadMore={onLoadMore} />}
    </>
  );
}

ArenaPosts.defaultProps = {
  itemsPerRow: 3,
  view: "CAROUSEL",
  variant: "TITLE_INSIDE",
};

ArenaPosts.propTypes = {
  title: PropTypes.string,
  limit: PropTypes.number,
  itemsPerRow: PropTypes.number,
  tagIds: PropTypes.arrayOf(PropTypes.string),
  rightholderIds: PropTypes.arrayOf(PropTypes.string),
  arena: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  view: PropTypes.oneOf(["CAROUSEL", "GRID"]).isRequired,
  variant: PropTypes.oneOf(["TITLE_INSIDE", "TITLE_OUTSIDE"]),
  loadMore: PropTypes.bool,
};
