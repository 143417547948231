import React, { useState } from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import SelectDocumentVersion from "./SelectDocumentVersion";

export default function DocumentBody({ document }) {
  const { t } = useTranslation("arena");
  const [version, setVersion] = useState(document);

  const onChangeVersion = (event) => {
    if (event.target.value === document.id) {
      setVersion(document);
      return;
    }

    const version = document.versions.find(
      (version) => version.id === event.target.value
    );
    setVersion(version);
  };

  return (
    <>
      <Typography variant="h4" gutterBottom>
        {version.title}
      </Typography>

      <Stack spacing={2} direction="row" alignItems="center">
        <Typography variant="body1" color="textSecondary">
          {t("document.version")}
        </Typography>

        <SelectDocumentVersion
          document={document}
          value={version.id}
          onChange={onChangeVersion}
        />
      </Stack>

      <Typography
        component="div"
        className="help-page"
        dangerouslySetInnerHTML={{ __html: version.text }}
      />
    </>
  );
}

DocumentBody.propTypes = {
  document: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    viewerCountry: PropTypes.string.isRequired,
    versions: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        titleRu: PropTypes.string,
        titleEn: PropTypes.string,
      })
    ),
  }),
};
