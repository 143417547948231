import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga4";
import { useMutation, useSubscription } from "@apollo/client";
import ContentIsNotAvailableByParallelLimit from "core/components/ContentIsNotAvailableByParallelLimit";
import ContentIsNotAvailableBySessionError from "core/components/ContentIsNotAvailableBySessionError";
import ContentIsNotAvailableError from "core/components/ContentIsNotAvailableError";
import Status from "core/components/Status";
import PaymentScreen from "core/components/PaymentScreen";
import PreviewScreen from "core/components/PreviewScreen";
import VideoScreen from "core/components/VideoScreen";
import StreamScreen from "core/components/StreamScreen";
import StreamPendingScreen from "core/components/StreamPendingScreen";
import StreamFinishedScreen from "core/components/StreamFinishedScreen";
import WaitingScreen from "core/components/WaitingScreen";
import ContentLoading from "core/components/ContentLoading";
import { CreateViewMutation } from "core/mutations";
import { createEvent } from "core/utils/tracker";
import getClientIp from "core/utils/getClientIp";
import { useTranslation } from "react-i18next";
import getReferrer from "core/utils/getReferrer";
import { ViewSessionClosedSubscription } from "core/subscriptions";
import SponsorPreAdv from "core/components/SponsorPreAdv";
import Button from "@mui/material/Button";
import { getUtmFromLocalStorage } from "core/utils/utm";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import apm from "core/utils/apm";
import useUpdateViewData from "core/hooks/useUpdateViewData";

const COLLECTION = "COLLECTION";
const VIDEO = "VIDEO";
const STREAM = "STREAM";
const PENDING = "PENDING";
const FINISHED = "FINISHED";
const STARTED = "STARTED";

const CONTENT_IS_NOT_AVAILABLE_FOR_GUESTS =
  "CONTENT_IS_NOT_AVAILABLE_FOR_GUESTS";

const CONTENT_IS_NOT_AVAILABLE_IN_COUNTRY =
  "CONTENT_IS_NOT_AVAILABLE_IN_COUNTRY";

const CONTENT_IS_NOT_AVAILABLE_BY_SESSIONS_LIMIT =
  "CONTENT_IS_NOT_AVAILABLE_BY_SESSIONS_LIMIT";

const CONTENT_IS_NOT_AVAILABLE_BY_PARALLEL_LIMIT =
  "CONTENT_IS_NOT_AVAILABLE_BY_PARALLEL_LIMIT";

const CONTENT_IS_NOT_AVAILABLE_BY_ACCESS_START_AT =
  "CONTENT_IS_NOT_AVAILABLE_BY_ACCESS_START_AT";

const CONTENT_IS_NOT_AVAILABLE_FOR_FREE_VIEWING =
  "CONTENT_IS_NOT_AVAILABLE_FOR_FREE_VIEWING";

const CONTENT_IS_NOT_AVAILABLE_BY_PRODUCT_PURCHASE_START_AT =
  "CONTENT_IS_NOT_AVAILABLE_BY_PRODUCT_PURCHASE_START_AT";

const CONTENT_IS_NOT_AVAILABLE_BY_BROWSER_DRM_COMPATIBILITY =
  "CONTENT_IS_NOT_AVAILABLE_BY_BROWSER_DRM_COMPATIBILITY";

const CONTENT_UNSUPPORTED_BY_BROWSER = "CONTENT_UNSUPPORTED_BY_BROWSER";
const WATERMARK_REMOVED = "WATERMARK_REMOVED";

function Player({
  nextPlaylistContentButton,
  sponsorCornerBannerPlace,
  sponsorInStreamBannerPlace,
  sponsorPlayerLogoPlace,
  sponsorPlayerButtonPlace,
  sponsorVideoPlace,
  sponsorChatMessagePlace,
  onChatAdv,
  onPlay,
  onPause,
  onEnded,
  onLastQuartileStart,
  content,
  hideTitle,
  hideShare,
  hideLogo,
  autoPlay,
  autoPause,
  loop,
  muted,
  hideRelatedContent,
  triggerPostMessage,
  fullscreenTarget,
  updateVideoPosition,
  subscribeButton,
  start,
  finish,
  onSeeked,
  onTimeUpdate,
  onSeekEnd,
  onUpdate,
  targetStreamId,
  onClickProductButton,
  dubugVastUrl,
  startOffset,
}) {
  const { t } = useTranslation("core");
  const [clientIp, setClientIp] = useState(null);
  const [preRollCompleted, setPreRollCompleted] = useState(0);
  const [error, setError] = useState();
  const [videoError, setVideoError] = useState();

  const refetchQueries = [];

  if (content.premium) {
    refetchQueries.push("Viewer");
  }

  const [createView, createViewResult] = useMutation(CreateViewMutation, {
    refetchQueries,
    onCompleted() {
      createEvent(content.type.toLowerCase(), "start");

      ReactGA.event({
        category: "User",
        action: "Create view",
        label: content.title,
      });
    },
  });

  const sessionId = createViewResult?.data?.createView?.viewSession?.id;

  const onVideoError = (error) => {
    if (
      [WATERMARK_REMOVED, CONTENT_UNSUPPORTED_BY_BROWSER].includes(
        error?.message
      )
    ) {
      return setVideoError(error.message);
    }
  };
  /**
   * подписываемся на закрытие сеанса только если
   * она помечена как для просмотра премиум контента
   */
  useSubscription(ViewSessionClosedSubscription, {
    skip: !process.browser || !sessionId,
    variables: {
      id: sessionId,
    },
    onData: ({ data }) => {
      if (data?.data?.viewSessionClosed?.closedAt) {
        location.reload();
      }
    },
  });

  const onGuestClick = (event) => {
    const signInButton = document.querySelector(".js-sign-in");

    if (signInButton) {
      signInButton.click();
      event.preventDefault();
    }
  };

  const onClickPlay = () => {
    const variables = {
      token: content.access.token,
      utm: getUtmFromLocalStorage(),
    };

    getClientIp(content.getIpUrl)
      .then((ip) => {
        if (ip) {
          setClientIp(ip);
          variables.clientIp = ip;
        }

        return createView({
          variables,
        });
      })
      .catch((error) => {
        apm.captureError(error);
        console.error("create view error", error);

        setError(error);
      });
  };

  const view = createViewResult?.data?.createView;
  const video = view?.video;
  const records = view?.records;
  const hasRecord = content?.streamData?.hasRecord;

  const viewDataTracker = useUpdateViewData({ view, content });

  if (createViewResult.loading) {
    return <ContentLoading content={content} hideLogo={hideLogo} />;
  }

  if (createViewResult.error || error) {
    return (
      <ContentIsNotAvailableError
        title={t("contentIsNotAvailable.purchaseError.title")}
        text={t("contentIsNotAvailable.purchaseError.text")}
        button={null}
        content={content}
      />
    );
  }

  if (content.access.error || videoError) {
    const error = content?.access?.error || videoError;

    if (error === CONTENT_IS_NOT_AVAILABLE_BY_ACCESS_START_AT) {
      return (
        <ContentIsNotAvailableError
          title={t("contentIsNotAvailable.accessStartAt.title")}
          text={t("contentIsNotAvailable.accessStartAt.text", {
            date: content.accessStartAt,
          })}
          button={null}
          content={content}
          icon={AccessTimeFilledIcon}
        />
      );
    }

    if (error === CONTENT_IS_NOT_AVAILABLE_FOR_GUESTS) {
      return (
        <ContentIsNotAvailableError
          title={t("contentIsNotAvailable.onlyUsers.title")}
          text={null}
          button={
            <Button
              href={content.shareUrl + "?signin"}
              variant="contained"
              color="primary"
              target="_blank"
              onClick={onGuestClick}
            >
              {t("contentIsNotAvailable.onlyUsers.button")}
            </Button>
          }
          content={content}
        />
      );
    }

    if (error === CONTENT_IS_NOT_AVAILABLE_IN_COUNTRY) {
      return (
        <ContentIsNotAvailableError
          title={t("contentIsNotAvailable.invalidCountry.title")}
          text={t("contentIsNotAvailable.invalidCountry.text")}
          button={null}
          content={content}
        />
      );
    }

    if (
      [
        CONTENT_UNSUPPORTED_BY_BROWSER,
        CONTENT_IS_NOT_AVAILABLE_BY_BROWSER_DRM_COMPATIBILITY,
        WATERMARK_REMOVED,
      ].includes(error)
    ) {
      return (
        <ContentIsNotAvailableError
          title={t("contentIsNotAvailable.browserDrmCompatibility.title")}
          text={t("contentIsNotAvailable.browserDrmCompatibility.text")}
          button={null}
          content={content}
        />
      );
    }

    if (error === CONTENT_IS_NOT_AVAILABLE_BY_SESSIONS_LIMIT) {
      return <ContentIsNotAvailableBySessionError content={content} />;
    }

    if (error === CONTENT_IS_NOT_AVAILABLE_BY_PARALLEL_LIMIT) {
      return <ContentIsNotAvailableByParallelLimit content={content} />;
    }

    if (error === CONTENT_IS_NOT_AVAILABLE_FOR_FREE_VIEWING) {
      return (
        <PaymentScreen
          content={content}
          onClickProductButton={onClickProductButton}
        />
      );
    }

    if (error === CONTENT_IS_NOT_AVAILABLE_BY_PRODUCT_PURCHASE_START_AT) {
      return <WaitingScreen content={content} />;
    }
  }

  if (typeof window !== "undefined") {
    if (content.access.hostnames.length) {
      const url = getReferrer();

      if (
        !content.access.hostnames.some((hostname) => url.includes(hostname))
      ) {
        return (
          <ContentIsNotAvailableError
            title={t("contentIsNotAvailable.invalidHost.title")}
            text={t("contentIsNotAvailable.invalidHost.text")}
            button={null}
            content={content}
          />
        );
      }
    }
  }

  if (preRollCompleted < content.preRollCount) {
    if (content.isAdvEnabled) {
      if (view) {
        const onFinish = () => {
          setPreRollCompleted((count) => count + 1);
        };

        return (
          <SponsorPreAdv
            key={preRollCompleted}
            dubugVastUrl={dubugVastUrl}
            content={content}
            hideShare={hideShare}
            hideLogo={hideLogo}
            fullscreenTarget={fullscreenTarget}
            sponsorVideoPlace={sponsorVideoPlace}
            muted={muted || autoPlay}
            onFinish={onFinish}
            onNotFound={onFinish}
            onChatAdv={onChatAdv}
            sponsorChatMessagePlace={sponsorChatMessagePlace}
          />
        );
      }
    }
  }

  if (content.type === VIDEO) {
    if (!createViewResult.data) {
      return (
        <PreviewScreen
          content={content}
          onClickPlay={onClickPlay}
          hideTitle={hideTitle}
          hideShare={hideShare}
          hideLogo={hideLogo}
          autoPlay={autoPlay}
          fullscreenTarget={fullscreenTarget}
        />
      );
    }

    if (!video) {
      return (
        <ContentIsNotAvailableError
          title={t("contentIsNotAvailable.noVideo.title")}
          text={t("contentIsNotAvailable.noVideo.text")}
          button={null}
          content={content}
        />
      );
    }

    return (
      <VideoScreen
        nextPlaylistContentButton={nextPlaylistContentButton}
        view={view}
        video={video}
        onSeeked={onSeeked}
        onTimeUpdate={onTimeUpdate}
        onSeekEnd={onSeekEnd}
        onUpdate={onUpdate}
        content={content}
        hideTitle={hideTitle}
        hideShare={hideShare}
        hideLogo={hideLogo}
        autoPause={autoPause}
        autoPlay
        muted={muted}
        loop={loop}
        hideRelatedContent={hideRelatedContent}
        triggerPostMessage={triggerPostMessage}
        fullscreenTarget={fullscreenTarget}
        onLastQuartileStart={onLastQuartileStart}
        onEnded={onEnded}
        onPlay={onPlay}
        onPause={onPause}
        updateVideoPosition={updateVideoPosition}
        start={start}
        finish={finish}
        sponsorPlayerLogoPlace={sponsorPlayerLogoPlace}
        sponsorPlayerButtonPlace={sponsorPlayerButtonPlace}
        sponsorVideoPlace={sponsorVideoPlace}
        sponsorInStreamBannerPlace={sponsorInStreamBannerPlace}
        sponsorCornerBannerPlace={sponsorCornerBannerPlace}
        viewDataTracker={viewDataTracker}
        onVideoError={onVideoError}
      />
    );
  }

  if (content.type === STREAM) {
    if (content.streamData.status === PENDING) {
      return (
        <StreamPendingScreen
          content={content}
          subscribeButton={subscribeButton}
        />
      );
    }

    if (content.streamData.status === STARTED) {
      if (!content.streamData.hasOnlineStreams) {
        return (
          <ContentIsNotAvailableError
            title={t("contentIsNotAvailable.noStream.title")}
            text={t("contentIsNotAvailable.noStream.text")}
            button={null}
            content={content}
            hideTitle={hideTitle}
            hideShare={hideShare}
            hideLogo={hideLogo}
            fullscreenTarget={fullscreenTarget}
          />
        );
      }
    }

    if (content.streamData.status === FINISHED) {
      if (!hasRecord) {
        return <StreamFinishedScreen content={content} />;
      }

      if (createViewResult?.data?.createView?.records?.length) {
        return (
          <VideoScreen
            nextPlaylistContentButton={nextPlaylistContentButton}
            records={records}
            view={view}
            video={video}
            content={content}
            hideTitle={hideTitle}
            hideShare={hideShare}
            hideLogo={hideLogo}
            autoPause={autoPause}
            autoPlay
            muted={muted}
            loop={loop}
            hideRelatedContent={hideRelatedContent}
            triggerPostMessage={triggerPostMessage}
            fullscreenTarget={fullscreenTarget}
            onLastQuartileStart={onLastQuartileStart}
            onEnded={onEnded}
            onPlay={onPlay}
            onPause={onPause}
            updateVideoPosition={updateVideoPosition}
            start={start}
            finish={finish}
            sponsorCornerBannerPlace={sponsorCornerBannerPlace}
            sponsorInStreamBannerPlace={sponsorInStreamBannerPlace}
            sponsorPlayerLogoPlace={sponsorPlayerLogoPlace}
            sponsorPlayerButtonPlace={sponsorPlayerButtonPlace}
            sponsorVideoPlace={sponsorVideoPlace}
            onSeeked={onSeeked}
            onTimeUpdate={onTimeUpdate}
            onSeekEnd={onSeekEnd}
            onUpdate={onUpdate}
            viewDataTracker={viewDataTracker}
            onVideoError={onVideoError}
          />
        );
      }
    }

    if (!createViewResult?.data) {
      return (
        <PreviewScreen
          content={content}
          onClickPlay={onClickPlay}
          hideTitle={hideTitle}
          hideShare={hideShare}
          hideLogo={hideLogo}
          autoPlay={autoPlay}
          fullscreenTarget={fullscreenTarget}
        />
      );
    }

    return (
      <StreamScreen
        startOffset={startOffset}
        clientIp={clientIp}
        view={view}
        content={content}
        hideTitle={hideTitle}
        hideShare={hideShare}
        hideLogo={hideLogo}
        autoPause={autoPause}
        autoPlay
        muted={muted}
        hideRelatedContent={hideRelatedContent}
        triggerPostMessage={triggerPostMessage}
        fullscreenTarget={fullscreenTarget}
        onEnded={onEnded}
        onPlay={onPlay}
        onPause={onPause}
        onChatAdv={onChatAdv}
        sponsorCornerBannerPlace={sponsorCornerBannerPlace}
        sponsorInStreamBannerPlace={sponsorInStreamBannerPlace}
        sponsorPlayerLogoPlace={sponsorPlayerLogoPlace}
        sponsorPlayerButtonPlace={sponsorPlayerButtonPlace}
        sponsorVideoPlace={sponsorVideoPlace}
        sponsorChatMessagePlace={sponsorChatMessagePlace}
        targetStreamId={targetStreamId}
        viewDataTracker={viewDataTracker}
        onVideoError={onVideoError}
      />
    );
  }

  return <Status code={404}>content type is not defined </Status>;
}

Player.propTypes = {
  startOffset: PropTypes.number,
  nextPlaylistContentButton: PropTypes.func,
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
  onEnded: PropTypes.func,
  onLastQuartileStart: PropTypes.func,
  fullscreenTarget: PropTypes.string,
  updateVideoPosition: PropTypes.bool,
  triggerPostMessage: PropTypes.bool,
  hideShare: PropTypes.bool,
  hideLogo: PropTypes.bool,
  hideRelatedContent: PropTypes.bool,
  muted: PropTypes.bool,
  loop: PropTypes.bool,
  autoPlay: PropTypes.bool,
  autoPause: PropTypes.bool,
  hideTitle: PropTypes.bool,
  hideHeader: PropTypes.bool,
  targetStreamId: PropTypes.string,
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    getIpUrl: PropTypes.string,
    type: PropTypes.oneOf([STREAM, VIDEO, COLLECTION]).isRequired,
    title: PropTypes.string.isRequired,
    shareUrl: PropTypes.string.isRequired,
    accessStartAt: PropTypes.string,
    preRollCount: PropTypes.number.isRequired,
    isAdvEnabled: PropTypes.bool,
    streamData: PropTypes.shape({
      status: PropTypes.string.isRequired,
      hasRecord: PropTypes.bool,
      hasOnlineStreams: PropTypes.bool,
    }),
    access: PropTypes.shape({
      token: PropTypes.string,
      error: PropTypes.string,
      hostnames: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    premium: PropTypes.bool,
    viewerPurchase: PropTypes.shape({
      id: PropTypes.string.isRequired,
      expiredAt: PropTypes.string,
      started: PropTypes.bool,
    }),
    rightholder: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }),
    viewerSubscription: PropTypes.shape({
      notify: PropTypes.bool,
      active: PropTypes.bool,
    }),
  }),
  subscribeButton: PropTypes.node,
  start: PropTypes.number,
  finish: PropTypes.number,
  onChatAdv: PropTypes.func,
  sponsorCornerBannerPlace: PropTypes.string,
  sponsorInStreamBannerPlace: PropTypes.string,
  sponsorPlayerLogoPlace: PropTypes.string,
  sponsorPlayerButtonPlace: PropTypes.string,
  sponsorVideoPlace: PropTypes.string,
  sponsorChatMessagePlace: PropTypes.string,
  onSeeked: PropTypes.func,
  onTimeUpdate: PropTypes.func,
  onUpdate: PropTypes.func,
  onSeekEnd: PropTypes.func,
  onClickProductButton: PropTypes.func,
  dubugVastUrl: PropTypes.string,
};

export default Player;
