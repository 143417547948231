const RE_FPS_NUMBER = /^\d+$/;
const RE_FPS_FRACTION = /^(?<numerator>\d+)\/(?<denominator>\d+)$/;
const FPS_CACHE = new Map();

export function parseFps(fps) {
  const cached = FPS_CACHE.get(fps);

  if (cached) {
    return cached;
  }

  let match = fps.match(RE_FPS_NUMBER);
  let ret = null;

  if (match) {
    ret = {
      numerator: Number.parseInt(fps, 10),
      denominator: 1,
    };
  }

  match = fps.match(RE_FPS_FRACTION);

  if (match) {
    ret = {
      numerator: Number.parseInt(match.groups.numerator, 10),
      denominator: Number.parseInt(match.groups.denominator, 10),
    };
  }

  FPS_CACHE.set(fps, ret);

  return ret;
}

export function computeSpriteIndex(position, fps, picsPerFile) {
  const frame = Math.ceil((position * fps.numerator) / fps.denominator);
  const file = Math.floor(frame / picsPerFile);
  const pic = frame - file * picsPerFile;

  return { file, pic };
}
