import React from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import { useTranslation } from "react-i18next";

export default function CardStep({ step, title, onNext, last }) {
  const { t } = useTranslation("arena");

  return (
    <>
      <AppBar position="static" color="default">
        <Toolbar>
          <Typography variant="h5" sx={{ flex: 1 }}>
            {title}
          </Typography>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            type="submit"
            onClick={onNext}
          >
            {last ? t("close") : t("next")}
          </Button>
        </Toolbar>
      </AppBar>

      <DialogContent>
        <Card>
          <CardMedia component="img" image={step?.props?.banner} />
          <CardHeader title={step?.props?.title} />
          <CardContent>{step?.props?.text}</CardContent>
        </Card>
      </DialogContent>
    </>
  );
}

CardStep.propTypes = {
  last: PropTypes.bool,
  onNext: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  step: PropTypes.shape({
    type: PropTypes.string.isRequired,
    props: PropTypes.object,
  }),
};
