import React from "react";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import ProductDialog from "arena/components/ProductDialog";
import useProductDialog from "arena/hooks/useProductDialog";
import PaymentButtons from "core/components/PaymentButtons";
import getMinPrice from "core/utils/getMinPrice";
import formatCurrency from "core/utils/currency";

export default function AccessProductsPayment({ product, refetch }) {
  const { t } = useTranslation("arena");
  /**
   * Что бы не открывать диалог покупки передадим skipPaymentId
   * Диалог откроется в корневом компоненте
   */
  const { state, dispatch } = useProductDialog({ skipPaymentId: true });

  const products = product.accessProducts;

  const minPrice = getMinPrice(product);
  const priceText = formatCurrency(minPrice.value, minPrice.currency);

  return (
    <>
      <Stack spacing={2}>
        <Typography variant="body1">
          {t("productCover.accessProductPayments.availableForSubscribersOnly", {
            price: priceText,
          })}
        </Typography>
        <Typography variant="body1">
          {t("productCover.accessProductPayments.completeSubscription")}
        </Typography>
        <PaymentButtons products={products} onClickProductButton={dispatch} />
      </Stack>
      <ProductDialog
        products={products}
        state={state}
        dispatch={dispatch}
        refetch={refetch}
      />
    </>
  );
}

AccessProductsPayment.propTypes = {
  product: PropTypes.object,
  refetch: PropTypes.func,
};
