import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Slider from "@mui/material/Slider";
import SeekBarSliderRoot from "core/components/SeekBarSliderRoot";
import SeekBarPopup from "core/components/SeekBarPopup";

const PREFIX = "VodSeekBar";

const classes = {
  root: `${PREFIX}-root`,
  sliderRoot: `${PREFIX}-sliderRoot`,
  sliderContainer: `${PREFIX}-sliderContainer`,
  sliderTrack: `${PREFIX}-sliderTrack`,
  sliderRail: `${PREFIX}-sliderRail`,
  sliderThumb: `${PREFIX}-sliderThumb`,
  progressRoot: `${PREFIX}-progressRoot`,
  progressColorPrimary: `${PREFIX}-progressColorPrimary`,
  progressBarColorPrimary: `${PREFIX}-progressBarColorPrimary`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: "absolute",
    left: 0,
    right: 0,
    top: -12,
    zIndex: 3,
    height: 24,
  },

  [`& .${classes.sliderRoot}`]: {
    background: "transparent",
    position: "absolute",
    padding: "11px 0",
    top: 0,
    left: 0,
    right: 0,
  },

  [`& .${classes.sliderContainer}`]: {
    padding: "10px 0",
  },

  [`& .${classes.sliderTrack}`]: {
    height: 4,
    backgroundColor: theme.palette.primary.dark,
    border: 0,
  },

  [`& .${classes.sliderRail}`]: {
    backgroundColor: theme.palette.primary.dark,
    height: 4,
  },

  [`& .${classes.sliderThumb}`]: {
    background: "white",
    width: 10,
    height: 10,
  },

  [`& .${classes.progressRoot}`]: {
    height: 4,
    top: 11,
  },

  [`& .${classes.progressColorPrimary}`]: {
    background: "transparent",
  },

  [`& .${classes.progressBarColorPrimary}`]: {
    background: "rgba(255, 255, 255, 0.2)",
    animation: "none",
  },
}));

const THUMB_POSITION_UPDATE_INTERVAL = 50;

function getMaxBufferedValue(buffered) {
  const maxBuffered = buffered.reduce(
    (prev, current) => (prev[1] > current[1] ? prev : current),
    [0, 0]
  );
  return maxBuffered ? maxBuffered[1] : 0;
}

function getCurrentTime(videoState) {
  if (!videoState) {
    return 0;
  }

  return videoState.currentTime || 0;
}

// function ThumbComponent(props) {
//   return <span {...props} onKeyDown={null}></span>;
// }

class VodSeekBar extends React.Component {
  interval = null;
  lastTick = 0;
  lastIsRolling = false;

  constructor(props, context) {
    super(props, context);

    this.state = {
      isSeeking: false,
      sliderValue: getCurrentTime(props.videoState),
    };
  }

  isRolling() {
    const { videoState } = this.props;
    const { isSeeking } = this.state;

    if (!videoState) {
      return false;
    }

    return videoState.status === "playing" && !videoState.seeking && !isSeeking;
  }

  componentDidMount() {
    if (this.isRolling()) {
      this.lastIsRolling = true;
      this.startSliderValueUpdates();
    }
  }

  componentDidUpdate() {
    const { videoState } = this.props;
    const { isSeeking } = this.state;

    const currentIsRolling = this.isRolling();

    if (currentIsRolling !== this.lastIsRolling) {
      if (currentIsRolling) {
        this.startSliderValueUpdates();
      } else {
        this.stopSliderValueUpdates();
      }

      if (!isSeeking) {
        this.setState({
          sliderValue: getCurrentTime(videoState),
        });
      }

      this.lastIsRolling = currentIsRolling;
    }
  }

  componentWillUnmount() {
    this.stopSliderValueUpdates();
  }

  startSliderValueUpdates() {
    if (this.interval) {
      return;
    }

    this.lastTick = Date.now();
    this.interval = window.setInterval(
      this.updateSliderValue,
      THUMB_POSITION_UPDATE_INTERVAL
    );
  }

  stopSliderValueUpdates() {
    if (!this.interval) {
      return;
    }

    window.clearInterval(this.interval);
    this.interval = null;
  }

  updateSliderValue = () => {
    const { sliderValue } = this.state;

    const now = Date.now();
    const delta = now - this.lastTick;
    this.lastTick = now;

    this.setState({
      sliderValue: sliderValue + delta / 1000,
    });
  };

  handleChange = (_, value) => {
    const { videoState, videoActions, onSeekStart, onSeek } = this.props;
    const { isSeeking } = this.state;

    if (!isSeeking) {
      onSeekStart(value, videoState, videoActions);
    }

    if (onSeek) {
      onSeek(value, videoState, videoActions);
    }

    this.stopSliderValueUpdates();
    this.setState({
      isSeeking: true,
      sliderValue: value,
    });
  };

  handleChangeCommitted = (_, value) => {
    const { videoState, videoActions, onSeekEnd } = this.props;

    onSeekEnd(value, videoState, videoActions);

    this.setState({
      isSeeking: false,
      sliderValue: value,
    });
  };

  renderBuffered() {
    const { videoState } = this.props;
    const { duration, buffered } = videoState;

    const maxBufferedValue = getMaxBufferedValue(buffered);
    const value = duration ? 100 / (duration / maxBufferedValue) : 0;
    return (
      <LinearProgress
        color="primary"
        variant="determinate"
        value={value}
        classes={{
          root: classes.progressRoot,
          colorPrimary: classes.progressColorPrimary,
          barColorPrimary: classes.progressBarColorPrimary,
        }}
      />
    );
  }

  render() {
    const { videoState, videoPreviews, visible, disableSeekBarPopup } =
      this.props;
    const { isSeeking, sliderValue } = this.state;

    if (!visible) return null;
    if (!videoState) return null;
    if (videoState.live) return null;

    const { duration, buffered } = videoState;

    if (!duration) return null;

    const popupWidth = Math.max(
      videoPreviews?.low?.width || 0,
      SeekBarPopup.MIN_WIDTH
    );
    const popupMargin = Math.round(popupWidth / 2);

    return (
      <Root className={classes.root}>
        {buffered && this.renderBuffered()}

        <Slider
          component={SeekBarSliderRoot}
          // ThumbComponent={ThumbComponent}
          value={sliderValue}
          onChange={this.handleChange}
          onChangeCommitted={this.handleChangeCommitted}
          classes={{
            root: classes.sliderRoot,
            track: classes.sliderTrack,
            thumb: classes.sliderThumb,
            rail: classes.sliderRail,
          }}
          max={duration}
          min={0}
          step={0.01}
          PopupComponent={SeekBarPopup}
          isPopupFixed={isSeeking}
          popupMarginLeft={popupMargin}
          popupMarginRight={popupMargin}
          popupProps={{
            duration,
            videoPreviews,
            disableSeekBarPopup,
          }}
        />
      </Root>
    );
  }
}

VodSeekBar.propTypes = {
  visible: PropTypes.bool,
  videoActions: PropTypes.object,
  videoState: PropTypes.object,
  videoPreviews: PropTypes.object,
  onSeekStart: PropTypes.func,
  onSeek: PropTypes.func,
  onSeekEnd: PropTypes.func,
  disableSeekBarPopup: PropTypes.bool,
};

export default VodSeekBar;
