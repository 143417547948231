import React from "react";
import PropTypes from "prop-types";

import VideoLoading from "core/components/VideoLoading";
import PlayerPause from "core/components/PlayerPause";
import PlayerHeader from "core/components/PlayerHeader";
import PlayerFooter from "core/components/PlayerFooter";
import BigPlayButton from "core/components/BigPlayButton";
import Shortcut from "core/components/Shortcut";
import { createEvent } from "core/utils/tracker";
import useIdleRef from "core/hooks/useIdleRef";
import ViewerIdWatermark from "core/components/ViewerIdWatermark";

// Интервал показа инстрим рекламы (в секундах) - 30 мин
const AD_IMPRESSION_INTERVAL = 60 * 30;

// Интервал первого показа инстрим рекламы (в секундах) - 15 сек
const FIRST_AD_IMPRESSION_INTERVAL = 15;

// Интервал показа мидролов (в секундах) - 15 мин
const MIDROLL_IMPRESSION_INTERVAL = 60 * 15;

import SponsorPlayerLogo from "core/components/SponsorPlayerLogo";
import SponsorPlayerButton from "core/components/SponsorPlayerButton";
import SponsorMidAdv from "core/components/SponsorMidAdv";
import SponsorPauseAdv from "core/components/SponsorPauseAdv";
import SponsorLiveAdv from "core/components/SponsorLiveAdv";
import PlayerInView from "core/components/PlayerInView";

export default function StreamScreenContent({
  videoElement,
  videoState,
  videoActions,
  relatedContent,
  chooseCameraButton,
  inStreamBannerHeight,
  setInStreamBannerHeight,
  cornerBannerHeight,
  setCornerBannerHeight,
  videoRef,
  sponsorCornerBannerPlace,
  sponsorPlayerLogoPlace,
  sponsorPlayerButtonPlace,
  sponsorInStreamBannerPlace,
  sponsorVideoPlace,
  autoPause,
  fullscreenTarget,
  autoPlay,
  content,
  muted,
  pause,
  hideTitle,
  hideShare,
  hideLogo,
  setPause,
  onChatAdv,
  liveSeekBar,
  sponsorChatMessagePlace,
  hlsStreamPlaylist,
  onVideoError,
}) {
  const idle = useIdleRef({ ref: videoRef });
  const visible = !idle;
  const onClickBigPlay = () => {
    videoActions.togglePlayUser();
    if (videoState.status === "playing") {
      createEvent("stream", "pause");
    } else {
      createEvent("stream", "play");
    }
  };

  const onClickPlay = () => {
    videoActions.togglePlayUser();
    createEvent("stream", "play");
  };

  const onClickPause = () => {
    videoActions.togglePlayUser();
    createEvent("stream", "pause");
  };

  const onChangeInView = (inView) => {
    if (autoPause) {
      if (inView) {
        if (!videoState.isUserPause) {
          videoActions.play();
        }
      } else {
        videoActions.pause();
      }
    }
  };

  let playerHeaderVisible = false;
  let playerFooterVisible = false;

  if (!cornerBannerHeight && !inStreamBannerHeight) {
    if (pause) {
      playerHeaderVisible = true;
    } else {
      if (hideTitle) {
        if (visible || videoState.isUserPause) {
          playerHeaderVisible = true;
          playerFooterVisible = true;
        }
      } else {
        if (videoState.isUserPause) {
          if (visible) {
            playerHeaderVisible = true;
            playerFooterVisible = true;
          }
        } else {
          if (visible) {
            playerHeaderVisible = true;
            playerFooterVisible = true;
          }
        }
      }
    }
  }

  const sponsorPlayerLogoVisible = !(
    cornerBannerHeight > 0 || inStreamBannerHeight > 0
  );

  const sponsorPlayerButtonVisible = !(
    cornerBannerHeight > 0 || inStreamBannerHeight > 0
  );

  return (
    <>
      <PlayerInView
        cornerBannerHeight={cornerBannerHeight}
        inStreamBannerHeight={inStreamBannerHeight}
        onChange={onChangeInView}
      >
        <ViewerIdWatermark
          onVideoError={onVideoError}
          watermarked={content.enableViewerIdWatermark}
          videoRef={videoRef}
        >
          {videoElement}
        </ViewerIdWatermark>

        <VideoLoading videoState={videoState} />

        <Shortcut videoActions={videoActions} videoState={videoState} />

        {!hideTitle && videoState.isUserPause ? (
          <PlayerPause
            title={content.title}
            contentUrl={content.shareUrl}
            relatedContent={relatedContent}
            onClickPlay={onClickBigPlay}
            setVisible={setPause}
            visible={pause}
            hideLogo={hideLogo || content.hideLogo}
          />
        ) : (
          <>
            {sponsorPlayerLogoVisible &&
              sponsorPlayerButtonVisible &&
              playerFooterVisible && (
                <BigPlayButton
                  onClick={onClickBigPlay}
                  videoState={videoState}
                />
              )}
          </>
        )}

        <PlayerHeader
          content={content}
          position="absolute"
          visible={playerHeaderVisible}
          tracker
        />

        <PlayerFooter
          liveSeekBar={liveSeekBar}
          position="absolute"
          content={content}
          visible={playerFooterVisible}
          videoElement={videoElement}
          videoState={videoState}
          videoActions={videoActions}
          onClickPlay={onClickPlay}
          onClickPause={onClickPause}
          hideShare={hideShare}
          hideLogo={hideLogo}
          fullscreenTarget={fullscreenTarget}
          chooseCameraButton={chooseCameraButton}
          hlsUrl={hlsStreamPlaylist}
        />

        {content.isAdvEnabled && (
          <SponsorPlayerLogo
            contentId={content.id}
            rightholderId={content.rightholder.id}
            place={sponsorPlayerLogoPlace}
            footerVisible={playerFooterVisible}
            visible={sponsorPlayerLogoVisible}
          />
        )}

        {content.isAdvEnabled && !content.widget && (
          <SponsorPlayerButton
            contentId={content.id}
            rightholderId={content.rightholder.id}
            place={sponsorPlayerButtonPlace}
            footerVisible={playerFooterVisible}
            visible={sponsorPlayerButtonVisible}
          />
        )}
      </PlayerInView>

      {content.isAdvEnabled && (
        <>
          <SponsorLiveAdv
            liveSeekBar={liveSeekBar}
            fullscreenTarget={fullscreenTarget}
            videoActions={videoActions}
            videoState={videoState}
            content={content}
            rightholderId={content.rightholder.id}
            sponsorChatMessagePlace={sponsorChatMessagePlace}
            sponsorVideoPlace={sponsorVideoPlace}
            muted={muted || autoPlay}
            onChatAdv={onChatAdv}
            impressionInterval={MIDROLL_IMPRESSION_INTERVAL}
            disabled={!sponsorPlayerLogoVisible || !sponsorPlayerButtonVisible}
          />

          <SponsorMidAdv
            firstImpressionInterval={FIRST_AD_IMPRESSION_INTERVAL}
            impressionInterval={AD_IMPRESSION_INTERVAL}
            fullscreenTarget={fullscreenTarget}
            videoActions={videoActions}
            videoState={videoState}
            contentId={content.id}
            rightholderId={content.rightholder.id}
            sponsorInStreamBannerPlace={sponsorInStreamBannerPlace}
            sponsorCornerBannerPlace={sponsorCornerBannerPlace}
            setInStreamBannerHeight={setInStreamBannerHeight}
            setCornerBannerHeight={setCornerBannerHeight}
          />

          <SponsorPauseAdv
            fullscreenTarget={fullscreenTarget}
            videoState={videoState}
            content={content}
            rightholderId={content.rightholder.id}
            sponsorVideoPlace={sponsorVideoPlace}
            muted={muted}
          />
        </>
      )}
    </>
  );
}

StreamScreenContent.propTypes = {
  fullscreenTarget: PropTypes.string,
  muted: PropTypes.bool,
  hideTitle: PropTypes.bool,
  hideShare: PropTypes.bool,
  hideLogo: PropTypes.bool,
  autoPlay: PropTypes.bool,
  autoPause: PropTypes.bool,
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    shareUrl: PropTypes.string.isRequired,
    widget: PropTypes.object,
    enableViewerIdWatermark: PropTypes.bool,

    relatedContent: PropTypes.arrayOf(PropTypes.object),
    hideRelatedContent: PropTypes.bool,
    isAdvEnabled: PropTypes.bool,
    rightholder: PropTypes.shape({
      id: PropTypes.string,
      hideRelatedContent: PropTypes.bool,
    }),
    sport: PropTypes.shape({
      title: PropTypes.string,
      slug: PropTypes.string,
      shareUrl: PropTypes.string,
    }),
    hideLogo: PropTypes.bool,
  }),
  sponsorPlayerLogoPlace: PropTypes.string,
  sponsorPlayerButtonPlace: PropTypes.string,
  sponsorInStreamBannerPlace: PropTypes.string,
  sponsorCornerBannerPlace: PropTypes.string,
  sponsorVideoPlace: PropTypes.string,

  videoElement: PropTypes.object,
  videoState: PropTypes.object,
  videoActions: PropTypes.object,
  relatedContent: PropTypes.object,
  chooseCameraButton: PropTypes.object,
  inStreamBannerHeight: PropTypes.object,
  setInStreamBannerHeight: PropTypes.func,
  cornerBannerHeight: PropTypes.object,
  setCornerBannerHeight: PropTypes.func,

  videoRef: PropTypes.object,
  pause: PropTypes.bool,
  setPause: PropTypes.func,
  preview: PropTypes.string,

  onChatAdv: PropTypes.func,
  liveSeekBar: PropTypes.object,

  sponsorChatMessagePlace: PropTypes.string,
  hlsStreamPlaylist: PropTypes.string,
  onVideoError: PropTypes.func,
};
