import getReferrer from "core/utils/getReferrer";
import { client } from "core/utils/apollo";

import {
  CreateSponsorClickMutation,
  CreateSponsorImpressionMutation,
  CreateEventMutation,
  TrackWidgetMutation,
  UpdateViewDataMutation,
} from "core/mutations";
import { getUtmFromLocalStorage } from "core/utils/utm";

/**
 * Обновить продолжительность просмотра
 */
export function updateViewData(variables) {
  return client.mutate({
    mutation: UpdateViewDataMutation,
    variables,
  });
}

/**
 * JWT токен контента
 *
 * Каждый контент имеет токен, в который зашит contentId, rightholderId и тд.
 * С этим токеном трекаются события, а сервер знает на каком контенте случилось событие.
 */
let token = null;

/**
 * Установить токен. Выполняется после загрузки контента с сервера.
 *
 * @param {String} newToken Токен который необходимо уставонить в качестве текущего.
 */
export function setToken(newToken) {
  token = newToken;
}

/**
 * Создать событие.
 *
 * Функция позволяет отправить на сервер информацию о событиях случившихся на определенном контенте.
 *
 * @param {String} category - Категория события (page, video)
 * @param {String} [action] - Действие события (load, play, pause, end, thirdQuartile, midpoint, firstQuartile)
 * @param {String} [label] - Ярлык события
 * @param {String} [value] - Значение события
 */
export function createEvent(category, action, label, value) {
  if (!token) return;

  // TODO: Временно отключил отправку эвентов (пока не придумаем как собирать эвенты)
  // https://datascreen.atlassian.net/browse/SPORTRECS-1125
  if (category !== "video") return;
  if (action !== "watch") return;

  return client.mutate({
    mutation: CreateEventMutation,
    variables: {
      token,
      category,
      action,
      label,
      value,
      referer: window?.location?.href,
      utm: getUtmFromLocalStorage(),
    },
  });
}

/**
 * Создать событие виджета
 *
 * @param {String} widgetId - ID виджета на котором случилось событие
 * @param {String} event - Название события в виджете
 */
export function trackWidget(widget, event) {
  return client.mutate({
    mutation: TrackWidgetMutation,
    variables: {
      token: widget.token,
      event,
      utm: getUtmFromLocalStorage(),
    },
  });
}

/**
 * Создать спонсорское событие
 *
 * @param {String} token - Токен в котором зашити данные креатива
 * @param {String} creativeType - Название типа креатива
 * @param {String} event - Название события которое надо засчитать
 * @param {Object} params - Прочие параметры
 */
export function createSponsorImpression(token, creativeType, event, params) {
  const referer = getReferrer();

  console.log("createSponsorImpression", creativeType, event, params);

  return client.mutate({
    mutation: CreateSponsorImpressionMutation,
    variables: {
      token,
      event,
      referer,
      utm: getUtmFromLocalStorage(),
      ...params,
    },
  });
}

/**
 * Создать спонсорский клик
 *
 * @param {String} token - Токен в котором зашити данные креатива
 * @param {String} id - ID клика
 */
export function createSponsorClick(token, id) {
  const referer = getReferrer();

  console.log("createSponsorClick");

  return client.mutate({
    mutation: CreateSponsorClickMutation,
    variables: {
      id,
      token,
      referer,
      utm: getUtmFromLocalStorage(),
    },
  });
}

/**
 * Дернуть проверочный пиксель
 */
export function trackAdPixel(url) {
  // console.log("trackAdPixel", url);

  fetch(url).catch((error) => {
    console.log("trackAdPixel error", error);
  });
}

/**
 * Отправить событие наружу из айфреймма
 *
 * @param {Object} data - Данные события
 */
export function postMessage(data) {
  if (typeof window !== "undefined") {
    if (window.parent) {
      window.parent.postMessage(data, "*");
      console.log("postMessage", data);
    }
  }
}
