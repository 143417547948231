import LocalStorage from "core/utils/localstorage";
const lsApollo = new LocalStorage(`apollo`);

function prepareScript({ script, viewerId, payment, viewer }) {
  if (!script) {
    return;
  }

  const data = {
    viewerId,
    userId: viewer?.id,
    userEmail: viewer?.email,
    paymentId: payment?.id,
    paymentAmountCurrency: payment?.amount?.currency,
    paymentAmountValue: payment?.amount?.value,
    productTitle: payment?.product?.title,
    productId: payment?.product?.id,
  };

  const __script = Object.keys(data).reduce((acc, key) => {
    return acc.replaceAll("${" + key + "}", data[key]);
  }, script);

  return __script;
}

export default function trackEvent(event, payload) {
  const { arena, payment, viewer } = payload;

  const viewerId = lsApollo.getItem("viewerId");
  const tracker = arena?.tracker;

  if (!process.browser) return;
  if (!arena?.tracker?.[event]) return;

  const __script = prepareScript({
    script: tracker[event],
    viewerId,
    payment,
    viewer,
  });

  try {
    eval(__script);
  } catch (err) {
    console.error("Arena track error", err);
  }
}
