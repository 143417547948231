import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const StyledButton = styled(Button)(() => ({
  marginRight: "auto",
}));

export default function DisableAdButton({ href }) {
  const { t } = useTranslation("core");

  return (
    <StyledButton
      href={href}
      target="_blank"
      color="primary"
      variant="contained"
      size="small"
    >
      {t("disableAdButton.text")}
    </StyledButton>
  );
}

DisableAdButton.propTypes = {
  href: PropTypes.string.isRequired,
};
