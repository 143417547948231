import { useMemo } from "react";
import debounce from "core/utils/debounce";
import { updateViewData } from "core/utils/tracker";

export default function useUpdateViewData({ view, content }) {
  return useMemo(() => {
    let version = 1;

    function track(data) {
      version += 1;

      if (data.videoPosition) {
        Object.assign(data.videoPosition, {
          contentId: content.id,
        });
      }

      if (data.trackDuration) {
        Object.assign(data.trackDuration, {
          version,
          token: view.token,
        });
      }

      if (!data.trackDuration && !data.videoPosition) {
        return;
      }

      updateViewData(data);
    }

    return {
      track,
      trackDebounce: debounce(track, 20 * 1000),
    };
  }, [view, content]);
}
