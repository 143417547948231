import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import ChatBans from "core/components/ChatBans";
import ChatForm from "core/components/ChatForm";
import ChatMessageList from "core/components/ChatMessageList";
import ChatHeader from "core/components/ChatHeader";
import ChatHeaderMenu from "core/components/ChatHeaderMenu";
import Skeleton from "@mui/material/Skeleton";
import Paper from "@mui/material/Paper";

const PREFIX = "Chat";

const classes = {
  root: `${PREFIX}-root`,
  rootSkeleton: `${PREFIX}-rootSkeleton`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  height: "100%",
  width: "100%",
  position: "relative",
  backgroundColor: "rgba(255, 255, 255, 0.03)",
  overflow: "hidden",
  [theme.breakpoints.up("md")]: {
    borderRadius: 0,
  },

  [`& .${classes.rootSkeleton}`]: {
    // height: "100%",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      borderRadius: 0,
    },
  },
}));

const CHAT = "CHAT";
const BANNED_USERS = "BANNED_USERS";

function Chat({ content, loading, viewer, inPlayer, onOpenSignIn }) {
  const [tab, setTab] = useState(CHAT);
  const [chatMenuAnchorEl, setChatMenuAnchorEl] = useState(null);

  const onCloseChatMenu = () => setChatMenuAnchorEl(null);
  const onClickChatMenu = (event) => {
    setChatMenuAnchorEl(event.currentTarget);
  };

  const onClickChatMenuBannedUsers = () => {
    setTab(BANNED_USERS);
    setChatMenuAnchorEl(null);
  };

  const onClickChatMenuBack = () => {
    setTab(CHAT);
  };

  if (loading) {
    return <Skeleton variant="rectangular" className={classes.rootSkeleton} />;
  }

  if (!content?.chatRoom) {
    // Если нет чатрума, значит это не стрим
    return null;
  }

  if (!content.chatRoom.enabled) {
    // Если чат выключен, показываем пустоту
    return null;
  }

  const viewerIsAdmin = content.chatRoom.viewerIsAdmin;

  return (
    <StyledPaper>
      <ChatHeader
        tab={tab}
        viewer={viewer}
        viewerIsAdmin={viewerIsAdmin}
        onClickChatMenuBack={onClickChatMenuBack}
        onClickChatMenu={onClickChatMenu}
      />

      {tab === BANNED_USERS && <ChatBans rowsPerPage={12} />}

      {tab === CHAT && (
        <ChatMessageList
          key={viewer ? "viewer" : "guest"} // Это нужно, чтобы после входа, пересоздались подписки (иначе так и останутся висеть подписки от имени гостя)
          viewer={viewer}
          viewerIsAdmin={viewerIsAdmin}
          chatRoomId={content.chatRoom.id}
          inPlayer={inPlayer}
        />
      )}

      {tab === CHAT && (
        <ChatForm
          key={viewer ? "viewer-form" : "guest-form"} // Это нужно, чтобы после входа, пересоздались подписки (иначе так и останутся висеть подписки от имени гостя)
          viewer={viewer}
          content={content}
          chatRoom={content.chatRoom}
          rightholderId={content.rightholder.id}
          onOpenSignIn={onOpenSignIn}
        />
      )}

      {viewerIsAdmin && (
        <ChatHeaderMenu
          anchorEl={chatMenuAnchorEl}
          open={Boolean(chatMenuAnchorEl)}
          onClose={onCloseChatMenu}
          onClickChatMenuBannedUsers={onClickChatMenuBannedUsers}
        />
      )}
    </StyledPaper>
  );
}

Chat.propTypes = {
  inPlayer: PropTypes.bool,
  loading: PropTypes.bool,
  onOpenSignIn: PropTypes.func,
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    chatRoom: PropTypes.shape({
      id: PropTypes.string.isRequired,
      enabled: PropTypes.bool,
      viewerIsAdmin: PropTypes.bool,
    }),
    rightholder: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    widget: PropTypes.object,
  }),
  viewer: PropTypes.object,
};

export default Chat;
