import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";

const StyledButton = styled(Button)(() => ({
  marginRight: "auto",
}));

export default function SkipAdButton({ onClick, canSkip, skipTimeout }) {
  const { t } = useTranslation("core");

  return (
    <StyledButton
      onClick={onClick}
      variant="contained"
      disabled={!canSkip}
      size="small"
    >
      {canSkip ? t("adv.skipAd") : t("adv.skipAfter", { count: skipTimeout })}
    </StyledButton>
  );
}

SkipAdButton.propTypes = {
  onClick: PropTypes.func,
  canSkip: PropTypes.bool,
  skipTimeout: PropTypes.number,
};
