import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import { useTranslation } from "react-i18next";
// import HistoryIcon from "@mui/icons-material/History";
import { Link } from "react-router-dom";
import { getAvatarSrcSet } from "core/utils/getSrcSet";

const DEFAULT = "DEFAULT";
const HIGH = "HIGH";
const MAXRES = "MAXRES";
const MEDIUM = "MEDIUM";

const PREFIX = "UserMenu";

const classes = {
  avatarButton: `${PREFIX}-avatarButton`,
  avatar: `${PREFIX}-avatar`,
  listItemIcon: `${PREFIX}-listItemIcon`,
};

const Root = styled("span")(({ theme }) => ({
  [`& .${classes.avatarButton}`]: {
    padding: 9,
    [theme.breakpoints.up("sm")]: {
      padding: 4,
    },
  },

  [`& .${classes.avatar}`]: {
    width: 30,
    height: 30,
    borderRadius: 30,

    [theme.breakpoints.up("sm")]: {
      width: 40,
      height: 40,
      borderRadius: 40,
    },
  },

  [`& .${classes.listItemIcon}`]: {
    marginRight: 16,
    minWidth: 40,
    justifyContent: "center",
  },
}));

export default function UserMenu({ viewer, menuItems }) {
  const { t } = useTranslation("arena");

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Root>
      <Tooltip title={t("userMenu.tooltip")}>
        <IconButton
          className={classes.avatarButton + " js-user-settings"}
          onClick={handleClick}
          edge="end"
          size="large"
        >
          <Avatar
            src={viewer.photo?.url}
            srcSet={getAvatarSrcSet(viewer?.photo?.thumbnails)}
            className={classes.avatar}
            alt="Profile photo"
          >
            {(viewer.fullName || viewer.email)[0].toUpperCase()}
          </Avatar>
        </IconButton>
      </Tooltip>

      <Menu
        MenuListProps={{ disablePadding: true }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <ListItem divider>
          <ListItemAvatar>
            <Avatar src={viewer.photo?.url}>
              {(viewer.fullName || viewer.email)[0].toUpperCase()}
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={viewer.fullName} secondary={viewer.email} />
        </ListItem>

        {menuItems.map((menuItem, index) => (
          <MenuItem
            component={menuItem.to ? Link : undefined}
            onClick={() => {
              menuItem.onClick();
              handleClose();
            }}
            key={index}
            to={menuItem.to}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <menuItem.icon color="disabled" />
            </ListItemIcon>
            <ListItemText primary={menuItem.primary} />
          </MenuItem>
        ))}
      </Menu>
    </Root>
  );
}

UserMenu.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.elementType.isRequired,
      onClick: PropTypes.func.isRequired,
      primary: PropTypes.string.isRequired,
      to: PropTypes.string,
    })
  ),
  viewer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    fullName: PropTypes.string,
    email: PropTypes.string.isRequired,
    photo: PropTypes.shape({
      url: PropTypes.string,
      thumbnails: PropTypes.arrayOf(
        PropTypes.shape({
          format: PropTypes.oneOf([DEFAULT, MEDIUM, HIGH, MAXRES]).isRequired,
          url: PropTypes.string.isRequired,
          webpUrl: PropTypes.string,
        })
      ),
    }),
  }),
};
