import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";

export default function ArenaButton({
  align,
  text,
  url,
  color,
  variant,
  size,
  paddingTop,
  paddingBottom,
  maxWidth,
  fullWidth,
}) {
  return (
    <div
      style={{
        paddingTop,
        paddingBottom,
        maxWidth,
        margin: "auto",
        textAlign: align,
      }}
    >
      <Button
        href={url}
        color={color}
        variant={variant}
        size={size}
        fullWidth={fullWidth}
      >
        {text}
      </Button>
    </div>
  );
}

ArenaButton.propTypes = {
  fullWidth: PropTypes.bool,
  align: PropTypes.string,
  size: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
  maxWidth: PropTypes.string,
  code: PropTypes.string,
  arena: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};
