import React from "react";
import PropTypes from "prop-types";
import HomepageTitle from "arena/components/HomepageTitle";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";

import AndroidTv from "./badges/AndroidTv";
import GooglePlay from "./badges/GooglePlay";
import AppStore from "./badges/AppStore";
import Roku from "./badges/Roku";
import AppleTV from "./badges/AppleTv";
import Chromecast from "./badges/Chromecast";

const PREFIX = "ArenaAppsList";

const classes = {
  title: `${PREFIX}-title`,
  itemLink: `${PREFIX}-title`,
  itemContainer: `${PREFIX}-itemContainer`,
};

const platforms = {
  GooglePlay,
  AppStore,
  Roku,
  AndroidTv,
  AppleTV,
  Chromecast,
};

const Root = styled("div")(({ theme }) => ({
  position: "relative",
  padding: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(3),
  },
  [`& .${classes.itemContainer}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  [`& .${classes.title}`]: {
    padding: theme.spacing(5, 0, 1),
  },

  [`& .${classes.itemLink}`]: {
    transition: "all 0.3s",
    display: "flex",
    // opacity: 0.8,
    filter: "saturate(30%) brightness(50%)",
    "&:hover": {
      filter: "none",
      // opacity: 1,
    },
  },
}));

const badgeStyle = { width: "100%", maxWidth: "170px" };

export default function ArenaAppsList({ title, links }) {
  return (
    <Root>
      {title && <HomepageTitle className={classes.title} title={title} />}
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        {Object.keys(platforms)
          .map((platform) => {
            const PlatformBadge = platforms[platform];

            const url = links[platform];

            if (!url) {
              return null;
            }

            return (
              <Grid
                item
                xs={6}
                sm={4}
                md={3}
                lg={2}
                key={platform}
                className={classes.itemContainer}
              >
                <Link className={classes.itemLink} target="_blank" href={url}>
                  <PlatformBadge style={badgeStyle} />
                </Link>
              </Grid>
            );
          })
          .filter(Boolean)}
      </Grid>
    </Root>
  );
}

ArenaAppsList.defaultProps = {
  links: {},
};

ArenaAppsList.propTypes = {
  title: PropTypes.string,
  links: PropTypes.object,
};
