import React from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import AppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import DonationProgress from "arena/components/DonationProgress";
import { Link } from "react-router-dom";

const Root = styled("div")({
  margin: "0 auto auto",
  width: "100%",
  maxWidth: 400,
});

export default function Donation({ product }) {
  if (!product) return null;

  return (
    <Root>
      <Card
        sx={{
          backdropFilter: "blur(12px)",
          backgroundColor: "rgba(255,255,255,.05)",
        }}
      >
        <CardActionArea component={Link} to={product.siteUrl}>
          <AppBar
            sx={{
              backdropFilter: "blur(12px)",
              backgroundColor: "rgba(0,0,0,.25)",
            }}
            color="default"
            elevation={0}
            position="static"
          >
            <CardHeader
              title={product.title}
              titleTypographyProps={{ align: "center" }}
            />
          </AppBar>
          <CardContent>
            <DonationProgress product={product} />
          </CardContent>
        </CardActionArea>
      </Card>
    </Root>
  );
}

Donation.propTypes = {
  product: PropTypes.shape({
    siteUrl: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
};
