import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
// import Avatar from "@mui/material/Avatar";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import InstallPWAButton from "arena/components/InstallPWAButton";

const PREFIX = "AppDrawer";

const classes = {
  drawerPaper: `${PREFIX}-drawerPaper`,
  title: `${PREFIX}-title`,
  backButton: `${PREFIX}-backButton`,
  scrollable: `${PREFIX}-scrollable`,
  listItemIcon: `${PREFIX}-listItemIcon`,
  listItemSelected: `${PREFIX}-listItemSelected`,
};

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  [`& .${classes.drawerPaper}`]: {
    zIndex: 1,
    width: "100%",
    // maxWidth: "320px",

    backdropFilter: "blur(12px)",
    backgroundColor: "rgba(0,0,0,.65)",
  },

  [`& .${classes.title}`]: {
    marginLeft: 12,
    marginRight: "auto",
  },

  [`& .${classes.backButton}`]: {
    marginLeft: -6,
    [theme.breakpoints.up("sm")]: {
      marginLeft: -12,
    },
  },

  [`& .${classes.scrollable}`]: {
    // height: "100%",
    display: "flex",
    flexDirection: "column",

    overflowX: "hidden",
    overflowY: "auto",

    msOverflowStyle: "none", // IE 10+
    scrollbarWidth: "none", // Firefox
    "&::-webkit-scrollbar": {
      display: "none", // Safari and Chrome
    },
  },

  [`& .${classes.listItemIcon}`]: {
    marginRight: 16,
    minWidth: 40,
    justifyContent: "center",
  },

  [`& .${classes.listItemSelected}`]: {
    backgroundColor: "rgba(255, 255, 255, 0.06) !important",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.06) !important",
    },
  },
}));

function getMenuItemProps(item) {
  const isExternalLink = item.path.startsWith("http");

  if (isExternalLink) {
    return {
      href: item.path,
      component: "a",
      target: "_blank",
    };
  } else {
    return {
      to: item.path,
      component: Link,
    };
  }
}

export default function AppDrawer({ open, onClose, onClickMenuItem, arena }) {
  const { t } = useTranslation("arena");
  const location = useLocation();
  const arenaMenuItems = arena.menu;

  return (
    <StyledDrawer
      variant="temporary"
      anchor="left"
      open={open}
      onClose={onClose}
      classes={{
        paper: classes.drawerPaper,
      }}
      ModalProps={{
        keepMounted: true,
        BackdropProps: {
          invisible: true,
        },
      }}
    >
      <Toolbar>
        <IconButton
          onClick={onClose}
          className={classes.backButton}
          size="large"
        >
          <ArrowBackIcon />
        </IconButton>

        <Typography variant="h5" className={classes.title}>
          {t("appDrawer.title")}
        </Typography>
      </Toolbar>

      <div className={classes.scrollable}>
        <List dense disablePadding>
          {arenaMenuItems.map((item) => {
            const selected = item.path === location?.pathname;
            const props = getMenuItemProps(item);

            return (
              <ListItem
                button
                key={item.label}
                onClick={(event) => onClickMenuItem(event, item)}
                selected={selected}
                classes={{
                  root: classes.listItem,
                  selected: classes.listItemSelected,
                }}
                {...props}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  {/* <AccountCircleIcon color="disabled" /> */}
                  {/* {item.icon && <Avatar src={item.icon.url} />} */}
                </ListItemIcon>
                <ListItemText
                  primary={item.label}
                  primaryTypographyProps={{ variant: "subtitle1" }}
                />
              </ListItem>
            );
          })}
        </List>
      </div>

      <InstallPWAButton arena={arena} />
    </StyledDrawer>
  );
}

AppDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onClickMenuItem: PropTypes.func.isRequired,
  arena: PropTypes.shape({
    logo: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
    menu: PropTypes.arrayOf(
      PropTypes.shape({
        path: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};
