import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import Slider from "@mui/material/Slider";
import { useTranslation } from "react-i18next";
import PlayerFooterButton from "core/components/PlayerFooterButton";

const PREFIX = "MuteButton";

const classes = {
  slider: `${PREFIX}-slider`,
  track: `${PREFIX}-track`,
  thumb: `${PREFIX}-thumb`,
  rail: `${PREFIX}-rail`,
};

const Root = styled("span")({
  display: "flex",
  flexWrap: "nowrap",
  paddingLeft: 10,
  alignItems: "center",

  [`& .${classes.slider}`]: {
    marginRight: 10,
    width: 50,
    "@media (max-width: 450px)": {
      display: "none",
    },
  },
  [`& .${classes.track}`]: {
    backgroundColor: "#fff",
    border: 0,
  },
  [`& .${classes.thumb}`]: {
    backgroundColor: "#fff",
    "&:hover": {
      boxShadow: "0px 0px 0px 9px rgba(255, 255, 255, 0.16)",
    },
  },
  [`& .${classes.rail}`]: {
    backgroundColor: "#fff",
  },
});

export default function MuteButton({ videoState, videoActions }) {
  const { t } = useTranslation("core");

  const [isOpen, setOpen] = useState(true);
  const [isSeeking, setIsSeeking] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);

  if (!videoState) return null;

  const volume = videoState.volume;
  const isMuted = videoState.muted;
  const Icon = isMuted ? VolumeOffIcon : VolumeUpIcon;
  const title = isMuted ? t("unmute") : t("mute");
  const displaySlider = isOpen && !isMuted;

  const onChangeCommitted = () => {
    setIsSeeking(false);

    if (!isMouseOver) {
      setOpen(false);
    }
  };

  const onChange = (event, value) => {
    setIsSeeking(true);

    videoActions.setVolume(value);
  };

  const onMouseLeave = () => {
    setIsMouseOver(false);

    if (isSeeking) {
      return;
    }

    setOpen(false);
  };

  const onMouseEnter = () => {
    setIsMouseOver(true);
    setOpen(true);
  };

  return (
    <Root
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      onBlur={() => setOpen(false)}
      onFocus={() => setOpen(true)}
    >
      {displaySlider && (
        <Slider
          classes={{
            root: classes.slider,
            track: classes.track,
            thumb: classes.thumb,
            rail: classes.rail,
          }}
          value={(!isMuted && volume) || 0}
          onChange={onChange}
          onChangeCommitted={onChangeCommitted}
          min={0}
          max={1}
          step={0.01}
        />
      )}

      <PlayerFooterButton
        title={title}
        onClick={() => videoActions.toggleMute()}
      >
        <Icon />
      </PlayerFooterButton>
    </Root>
  );
}

MuteButton.propTypes = {
  videoState: PropTypes.object,
  videoActions: PropTypes.object,
};
