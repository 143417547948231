import React from "react";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack";
import DialogContent from "@mui/material/DialogContent";
import DialogHeader from "core/components/DialogHeader";
import MenuItemDialogItem from "./MenuItemDialogItem";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MenuItemDialog({ open, onClose, menuItem }) {
  return (
    <Dialog
      fullWidth
      //   disableScrollLock
      TransitionComponent={Transition}
      fullScreen
      open={open}
      onClose={onClose}
      scroll="paper"
    >
      {menuItem && (
        <>
          <DialogHeader title={menuItem.label} onClose={onClose} />
          <DialogContent sx={{ pl: 0, pr: 0 }}>
            <Container disableGutters>
              <Stack spacing={4}>
                {menuItem.items.map((item) => (
                  <MenuItemDialogItem
                    item={item}
                    key={item.id}
                    onClick={onClose}
                  />
                ))}
              </Stack>
            </Container>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}

MenuItemDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  menuItem: PropTypes.shape({
    label: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string,
      })
    ),
  }),
};
