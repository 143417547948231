import React from "react";
import { styled } from "@mui/material/styles";
import SubscribeStreamButton from "core/components/SubscribeStreamButton";
import ContentPlayer from "arena/components/ContentPlayer";
import ContentBody from "arena/components/ContentBody";
import ErrorScreen from "core/components/ErrorScreen";
import Chat from "core/components/Chat";
import useScrollToTop from "core/hooks/useScrollToTop";
import useWsLinkStatus from "core/hooks/useWsLinkStatus";

import { ContentQuery, ContentStreamStatusQuery } from "arena/queries";
import { useApolloClient, useSubscription, useQuery } from "@apollo/client";
import ReactGA from "react-ga4";
import { createEvent, setToken } from "core/utils/tracker";
import {
  Navigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import NoMatchScreen from "arena/components/NoMatchScreen";
import { useViewer } from "core/components/ViewerProvider";
import ContentHelmet from "arena/components/ContentHelmet";
import SponsorChatTopBanner from "core/components/SponsorChatTopBanner";
import addAdToChat from "core/utils/addAdToChat";
import { ContentStreamStatusChangedSubscription } from "core/subscriptions";
import { useArena } from "core/components/ArenaProvider";

const PREFIX = "Stream";

const STATUS_POLL_INTERVAL = 10 * 1000;

const classes = {
  top: `${PREFIX}-top`,
  player: `${PREFIX}-player`,
  chat: `${PREFIX}-chat`,
  chatInner: `${PREFIX}-chatInner`,
  bottom: `${PREFIX}-bottom`,
  body: `${PREFIX}-body`,
};

const Root = styled("div")(({ theme }) => ({
  position: "relative",
  padding: theme.spacing(0),

  [`& .${classes.top}`]: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },

  [`& .${classes.player}`]: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      // marginRight: theme.spacing(3),
    },
  },

  [`& .${classes.chat}`]: {
    flexShrink: 0,
    minHeight: 400,
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
      width: SIDEBAR_WIDTH,
    },
  },

  [`& .${classes.chatInner}`]: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },

  [`& .${classes.bottom}`]: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },

  [`& .${classes.body}`]: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(3),
    },
  },
}));

const SITE = "SITE";
const STREAM = "STREAM";
const SIDEBAR_WIDTH = 345;
const STREAM_ON_SITE = "STREAM_ON_SITE";

export default function Stream() {
  const arena = useArena();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const params = useParams();
  const { contentId } = params;
  const startOffset = searchParams.get("startOffset")
    ? Number(searchParams.get("startOffset"))
    : 0;

  const { online: wsOnline } = useWsLinkStatus();

  const viewer = useViewer();
  const client = useApolloClient();

  useScrollToTop();

  const onCompleted = (data) => {
    if (data.content) {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname,
        title: data.content.title,
      });

      setToken(data.content.access.token);
      createEvent("page", "load");
    }
  };

  const { data, loading, error, refetch } = useQuery(ContentQuery, {
    variables: {
      contentId,
    },
    onCompleted,
  });

  useSubscription(ContentStreamStatusChangedSubscription, {
    skip: !process.browser,
    variables: {
      id: contentId,
    },
  });

  /**
   * При отключении websocket
   * что бы не пропустить начало или конец стрима
   * запускаем polling
   */
  useQuery(ContentStreamStatusQuery, {
    skip: wsOnline || !data,
    pollInterval: wsOnline ? 0 : STATUS_POLL_INTERVAL,
    variables: {
      contentId,
    },
  });

  const content = data?.content;

  if (error) {
    return <ErrorScreen error={error} />;
  }

  if (!content && !loading) {
    return <NoMatchScreen />;
  }

  if (content && content.type !== STREAM) {
    return (
      <Navigate
        to={content.siteUrl.replace("stream", content.type.toLowerCase())}
      />
    );
  }

  const chatEnabled = content?.chatRoom?.enabled && arena.isRegistrationEnabled;

  const onChatAdv = (sponsorChatMessage) => {
    addAdToChat(content, client, sponsorChatMessage);
  };

  return (
    <>
      <Root data-testid="Stream" key={contentId}>
        <ContentHelmet content={content} arena={arena} />

        <div className={classes.top}>
          <div className={classes.player}>
            <ContentPlayer
              sponsorCornerBannerPlace={STREAM_ON_SITE}
              sponsorInStreamBannerPlace={STREAM_ON_SITE}
              sponsorPlayerLogoPlace={STREAM_ON_SITE}
              sponsorPlayerButtonPlace={STREAM_ON_SITE}
              sponsorVideoPlace={STREAM_ON_SITE}
              sponsorChatMessagePlace={STREAM_ON_SITE}
              hideTitle
              autoPlay
              content={content}
              loading={loading}
              subscribeButton={
                content && <SubscribeStreamButton content={content} />
              }
              onChatAdv={onChatAdv}
              refetch={refetch}
              startOffset={startOffset}
            />
          </div>

          {chatEnabled && (
            <div className={classes.chat}>
              <div className={classes.chatInner}>
                {content?.isAdvEnabled && (
                  <SponsorChatTopBanner
                    contentId={contentId}
                    rightholderId={content.rightholder.id}
                    place={SITE}
                  />
                )}

                <Chat content={content} loading={loading} viewer={viewer} />
              </div>
            </div>
          )}
        </div>

        <div className={classes.bottom}>
          <div className={classes.body}>
            <ContentBody content={content} loading={loading} padding />
          </div>
        </div>
      </Root>
    </>
  );
}
