import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { useArena } from "core/components/ArenaProvider";

export default function ArenaHelmet({ title, description, imageUrl }) {
  const arena = useArena();

  const helmetTitle = title || arena.title;
  const helmetDescription = description || arena.description;
  const image = imageUrl || arena?.logo?.url;

  return (
    <Helmet>
      <title>{helmetTitle}</title>
      <meta name="twitter:title" content={helmetTitle} />
      <meta property="og:title" content={helmetTitle} />

      {helmetDescription && (
        <meta name="description" content={helmetDescription} />
      )}

      {helmetDescription && (
        <meta property="og:description" content={helmetDescription} />
      )}

      {helmetDescription && (
        <meta name="twitter:description" content={helmetDescription} />
      )}

      <meta name="twitter:card" content="summary_large_image" />

      {image && <meta property="og:image" content={image} />}
      {image && <link rel="image_src" href={image} />}
      {image && <meta name="twitter:image" content={image} />}
      {image && <meta property="vk:image" content={image} />}
    </Helmet>
  );
}

ArenaHelmet.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
};
