import { UAParser } from "ua-parser-js";

export default function getUserAgent() {
  const { device, os, browser } = new UAParser().getResult();

  return {
    browser,
    userDeviceName: device && normalizeDeviceName(device.model, os.name),
    userDeviceOs: os && normalizeDeviceOs(os.name),
    userDeviceType: device && normalizeDeviceType(device.type),
  };
}

function normalizeDeviceName(name, os) {
  if (os === "Mac OS") {
    return "Macintosh";
  }
  return name || "PC";
}

// AIX, Amiga OS, Android, Arch, Bada, BeOS, BlackBerry, CentOS, Chromium OS, Contiki,
// Fedora, Firefox OS, FreeBSD, Debian, DragonFly, Gentoo, GNU, Haiku, Hurd, iOS,
// Joli, Linpus, Linux, Mac OS, Mageia, Mandriva, MeeGo, Minix, Mint, Morph OS, NetBSD,
// Nintendo, OpenBSD, OpenVMS, OS/2, Palm, PC-BSD, PCLinuxOS, Plan9, Playstation, QNX, RedHat,
// RIM Tablet OS, RISC OS, Sailfish, Series40, Slackware, Solaris, SUSE, Symbian, Tizen,
// Ubuntu, Unix, VectorLinux, WebOS, Windows [Phone/Mobile], Zenwalk
function normalizeDeviceOs(os) {
  switch (os) {
    case "iOS":
      return "iOS";
    case "Android":
      return "Android";
    case "Windows":
    case "Windows Phone":
    case "Windows Mobile":
      return "Windows";
    case "Mac OS":
      return "macOS";
    default:
      return undefined;
  }
}

// console, mobile, tablet, smarttv, wearable, embedded
function normalizeDeviceType(type) {
  switch (type) {
    case "mobile":
      return "Mobile";
    case "tablet":
      return "Tablet";
    default:
      return "Web";
  }
}
