import React from "react";
import PropTypes from "prop-types";
import { InView } from "react-intersection-observer";
import CircularProgress from "@mui/material/CircularProgress";

export default function LoadMore({ onLoadMore }) {
  const onChange = (inView) => {
    if (inView) {
      onLoadMore();
    }
  };

  return (
    <InView
      as="div"
      rootMargin="30% 0px"
      threshold={0.4}
      triggerOnce
      onChange={onChange}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress color="secondary" />
      </div>
    </InView>
  );
}

LoadMore.propTypes = {
  onLoadMore: PropTypes.func.isRequired,
};
