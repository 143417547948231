import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const Root = styled("div", {
  shouldForwardProp: (prop) => !["visible"].includes(prop),
})(({ visible }) => ({
  position: "absolute",
  left: 120,
  right: 120,
  bottom: visible ? 80 : 20,
  textAlign: "center",
  transition: "all ease 0.225s",
}));

export default function Captions({ cues, visible }) {
  if (cues.length === 0) return null;

  return (
    <Root visible={visible}>
      {cues.map((text, index) => (
        <Typography
          key={index}
          component="span"
          sx={{
            background: "rgba(0,0,0,0.75)",
            padding: "2px 5px",
            fontSize: {
              sm: "1.2rem",
              md: "1.5rem",
              lg: "2rem",
            },
          }}
        >
          {text}
        </Typography>
      ))}
    </Root>
  );
}

Captions.propTypes = {
  visible: PropTypes.bool,
  cues: PropTypes.arrayOf(PropTypes.string),
};

Captions.defaultProps = {
  cues: [],
};
