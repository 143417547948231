import React from "react";
import PropTypes from "prop-types";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useTranslation } from "react-i18next";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";

export default function ChatHeaderMenu({
  anchorEl,
  open,
  onClose,
  onClickChatMenuBannedUsers,
}) {
  const { t } = useTranslation("core");

  return (
    <Menu
      id="chat-header-menu"
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={onClose}
      MenuListProps={{
        dense: true,
      }}
    >
      <MenuItem dense onClick={onClickChatMenuBannedUsers}>
        <ListItemIcon style={{ minWidth: 40 }}>
          <SupervisorAccountIcon color="disabled" />
        </ListItemIcon>
        <ListItemText primary={t("chat.bannedFans")} />
      </MenuItem>
    </Menu>
  );
}

ChatHeaderMenu.propTypes = {
  anchorEl: PropTypes.any,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onClickChatMenuBannedUsers: PropTypes.func,
};
