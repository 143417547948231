import React from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const DEFAULT = "DEFAULT";
const HIGH = "HIGH";
const MAXRES = "MAXRES";
const MEDIUM = "MEDIUM";

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => !["thumbnails", "size"].includes(prop),
})(({ thumbnails, size }) => {
  const styles = {};

  if (thumbnails) {
    const thumbnail =
      thumbnails.find((t) => t.format === size) ||
      thumbnails.find((t) => t.format === HIGH) ||
      thumbnails.find((t) => t.format === MAXRES) ||
      thumbnails.find((t) => t.format === MEDIUM) ||
      thumbnails.find((t) => t.format === DEFAULT);

    if (thumbnail) {
      styles["backgroundImage"] = `url('${thumbnail.url}')`;
      styles["backgroundSize"] = "cover";

      if (thumbnail.webpUrl) {
        styles[`@supports (background-image: url('${thumbnail.webpUrl}'))`] = {
          backgroundImage: `url('${thumbnail.webpUrl}')`,
        };
      }
    }
  }

  return styles;
});

export default function ThumbnailCardMedia({ thumbnails, ...props }) {
  return <StyledBox thumbnails={thumbnails} {...props} />;
}

ThumbnailCardMedia.defaultProps = {
  size: HIGH,
};

ThumbnailCardMedia.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  thumbnails: PropTypes.arrayOf(
    PropTypes.shape({
      format: PropTypes.oneOf([DEFAULT, MEDIUM, HIGH, MAXRES]).isRequired,
      url: PropTypes.string.isRequired,
      webpUrl: PropTypes.string,
    })
  ),
};
