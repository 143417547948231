import React, { useState } from "react";
import PropTypes from "prop-types";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import SubtitlesOffIcon from "@mui/icons-material/SubtitlesOff";
import { useTranslation } from "react-i18next";
import PlayerFooterButton from "core/components/PlayerFooterButton";

function SubtitlesButton({ videoActions, videoState }) {
  const { t } = useTranslation("core");

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((v) => !v);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleClickItem = (track) => () => {
    videoActions.setTextTrack(track.language);
    setOpen(false);
  };

  if (!videoState) return null;

  const { textTracks = [] } = videoState;

  if (textTracks.length === 0) return null;

  const activeTrack = textTracks.find((t) => t.mode !== "disabled");

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box sx={{ position: "relative" }}>
        <PlayerFooterButton
          title={open ? "" : t("subtitlesButton.title")}
          onClick={handleClick}
        >
          {activeTrack ? <SubtitlesIcon /> : <SubtitlesOffIcon />}
        </PlayerFooterButton>

        {open && (
          <Paper
            sx={{
              backgroundColor: "rgba(0,0,0,0.75)",
              position: "absolute",
              bottom: 60,
              right: -12,
              overflow: "hidden",
            }}
          >
            <List disablePadding dense>
              {textTracks.map((track, key) => (
                <ListItem
                  key={key}
                  button
                  selected={
                    activeTrack && activeTrack.language === track.language
                  }
                  onClick={handleClickItem(track)}
                >
                  {track.label}
                </ListItem>
              ))}
              <ListItem
                button
                selected={!activeTrack}
                onClick={handleClickItem("disabled")}
              >
                {t("subtitlesButton.disabled")}
              </ListItem>
            </List>
          </Paper>
        )}
      </Box>
    </ClickAwayListener>
  );
}

SubtitlesButton.propTypes = {
  videoState: PropTypes.object,
  videoActions: PropTypes.object,
};

export default SubtitlesButton;
