import { useState, useEffect } from "react";

/**
 * События на которые реагирует хук
 */
const EVENTS = [
  "mousemove",
  "mousedown",
  "keydown",
  "touchstart",
  "touchmove",
  "scroll",
];

/**
 * Кол-во миллисекунд, которое должно пройти после последнего взаимодействия пользователя с элементом
 */
const TIMEOUT = 5000;

/**
 * Хук позволяет определить взаимодействует ли пользователь с DOM элементом.
 * idle === true когда пользователь бездействует
 *
 * Надо помнить про особенности поведения событий в Safari на IOS.
 * Если на onmousemove меняется контент страницы, то клик не случается.
 * Поэтому все кликабельные элемены НЕ ДОЛЖНЫ лежать внутри элемента target.
 * Иначе при первом клике на них ни чего не произойдет, а только значение idle поменяется.
 * Подробнее об этом: https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariWebContent/HandlingEvents/HandlingEvents.html
 *
 * @param {Object} params
 * @param {String} params.target - ID DOM элемена на который навешивать события
 * @returns {Boolean}
 */
export default function useIdle({ target }) {
  const [idle, setIdle] = useState(true);

  useEffect(() => {
    if (typeof window === "undefined") return;

    let timeout = null;

    const handleEvent = (event) => {
      if (idle) {
        event.stopPropagation();
        event.preventDefault();
        setIdle(false);
      }
      clearTimeout(timeout);
      setIdleTimeout();
    };

    const setIdleTimeout = () => {
      timeout = setTimeout(() => {
        setIdle(true);
      }, TIMEOUT);
    };

    const el = document.getElementById(target) || window;

    EVENTS.forEach((event) => el.addEventListener(event, handleEvent));

    setIdleTimeout();

    return () => {
      EVENTS.forEach((event) => el.removeEventListener(event, handleEvent));

      clearTimeout(timeout);
    };
  }, [target, idle]);

  return idle;
}
