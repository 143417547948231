import React from "react";
import PropTypes from "prop-types";
import ArenaHelmet from "arena/components/ArenaHelmet";

export default function TagHelmet({ tag }) {
  const imageUrl = tag?.image?.url;

  return (
    <ArenaHelmet
      title={tag.title}
      description={tag.description}
      imageUrl={imageUrl}
    />
  );
}

TagHelmet.propTypes = {
  tag: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
  }),
};
