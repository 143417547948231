const DONATION = "DONATION";

/**
 * Получить минимальную цену/донат продукта
 *
 * @param {Object} product
 * @param {Array} product.prices
 * @param {Array} product.donates
 */
export default function getMinPrice(product) {
  let prices = product.prices;

  if (product.type === DONATION) {
    prices = product.donates.map((donate) => {
      const minAmount = Math.min(...donate.amounts);
      return {
        value: minAmount,
        currency: donate.currency,
        providers: donate.providers,
      };
    });
  }

  return prices.reduce((previousPrice, currentPrice) => {
    if (currentPrice.value < previousPrice.value) {
      return currentPrice;
    }

    return previousPrice;
  }, prices[0]);
}
