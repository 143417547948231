import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTranslation } from "react-i18next";
const PREFIX = "ChatHeader";

const classes = {
  title: `${PREFIX}-title`,
  button: `${PREFIX}-button`,
};

const StyledAppBar = styled(AppBar)({
  zIndex: 2,

  [`& .${classes.title}`]: {
    flex: 1,
  },
  [`& .${classes.button}`]: {
    marginRight: -10,
  },
});

const CHAT = "CHAT";
const BANNED_USERS = "BANNED_USERS";

function ChatHeader({
  tab,
  viewer,
  viewerIsAdmin,
  onClickChatMenuBack,
  onClickChatMenu,
}) {
  const [t] = useTranslation("core");

  return (
    <StyledAppBar color="default" position="absolute">
      <Toolbar variant="dense">
        <Typography variant="subtitle1" className={classes.title}>
          {tab === CHAT && t("chat.chat")}
          {tab === BANNED_USERS && t("chat.bannedFans")}
        </Typography>

        {tab === BANNED_USERS && (
          <IconButton
            size="small"
            className={classes.button}
            onClick={onClickChatMenuBack}
          >
            <CloseIcon color="disabled" />
          </IconButton>
        )}

        {tab === CHAT && viewer && viewerIsAdmin && (
          <IconButton
            size="small"
            className={classes.button}
            onClick={onClickChatMenu}
          >
            <MoreVertIcon color="disabled" />
          </IconButton>
        )}
      </Toolbar>
    </StyledAppBar>
  );
}

ChatHeader.propTypes = {
  tab: PropTypes.oneOf([CHAT, BANNED_USERS]).isRequired,
  viewer: PropTypes.object,
  viewerIsAdmin: PropTypes.bool,
  onClickChatMenuBack: PropTypes.func,
  onClickChatMenu: PropTypes.func,
};

export default ChatHeader;
