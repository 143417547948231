import React, { useState } from "react";
import PropTypes from "prop-types";
import StripePaymentForm from "arena/components/StripePaymentForm";
import YandexPaymentForm from "arena/components/YandexPaymentForm";
import PaypalPaymentForm from "arena/components/PaypalPaymentForm";
import RobokassaProcessor from "arena/components/RobokassaProcessor";
import { useTranslation } from "react-i18next";
import ErrorScreen from "core/components/ErrorScreen";
import apm from "core/utils/apm";
import { Typography } from "@mui/material";
import formatCurrency from "core/utils/currency";
import Alert from "@mui/material/Alert";
import currencyJs from "currency.js";
import getPeriodTitleWithCount from "arena/utils/getPeriodTitleWithCount";
import getPricePeriodPer from "arena/utils/getPricePeriodPer";

const SUBSCRIPTION = "SUBSCRIPTION";
const PPV = "PPV";
const DONATION = "DONATION";

export default function PaymentForm({
  productType,
  paymentHasError,
  setPaymentHasError,
  payment,
  onClose,
}) {
  const { t } = useTranslation("arena");
  const [error, setError] = useState(null);

  const {
    provider,
    recurrent,
    paymentData,
    returnUrl,
    originalAmount,
    amount,
    trialPeriod,
    providerServiceFeeAmount,
  } = payment;

  const onError = (error) => {
    setError(error);
    apm.captureError(error);
  };

  if (error) {
    return (
      <ErrorScreen
        title={t("paymentDialog.paymentError")}
        text={t("paymentDialog.paymentErrorDesc")}
      />
    );
  }

  const paymentDescription = [];

  let value = amount.value;
  if (recurrent) {
    value = trialPeriod
      ? currencyJs(originalAmount.value).add(providerServiceFeeAmount.value)
      : amount.value;

    const price = formatCurrency(value, amount.currency);
    const period = getPricePeriodPer({ t, period: payment.period });
    const priceOnPeriod = `${price} ${period.toLowerCase()}`;
    const minimumPeriod = getPeriodTitleWithCount({
      t,
      period: payment.period,
      minimumBillingCycles: payment.minimumBillingCycles,
    });

    const descriptions = {
      SUBSCRIPTION: t("productPayment.subscriptionDescription", {
        priceOnPeriod,
      }),
      DONATION: t("productPayment.donationDescription", { priceOnPeriod }),
    };

    const titles = {
      SUBSCRIPTION: t("productPayment.subscriptionTitle"),
      DONATION: t("productPayment.donationTitle"),
    };

    if (provider !== "PAYPAL") {
      paymentDescription.push(titles[productType]);
    }
    paymentDescription.push(descriptions[productType]);

    if (payment.minimumBillingCycles === 1) {
      paymentDescription.push(t("productPayment.canCancel"));
    } else {
      // paymentDescription.push(descriptions.SUBSCRIPTION_MINIMUM_PERIOD);
      paymentDescription.push(
        t("productPayment.canCancelAfter", { after: minimumPeriod })
      );
    }
  }

  if (providerServiceFeeAmount.value) {
    paymentDescription.push(
      t("productPayment.includedServiceFee", {
        finalCost: formatCurrency(value, amount.currency),
        providerServiceFee: formatCurrency(
          providerServiceFeeAmount.value,
          providerServiceFeeAmount.currency
        ),
      })
    );
  }

  let descriptionComponent;

  if (paymentDescription.length) {
    descriptionComponent = (
      <Alert icon={false} severity="info">
        {paymentDescription.map((text, index) => {
          return (
            <Typography key={index} variant="body2" gutterBottom>
              {text}
            </Typography>
          );
        })}
      </Alert>
    );
  }

  if (!paymentData) {
    return null;
  }

  const baseProps = {
    paymentHasError,
    setPaymentHasError,
    setError: onError,
    descriptionComponent,
    returnUrl,
    amount,
  };

  if (provider === "ROBOKASSA") {
    return (
      <RobokassaProcessor
        paymentData={paymentData}
        onClose={onClose}
        {...baseProps}
      />
    );
  }

  if (provider === "YANDEX") {
    const { confirmationToken } = paymentData;

    if (confirmationToken) {
      return (
        <YandexPaymentForm
          confirmationToken={confirmationToken}
          {...baseProps}
        />
      );
    }
  }

  if (provider === "STRIPE") {
    return <StripePaymentForm paymentData={paymentData} {...baseProps} />;
  }

  if (provider === "PAYPAL") {
    return (
      <PaypalPaymentForm
        paymentId={payment.id}
        paymentData={paymentData}
        descriptionComponent={descriptionComponent}
        {...baseProps}
      />
    );
  }

  return null;
}

PaymentForm.propTypes = {
  productType: PropTypes.oneOf([SUBSCRIPTION, PPV, DONATION]).isRequired,
  paymentHasError: PropTypes.bool,
  setPaymentHasError: PropTypes.func.isRequired,
  payment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    paymentData: PropTypes.object.isRequired,
    returnUrl: PropTypes.string.isRequired,
    recurrent: PropTypes.bool,
    trialPeriod: PropTypes.number,
    minimumBillingCycles: PropTypes.number,
    originalAmount: PropTypes.shape({
      value: PropTypes.number,
      currency: PropTypes.string,
    }),
    providerServiceFeeAmount: PropTypes.shape({
      value: PropTypes.number,
      currency: PropTypes.string,
    }),
    amount: PropTypes.shape({
      value: PropTypes.number,
      currency: PropTypes.string,
    }),
    period: PropTypes.number,
  }).isRequired,
  onClose: PropTypes.func,
};
