// amount
// discount
// type
// trialPeriod
export default function getPromocodeTitle(promocode) {
  let title = "";
  if (promocode.amount) {
    title += ` ${promocode.amount} amount `;
  }
  if (promocode.discount) {
    title += ` ${promocode.discount}% discount `;
  }
  // if (promocode.type) {
  //   title += ` ${promocode.type} `;
  // }
  if (promocode.trialPeriod) {
    title += ` ${promocode.trialPeriod ? "trial period" : ""} `;
  }
  return title;
}
