/**
 * Возвращает период с учетом minimumBillingCycles
 * 3 месяца
 * 6 месяцев
 * 12 месяцев
 */
export default function getPeriodTitleWithCount({
  t,
  minimumBillingCycles,
  period,
}) {
  const periodTitlesWithCount = {
    7: t("productPayment.weeksCount", {
      count: minimumBillingCycles,
    }),
    30: t("productPayment.monthsCount", {
      count: minimumBillingCycles,
    }),
    90: t("productPayment.monthsCount", {
      count: minimumBillingCycles * 3,
    }),
    180: t("productPayment.monthsCount", {
      count: minimumBillingCycles * 6,
    }),
    365: t("productPayment.yearsCount", {
      count: minimumBillingCycles,
    }),
  };

  return periodTitlesWithCount[period];
}
