import React from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useViewer } from "core/components/ViewerProvider";
import { useArena } from "core/components/ArenaProvider";
import ChooseProduct from "./ChooseProduct";
import ChooseProvider from "./ChooseProvider";
import CreatePayment from "./CreatePayment";
import Payment from "./Payment";
import Product from "./Product";
import SignIn from "./SignIn";
import { useSearchParams } from "react-router-dom";

const screens = {
  SignIn,
  ChooseProduct,
  ChooseProvider,
  CreatePayment,
  Product,
  Payment,
};

const DefaultComponent = () => null;

export default function ProductDialog({
  product,
  products,
  state,
  dispatch,
  refetch,
  setPromocodeIds,
  referralContentId,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const viewer = useViewer();
  const arena = useArena();
  const [searchParams, setSearchParams] = useSearchParams();

  const Component =
    screens[viewer === null ? "SignIn" : state.screen] || DefaultComponent;

  const onClose = () => {
    dispatch({ type: "close_dialog" });

    if (state.screen === "Payment") {
      searchParams.delete("paymentId");

      setSearchParams(searchParams);

      refetch();
    }
  };

  return (
    <Dialog
      scroll="body"
      fullWidth
      fullScreen={fullScreen}
      maxWidth="sm"
      open={state.open}
      disableAutoFocus
      disableEnforceFocus
      disableRestoreFocus
    >
      <Component
        products={products}
        state={state}
        onClose={onClose}
        dispatch={dispatch}
        arena={arena}
        refetch={refetch}
        product={product}
        setPromocodeIds={setPromocodeIds}
        referralContentId={referralContentId}
      />
    </Dialog>
  );
}

ProductDialog.propTypes = {
  products: PropTypes.array,
  dispatch: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
  product: PropTypes.object,
  setPromocodeIds: PropTypes.func,
  referralContentId: PropTypes.string,
};
