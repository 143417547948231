import React from "react";
import PropTypes from "prop-types";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import Fullscreen from "core/components/Fullscreen";
import PlayerFooterButton from "core/components/PlayerFooterButton";
import { canVideoFullscreen } from "core/utils/features";
import { useTranslation } from "react-i18next";

export default function FullscreenButton({
  onClick,
  videoElement,
  onFullScreenChange,
  target,
  ...props
}) {
  const { t } = useTranslation("core");

  if (videoElement) {
    const vid = videoElement.ref.current;

    if (vid && vid.webkitSupportsFullscreen && canVideoFullscreen) {
      // это только для IOS
      const title = t("enterFullscreen");
      return (
        <PlayerFooterButton
          title={title}
          onClick={() => vid.webkitEnterFullscreen()}
        >
          <FullscreenIcon fontSize="inherit" />
        </PlayerFooterButton>
      );
    }
  }

  return (
    <Fullscreen onFullScreenChange={onFullScreenChange} target={target}>
      {({ isFullscreen, enabled, toggleFullscreen }) => {
        if (!enabled) return null;

        const Icon = isFullscreen ? FullscreenExitIcon : FullscreenIcon;
        const title = isFullscreen ? t("exitFullscreen") : t("enterFullscreen");

        return (
          <PlayerFooterButton
            title={title}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();

              toggleFullscreen();

              if (onClick) onClick();
            }}
            {...props}
          >
            <Icon />
          </PlayerFooterButton>
        );
      }}
    </Fullscreen>
  );
}

FullscreenButton.propTypes = {
  target: PropTypes.string,
  onClick: PropTypes.func,
  onFullScreenChange: PropTypes.func,
  videoElement: PropTypes.shape({
    ref: PropTypes.shape({
      current: PropTypes.shape({
        webkitEnterFullscreen: PropTypes.func,
        webkitSupportsFullscreen: PropTypes.bool,
      }),
    }),
  }),
};
