import React from "react";
import PropTypes from "prop-types";
import { useInView } from "react-intersection-observer";

const InView = ({ children }) => {
  const [ref, inView] = useInView({ rootMargin: "-49% 0px" });

  return <div ref={ref}>{children(inView)}</div>;
};

InView.propTypes = {
  children: PropTypes.func.isRequired,
};

export default InView;
