import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import getRandomColor from "core/utils/getRandomColor";
import getRandomInt from "core/utils/getRandomInt";
import LocalStorage from "core/utils/localstorage";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

const ls = new LocalStorage(`apollo`);

const drawText = ({ canvas, text, dimensions, isXs }) => {
  canvas.width = `${dimensions.width}`;
  canvas.height = `${dimensions.height}`;

  const ctx = canvas.getContext("2d");
  const width = canvas.width;
  const height = canvas.height;

  ctx.font = isXs ? `11px Arial` : `22px Arial`;
  // Очистить холст
  ctx.clearRect(0, 0, width, height);

  const color = getRandomColor();
  ctx.fillStyle = color;

  const x = getRandomInt(width * 0.15, width * 0.85);
  const y = getRandomInt(height * 0.15, height * 0.85);

  const angle = (getRandomInt(0, 90) * Math.PI) / 180;

  ctx.save();
  ctx.translate(x, y);
  ctx.rotate(angle);
  ctx.fillText(text, 0, 0);
  ctx.restore();

  setTimeout(() => {
    ctx.clearRect(0, 0, width, height);
  }, 5000);
};

export default function Watermark({ containerRef, videoRef, onVideoError }) {
  const viewerId = ls.getItem("viewerId");
  const ref = useRef();
  const [dimensions, setDimensions] = useState(null);
  const [rendered, setRendered] = useState(false);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));

  /**
   * detect dom manipulations
   */
  useEffect(() => {
    if (!containerRef.current) return;
    if (!ref.current) return;
    if (!videoRef.current) return;
    if (!rendered) return;

    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === "attributes") {
          onVideoError(new Error("WATERMARK_REMOVED"));
        }

        mutation.removedNodes.forEach((node) => {
          if (node === ref.current || node === videoRef.current) {
            onVideoError(new Error("WATERMARK_REMOVED"));
          }
        });
      });
    });

    observer.observe(containerRef.current, {
      childList: true,
      subtree: true,
      attributes: true,
      attributeFilter: ["style", "class"],
    });

    return () => {
      observer.disconnect();
    };
  }, [containerRef, ref, videoRef, rendered]);

  /**
   * resize canvas to container size
   */
  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        const { clientWidth, clientHeight } = ref.current.parentNode;
        setDimensions({
          width: clientWidth,
          height: clientHeight,
        });
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [ref]);

  /**
   * add important styles for protect canvas from being not visible
   */
  useEffect(() => {
    if (!ref.current) return;
    if (!containerRef.current) return;
    if (!videoRef.current) return;
    if (!dimensions) return;

    const canvas = ref.current;

    const videoImportantStyles = {
      opacity: "1",
      width: "100%",
      height: "100%",
      "max-width": "100%",
      "max-height": "100%",
      top: "0px",
      bottom: "0px",
      left: "0px",
      right: "0px",
      border: "none",
      visibility: "visible",
      display: "block",
      position: "absolute",
      transform: "none",
    };

    Object.keys(videoImportantStyles).forEach((key) => {
      videoRef.current.style.setProperty(
        key,
        videoImportantStyles[key],
        "important"
      );
    });

    const containerImportantStyles = {
      width: "100%",
      height: "100%",
      position: "relative",
    };

    Object.keys(containerImportantStyles).forEach((key) => {
      containerRef.current.style.setProperty(
        key,
        containerImportantStyles[key],
        "important"
      );
    });

    const importantStyles = {
      width: "100%",
      height: "100%",
      "max-width": "100%",
      "max-height": "100%",
      "z-index": "2000",
      top: "0px",
      bottom: "0px",
      left: "0px",
      right: "0px",
      border: "none",
      visibility: "visible",
      display: "block",
      opacity: "0.7",
      position: "absolute",
      "pointer-events": "none",
      transform: "none",
    };

    Object.keys(importantStyles).forEach((key) => {
      canvas.style.setProperty(key, importantStyles[key], "important");
    });

    /**
     * Есть какие-то артефакты на мобильном сафари
     */
    const ctx = canvas.getContext("2d");
    const width = canvas.width;
    const height = canvas.height;
    ctx.clearRect(0, 0, width, height);

    /**
     * draw text on canvas every 60 seconds
     */
    const intervalId = setInterval(
      () => drawText({ canvas, text: viewerId, dimensions, isXs }),
      60000 // 60 секунд
    );

    setRendered(true);

    return () => clearInterval(intervalId);
  }, [ref, containerRef, dimensions]);

  return (
    <canvas ref={ref} width={dimensions?.width} height={dimensions?.height} />
  );
}

Watermark.propTypes = {
  containerRef: PropTypes.object,
  videoRef: PropTypes.object,
  onVideoError: PropTypes.func,
};
