import React from "react";
import PropTypes from "prop-types";
import TvOffIcon from "@mui/icons-material/TvOff";
import ErrorScreen from "core/components/ErrorScreen";
import PlayerPreview from "core/components/PlayerPreview";
import { useTranslation } from "react-i18next";

export default function StreamFinishedScreen({ content }) {
  const { t } = useTranslation("core");

  return (
    <PlayerPreview preview={content.preview} overlay>
      <ErrorScreen
        icon={TvOffIcon}
        title={content.title}
        text={t("streamFinished.title")}
      />
    </PlayerPreview>
  );
}

StreamFinishedScreen.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    preview: PropTypes.string,
  }).isRequired,
};
