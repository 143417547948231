import { useEffect } from "react";
import PropTypes from "prop-types";
import useLiveEvents from "core/hooks/useLiveEvents";

/**
 * @param {String} currentTime - это текущее время в видео из hls.js (rawProgramDateTime)
 */
export default function LiveEvents({ contentId, currentTime, onEvent }) {
  const { update } = useLiveEvents({ contentId, onEvent, currentTime });

  useEffect(() => {
    update(currentTime);
  }, [currentTime]);

  return null;
}

LiveEvents.propTypes = {
  contentId: PropTypes.string.isRequired,
  currentTime: PropTypes.string.isRequired,
  onEvent: PropTypes.func.isRequired,
};
