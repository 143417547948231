import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ProductPriceButton from "arena/components/ProductPriceButton";
import PromocodeButton from "arena/components/PromocodeButton";
import formatCurrency from "core/utils/currency";
import { useTranslation } from "react-i18next";
import getPricePeriodPer from "arena/utils/getPricePeriodPer";

function getTrialDescription(price, t) {
  const value = formatCurrency(price.originalValue, price.currency);
  const per = getPricePeriodPer({ period: price.period, t }).toLowerCase();

  if (price.value > 0) {
    const trialValue = formatCurrency(price.value, price.currency);
    return t("productCover.trialDescCost", {
      count: price.trialPeriod,
      trialValue,
      value,
      per,
    });
  } else {
    return t("productCover.trialDescFree", {
      count: price.trialPeriod,
      value,
      per,
    });
  }
}

export default function PurchaseButtons({
  tab,
  product,
  onClickPriceButton,
  onCompletedPromocodeButton,
  promocodeIds,
  setPromocodeIds,
}) {
  const { t } = useTranslation("arena");

  const price = product.prices[tab];
  const isRub = price.currency === "RUB";

  return (
    <>
      {isRub && (
        <Grid item xs={12}>
          <Typography
            color="textSecondary"
            component="div"
            variant="caption"
            align="center"
          >
            {t("productPayment.onlyRussianCards")}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <ProductPriceButton
          product={product}
          price={price}
          onClick={(event) => onClickPriceButton(event, price)}
        />
      </Grid>

      {price.trialPeriod > 0 && (
        <Grid item xs={12}>
          <Typography variant="body1" align="center">
            {getTrialDescription(price, t)}
          </Typography>
        </Grid>
      )}

      {price.nextPrice && price.nextPrice.value > price.value && (
        <Grid item xs={12}>
          <Typography variant="body1" align="center">
            {t("productPayment.nextPriceDesc", {
              date: price.nextPrice.dateFrom,
              value: formatCurrency(price.nextPrice.value, price.currency),
            })}
          </Typography>
        </Grid>
      )}

      {product.enablePromoCodes && (
        <Grid item xs={12}>
          <PromocodeButton
            productId={product.id}
            productType={product.type}
            price={price}
            onCompleted={onCompletedPromocodeButton}
            promocodeIds={promocodeIds}
            setPromocodeIds={setPromocodeIds}
          />
        </Grid>
      )}
    </>
  );
}

PurchaseButtons.propTypes = {
  tab: PropTypes.number.isRequired,
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    enablePromoCodes: PropTypes.bool.isRequired,
    prices: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired,
        period: PropTypes.number.isRequired,
        trialPeriod: PropTypes.number,
        nextPrice: PropTypes.shape({
          value: PropTypes.number.isRequired,
          dateFrom: PropTypes.string.isRequired,
        }),
        providers: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            token: PropTypes.string.isRequired,
          })
        ),
      }).isRequired
    ).isRequired,
  }).isRequired,
  onClickPriceButton: PropTypes.func.isRequired,
  onCompletedPromocodeButton: PropTypes.func.isRequired,
  promocodeIds: PropTypes.array.isRequired,
  setPromocodeIds: PropTypes.func.isRequired,
};
