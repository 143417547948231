import React from "react";

export default function SberIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 210 33"
      {...props}
    >
      <path
        fill="#21A038"
        d="M28.908 6.355a16.45 16.45 0 011.934 3.252l-14.7 10.982-6.144-3.903V11.99l6.144 3.903 12.766-9.538z"
      />
      <path
        fill="url(#paint0_linear)"
        d="M3.728 16.355c0-.211.005-.42.015-.629L.02 15.541a16.501 16.501 0 001.206 7.074c.811 1.984 2.001 3.786 3.5 5.302l2.642-2.675a12.544 12.544 0 01-2.695-4.076 12.679 12.679 0 01-.945-4.811z"
      />
      <path
        fill="url(#paint1_linear)"
        d="M16.139 3.777c.207 0 .414.008.62.018l.185-3.774a15.909 15.909 0 00-6.983 1.222A16.082 16.082 0 004.727 4.79l2.64 2.676a12.357 12.357 0 014.023-2.732 12.223 12.223 0 014.748-.958z"
      />
      <path
        fill="url(#paint2_linear)"
        d="M16.139 28.932c-.208 0-.415 0-.621-.017l-.187 3.773a15.898 15.898 0 006.981-1.221 16.072 16.072 0 005.232-3.549l-2.636-2.675a12.363 12.363 0 01-4.022 2.731 12.228 12.228 0 01-4.747.958z"
      />
      <path
        fill="url(#paint3_linear)"
        d="M23.134 5.972l3.138-2.344A15.933 15.933 0 0016.134 0v3.777c2.498-.003 4.94.762 7 2.195z"
      />
      <path
        fill="#21A038"
        d="M32.276 16.355a16.67 16.67 0 00-.254-2.931l-3.473 2.594v.337c0 1.757-.363 3.495-1.067 5.1a12.538 12.538 0 01-3.021 4.221l2.505 2.806a16.306 16.306 0 003.927-5.492 16.498 16.498 0 001.383-6.635z"
      />
      <path
        fill="url(#paint4_linear)"
        d="M16.139 28.932c-1.734 0-3.45-.368-5.034-1.082A12.393 12.393 0 016.94 24.79l-2.768 2.537a16.105 16.105 0 005.419 3.98 15.913 15.913 0 006.548 1.403v-3.777z"
      />
      <path
        fill="url(#paint5_linear)"
        d="M7.816 7.033L5.312 4.227a16.304 16.304 0 00-3.928 5.491A16.498 16.498 0 000 16.354h3.728c0-1.757.363-3.495 1.067-5.1a12.54 12.54 0 013.021-4.221z"
      />
      <g fill="#21A038" clipPath="url(#clip0)">
        <path d="M93.13 9.353l4.617-3.409H82.293v20.483h15.454v-3.409H86.703v-5.246h9.421v-3.409h-9.42v-5.01h6.426zM71.895 13.924H66.53V9.35h8.563l4.614-3.419H62.123v20.483h9.205c5.16 0 8.122-2.343 8.122-6.43 0-3.91-2.683-6.061-7.555-6.061zm-.766 9.082H66.53V17.33h4.6c2.79 0 4.1.951 4.1 2.838s-1.393 2.838-4.1 2.838zM109.107 5.941h-8.412v20.483h4.409v-5.802h4.003c5.367 0 8.687-2.83 8.687-7.351 0-4.522-3.32-7.33-8.687-7.33zm-.093 11.27h-3.91V9.351h3.91c2.823 0 4.381 1.397 4.381 3.931s-1.558 3.927-4.381 3.927zM55.506 21.59a7.685 7.685 0 01-3.71.93c-3.876 0-6.688-2.772-6.688-6.59 0-3.819 2.812-6.598 6.688-6.598a6.545 6.545 0 013.907 1.212l3.084-2.276-.209-.148c-1.799-1.585-4.203-2.424-6.954-2.424-2.985 0-5.69 1.006-7.623 2.836a9.892 9.892 0 00-3.024 7.294 10.238 10.238 0 003.006 7.4c1.941 1.904 4.641 2.952 7.605 2.952 3.096 0 5.802-1.082 7.635-3.051l-2.761-2.061-.956.524zM135.277 13.924h-5.363V9.35h11.525V5.932h-15.935v20.483h9.193c5.16 0 8.122-2.343 8.122-6.43.012-3.91-2.67-6.061-7.542-6.061zm-.766 9.082h-4.597V17.33h4.597c2.791 0 4.099.951 4.099 2.838s-1.38 2.838-4.099 2.838zM192.947 26.386h-4.47V5.903h4.47v8.603h2.433l6.57-8.603h5.066l-7.793 9.744 9.049 10.739h-5.931l-6.651-8.056h-2.743v8.056zM159.059 22.493h-9.456L148 26.471h-4.757l8.869-20.483h4.756l8.725 20.483h-4.931l-1.603-3.978zm-1.481-3.71l-3.219-7.956-3.237 7.955h6.456zM171.611 5.988v8.553h9.25V5.988h4.609v20.483h-4.609V18.4h-9.25v8.07h-4.609V5.989h4.609z" />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="5.481"
          x2="1.31"
          y1="27.446"
          y2="15.548"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".14" stopColor="#F1E813" />
          <stop offset=".3" stopColor="#E6E418" />
          <stop offset=".58" stopColor="#C9DA26" />
          <stop offset=".89" stopColor="#A2CC39" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="5.791"
          x2="16.318"
          y1="5.459"
          y2="1.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".06" stopColor="#0FA7DF" />
          <stop offset=".54" stopColor="#0098F8" />
          <stop offset=".92" stopColor="#0290EA" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="15.085"
          x2="26.965"
          y1="30.429"
          y2="27.781"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".12" stopColor="#A2CC39" />
          <stop offset=".28" stopColor="#86C239" />
          <stop offset=".87" stopColor="#219F38" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="15.358"
          x2="25.524"
          y1="1.265"
          y2="4.361"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".06" stopColor="#0290EA" />
          <stop offset=".79" stopColor="#0C89CA" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="5.107"
          x2="16.183"
          y1="26.867"
          y2="30.951"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".13" stopColor="#F1E813" />
          <stop offset=".3" stopColor="#EAE616" />
          <stop offset=".53" stopColor="#D8DF1F" />
          <stop offset=".8" stopColor="#BAD52D" />
          <stop offset=".98" stopColor="#A2CC39" />
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="1.389"
          x2="5.925"
          y1="16.812"
          y2="5.169"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".07" stopColor="#A2CC39" />
          <stop offset=".26" stopColor="#81C45E" />
          <stop offset=".92" stopColor="#0FA7DF" />
        </linearGradient>
        <clipPath id="clip0">
          <path
            fill="#fff"
            d="M0 0h168.414v21.621H0z"
            transform="translate(40.966 5.69)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
