import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import DialogContent from "@mui/material/DialogContent";
import DialogHeader from "core/components/DialogHeader";
import PaymentForm from "arena/components/PaymentForm";
import { useMutation } from "@apollo/client";
import { CreatePaymentMutation } from "arena/mutations";
import { withSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import RetargetPixels from "core/components/RetargetPixels";
import { getUtmFromLocalStorage } from "core/utils/utm";
import { paymentProviderNames } from "core/utils/paymentProviders";

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(0),
}));

function CreatePayment({
  onClose,
  product,
  state,
  enqueueSnackbar,
  dispatch,
  refetch,
  setPromocodeIds,
  referralContentId,
}) {
  const { t } = useTranslation("arena");
  const [payHasError, setPaymentHasError] = useState(false);

  let {
    providers,
    provider: { token, name },
    amount,
    recurrent,
    productType,
  } = state;

  /**
   * Этот код работает только на странице продукта (тк там можно открыть страницу продукта с промокодом в урле неавторизованным)
   */
  if (product && state.priceId) {
    const price = product.prices.find((price) => price.id === state.priceId);
    providers = price.providers;

    const provider = providers.find((provider) => provider.name === name);
    token = provider.token;
  }

  const errors = {
    PROMOCODE_LIMIT_REACHED: t("productDialog.createPayment.limitReachedError"),
    DEFAULT: t("productDialog.createPayment.error"),
  };

  const [createPayment, { data, loading }] = useMutation(
    CreatePaymentMutation,
    {
      variables: {
        recurrent,
        amount,
        token,
        utm: getUtmFromLocalStorage(),
        referralContentId,
      },
      onCompleted(data) {
        ReactGA.event({
          category: "CreatePayment",
          action: "Payment created",
        });
        if (data?.createPayment?.status === "SUCCEEDED") {
          onClose();
          window.location = data.createPayment.returnUrl;
        }

        if (data?.createPayment?.provider === "PAYEER") {
          window.location = data.createPayment.paymentData.redirectUrl;
          onClose();
        }
      },
      onError(error) {
        if (error.message?.includes("Already purchased")) {
          onClose();
          refetch();
        } else {
          const cause = error.graphQLErrors?.[0]?.extensions?.cause;

          const text = errors[cause] || errors.DEFAULT;
          enqueueSnackbar(text, {
            variant: "error",
          });
          if (cause === "PROMOCODE_LIMIT_REACHED") {
            if (setPromocodeIds) {
              setPromocodeIds([]);
            }
            onClose();
            refetch();
          }
        }
      },
    }
  );

  const payment = data?.createPayment;

  const anotherProvider = providers.find((provider) => provider.name !== name);

  useEffect(() => {
    if (!token) return;

    createPayment();
  }, [token]);

  const onClick = () => {
    dispatch({ type: "choose_provider", providers });
  };

  const onSelectAnother = () => {
    dispatch({
      type: "create_payment",
      provider: anotherProvider,
      providers,
      productType,
    });

    ReactGA.event({
      category: "ChooseProvider",
      action: "Click to provider",
      label: anotherProvider.name,
    });
  };

  return (
    <>
      <DialogHeader
        onClose={onClose}
        title={t("productDialog.createPayment.title")}
      />
      <StyledDialogContent>
        {loading && <LinearProgress />}

        {payment && (
          <PaymentForm
            productType={productType}
            paymentHasError={payHasError}
            setPaymentHasError={setPaymentHasError}
            payment={payment}
            onClose={onClose}
          />
        )}

        {providers.length === 2 && (
          <AppBar color="default" position="static" elevation={0}>
            <Toolbar>
              <Button onClick={onSelectAnother} fullWidth>
                {t("productDialog.createPayment.orPayWith", {
                  name: paymentProviderNames[anotherProvider.name],
                })}
              </Button>
            </Toolbar>
          </AppBar>
        )}

        {providers.length > 2 && (
          <AppBar color="default" position="static" elevation={0}>
            <Toolbar>
              <Button onClick={onClick} fullWidth>
                {t("productDialog.createPayment.chooseProvider")}
              </Button>
            </Toolbar>
          </AppBar>
        )}

        <RetargetPixels eventType="createPayment" />
      </StyledDialogContent>
    </>
  );
}

CreatePayment.propTypes = {
  product: PropTypes.object,
  state: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  setPromocodeIds: PropTypes.func,
  referralContentId: PropTypes.string,
};

export default withSnackbar(CreatePayment);
