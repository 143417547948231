import React, { useEffect } from "react";
import PropTypes from "prop-types";
import PictureInPictureAltIcon from "@mui/icons-material/PictureInPictureAlt";
import PlayerFooterButton from "core/components/PlayerFooterButton";
import { useTranslation } from "react-i18next";

function PipButton({ videoElement }) {
  const { t } = useTranslation("core");

  useEffect(() => {
    return () => {
      if (typeof document === "undefined") return;
      if (document.pictureInPictureElement) {
        document.exitPictureInPicture();
      }
    };
  }, []);

  if (typeof document === "undefined") return null;
  if (!document.pictureInPictureEnabled) return null;
  if (!videoElement) return null;

  const onClick = () => {
    if (document.pictureInPictureElement) {
      document.exitPictureInPicture();
    } else {
      if (document.pictureInPictureEnabled) {
        const video = videoElement.ref.current;
        video.requestPictureInPicture();
      }
    }
  };

  return (
    <PlayerFooterButton title={t("picture-in-picture")} onClick={onClick}>
      <PictureInPictureAltIcon />
    </PlayerFooterButton>
  );
}

PipButton.propTypes = {
  videoElement: PropTypes.object,
};

export default PipButton;
