import React from "react";
import PropTypes from "prop-types";
import { useForkRef } from "@mui/material/utils";
import { clamp } from "core/utils/math";

let boundingRectCache = new WeakMap();
let isBoundingRectCachePopulated = false;

function getBoundingClientRect(element) {
  const cached = boundingRectCache.get(element);

  if (cached) {
    return cached;
  }

  const boundingRect = element.getBoundingClientRect();

  boundingRectCache.set(element, boundingRect);
  isBoundingRectCachePopulated = true;

  return boundingRect;
}

function clearBoundingRectCache() {
  if (isBoundingRectCachePopulated) {
    boundingRectCache = new WeakMap();
    isBoundingRectCachePopulated = false;
  }
}

const SeekBarSliderRoot = React.forwardRef(function SeekBarSliderRoot(
  {
    PopupComponent = null,
    isPopupFixed = false,
    popupMarginLeft = 0,
    popupMarginRight = 0,
    popupProps = {},
    children,
    ...other
  },
  ref
) {
  const [isPopupVisible, setIsPopupVisible] = React.useState(false);
  const [popupPosition, setPopupPosition] = React.useState({
    absolute: 0,
    relative: 0,
  });
  const spanRef = React.useRef();
  const handleRef = useForkRef(ref, spanRef);

  function updatePopupPosition(cursorX) {
    const spanRect = getBoundingClientRect(spanRef.current);
    const spanLeft = spanRect.left + window.scrollX;
    const spanWidth = spanRect.width;
    const newAbsolutePosition = cursorX - spanLeft;
    const newRelativePosition = newAbsolutePosition / spanWidth;

    setPopupPosition({
      absolute: clamp(
        newAbsolutePosition,
        popupMarginLeft,
        spanWidth - popupMarginRight
      ),
      relative: clamp(newRelativePosition, 0, 1),
    });
  }

  function handleMouseOver(event) {
    updatePopupPosition(event.nativeEvent.pageX);
    setIsPopupVisible(true);
  }

  function handleMouseOut() {
    setIsPopupVisible(false);
  }

  function handleMouseMove(event) {
    if (!isPopupVisible && !isPopupFixed) return;
    updatePopupPosition(event.pageX);
  }

  React.useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("resize", clearBoundingRectCache);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("resize", clearBoundingRectCache);
    };
  }, [
    isPopupVisible,
    isPopupFixed,
    updatePopupPosition,
    isBoundingRectCachePopulated,
    boundingRectCache,
    isBoundingRectCachePopulated,
  ]);

  let popup = null;

  if (PopupComponent) {
    const popupStyle = {
      display: isPopupFixed || isPopupVisible ? "block" : "none",
      transform: `translateX(${popupPosition.absolute}px)`,
    };

    popup = (
      <div style={popupStyle}>
        <PopupComponent position={popupPosition.relative} {...popupProps} />
      </div>
    );
  }

  return (
    <>
      {popup}

      <span
        ref={handleRef}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        {...other}
      >
        {children}
      </span>
    </>
  );
});

SeekBarSliderRoot.propTypes = {
  PopupComponent: PropTypes.elementType,
  isPopupFixed: PropTypes.bool,
  popupMarginLeft: PropTypes.number,
  popupMarginRight: PropTypes.number,
  popupProps: PropTypes.object,
  children: PropTypes.node,
};

export default SeekBarSliderRoot;
