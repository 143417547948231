const urlPattern =
  /(?:https?:)?\/\/(?:(?:[\w-]+\.)+[\w/#@~.-]*)(?:\?(?:[\w&=.!,;$#%-]+)?)?/gi;

/**
 * Форматировать текст.
 * Добавляет <br> и ссылки
 */
export function textFormat(text = "") {
  return text
    .replace(urlPattern, function (url) {
      if (text.includes(`src="${url}"`)) {
        return url;
      }

      return (
        '<a href="' +
        url +
        '" target="_blank" rel="noopener noreferer">' +
        url +
        "</a>"
      );
    })
    .replace(/\n/g, "<br />");
}

export function normalizeUrl(url) {
  return url.replace(/^https?:\/\//, "").replace(/\/$/, "");
}

export function truncate(str, limit) {
  if (!str) return "";
  if (str.length < limit) return str;
  return str.slice(0, limit) + "...";
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// duration - sec
export function durationFormat(duration) {
  duration = duration.toFixed();
  // let days = Math.floor(duration / 86400);
  let hours = Math.floor(duration / 3600);
  let minutes = Math.floor((duration - hours * 3600) / 60);
  let seconds = duration - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  if (hours === "00") {
    return minutes + ":" + seconds;
  }

  return hours + ":" + minutes + ":" + seconds;
}

let defaultSi = [
  { value: 1, symbol: "" },
  { value: 1e3, symbol: "K" },
  { value: 1e6, symbol: "M" },
  { value: 1e9, symbol: "B" },
  { value: 1e12, symbol: "T" },
];

/**
 * Функция форматириует число в сокращенный формат:
 * 1000 - 1K
 * 1000000 - 1M
 * 1000000000 - 1B
 * 1000000000000 - 1T
 * @param {*} size
 */
export function numberFormat(num, digits = 1, si = defaultSi) {
  let rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}

/**
 * // Форматируем из отформатированного времени вида 00:00:00 или 00:00 в число секунд
 * @example
 * // returns 450
 * formatDurationToSeconds("00:07:30")
 * duration - sec
 * @param durationString {string | number} - отформатированное время
 * @returns numberOfSeconds {number}
 */
export function formatDurationToSeconds(durationString) {
  const timeArr = String(durationString).split(":");

  const HOUR = 60 * 60;
  const MINUTE = 60;

  if (timeArr.length === 3) {
    const [hours, minutes, seconds] = timeArr;

    return Number(hours) * HOUR + Number(minutes) * MINUTE + Number(seconds);
  }

  if (timeArr.length === 2) {
    const [minutes, seconds] = timeArr;
    return Number(minutes) * MINUTE + Number(seconds);
  }

  return Number(durationString);
}

/**
 * // Форматируем время из количеств секунд, например 127с в 02:07 или из 02:07 в 02:07
 * @example
 * // returns 02:07
 * formatStringToHours("127")
 * @param durationString {string} - количество секунд или отформатированное время
 * @returns formattedString {string}
 */
export function formatStringToHours(durationString) {
  const numberOfSeconds = formatDurationToSeconds(durationString);

  const HOUR = 60 * 60;
  const MINUTE = 60;

  const hours = Math.trunc(numberOfSeconds / HOUR);
  const minutes = Math.trunc((numberOfSeconds - hours * HOUR) / MINUTE);
  const seconds = Math.trunc(numberOfSeconds - hours * HOUR - minutes * MINUTE);

  const formatFirstZero = (time) =>
    String(time).split("").length > 1 ? time : `0${time}`;

  // Форматируем, двоеточие после числа
  const formatTime = (time) => (time ? `${time}:` : "");

  return `${formatTime(hours)}${formatTime(formatFirstZero(minutes)) || "00:"}${
    formatFirstZero(seconds) || "00"
  }`;
}

/**
 * Удаляет из строчки ссылки (слова начинающиеся с "https://" или "http://"")
 *
 * @example
 * removeLinks("Контент https://google.com другие слова") // => Контент другие слова
 *
 * @param text {string | number} - отформатированное время
 *
 * @returns formattedText {number}
 */
export function removeLinks(text) {
  return text.replace(/(?:https?|ftp):\/\/[\n\S]+/g, "");
}

/*
pluralize(0, 'нет штук', 'штука', 'штуки', 'штук')  // => нет штук
pluralize(1, 'нет штук', 'штука', 'штуки', 'штук')  // => штука
pluralize(2, 'нет штук', 'штука', 'штуки', 'штук')  // => штуки
pluralize(5, 'нет штук', 'штука', 'штуки', 'штук')  // => штук

pluralize(0, 'нет штук', '%d штука', '%d штуки', '%d штук')  // => нет штук
pluralize(1, 'нет штук', '%d штука', '%d штуки', '%d штук')  // => 1 штука
pluralize(2, 'нет штук', '%d штука', '%d штуки', '%d штук')  // => 2 штуки
pluralize(5, 'нет штук', '%d штука', '%d штуки', '%d штук')  // => 5 штук
*/
export function pluralize(i, str0, str1, str2, str3) {
  if (!i) return parse(str0, i);
  switch (plural(i)) {
    case 0:
      return parse(str1, i);
    case 1:
      return parse(str2, i);
    default:
      return parse(str3, i);
  }
}

function parse(str) {
  var args = [].slice.call(arguments, 1);
  var i = 0;

  return str.replace(/%d/g, function () {
    return args[i++];
  });
}

function plural(a) {
  if (a % 10 === 1 && a % 100 !== 11) {
    return 0;
  } else if (a % 10 >= 2 && a % 10 <= 4 && (a % 100 < 10 || a % 100 >= 20)) {
    return 1;
  } else {
    return 2;
  }
}
