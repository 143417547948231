import React, { Component } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { VASTClient, VASTTracker } from "core/libs/vast-client";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import BigPlayButton from "core/components/BigPlayButton";
import FullscreenButton from "core/components/FullscreenButton";
import ToggleMuteButton from "core/components/ToggleMuteButton";
import apm from "core/utils/apm";
import getSkipTime from "core/utils/getSkipTime";
import { InView } from "react-intersection-observer";
import PageVisibility from "react-page-visibility";
import { withTranslation } from "react-i18next";
import LocalStorage from "core/utils/localstorage";
import {
  createSponsorClick,
  createSponsorImpression,
  trackAdPixel,
  postMessage,
} from "core/utils/tracker";
import getAdUrl from "core/utils/getAdUrl";

const StyledVpaidVideo = styled("video")({
  width: "100%",
  height: "100%",
  background: "black",
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  zIndex: -1,
});

const StyledVpaidSlot = styled("div")({
  position: "absolute",
  width: "100%",
  height: "100%",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  zIndex: 10000,
});

const Root = styled("div")({
  position: "absolute",
  width: "100%",
  height: "100%",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  zIndex: 10000,
  background: "black",
});

const StyledAdLabel = styled(Typography)({
  backgroundColor: "rgba(0, 0, 0, 0.2)",
  borderRadius: 4,
  padding: "5px 10px",
  position: "absolute",
  left: 10,
  top: 10,
  zIndex: 100000,
});

const StyledControls = styled("div")({
  position: "absolute",
  right: 10,
  bottom: 10,
  left: 10,
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  zIndex: 100000,
  pointerEvents: "auto",
});

const StyledCloseButton = styled(IconButton)({
  position: "absolute",
  right: 10,
  top: 10,
  zIndex: 100000,
});

const StyledSkipButton = styled(Button)({
  marginRight: "auto",
  zIndex: 100000,
});

const StyledVideo = styled("video")({
  height: "100%",
  width: "100%",
  position: "absolute",
});

const START = "START";
const IMPRESSION = "IMPRESSION";
const CREATIVEVIEW = "CREATIVEVIEW";
const FULLSCREEN = "FULLSCREEN";
const EXIT_FULLSCREEN = "EXIT_FULLSCREEN";
const ERROR = "ERROR";
const COMPLETE = "COMPLETE";
const CLICKTHROUGH = "CLICKTHROUGH";
const CLOSE = "CLOSE";
const UNMUTE = "UNMUTE";
const MUTE = "MUTE";
const SKIP = "SKIP";
const FIRSTQUARTILE = "FIRSTQUARTILE";
const MIDPOINT = "MIDPOINT";
const THIRDQUARTILE = "THIRDQUARTILE";
const NOBANNER_ERROR = "NOBANNER_ERROR";
const NETWORK_ERROR = "NETWORK_ERROR";

const LOAD_VPAID_TIMEOUT = 5000; // если за 500 ms объявление не загрузилось, то продолжаем показывать кино
const INIT_AD_TIMEOUT = 5000; // если за 500 ms объявление не инициализировалось, то продолжаем показывать кино
const NO_BANNER_TIMEOUT = 5; // если за 5 секунд реклама не начала показываться (START), то дергаем событие NO_BANNER_ERROR (чтобы искалось следующее)

const DEFAULT_SKIP_TIME = 11;
const SUPPORTED_MEDIAFILE_MIMETYPES = [
  // "video/x-flv",
  "video/mp4",
  "video/webm",
  "video/quicktime",
  // "application/x-shockwave-flash",
  "application/javascript",
  "application/x-javascript",
  // "image/jpeg",
  // "image/png",
  // "image/gif"
];

const ls = new LocalStorage("video-player");

/**
 * Компонент для показа рекламных видеороликов
 */
class Vast extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object,

    onStart: PropTypes.func,
    onFinish: PropTypes.func,
    onUpdate: PropTypes.func,
    onChangeMute: PropTypes.func,
    onError: PropTypes.func,
    fullscreenTarget: PropTypes.string,
    sportId: PropTypes.string,
    vastPlace: PropTypes.string,
    antiFrod: PropTypes.bool,
    muted: PropTypes.bool,
    shareUrl: PropTypes.string,
    vast: PropTypes.shape({
      url: PropTypes.string.isRequired,
      token: PropTypes.string, // необязательное потому что есть страница тестирования вастов где это не передается (в этом случае не дергаем наши ручки)
      clickPixel: PropTypes.string,
      impressionPixel: PropTypes.string,
      adLabel: PropTypes.bool,
      isClickable: PropTypes.bool,
      isCloseable: PropTypes.bool,
      isSkippable: PropTypes.bool,
      puid: PropTypes.object,
      timeout: PropTypes.number,
    }).isRequired,
  };

  static defaultProps = {
    onStart() {},
    onFinish() {},
    onUpdate() {},
    onChangeMute() {},
    onError() {},
  };

  videoRef = React.createRef();
  slotRef = React.createRef();
  emptyRef = React.createRef();
  vastClient = new VASTClient();

  /**
   * Состояние компонента
   */
  state = {
    // Стейт компонента
    loading: true,
    error: null,
    ads: [],
    // Стейт видео элемента
    status: "paused",
    muted: true,
    currentTime: 0,
    completedAdCount: 0,
    // пользователь кликнул? (чтобы второй раз кликнуть было нельзя)
    clicked: false,
    visible: true,
    played: false,
  };

  componentDidMount() {
    // console.log("Adv.componentDidMount()");

    const { current } = this.emptyRef;

    let url = getAdUrl({
      url: this.props.vast.url,
      puid: this.props.vast.puid,
      sportId: this.props.sportId,
      shareUrl: this.props.shareUrl,
      width: current?.offsetWidth,
      height: current?.offsetHeight,
    });

    console.log("Load VAST file:", url);

    this.vastClient
      .get(url, { withCredentials: true })
      .then((data) => {
        console.log("VAST file loaded", data);

        if (data.ads.length) {
          this.props.onStart();

          return this.setState(
            {
              ads: data.ads,
              loading: false,
              muted: this.props.muted,
            },
            this.showNextAd
          );
        } else {
          throw new Error("Invalid VAST XMLDocument");
        }
      })
      .catch((error) => {
        apm.captureError(error);

        if (error.message.includes("Invalid VAST XMLDocument")) {
          this.createSponsorImpression(NOBANNER_ERROR);
          this.triggerPostMessage(NOBANNER_ERROR);
        } else {
          this.createSponsorImpression(NETWORK_ERROR);
          this.triggerPostMessage(NETWORK_ERROR);
        }

        this.props.onError(error);
      });

    window.addEventListener("message", this.onVPAIDReady, false);

    const timeout = this.props.vast.timeout || NO_BANNER_TIMEOUT;

    this.noBannerTimeout = setTimeout(() => {
      const error = new Error("no banner timeout");
      console.log(error);
      this.setState({ visible: false });
      this.createSponsorImpression(NOBANNER_ERROR);
      this.triggerPostMessage(NOBANNER_ERROR);
      this.props.onError(error);
    }, timeout * 1000);
  }

  /**
   * Показать объявление, если есть, или закрыть окно если нет?
   */
  showNextAd = () => {
    // console.log("2. Adv.showNextAd()");

    this.removeVPAIDIframe();
    this.removeCallbacksForVPAIDCreative();
    this.removeVastTrackerEvents();

    // console.log("state", this.state);

    this.ad = this.state.ads[0];

    if (!this.ad) {
      // объявлений больше нет, завершаем показ
      // console.log("3. объявлений больше нет, завершаем показ");
      this.setState({ visible: false });

      return this.props.onFinish(this.state.completedAdCount);
    }

    this.creative = this.ad.creatives && this.ad.creatives[0];

    if (!this.creative) {
      // нет креатива у этого объявления, чо делать?
      // console.log("3. нет креатива у этого объявления");
      this.setState({ visible: false });

      return this.props.onFinish(this.state.completedAdCount);
    }

    // console.log("ad", this.ad);
    // console.log("creative", this.creative);

    this.vastTracker = new VASTTracker(this.vastClient, this.ad, this.creative);

    this.addVastTrackerEvents();

    this.setState(
      {
        ads: this.state.ads.slice(1),
      },
      () => {
        if (this.props.antiFrod) {
          const pixels = this.getAdPixels();
          const frodPixels = [
            "ad.mail.ru",
            "ad.adriver.ru",
            "tps.doubleverify.com",
            "ad.doubleclick.net",
            "bs.serving-sys.ru",
          ];
          const hasFrod = pixels.some((pixel) => frodPixels.includes(pixel));

          if (hasFrod) {
            console.log("VAST has frod pixel");
            this.vastTracker.error();
            return this.showNextAd();
          }
        }

        if (this.ad.id === "empty") {
          console.log("4. VAST empty creative");
          this.vastTracker.error();
          return this.showNextAd();
        }

        if (!this.fileURL) {
          console.log("4. VAST fileURL not found");
          this.vastTracker.error();
          return this.showNextAd();
        }

        if (this.apiFramework === "VPAID") {
          // console.log("4. load VPAID");
          this.loadVPAID();

          return;
        }

        // console.log("4. play video");
        this.playVideo();
      }
    );
  };

  componentWillUnmount() {
    // console.log("Adv.componentWillUnmount()");
    clearTimeout(this.initAdTimeout);
    clearTimeout(this.loadVPAIDTimeout);
    clearTimeout(this.noBannerTimeout);
    this.removeVastTrackerEvents();
    this.removeCallbacksForVPAIDCreative();
    window.removeEventListener("message", this.onVPAIDReady);
  }

  addVastTrackerEvents() {
    // console.log("Adv.addVastTrackerEvents()");

    if (this.vastTracker) {
      this.vastTracker.on("creativeView", this.onCreativeView);
      this.vastTracker.on("start", this.onStart);
      this.vastTracker.on("skip-countdown", this.onSkipCountdown);
      this.vastTracker.on("rewind", this.onRewind);
      this.vastTracker.on("midpoint", this.onMidpoint);
      this.vastTracker.on("firstQuartile", this.onFirstQuartile);
      this.vastTracker.on("thirdQuartile", this.onThirdQuartile);
      this.vastTracker.on("resume", this.onResume);
      this.vastTracker.on("pause", this.onPause);
      this.vastTracker.on("mute", this.onMute);
      this.vastTracker.on("unmute", this.onUnmute);
      this.vastTracker.on("skip", this.onSkip);
      this.vastTracker.on("closeLinear", this.onCloseLinear);
      this.vastTracker.on("close", this.onClose);
      this.vastTracker.on("complete", this.onComplete);
      this.vastTracker.on("clickthrough", this.onClickThrough);
      this.vastTracker.on("fullscreen", this.onFullscreen);
      this.vastTracker.on("exitFullscreen", this.onExitFullscreen);
      window.addEventListener("beforeunload", this.onBeforeUnload);
    }
  }

  removeVastTrackerEvents() {
    // console.log("Adv.removeVastTrackerEvents()");

    if (this.vastTracker) {
      this.vastTracker.off("creativeView", this.onCreativeView);
      this.vastTracker.off("start", this.onStart);
      this.vastTracker.off("skip-countdown", this.onSkipCountdown);
      this.vastTracker.off("rewind", this.onRewind);
      this.vastTracker.off("midpoint", this.onMidpoint);
      this.vastTracker.off("firstQuartile", this.onFirstQuartile);
      this.vastTracker.off("thirdQuartile", this.onThirdQuartile);
      this.vastTracker.off("resume", this.onResume);
      this.vastTracker.off("pause", this.onPause);
      this.vastTracker.off("mute", this.onMute);
      this.vastTracker.off("unmute", this.onUnmute);
      this.vastTracker.off("skip", this.onSkip);
      this.vastTracker.off("closeLinear", this.onCloseLinear);
      this.vastTracker.off("close", this.onClose);
      this.vastTracker.off("complete", this.onComplete);
      this.vastTracker.off("clickthrough", this.onClickThrough);
      this.vastTracker.off("fullscreen", this.onFullscreen);
      this.vastTracker.off("exitFullscreen", this.onExitFullscreen);
      window.removeEventListener("beforeunload", this.onBeforeUnload);
    }
  }

  removeVPAIDIframe() {
    const iframe = document.getElementById("adloaderframe");

    if (iframe) {
      iframe.parentNode.removeChild(iframe);
    }
  }

  get renderVPAID() {
    const { t } = this.props;
    const { muted, currentTime } = this.state;

    const skipTime = this.getExtensionValue("skipTime");
    let skipDelay = getSkipTime(skipTime) || this.creative.skipDelay;
    const isOverTime = skipDelay > this.creative.duration;
    if (isOverTime) {
      skipDelay = DEFAULT_SKIP_TIME;
    }
    const isSkippable =
      this.props.vast.isSkippable && this.creative.duration > DEFAULT_SKIP_TIME;
    const skipTimeout = Math.floor(skipDelay - currentTime);
    const canSkip = isSkippable && skipTimeout < 1;
    const skipTime2 = this.getExtensionValue("skipTime2");
    const closeTime = getSkipTime(skipTime2);
    const canClose = skipTime2 && currentTime > closeTime;
    // const isClickable = this.getExtensionValue("isClickable");

    // console.table({
    //   muted,
    //   currentTime,
    //   skipTime,
    //   skipDelay,
    //   isSkippable,
    //   skipTimeout,
    //   canSkip,
    //   skipTime2,
    //   closeTime,
    //   canClose,
    //   isOverTime,
    //   duration: this.creative.duration,
    //   isClickable,
    // });

    const videoElement = (
      <StyledVpaidVideo
        playsInline
        autoPlay
        muted={muted}
        ref={this.videoRef}
        onTimeUpdate={this.onVideoTimeUpdate}
        aria-label="vpaid-video"
      />
    );

    return (
      <PageVisibility onChange={this.onChangeVisibility}>
        <InView as={Root} threshold={0.5} onChange={this.onChangeInView}>
          <StyledVpaidSlot ref={this.slotRef}>{videoElement}</StyledVpaidSlot>

          {canClose && (
            <StyledCloseButton onClick={this.onClickCloseButton} size="large">
              <CloseIcon />
            </StyledCloseButton>
          )}

          <StyledControls>
            {isSkippable && (
              <StyledSkipButton
                onClick={this.onClickSkipButton}
                variant="contained"
                disabled={!canSkip}
                size="small"
              >
                {canSkip
                  ? t("adv.skipAd")
                  : t("adv.skipAfter", { count: skipTimeout })}
              </StyledSkipButton>
            )}

            <ToggleMuteButton
              muted={muted}
              onClick={this.onClickToggleMuteButton}
            />

            {/* <FullscreenButton
              onFullScreenChange={this.onFullScreenChange}
              videoElement={videoElement}
              target={fullscreenTarget}
            /> */}
          </StyledControls>

          {this.props.vast.adLabel && (
            <StyledAdLabel variant="body1">{t("adv.ad")}</StyledAdLabel>
          )}
        </InView>
      </PageVisibility>
    );
  }

  get renderEmpty() {
    return (
      <div style={{ width: "100%", height: "100%" }} ref={this.emptyRef} />
    );
  }
  /**
   * Рендер компонента
   */
  render() {
    // console.log("Adv.render()");
    const { t, fullscreenTarget } = this.props;
    const { visible, muted, loading, error, status, currentTime } = this.state;

    if (loading) return this.renderEmpty;
    if (error) return this.renderEmpty;
    if (!visible) return this.renderEmpty;
    if (!this.creative) return this.renderEmpty;

    if (this.apiFramework === "VPAID") {
      return this.renderVPAID;
    }
    if (!this.fileURL) return this.renderEmpty;

    const skipTime = this.getExtensionValue("skipTime");
    let skipDelay = getSkipTime(skipTime) || this.creative.skipDelay;
    const isOverTime = skipDelay > this.creative.duration;
    if (isOverTime) {
      skipDelay = DEFAULT_SKIP_TIME;
    }
    const isSkippable =
      this.props.vast.isSkippable && this.creative.duration > DEFAULT_SKIP_TIME;
    const skipTimeout = Math.floor(skipDelay - currentTime);
    const canSkip = isSkippable && skipTimeout < 1;
    const skipTime2 = this.getExtensionValue("skipTime2");
    const closeTime = getSkipTime(skipTime2);
    const canClose = skipTime2 && currentTime > closeTime;

    // console.table({
    //   skipTime,
    //   skipDelay,
    //   isSkippable,
    //   skipTimeout,
    //   canSkip,
    //   skipTime2,
    //   closeTime,
    //   canClose,
    //   isOverTime,
    //   duration: this.creative.duration,
    // });

    const videoElement = (
      <StyledVideo
        key={this.fileURL}
        src={this.fileURL}
        playsInline
        // autoPlay
        muted={muted}
        ref={this.videoRef}
        onError={this.onVideoError}
        onEnded={this.onVideoEnded}
        onClick={this.onVideoClick}
        onDurationChange={this.onVideoDurationChange}
        onVolumeChange={this.onVideoVolumeChange}
        onPlay={this.onVideoPlay}
        onPause={this.onVideoPause}
        onTimeUpdate={this.onVideoTimeUpdate}
        onCanPlay={this.onVideoCanPlay}
        onPlaying={this.onPlaying}
        aria-label="vast-video"
      />
    );

    return (
      <PageVisibility onChange={this.onChangeVisibility}>
        <InView as={Root} threshold={0.5} onChange={this.onChangeInView}>
          {videoElement}

          {status !== "playing" && (
            <BigPlayButton
              onClick={this.playVideo}
              videoState={{
                status,
              }}
            />
          )}

          {canClose && (
            <StyledCloseButton onClick={this.onClickCloseButton} size="large">
              <CloseIcon />
            </StyledCloseButton>
          )}

          <StyledControls>
            {isSkippable && (
              <StyledSkipButton
                onClick={this.onClickSkipButton}
                variant="contained"
                disabled={!canSkip}
                size="small"
              >
                {canSkip
                  ? t("adv.skipAd")
                  : t("adv.skipAfter", { count: skipTimeout })}
              </StyledSkipButton>
            )}

            <ToggleMuteButton
              muted={muted}
              onClick={this.onClickToggleMuteButton}
            />

            <FullscreenButton
              onFullScreenChange={this.onFullScreenChange}
              videoElement={videoElement}
              target={fullscreenTarget}
            />
          </StyledControls>

          {this.props.vast.adLabel && (
            <StyledAdLabel variant="body1">{t("adv.ad")}</StyledAdLabel>
          )}
        </InView>
      </PageVisibility>
    );
  }

  onChangeVisibility = (isVisible) => {
    // console.log("onChangeVisibility", isVisible);

    const video = this.videoRef.current;

    if (isVisible) {
      // resume ad
      if (this.state.status !== "playing") {
        // console.log("resume ad video");
        video.play();
      }
    } else {
      // pause ad
      if (this.state.status !== "pause") {
        // console.log("pause ad video");
        video.pause();
      }
    }
  };

  onChangeInView = (inView) => {
    // console.log("onChangeInView", inView);

    const video = this.videoRef.current;

    if (inView) {
      // resume ad
      if (this.state.status !== "playing") {
        // console.log("resume ad video");
        video.play();
      }
    } else {
      // pause ad
      if (this.state.status !== "pause") {
        // console.log("pause ad video");
        video.pause();
      }
    }
  };

  /**
   * Получить значение параметра из блока <Extensions>
   */
  getExtensionValue(type) {
    // console.log("Adv.getExtensionValue()", this.ad);
    if (this.ad) {
      if (this.ad.extensions) {
        return this.ad.extensions.find((ext) => ext.attributes.type === type)
          ?.value;
        // if (extension) {
        //   if (extension.children) {
        //     if (extension.children[0]) {
        //       return extension.children[0].value;
        //     }
        //   }
        // }
      }
    }
  }

  /**
   * Получить URL видеофайла
   */
  get fileURL() {
    // console.log("Adv.fileURL()");
    if (this.creative) {
      if (this.creative.mediaFiles) {
        const mediaFile = this.creative.mediaFiles.find((file) =>
          SUPPORTED_MEDIAFILE_MIMETYPES.includes(file.mimeType)
        );
        if (mediaFile) {
          return mediaFile.fileURL;
        }
      }
    }
    return null;
  }

  /**
   * Получить apiFramework текущего креатива
   */
  get apiFramework() {
    // console.log("Adv.apiFramework()");
    if (this.creative) {
      if (this.creative.mediaFiles) {
        if (this.creative.mediaFiles[0]) {
          return this.creative.mediaFiles[0].apiFramework;
        }
      }
    }
    return null;
  }

  /**
   * Проверить креатив на соответствие спеке
   */
  checkVPAIDInterface(creative) {
    // console.log("Adv.checkVPAIDInterface()");
    if (
      creative.handshakeVersion &&
      typeof creative.handshakeVersion === "function" &&
      creative.initAd &&
      typeof creative.initAd === "function" &&
      creative.startAd &&
      typeof creative.startAd === "function" &&
      creative.stopAd &&
      typeof creative.stopAd === "function" &&
      creative.skipAd &&
      typeof creative.skipAd === "function" &&
      creative.resizeAd &&
      typeof creative.resizeAd === "function" &&
      creative.pauseAd &&
      typeof creative.pauseAd === "function" &&
      creative.resumeAd &&
      typeof creative.resumeAd === "function" &&
      creative.expandAd &&
      typeof creative.expandAd === "function" &&
      creative.collapseAd &&
      typeof creative.collapseAd === "function" &&
      creative.subscribe &&
      typeof creative.subscribe === "function" &&
      creative.unsubscribe &&
      typeof creative.unsubscribe === "function"
    ) {
      return true;
    }
    return false;
  }

  /**
   * Загрузить Vpaid
   */
  loadVPAID() {
    // console.log("5. Adv.loadVPAID()");

    const iframe = document.createElement("iframe");
    iframe.id = "adloaderframe";
    iframe.setAttribute(
      "style",
      "width:0;height:0;position:absolute;top:-10px;left:-10px;"
    );
    document.body.appendChild(iframe);

    iframe.contentWindow.document.write(
      `<html>
        <body>
        <script type="text/javascript" id="adloaderscript" src="${this.fileURL}"></script>
        <script type="text/javascript">window.parent.postMessage('adready', '${window.location.origin}');</script>
        </body>
      </html>`
    );

    this.loadVPAIDTimeout = setTimeout(() => {
      document.body.removeChild(iframe);
      this.showNextAd();
      console.log("LOAD_VPAID_TIMEOUT");
    }, LOAD_VPAID_TIMEOUT);
  }

  onVPAIDReady = (event) => {
    if (event.data !== "adready") return;

    clearTimeout(this.loadVPAIDTimeout);

    // console.log("Adv.onVPAIDReady()", event.data);

    const iframe = document.getElementById("adloaderframe");

    var fn = iframe.contentWindow["getVPAIDAd"];
    if (fn && typeof fn === "function") {
      this.VPAIDCreative = fn();

      if (!this.checkVPAIDInterface(this.VPAIDCreative)) {
        // console.log("The VPAIDCreative doesnt conform to the VPAID spec");
        this.showNextAd();
        return;
      }

      const version = this.VPAIDCreative.handshakeVersion("2.0");

      if (!version || version.toString() !== "2.0") {
        // console.log("invalide vpaid version", version);
        this.showNextAd();
        return;
      }

      // console.log("initAdTimeout");
      this.initAdTimeout = setTimeout(() => {
        // console.log("INIT_AD_TIMEOUT");
        document.body.removeChild(iframe);
        this.showNextAd();
      }, INIT_AD_TIMEOUT);

      this.setCallbacksForVPAIDCreative();

      const slot = this.slotRef.current;
      const videoSlot = this.videoRef.current;
      const width = slot.clientWidth; // указывает доступную ширину области отображения рекламы в пикселях
      const height = slot.clientHeight; // указывает доступную высоту области отображения рекламы в пикселях
      const viewMode = "normal"; // указывает "normal", "thumbnail" или "fullscreen" в качестве режима просмотра для видеопроигрывателя в соответствии с определением издателя. По умолчанию «нормальный»
      const desiredBitrate = 500; // указывает желаемую скорость передачи в битах в килобитах в секунду (кбит / с). Рекламный блок может использовать эту информацию, чтобы выбрать подходящий битрейт для любого потокового контента.
      const creativeData = {
        AdParameters: this.creative.adParameters,
      };

      const environmentVars = {
        slot,
        videoSlot,
        videoSlotCanAutoPlay: true,
      };

      // console.log("VPAID initAd", this.VPAIDCreative, {
      //   width,
      //   height,
      //   viewMode,
      //   desiredBitrate,
      //   creativeData,
      //   environmentVars,
      // });

      this.VPAIDCreative.initAd(
        width,
        height,
        viewMode,
        desiredBitrate,
        creativeData,
        environmentVars
      );

      if (this.props.muted) {
        this.VPAIDCreative.setAdVolume(0);
      }
    }
  };

  /**
   * Навесим слушатели событий для vpaid
   */
  setCallbacksForVPAIDCreative = () => {
    // console.log("Adv.setCallbacksForVPAIDCreative()");
    var callbacks = {
      AdStarted: this.onStartAd,
      AdStopped: this.onStopAd,
      AdSkipped: this.onSkipAd,
      AdLoaded: this.onAdLoaded,
      AdLinearChange: this.onAdLinearChange,
      AdSizeChange: this.onAdSizeChange,
      AdExpandedChange: this.onAdExpandedChange,
      AdSkippableStateChange: this.onAdSkippableStateChange,
      AdDurationChange: this.onAdDurationChange,
      AdRemainingTimeChange: this.onAdRemainingTimeChange,
      AdVolumeChange: this.onAdVolumeChange,
      AdImpression: this.onAdImpression,
      AdClickThru: this.onAdClickThru,
      AdInteraction: this.onAdInteraction,
      AdVideoStart: this.onAdVideoStart,
      AdVideoFirstQuartile: this.onAdVideoFirstQuartile,
      AdVideoMidpoint: this.onAdVideoMidpoint,
      AdVideoThirdQuartile: this.onAdVideoThirdQuartile,
      AdVideoComplete: this.onAdVideoComplete,
      AdUserAcceptInvitation: this.onAdUserAcceptInvitation,
      AdUserMinimize: this.onAdUserMinimize,
      AdUserClose: this.onAdUserClose,
      AdPaused: this.onAdPaused,
      AdPlaying: this.onAdPlaying,
      AdError: this.onAdError,
      AdLog: this.onAdLog,
    };
    // Looping through the object and registering each of the callbacks with the creative;
    for (var eventName in callbacks) {
      this.VPAIDCreative.subscribe(callbacks[eventName], eventName, this);
    }
  };

  /**
   * Уберем слушатели событий для vpaid
   */
  removeCallbacksForVPAIDCreative = () => {
    // console.log("Adv.removeCallbacksForVPAIDCreative()");
    if (!this.VPAIDCreative) return;

    var callbacks = {
      AdStarted: this.onStartAd,
      AdStopped: this.onStopAd,
      AdSkipped: this.onSkipAd,
      AdLoaded: this.onAdLoaded,
      AdLinearChange: this.onAdLinearChange,
      AdSizeChange: this.onAdSizeChange,
      AdExpandedChange: this.onAdExpandedChange,
      AdSkippableStateChange: this.onAdSkippableStateChange,
      AdDurationChange: this.onAdDurationChange,
      AdRemainingTimeChange: this.onAdRemainingTimeChange,
      AdVolumeChange: this.onAdVolumeChange,
      AdImpression: this.onAdImpression,
      AdClickThru: this.onAdClickThru,
      AdInteraction: this.onAdInteraction,
      AdVideoStart: this.onAdVideoStart,
      AdVideoFirstQuartile: this.onAdVideoFirstQuartile,
      AdVideoMidpoint: this.onAdVideoMidpoint,
      AdVideoThirdQuartile: this.onAdVideoThirdQuartile,
      AdVideoComplete: this.onAdVideoComplete,
      AdUserAcceptInvitation: this.onAdUserAcceptInvitation,
      AdUserMinimize: this.onAdUserMinimize,
      AdUserClose: this.onAdUserClose,
      AdPaused: this.onAdPaused,
      AdPlaying: this.onAdPlaying,
      AdError: this.onAdError,
      AdLog: this.onAdLog,
    };
    // Looping through the object and registering each of the callbacks with the creative;
    for (var eventName in callbacks) {
      this.VPAIDCreative.unsubscribe(callbacks[eventName], eventName);
    }
  };

  mute = (muted) => {
    // console.log("Adv.mute()", { muted });
    const video = this.videoRef.current;
    video.muted = muted;
    this.setState({ muted });
    this.props.onChangeMute(muted);
  };

  playVideo = () => {
    const video = this.videoRef.current;
    const promise = video.play();
    // console.log("Adv.play()", {
    //   muted: video.muted
    // });
    if (promise !== undefined) {
      promise.catch((error) => {
        // console.log("Adv.play()", error.name, error.message);
        console.dir(error);

        // if (error.name === "NotAllowedError") {
        console.log(
          "Adv: Не удалось воспроизвести видеорекламу со звуком, отключаю звук"
        );
        video.muted = true;
        video.play();
        this.mute(true);
        // }
      });
    }
  };

  /**
   * Обработчик события "creativeView" (Ролик выбран) в VASTTracker
   *
   * XML может содержать несколько креативов для различных платформ. Событие вызывается при выборе файла для проигрывания.
   * Событие creativeView запрашивается один раз и НЕ должно запрашиваться каждую секунду.
   */
  onCreativeView = () => {
    // console.log("Adv.onCreativeView()");
    // console.log("VASTTracker.creativeView");
    this.createSponsorImpression(CREATIVEVIEW);
    this.triggerPostMessage(CREATIVEVIEW);
  };

  /**
   * Обработчик события "start" (Начало просмотра) в VASTTracker
   *
   * Первая секунда показа креатива
   * События impression, creativeView и start вызываются практически одновременно на первом кадре ролика в описанной последовательности.
   */
  onStart = () => {
    // console.log("Adv.onStart()");
    // console.log("VASTTracker.start");
    this.createSponsorImpression(IMPRESSION);
    this.triggerPostMessage(IMPRESSION);

    this.createSponsorImpression(START);
    this.triggerPostMessage(START);

    if (this.props.vast.impressionPixel) {
      trackAdPixel(this.props.vast.impressionPixel);
    }

    clearTimeout(this.noBannerTimeout);

    this.setState({ played: true });
  };

  /**
   * Обработчик события "skip-countdown" (Пропустить обратный отсчет) в VASTTracker
   */
  onSkipCountdown = () => {
    // console.log("Adv.onSkipCountdown()");
    // console.log("VASTTracker.skip-countdown");
    // createSponsorImpression(this.props.vast.token, SKIP);
  };

  /**
   * Обработчик события "rewind" (Просмотр сначала) в VASTTracker
   *
   * Возобновление просмотра того же ролика с начала по желанию пользователя.
   */
  onRewind = () => {
    // console.log("Adv.onRewind()");
    // console.log("VASTTracker.rewind");
  };

  /**
   * Обработчик события "firstQuartile" (Первая четверть просмотра) в VASTTracker
   *
   * Завершение показа четверти ролика, или четверти времени, указанного для показа оверлея в атрибуте minSuggestedDuration тега NonLinear.
   */
  onFirstQuartile = () => {
    // console.log("Adv.onFirstQuartile()");
    // console.log("VASTTracker.firstQuartile");
    this.createSponsorImpression(FIRSTQUARTILE);
    this.triggerPostMessage(FIRSTQUARTILE);
  };

  /**
   * Обработчик события "midpoint" (Середина просмотра) в VASTTracker
   *
   * Завершение показа половины ролика, или половины времени,
   * указанного для показа оверлея в атрибуте minSuggestedDuration тега NonLinear.
   */
  onMidpoint = () => {
    // console.log("Adv.onMidpoint()");
    // console.log("VASTTracker.midpoint");
    this.createSponsorImpression(MIDPOINT);
    this.triggerPostMessage(MIDPOINT);
  };

  /**
   * Обработчик события "thirdQuartile" (Третья четверть просмотра) в VASTTracker
   *
   * Завершение показа трёх четвертей ролика, или трёх четвертей времени,
   * указанного для показа оверлея в атрибуте minSuggestedDuration тега NonLinear.
   */
  onThirdQuartile = () => {
    // console.log("Adv.onThirdQuartile()");
    // console.log("VASTTracker.thirdQuartile");
    this.createSponsorImpression(THIRDQUARTILE);
    this.triggerPostMessage(THIRDQUARTILE);
  };

  /**
   * Обработчик события "resume" (Продолжение просмотра рекламного ролика) в VASTTracker
   *
   * Пользователь возобновил просмотр рекламного ролика (самостоятельно или в соответствии с механикой работы видеоплеера).
   */
  onResume = () => {
    // console.log("Adv.onResume()");
    // this.setState({ status: "playing" });
  };

  /**
   * Обработчик события "pause" (Постановка рекламного ролика на паузу) в VASTTracker
   *
   * Пользователь поставил на паузу рекламный ролик (самостоятельно или в соответствии с механикой работы видеоплеера).
   */
  onPause = () => {
    // console.log("Adv.onPause()");
    // console.log("VASTTracker.pause");
    // this.setState({ status: "paused" });
  };

  /**
   * Обработчик события "mute" (Выключить звук) в VASTTracker
   *
   * Слайдер управления громкостью рекламных роликов по умолчанию выставляется на 40%
   */
  onMute = () => {
    // console.log("Adv.onMute()");
    // console.log("VASTTracker.mute");
    this.createSponsorImpression(MUTE);
    this.triggerPostMessage(MUTE);
  };

  /**
   * Обработчик события "unmute" (Включить звук) в VASTTracker
   *
   * Слайдер управления громкостью рекламных роликов по умолчанию выставляется на 40%
   */
  onUnmute = () => {
    // console.log("Adv.onUnmute()");
    // console.log("VASTTracker.unmute");
    this.createSponsorImpression(UNMUTE);
    this.triggerPostMessage(UNMUTE);
  };

  /**
   * Обработчик события "skip" (Клик по плашке «пропустить рекламу») в VASTTracker
   *
   * Пользователь нажал на плашку «пропустить рекламу». Не путать с событием close.
   */
  onSkip = () => {
    // console.log("Adv.onSkip()");
    // console.log("VASTTracker.skip");
    this.createSponsorImpression(SKIP);
    this.triggerPostMessage(SKIP);
  };

  /**
   * Обработчик события "closeLinear" (Клик на крестик закрытия) в VASTTracker
   *
   * Пользователь кликнул на крестик закрытия
   */
  onCloseLinear = () => {
    // console.log("Adv.onCloseLinear()");
    // console.log("VASTTracker.closeLinear");
    this.createSponsorImpression(CLOSE);
    this.triggerPostMessage(CLOSE);
  };

  /**
   * Обработчик события "close" (Клик на крестик закрытия) в VASTTracker
   *
   * Пользователь кликнул на крестик закрытия
   */
  onClose = () => {
    // console.log("Adv.onClose()");
    // console.log("VASTTracker.close");
    if (!this.state.played) {
      // если закрыли до того как началось воспроизведение, считаем что это NO_BANNER
      const error = new Error("close before start");
      console.log(error);
      this.setState({ visible: false });
      this.createSponsorImpression(NOBANNER_ERROR);
      this.triggerPostMessage(NOBANNER_ERROR);
      this.props.onError(error);
      return;
    }

    this.createSponsorImpression(CLOSE);
    this.triggerPostMessage(CLOSE);
  };

  /**
   * Обработчик события "complete" (Окончание просмотра) в VASTTracker
   *
   * Ролик просмотрен до конца. Оверлей был виден всё время, указанное в атрибуте minSuggestedDuration тега NonLinear.
   * Событие complete вызывается при полном просмотре ролика и не вызывается при переходе или закрытии (close).
   */
  onComplete = () => {
    // console.log("Adv.onComplete()");
    // console.log("VASTTracker.complete");
    // this.props.onComplete();
    if (!this.state.played) {
      // если событие случилось до того как началось воспроизведение, считаем что это NO_BANNER
      const error = new Error("complete before start");
      console.log(error);
      this.setState({ visible: false });
      this.createSponsorImpression(NOBANNER_ERROR);
      this.triggerPostMessage(NOBANNER_ERROR);
      this.props.onError(error);
      return;
    }

    this.createSponsorImpression(COMPLETE);
    this.triggerPostMessage(COMPLETE);
  };

  /**
   * Обработчик события "clickthrough" (Клик по плееру?) в VASTTracker
   * Вызывается на VPAID
   * На VAST хз
   */
  onClickThrough = (url) => {
    this.createSponsorImpression(CLICKTHROUGH);
    if (this.props.vast.token) {
      createSponsorClick(this.props.vast.token);
    }
    this.triggerPostMessage(CLICKTHROUGH);

    if (this.props.vast.clickPixel) {
      trackAdPixel(this.props.vast.clickPixel);
    }

    if (url) {
      window.open(url, "_blank");
    }
  };

  /**
   * Обработчик события "fullscreen" (Переход в полный экран) в VASTTracker
   */
  onFullscreen = () => {
    // console.log("Adv.onFullscreen()");
    // console.log("VASTTracker.fullscreen");
    this.createSponsorImpression(FULLSCREEN);
    this.triggerPostMessage(FULLSCREEN);
  };

  /**
   * Обработчик события "exitFullscreen" (Выход из полноэкранного режима) в VASTTracker
   */
  onExitFullscreen = () => {
    // console.log("Adv.onExitFullscreen()");
    // console.log("VASTTracker.exitFullscreen");
    this.createSponsorImpression(EXIT_FULLSCREEN);
    this.triggerPostMessage(EXIT_FULLSCREEN);
  };

  /**
   * Обработчик события "beforeunload" (Закрытие вкладки браузера) в window
   */
  onBeforeUnload = () => {
    // console.log("Adv.onBeforeUnload()");
    if (this.vastTracker) {
      this.vastTracker.close();
    }
  };

  /**
   * Обработчик события "error" на элементе <video>
   */
  onVideoError = (event) => {
    // console.log("Adv.onVideoError()");
    console.log("video.onVideoError", event);

    if (this.vastTracker) {
      this.vastTracker.error();
    }

    this.createSponsorImpression(ERROR);
    this.triggerPostMessage(ERROR);
  };

  /**
   * Обработчик события "ended" на элементе <video>
   */
  onVideoEnded = () => {
    // console.log("Adv.onVideoEnded()");
    if (this.vastTracker) {
      this.vastTracker.complete();
    }
    this.setState(
      (state) => ({
        completedAdCount: state.completedAdCount + 1,
      }),
      this.showNextAd
    );
  };

  /**
   * Обработчик события "click" на элементе <video> (для VPAID не работает)
   * Это только для обычных вастов, не VPAID
   * isClickable это только для обычных вастов
   */
  onVideoClick = (event) => {
    if (!this.vastTracker) return;
    if (this.state.clicked) return;

    const isClickable = this.getExtensionValue("isClickable");

    if (isClickable) {
      if (isClickable === "0") {
        return;
      }
    }

    this.vastTracker.click();
    event.target.pause();
    this.vastTracker.setPaused(true);
    this.setState({ clicked: true });
  };

  /**
   * Обработчик события "durationchange" на элементе <video>
   */
  onVideoDurationChange = (event) => {
    // console.log("Adv.onVideoDurationChange()");
    if (this.vastTracker) {
      this.vastTracker.setDuration(event.target.duration);
    }
  };

  /**
   * Обработчик события "volumechange" на элементе <video>
   */
  onVideoVolumeChange = (event) => {
    // console.log("Adv.onVideoVolumeChange()");
    if (this.vastTracker) {
      this.vastTracker.setMuted(event.target.muted);
    }
  };

  /**
   * Обработчик события "play" на элементе <video>
   */
  onVideoPlay = () => {
    // console.log("Adv.onVideoPlay()");
    if (this.vastTracker) {
      this.setState({ status: "playing" });
      this.vastTracker.setPaused(false);
    }
  };

  /**
   * Обработчик события "pause" на элементе <video>
   */
  onVideoPause = () => {
    // console.log("Adv.onVideoPause()");
    if (this.vastTracker) {
      this.setState({ status: "paused" });
      // this.vastTracker.setPaused(true);
    }
  };

  /**
   * Обработчик события "timeupdate" на элементе <video>
   */
  onVideoTimeUpdate = (event) => {
    // console.log("Adv.onVideoTimeUpdate()");
    if (this.vastTracker) {
      const { currentTime } = event.target;
      this.vastTracker.setProgress(currentTime);
      this.setState({ currentTime });
      this.props.onUpdate(currentTime);
    }
  };

  /**
   * Обработчик события "canplay" на элементе <video>
   */
  onVideoCanPlay = () => {
    // console.log("Adv.onVideoCanPlay()");
    if (this.vastTracker) {
      this.vastTracker.trackImpression();
    }
  };

  /**
   * Обработчик события "click" на кнопке "Перейти на сайт рекламодателя"
   */
  onClickSiteButton = () => {
    // console.log("Adv.onClickSiteButton()");
    if (this.vastTracker) {
      this.vastTracker.click();
    }
  };

  onClickCloseButton = () => {
    // console.log("Adv.onClickCloseButton()");
    if (this.vastTracker) {
      this.vastTracker.close();
    }
    this.showNextAd();
  };

  onClickSkipButton = () => {
    // console.log("Adv.onClickSkipButton()");
    if (this.vastTracker) {
      this.vastTracker.skip();
    }
    this.showNextAd();
  };

  onClickToggleMuteButton = () => {
    // console.log("Adv.onClickToggleMuteButton()");
    const muted = !this.state.muted;
    ls.setItem("muted", muted);
    this.props.onChangeMute(muted);
    this.setState({ muted });
    this.videoRef.current.muted = muted;
    if (this.VPAIDCreative) {
      this.VPAIDCreative.setAdVolume(muted ? 0 : 1);
    }
  };

  /**
   * Обработчик события "fullscreen" в кнопке "развернуть на весь экран"
   */
  onFullScreenChange = (isFullscreen) => {
    // console.log("Adv.onFullScreenChange()");
    if (this.vastTracker) {
      this.vastTracker.setFullscreen(isFullscreen);
    }
  };

  ////////// VPAID ////////////
  /**
   * Обработчик события "AdStarted" в vpaid
   */
  onStartAd = () => {
    // console.log("Adv.onStartAd()");

    if (this.vastTracker) {
      this.vastTracker.track("start", { once: true });
    }
  };
  /**
   * Обработчик события "AdStopped" в vpaid
   */
  onStopAd = () => {
    // console.log("Adv.onStopAd()");
  };
  /**
   * Обработчик события "AdSkipped" в vpaid
   */
  onSkipAd = () => {
    // console.log("Adv.onSkipAd()");
    if (this.vastTracker) {
      this.vastTracker.skip();
    }
    this.showNextAd();
  };
  /**
   * Обработчик события "AdLoaded" в vpaid
   */
  onAdLoaded = () => {
    // console.log("Adv.onAdLoaded()");
    // console.timeEnd("initAdTimeout");
    clearTimeout(this.initAdTimeout);

    this.VPAIDCreative.startAd();
  };
  /**
   * Обработчик события "AdLinearChange" в vpaid
   */
  onAdLinearChange = () => {
    // console.log("Adv.onAdLinearChange()");
    // console.log("MVP:VPAID:AdLinearChange", this.VPAIDCreative.getAdLinear());
  };
  /**
   * Обработчик события "AdSizeChange" в vpaid
   */
  onAdSizeChange = () => {
    // console.log("Adv.onAdSizeChange()");
    // console.log(
    //   "Ad size changed to: w=" +
    //     this.VPAIDCreative.getAdWidth() +
    //     " h=" +
    //     this.VPAIDCreative.getAdHeight()
    // );
  };
  /**
   * Обработчик события "AdExpandedChange" в vpaid
   */
  onAdExpandedChange = () => {
    // console.log("Adv.onAdExpandedChange()");
    // console.log("MVP:VPAID:AdExpandedChange", this.VPAIDCreative.getAdExpanded());
  };
  /**
   * Обработчик события "AdSkippableStateChange" в vpaid
   */
  onAdSkippableStateChange = () => {
    // console.log("Adv.onAdSkippableStateChange()");
    // console.log("MVP:VPAID:AdSkippableStateChange", this.VPAIDCreative.getAdSkippableState());
  };
  /**
   * Обработчик события "AdDurationChange" в vpaid
   */
  onAdDurationChange = () => {
    // console.log("Adv.onAdDurationChange()");
    const duration = this.VPAIDCreative.getAdDuration();
    // console.log("Ad Duration Changed to: " + duration);
    if (this.vastTracker) {
      this.vastTracker.setDuration(duration);
    }
  };
  /**
   * Обработчик события "AdRemainingTimeChange" в vpaid
   */
  onAdRemainingTimeChange = () => {
    // console.log("Adv.onAdRemainingTimeChange()");
    // console.log("MVP:VPAID:AdRemainingTimeChange", this.VPAIDCreative.getAdRemainingTime());
  };
  /**
   * Обработчик события "AdVolumeChange" в vpaid
   */
  onAdVolumeChange = () => {
    // console.log("Adv.onAdVolumeChange()");
    // console.log("MVP:VPAID:AdVolumeChange", this.VPAIDCreative.getAdVolume());
    // console.log("video muted", this.videoRef.current.muted);
    if (this.vastTracker) {
      this.vastTracker.track("mute", { once: true });
      this.props.onChangeMute(this.videoRef.current.muted);
    }
  };
  /**
   * Обработчик события "AdImpression" в vpaid
   */
  onAdImpression = () => {
    // console.log("Adv.onAdImpression()");
    // console.log("MVP:VPAID:AdImpression");
    if (this.vastTracker) {
      // this.vastTracker.load();
      this.vastTracker.trackImpression();
    }
  };
  /**
   * Обработчик события "AdClickThru" в vpaid
   */
  onAdClickThru = (url) => {
    if (!this.vastTracker) return;
    if (this.state.clicked) return;

    this.vastTracker.click(url);
    this.vastTracker.setPaused(true);

    this.setState({ clicked: true });
  };
  /**
   * Обработчик события "AdInteraction" в vpaid
   */
  onAdInteraction = (/* id */) => {
    // console.log("Adv.onAdInteraction()");
    // console.log("MVP:VPAID:AdInteraction", id);
  };
  /**
   * Обработчик события "AdVideoStart" в vpaid
   */
  onAdVideoStart = () => {
    // console.log("Adv.onAdVideoStart()");
    if (this.vastTracker) {
      this.vastTracker.track("start", { once: true });
    }
  };
  /**
   * Обработчик события "AdVideoFirstQuartile" в vpaid
   */
  onAdVideoFirstQuartile = () => {
    // console.log("Adv.onAdVideoFirstQuartile()");
    if (this.vastTracker) {
      this.vastTracker.track("firstQuartile", { once: true });
    }
  };
  /**
   * Обработчик события "AdVideoMidpoint" в vpaid
   */
  onAdVideoMidpoint = () => {
    // console.log("Adv.onAdVideoMidpoint()");
    if (this.vastTracker) {
      this.vastTracker.track("midpoint", { once: true });
    }
  };
  /**
   * Обработчик события "AdVideoThirdQuartile" в vpaid
   */
  onAdVideoThirdQuartile = () => {
    // console.log("Adv.onAdVideoThirdQuartile()");
    if (this.vastTracker) {
      this.vastTracker.track("thirdQuartile", { once: true });
    }
  };
  /**
   * Обработчик события "AdVideoComplete" в vpaid
   */
  onAdVideoComplete = () => {
    // console.log("Adv.onAdVideoComplete()");
    if (this.vastTracker) {
      this.vastTracker.complete();
    }

    const completedAdCount = this.state.completedAdCount + 1;

    this.setState({ completedAdCount }, this.showNextAd);
  };
  /**
   * Обработчик события "AdUserAcceptInvitation" в vpaid
   */
  onAdUserAcceptInvitation = () => {
    // console.log("Adv.onAdUserAcceptInvitation()");
    if (this.vastTracker) {
      this.vastTracker.track("acceptInvitation", { once: true });
    }
  };
  /**
   * Обработчик события "AdUserMinimize" в vpaid
   */
  onAdUserMinimize = () => {
    // console.log("Adv.onAdUserMinimize()");
    if (this.vastTracker) {
      this.vastTracker.track("collapse", { once: true });
    }
  };
  /**
   * Обработчик события "AdUserClose" в vpaid
   */
  onAdUserClose = () => {
    // console.log("Adv.onAdUserClose()");

    if (this.vastTracker) {
      this.vastTracker.close();
    }
    this.showNextAd();
  };
  /**
   * Обработчик события "AdPaused" в vpaid
   */
  onAdPaused = () => {
    // console.log("Adv.onAdPaused()");
    if (this.vastTracker) {
      this.vastTracker.setPaused(true);
    }
  };
  /**
   * Обработчик события "AdPlaying" в vpaid
   */
  onAdPlaying = () => {
    // console.log("Adv.onAdPlaying()");
    if (this.vastTracker) {
      this.vastTracker.setPaused(false);
    }
  };
  /**
   * Обработчик события "AdError" в vpaid
   */
  onAdError = (message) => {
    console.log("Adv.onAdError()", message);

    if (this.vastTracker) {
      this.vastTracker.error();
    }

    this.createSponsorImpression(ERROR);
    this.triggerPostMessage(ERROR);

    this.props.onError(message);

    // this.showNextAd();
  };
  /**
   * Обработчик события "AdLog" в vpaid
   */
  onAdLog = (message) => {
    console.log("Adv.onAdLog()", message);
  };

  triggerPostMessage = (eventName) => {
    postMessage({
      eventName,
      creative: "SponsorVideo",
      place: this.props.vastPlace,
    });
  };

  getAdPixels = () => {
    try {
      return Array.from(
        new Set(
          [
            this.ad.errorURLTemplates,
            this.ad.impressionURLTemplates?.map((url) => url?.url),
            this.ad.extensions.map((ext) => ext?.value),
            Object.values(this.creative.trackingEvents),
          ]
            .flat(2)
            .filter((ext) => ext?.startsWith("http"))
            .map((url) => {
              try {
                const { hostname } = new URL(url);
                return hostname;
              } catch (error) {
                console.log(error);
              }
            })
            .filter(Boolean)
        )
      );
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  createSponsorImpression = (eventName) => {
    if (this.props.vast.token) {
      const pixels = this.getAdPixels();

      createSponsorImpression(this.props.vast.token, "Vast/Vpaid", eventName, {
        pixels,
      });
    }
  };
}

export default withTranslation()(Vast);
