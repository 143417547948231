import React, { useState } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { useViewer } from "core/components/ViewerProvider";
import { useMutation } from "@apollo/client";
import { CreateProductFeedbackMutation } from "arena/mutations";
import { useTranslation } from "react-i18next";

export default function ProductFeedbackForm({ productId }) {
  const { t } = useTranslation("arena");

  const viewer = useViewer();
  const [message, setMessage] = useState("");

  const [createProductFeedback, { loading, called }] = useMutation(
    CreateProductFeedbackMutation,
    {
      variables: {
        input: {
          message,
          productId,
        },
      },
    }
  );

  const onClickForm = () => {
    if (!viewer) {
      document.querySelector(".js-sign-in").click();
    }
  };

  const onSubmitForm = (event) => {
    event.preventDefault();
    createProductFeedback();
  };

  const onChangeMessage = (event) => {
    event.preventDefault();
    setMessage(event.target.value);
  };

  return (
    <form onClick={onClickForm} onSubmit={onSubmitForm}>
      {called ? (
        <Alert variant="filled" severity="success">
          <AlertTitle>{t("productFeedback.called.title")}</AlertTitle>

          {t("productFeedback.called.text", {
            email: viewer.email,
          })}
        </Alert>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" color="textPrimary">
              {t("productFeedback.title")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label={t("productFeedback.message")}
              helperText=""
              fullWidth
              multiline
              maxRows={15}
              value={message}
              onChange={onChangeMessage}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              size="large"
              color="primary"
              type="submit"
              disabled={loading || message.length < 1}
            >
              {t("productFeedback.submit")}
            </Button>
          </Grid>
        </Grid>
      )}
    </form>
  );
}

ProductFeedbackForm.propTypes = {
  productId: PropTypes.string.isRequired,
};
