import React from "react";
import PropTypes from "prop-types";
import DialogContent from "@mui/material/DialogContent";
import { withSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import SettingsHeader from "arena/components/SettingsHeader";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ErrorScreen from "core/components/ErrorScreen";
import ConnectedTvIcon from "@mui/icons-material/ConnectedTv";
import { useQuery } from "@apollo/client";
import { PromocodesQuery } from "arena/queries";
import getPromocodeTitle from "core/utils/getPromocodeTitle";

function SettingsPromocodes({ tabs, onClose, enqueueSnackbar }) {
  const { t } = useTranslation("arena");

  const onError = (error) => {
    enqueueSnackbar(error.message, {
      variant: "error",
    });
  };

  const { data } = useQuery(PromocodesQuery, {
    fetchPolicy: "cache-and-network",
    onError,
  });

  const promocodes = data?.promocodes?.nodes || [];

  return (
    <>
      <SettingsHeader
        onClose={onClose}
        title={t("settingsPromocodes.title")}
        tabs={tabs}
      />

      {promocodes.length === 0 && (
        <DialogContent style={{ minHeight: 317 + 64, display: "flex" }}>
          <ErrorScreen
            icon={ConnectedTvIcon}
            title={t("settingsPromocodes.empty.title")}
            text={t("settingsPromocodes.empty.text")}
            button={null}
          />
        </DialogContent>
      )}

      {promocodes.length > 0 && (
        <List style={{ minHeight: 317 + 64 }}>
          {promocodes.map((promocode, index) => {
            let title = getPromocodeTitle(promocode);
            return (
              <ListItem
                key={promocode.id}
                divider={index + 1 < promocodes.length}
              >
                <ListItemText primary={title} />
              </ListItem>
            );
          })}
        </List>
      )}
    </>
  );
}

SettingsPromocodes.propTypes = {
  onClose: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  tabs: PropTypes.node,
};

export default withSnackbar(SettingsPromocodes);
