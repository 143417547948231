import { useContext } from "react";
import PropTypes from "prop-types";
import HttpContext from "core/utils/httpContext";

export default function Status({ code, children }) {
  let context = useContext(HttpContext);

  if (code) {
    context.status = code;
  }

  return children;
}

Status.propTypes = {
  code: PropTypes.number,
  children: PropTypes.node,
};
