import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { durationFormat } from "core/utils/string";

const StyledTypography = styled(Typography)(({ theme }) => ({
  display: "none",
  alignItems: "center",
  marginLeft: 10,
  fontSize: "0.75rem",

  ["@media (min-width: 320px)"]: {
    display: "flex",
  },

  [theme.breakpoints.up("sm")]: {
    fontSize: "0.875rem",
  },
}));

export default function CurrentTime({ liveSeekBar }) {
  if (!liveSeekBar) return null;
  if (!liveSeekBar.offset) return null;

  return (
    <StyledTypography component="span" noWrap variant="body2">
      {"-" + durationFormat(liveSeekBar.offset)}
    </StyledTypography>
  );
}

CurrentTime.propTypes = {
  liveSeekBar: PropTypes.shape({
    offset: PropTypes.number.isRequired,
  }),
};
