import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SponsorMultiAdv from "core/components/SponsorMultiAdv";

export default function SponsorMidAdv({
  videoState,
  contentId,
  rightholderId,
  sponsorInStreamBannerPlace,
  sponsorCornerBannerPlace,
  fullscreenTarget,
  setInStreamBannerHeight,
  setCornerBannerHeight,
  firstImpressionInterval,
  impressionInterval,
}) {
  const [playedTime, setPlayedTime] = useState(0);
  const [playedCount, setPlayedCount] = useState(0);

  useEffect(() => {
    if (!process.browser) return;
    if (videoState.status !== "playing") return;

    const update = () => {
      setPlayedTime((t) => t + 1);
    };

    const timer = setInterval(update, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [videoState.status]);

  if (!process.browser) return null;
  if (videoState.isEnded) return null;
  if (playedCount === 0) {
    if (playedTime < firstImpressionInterval) return null;
  } else {
    if (playedTime < impressionInterval) return null;
  }

  const onStart = () => {
    //
  };

  const onFinish = () => {
    setPlayedCount((count) => count + 1);
    setPlayedTime(0);
  };

  const inStreamBannerPlace = sponsorInStreamBannerPlace;
  const cornerBannerPlace = sponsorCornerBannerPlace;

  return (
    <SponsorMultiAdv
      inStreamBannerPlace={inStreamBannerPlace}
      cornerBannerPlace={cornerBannerPlace}
      contentId={contentId}
      rightholderId={rightholderId}
      fullscreenTarget={fullscreenTarget}
      onStart={onStart}
      onFinish={onFinish}
      setInStreamBannerHeight={setInStreamBannerHeight}
      setCornerBannerHeight={setCornerBannerHeight}
    />
  );
}

SponsorMidAdv.defaultProps = {
  firstImpressionInterval: 30,
};

SponsorMidAdv.propTypes = {
  videoState: PropTypes.shape({
    status: PropTypes.oneOf(["playing", "paused"]).isRequired,
    isEnded: PropTypes.bool,
  }).isRequired,
  firstImpressionInterval: PropTypes.number.isRequired,
  impressionInterval: PropTypes.number.isRequired,
  contentId: PropTypes.string.isRequired,
  rightholderId: PropTypes.string.isRequired,
  sponsorInStreamBannerPlace: PropTypes.string.isRequired,
  sponsorCornerBannerPlace: PropTypes.string.isRequired,
  fullscreenTarget: PropTypes.string.isRequired,
  setInStreamBannerHeight: PropTypes.func.isRequired,
  setCornerBannerHeight: PropTypes.func.isRequired,
};
