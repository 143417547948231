import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

const PREFIX = "ProductQA";

const classes = {
  root: `${PREFIX}-root`,
  cardActionArea: `${PREFIX}-cardActionArea`,
  cardContent: `${PREFIX}-cardContent`,
  title: `${PREFIX}-title`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.root}`]: {},

  [`& .${classes.cardActionArea}`]: {
    padding: theme.spacing(2),
  },

  [`& .${classes.cardContent}`]: {
    padding: theme.spacing(2),
  },

  [`& .${classes.title}`]: {
    lineHeight: 1.4,
  },
}));

export default function ProductQA({ product }) {
  const { t } = useTranslation("arena");

  if (!product) return null;

  const questions = [
    {
      title: t("productQA.q1.title"),
      text: t("productQA.q1.text"),
    },
    {
      title: t("productQA.q2.title"),
      text: t("productQA.q2.text"),
    },
    {
      title: t("productQA.q3.title"),
      text: t("productQA.q3.text"),
    },
    {
      title: t("productQA.q4.title"),
      text: t("productQA.q4.text"),
    },
    {
      title: t("productQA.q5.title"),
      text: t("productQA.q5.text"),
    },
    {
      title: t("productQA.q6.title"),
      text: t("productQA.q6.text"),
    },
  ];

  return (
    <StyledGrid container spacing={3}>
      {questions.map((question, index) => (
        <Grid item xs={12} key={index}>
          <Typography
            variant="h6"
            color="textPrimary"
            gutterBottom
            className={classes.title}
          >
            {question.title}
          </Typography>

          <Typography variant="body1" color="textSecondary">
            {question.text}
          </Typography>
        </Grid>
      ))}
    </StyledGrid>
  );
}

ProductQA.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};
