/**
 * Утилита которая позволяет загрузить сторонный JS код и повесить кол-бек на успешную загрузку
 *
 * @param {String} src - Путь к файлу который необходимо загрузить
 * @param {String} id - ID скрипта
 * @returns
 */
export default function loadSDK(src, id) {
  return new Promise((resolve, reject) => {
    const exists = document.getElementById(id);

    if (exists) return resolve();

    const script = document.createElement("script");

    script.src = src;
    script.id = id;

    document.body.appendChild(script);

    script.onload = resolve;
    script.onerror = reject;
  });
}
