import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Player from "core/components/Player";
import Skeleton from "@mui/material/Skeleton";
import { useViewer } from "core/components/ViewerProvider";
import ProductDialog from "arena/components/ProductDialog";
import useProductDialog from "arena/hooks/useProductDialog";

const Root = styled("div")(({ theme }) => ({
  zIndex: 1,
  position: "relative",
  height: 0,
  paddingTop: "75%",
  background: "black",
  // overflow: "hidden",
  [smallScreen]: {
    paddingTop: "56.25%",
  },
  [theme.breakpoints.up("sm")]: {
    // paddingTop: "56.25%",
    paddingTop: "50%",
    borderRadius: 4,
  },
  "&:fullscreen": {
    paddingTop: 0,
  },
}));

const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  width: "100%",
  height: 0,
  paddingTop: "75%",
  [smallScreen]: {
    paddingTop: "56.25%",
  },
  [theme.breakpoints.up("sm")]: {
    // paddingTop: "56.25%",
    paddingTop: "50%",
    borderRadius: 4,
  },
}));

const smallScreen = "@media (min-width: 375px)";

export default function ContentPlayer({
  nextPlaylistContentButton,
  onChatAdv,
  muted,
  autoPlay,
  hideTitle,
  content,
  subscribeButton,
  onEnded,
  start,
  finish,
  sponsorCornerBannerPlace,
  sponsorInStreamBannerPlace,
  sponsorPlayerLogoPlace,
  sponsorPlayerButtonPlace,
  sponsorVideoPlace,
  sponsorChatMessagePlace,
  onSeeked,
  onTimeUpdate,
  onSeekEnd,
  onUpdate,
  refetch,
  startOffset,
}) {
  const viewer = useViewer();
  const { state, dispatch } = useProductDialog();

  if (!content) {
    return <StyledSkeleton variant="rectangular" />;
  }

  return (
    <Root id="player">
      <Player
        key={content.id}
        startOffset={startOffset}
        nextPlaylistContentButton={nextPlaylistContentButton}
        onChatAdv={onChatAdv}
        onEnded={onEnded}
        autoPlay={autoPlay}
        content={content}
        hideTitle={hideTitle}
        onSeeked={onSeeked}
        onTimeUpdate={onTimeUpdate}
        onUpdate={onUpdate}
        onSeekEnd={onSeekEnd}
        hideRelatedContent
        hideShare={content.hideShare}
        hideLogo
        muted={muted}
        fullscreenTarget="player"
        updateVideoPosition={!!viewer}
        subscribeButton={subscribeButton}
        start={start}
        finish={finish}
        sponsorCornerBannerPlace={sponsorCornerBannerPlace}
        sponsorInStreamBannerPlace={sponsorInStreamBannerPlace}
        sponsorPlayerLogoPlace={sponsorPlayerLogoPlace}
        sponsorPlayerButtonPlace={sponsorPlayerButtonPlace}
        sponsorVideoPlace={sponsorVideoPlace}
        sponsorChatMessagePlace={sponsorChatMessagePlace}
        onClickProductButton={dispatch}
      />
      <ProductDialog
        referralContentId={content.id}
        products={content.products.nodes}
        state={state}
        dispatch={dispatch}
        refetch={refetch}
      />
    </Root>
  );
}

ContentPlayer.propTypes = {
  startOffset: PropTypes.number,
  nextPlaylistContentButton: PropTypes.func,
  onChatAdv: PropTypes.func,
  muted: PropTypes.bool,
  autoPlay: PropTypes.bool,
  hideTitle: PropTypes.bool,
  onEnded: PropTypes.func,
  subscribeButton: PropTypes.node,
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    hideShare: PropTypes.bool,
    premium: PropTypes.bool,
    viewerPurchase: PropTypes.object,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    products: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }).isRequired,
    sport: PropTypes.shape({
      id: PropTypes.string.isRequired,
      slug: PropTypes.string,
    }),
  }),

  start: PropTypes.number,
  finish: PropTypes.number,
  sponsorCornerBannerPlace: PropTypes.string,
  sponsorInStreamBannerPlace: PropTypes.string,
  sponsorPlayerLogoPlace: PropTypes.string,
  sponsorPlayerButtonPlace: PropTypes.string,
  sponsorVideoPlace: PropTypes.string,
  sponsorChatMessagePlace: PropTypes.string,
  onSeeked: PropTypes.func,
  onTimeUpdate: PropTypes.func,
  onUpdate: PropTypes.func,
  onSeekEnd: PropTypes.func,
  refetch: PropTypes.func,
};
