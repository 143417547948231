import React from "react";
import PropTypes from "prop-types";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import { useQuery } from "@apollo/client";
import { PromocodesQuery } from "arena/queries";
import getPromocodeTitle from "core/utils/getPromocodeTitle";
import DoneIcon from "@mui/icons-material/Done";

export default function ProductPromocodes({ promocodeIds, setPromocodeIds }) {
  const { data, loading, error } = useQuery(PromocodesQuery, {
    variables: {
      rowsPerPage: 100,
    },
  });

  if (loading) return null;
  if (error) return null;

  const { nodes } = data.promocodes;

  if (nodes.length === 0) return null;

  return (
    <Grid container spacing={1}>
      {nodes.map((promocode) => {
        const title = getPromocodeTitle(promocode);
        const selected = promocodeIds.includes(promocode.id);
        const onClick = () => {
          setPromocodeIds((ids) => {
            if (ids.includes(promocode.id)) {
              return ids.filter((_id) => _id !== promocode.id);
            }
            return ids.concat(promocode.id);
          });
        };
        return (
          <Grid key={promocode.id} item>
            <Chip
              onClick={onClick}
              icon={selected ? <DoneIcon /> : undefined}
              color={selected ? "primary" : undefined}
              label={title}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}

ProductPromocodes.propTypes = {
  promocodeIds: PropTypes.array,
  setPromocodeIds: PropTypes.func.isRequired,
};
