import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import DialogContent from "@mui/material/DialogContent";
import { withSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import SettingsHeader from "arena/components/SettingsHeader";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorScreen from "core/components/ErrorScreen";
import ConnectedTvIcon from "@mui/icons-material/ConnectedTv";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import { useMutation, useQuery } from "@apollo/client";
import { DevicesQuery } from "arena/queries";
import {
  DisconnectDeviceMutation,
  ConnectDeviceMutation,
} from "arena/mutations";
import ConnectDeviceDialog from "arena/components/ConnectDeviceDialog";
import { useSearchParams } from "react-router-dom";

function SettingsDevices({
  tabs,
  onClose,
  enqueueSnackbar,
  addDevice,
  hasAddDevice,
}) {
  const { t } = useTranslation("arena");
  const [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState(hasAddDevice);

  const onError = (error) => {
    enqueueSnackbar(error.message, {
      variant: "error",
    });
  };

  const [connectDevice] = useMutation(ConnectDeviceMutation, {
    refetchQueries: ["Devices"],
    onError,
    onCompleted() {
      if (searchParams.has("addDevice")) {
        searchParams.delete("addDevice");
        setSearchParams(searchParams);
      }
      setOpen(false);
    },
  });

  const [disconnectDevice] = useMutation(DisconnectDeviceMutation, {
    refetchQueries: ["Devices"],
    onError,
  });

  const { loading, data } = useQuery(DevicesQuery, {
    fetchPolicy: "cache-and-network",
    onError,
  });

  const onClickDelete = (event, id) => {
    event.preventDefault();
    disconnectDevice({
      variables: { id },
    });
  };

  const onCloseConnectDialog = () => {
    setOpen(false);
  };

  const onClickAddDevice = () => {
    setOpen(true);
  };

  const onConnectDevice = (variables) => {
    connectDevice({
      variables,
    });
  };

  const devices = data?.devices?.nodes || [];

  return (
    <>
      <SettingsHeader
        onClose={onClose}
        title={t("settingsDevices.title")}
        action={
          <Button
            disableElevation
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={onClickAddDevice}
          >
            {t("settingsDevices.addDevice")}
          </Button>
        }
        tabs={tabs}
      />

      {devices.length === 0 && (
        <DialogContent style={{ minHeight: 317 + 64, display: "flex" }}>
          <ErrorScreen
            icon={ConnectedTvIcon}
            title={t("settingsDevices.empty.title")}
            text={t("settingsDevices.empty.text")}
            button={null}
          />
        </DialogContent>
      )}

      {devices.length > 0 && (
        <List style={{ minHeight: 317 + 64 }}>
          {devices.map((device, index) => {
            return (
              <ListItem key={device.id} divider={index + 1 < devices.length}>
                <ListItemAvatar>
                  <Avatar>
                    <LiveTvIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={device.title}
                  secondary={device.vendor}
                />
                <ListItemSecondaryAction>
                  <Toolbar title={t("delete")}>
                    <IconButton
                      edge="end"
                      onClick={(event) => onClickDelete(event, device.id)}
                      disabled={loading}
                      size="large"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Toolbar>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      )}

      <ConnectDeviceDialog
        open={open}
        code={addDevice}
        onClose={onCloseConnectDialog}
        onConnectDevice={onConnectDevice}
      />
    </>
  );
}

SettingsDevices.propTypes = {
  hasAddDevice: PropTypes.bool,
  addDevice: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  tabs: PropTypes.node,
  arena: PropTypes.shape({
    id: PropTypes.string.isRequired,
    userFields: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        required: PropTypes.bool,
      })
    ),
  }),
};

export default withSnackbar(SettingsDevices);
