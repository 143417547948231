import qs from "qs";
export default function contentUrl(url, params) {
  if (params) {
    const query = qs.stringify(params, {
      addQueryPrefix: true,
      skipNulls: true,
    });

    if (query) {
      url += query;
    }
  }

  return url;
}
