import React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";

export default function SelectDocumentVersion({ document, value, onChange }) {
  const { t } = useTranslation("arena");
  const options = document.versions
    .map((version) => ({
      value: version.id,
      label: t("dateFull", { date: version.createdAt }),
    }))
    .concat({
      value: document.id,
      label: t("dateFull", { date: document.createdAt }),
    });

  return (
    <TextField
      select
      variant="standard"
      value={value}
      onChange={onChange}
      InputProps={{ disableUnderline: true }}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
}

SelectDocumentVersion.propTypes = {
  document: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
