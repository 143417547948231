import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { useQuery } from "@apollo/client";
import {
  ArenaBannerQuery,
  VideoFileQuery,
  ProductDonationProgressQuery,
} from "arena/queries";
import { getBannerSrcSet } from "core/utils/getSrcSet";
import { alpha } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import BackgroundVideo from "./BackgroundVideo";
import BackgroundImage from "./BackgroundImage";
import Buttons from "./Buttons";
import Text from "./Text";
import Donation from "./Donation";
import Image from "./Image";
import Inner from "./Inner";

const PREFIX = "ArenaCover";

const classes = {
  innerGrid: `${PREFIX}-innerGrid`,
};

const horizontalAlignToFlex = {
  left: "flex-start",
  center: "center",
  right: "flex-end",
};

const verticalAlignToFlex = {
  top: "flex-start",
  center: "center",
  bottom: "flex-end",
};

const Root = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "horizontalAlign" &&
    prop !== "verticalAlign" &&
    prop !== "first" &&
    prop !== "last",
})(({ theme, horizontalAlign, verticalAlign, first, last }) => ({
  overflow: "hidden",
  position: "relative",
  height: "100vh",
  boxSizing: "border-box",
  display: "flex",
  marginBottom: last ? 0 : -100,

  padding: "80px 16px 120px",
  alignItems: verticalAlignToFlex[verticalAlign?.mobile], // vertical align
  justifyContent: horizontalAlignToFlex[horizontalAlign?.mobile], // horizontal align
  marginTop: first ? -54 : 0,

  [theme.breakpoints.up("sm")]: {
    padding: "100px 16px 160px",
    alignItems: verticalAlignToFlex[verticalAlign?.desktop],
    justifyContent: horizontalAlignToFlex[horizontalAlign?.desktop],
    marginTop: first ? -64 : 0,
  },

  [theme.breakpoints.up("md")]: {
    paddingLeft: 166,
    paddingRight: 166,
  },

  // Затемнение
  "&:before": {
    content: '""',
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    height: 200,
    pointerEvents: "none",
    // backgroundImage: `linear-gradient(transparent, rgba(16,16,16,0.1), rgba(16,16,16,0.8), ${theme.palette.background.body})`,
    backgroundImage: `linear-gradient(transparent, ${alpha(
      theme.palette.background.body,
      0.1
    )}, ${alpha(theme.palette.background.body, 0.5)}, ${
      theme.palette.background.body
    })`,
  },

  [`& .${classes.innerGrid}`]: {
    justifyContent: horizontalAlignToFlex[horizontalAlign?.mobile],
    [theme.breakpoints.up("sm")]: {
      justifyContent: horizontalAlignToFlex[horizontalAlign?.desktop],
    },
  },
}));

const MAXRES = "MAXRES";

function useImage(imageId) {
  const { data } = useQuery(ArenaBannerQuery, {
    skip: !imageId,
    variables: {
      id: imageId,
    },
  });

  return data?.banner;
}

function useVideoFile(videoFileId) {
  const { data } = useQuery(VideoFileQuery, {
    skip: !videoFileId,
    variables: {
      id: videoFileId,
    },
  });

  return data?.videoFile;
}

function useProduct(productId) {
  const { data } = useQuery(ProductDonationProgressQuery, {
    skip: !productId,
    variables: {
      id: productId,
    },
  });

  return data?.product;
}

export default function ArenaCover({
  title,
  subTitle,
  buttons,
  background,
  align,
  alignMobile,
  verticalAlign: _verticalAlign,
  first,
  last,
  imageId,
  productId,
}) {
  const image = useImage(imageId);

  const desktopVideo = useVideoFile(background?.desktopVideoFileId);
  const mobileVideo = useVideoFile(background?.mobileVideoFileId);

  const product = useProduct(productId);
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  const backgroundVideo = md ? desktopVideo : mobileVideo || desktopVideo;

  let imageSrc = null;
  let imageSrcSet = null;
  if (image) {
    imageSrc = image.find((b) => b.format === MAXRES)?.url;
    imageSrcSet = getBannerSrcSet(image);
  }

  const horizontalAlign = {
    desktop: align || "center",
    mobile: alignMobile || "center",
  };

  const verticalAlign = {
    desktop: _verticalAlign?.desktop || "center",
    mobile: _verticalAlign?.mobile || "center",
  };

  return (
    <Root
      test-id="ArenaCover"
      horizontalAlign={horizontalAlign}
      verticalAlign={verticalAlign}
      first={first}
      last={last}
    >
      <BackgroundImage
        background={background}
        horizontalAlign={horizontalAlign}
        verticalAlign={verticalAlign}
      />

      <BackgroundVideo
        backgroundVideo={backgroundVideo}
        background={background}
      />

      <Inner horizontalAlign={horizontalAlign} verticalAlign={verticalAlign}>
        <Donation product={product} />

        <Grid
          container
          spacing={3}
          className={classes.innerGrid}
          alignItems="center"
        >
          {imageSrc && (
            <Grid item>
              <Image imageSrc={imageSrc} imageSrcSet={imageSrcSet} />
            </Grid>
          )}

          <Grid item xs={12}>
            {title?.text && (
              <Text {...title} horizontalAlign={horizontalAlign} gutterBottom />
            )}
            {subTitle?.text && (
              <Text {...subTitle} horizontalAlign={horizontalAlign} />
            )}
          </Grid>

          <Buttons buttons={buttons} />
        </Grid>
      </Inner>
    </Root>
  );
}
ArenaCover.defaultProps = {
  buttons: [],
};

ArenaCover.propTypes = {
  title: PropTypes.shape({
    variant: PropTypes.string,
    color: PropTypes.string,
    text: PropTypes.string,
  }),
  subTitle: PropTypes.shape({
    variant: PropTypes.string,
    color: PropTypes.string,
    text: PropTypes.string,
  }),
  verticalAlign: PropTypes.shape({
    desktop: PropTypes.string,
    mobile: PropTypes.string,
  }),
  align: PropTypes.string,
  alignMobile: PropTypes.string,
  background: PropTypes.shape({
    bannerId: PropTypes.string,
    bannerMobileId: PropTypes.string,
    brightness: PropTypes.number,
    desktopVideoId: PropTypes.string,
    mobileVideoId: PropTypes.string,
    desktopVideoFileId: PropTypes.string,
    mobileVideoFileId: PropTypes.string,
  }),
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ),
  arena: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  first: PropTypes.bool,
  last: PropTypes.bool,
  imageId: PropTypes.string,
  productId: PropTypes.string,
};
