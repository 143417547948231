import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import LoadMore from "core/components/LoadMore";
import ErrorScreen from "core/components/ErrorScreen";
import { ProductPurchasesQuery } from "arena/queries";
import { useQuery } from "@apollo/client";
import Skeleton from "@mui/material/Skeleton";
import ReactGA from "react-ga4";
import ProductPurchaseListItem from "arena/components/ProductPurchaseListItem";
import { useTranslation } from "react-i18next";

const PREFIX = "ProductPurchaseList";

const classes = {
  item: `${PREFIX}-item`,
  focusVisible: `${PREFIX}-focusVisible`,
  focusHighlight: `${PREFIX}-focusHighlight`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.item}`]: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "flex-start",
    textAlign: "inherit",
    width: "100%",

    "&:hover $focusHighlight": {
      opacity: theme.palette.action.hoverOpacity,
    },
    "&$focusVisible $focusHighlight": {
      opacity: 0.12,
    },
  },

  [`& .${classes.focusVisible}`]: {},

  [`& .${classes.focusHighlight}`]: {
    overflow: "hidden",
    pointerEvents: "none",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    borderRadius: "inherit",
    opacity: 0,
    backgroundColor: "currentcolor",
    transition: theme.transitions.create("opacity", {
      duration: theme.transitions.duration.short,
    }),
  },
}));

export default function ProductPurchaseList({
  rowsPerPage,
  xs,
  sm,
  md,
  lg,
  xl,
}) {
  const { t } = useTranslation("arena");

  const { data, loading, error, fetchMore } = useQuery(ProductPurchasesQuery, {
    fetchPolicy: "cache-and-network",
    variables: {
      rowsPerPage,
    },
  });

  if (error) return <ErrorScreen error={error} />;

  if (loading && !data) {
    return (
      <Root>
        <Grid container spacing={2}>
          {Array.from({ length: rowsPerPage }, (v, k) => (
            <Grid
              item
              xs={xs}
              sm={sm}
              md={md}
              lg={lg}
              xl={xl}
              key={k}
              container
            >
              <div className={classes.item}>
                <Skeleton
                  variant="rectangular"
                  style={{
                    width: "100%",
                    height: 0,
                    paddingTop: "56.25%",
                  }}
                />

                <div>
                  <Skeleton
                    variant="rectangular"
                    style={{ width: 240, height: 36, marginTop: 10 }}
                  />
                  <Skeleton
                    variant="rectangular"
                    style={{ width: 190, height: 18, marginTop: 10 }}
                  />
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </Root>
    );
  }

  const nodes = data?.productPurchases?.nodes || [];
  const count = data?.productPurchases?.count || 0;
  const hasMore = nodes.length < count;
  const showLoadMore = hasMore && !loading;
  const nextPage = Math.ceil(nodes.length / rowsPerPage);
  const empty = nodes.length === 0;

  if (empty) {
    return (
      <div>
        <Typography variant="body1" color="textSecondary">
          {t("productPurchases.empty")}
        </Typography>
      </div>
    );
  }

  const onLoadMore = () => {
    ReactGA.event({
      category: "User",
      action: "Load more product purchases",
      label: nextPage + " page",
    });

    fetchMore({
      variables: {
        page: nextPage,
      },
    });
  };

  return (
    <Root>
      <Grid container spacing={2} data-testid="ProductPurchaseList">
        {nodes.map((productPurchase) => (
          <Grid
            item
            xs={xs}
            sm={sm}
            md={md}
            lg={lg}
            xl={xl}
            key={productPurchase.id}
            data-testid="ProductPurchaseListItem"
            container
          >
            <ProductPurchaseListItem productPurchase={productPurchase} />
          </Grid>
        ))}
      </Grid>
      {showLoadMore && <LoadMore key={nextPage} onLoadMore={onLoadMore} />}
    </Root>
  );
}

ProductPurchaseList.propTypes = {
  xs: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  sm: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  md: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  lg: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  xl: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  rowsPerPage: PropTypes.number,
};

ProductPurchaseList.defaultProps = {
  rowsPerPage: 10,
  xs: 12,
  sm: 6,
  md: 4,
  // lg: 3,
  // xl: 2,
};
