import React from "react";
import PropTypes from "prop-types";
import ErrorScreen from "core/components/ErrorScreen";
import PlayerPreview from "core/components/PlayerPreview";
import { useTranslation } from "react-i18next";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { truncate } from "core/utils/string";

export default function WaitingScreen({ content }) {
  const { t } = useTranslation("core");
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const title = smUp ? content.title : truncate(content.title, 30);

  return (
    <PlayerPreview preview={content.preview} overlay>
      <ErrorScreen
        icon={ScheduleIcon}
        title={title}
        text={t("waitingScreen.text", { date: content.viewerPurchase.startAt })}
      />
    </PlayerPreview>
  );
}

WaitingScreen.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    preview: PropTypes.string,
    premium: PropTypes.bool,
    viewerPurchase: PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      startAt: PropTypes.string.isRequired,
    }),
  }).isRequired,
};
