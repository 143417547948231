import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

import ButtonBase from "@mui/material/ButtonBase";
import NavigateBeforeRoundedIcon from "@mui/icons-material/NavigateBeforeRounded";
import { alpha } from "@mui/system";

const PREFIX = "ContentCarouselPrevButton";

const classes = {
  next: `${PREFIX}-next`,
  btnRoot: `${PREFIX}-btnRoot`,
  icon: `${PREFIX}-icon`,
  btnHolder: `${PREFIX}-btnHolder`,
};

const Root = styled(Box)(({ theme, size }) => ({
  [`&.${classes.next}`]: {
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    zIndex: 1,
    pointerEvents: "none",
    /**
     * Этим блоком задаем ширину как у плитки контента
     */
    width: `calc(${100 / size}%)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${100 / size}% - 12px)`,
    },
  },
  [`& .${classes.btnHolder}`]: {
    /**
     * Этим блоком делаем 16:9 соотношение
     */
    paddingTop: "56.3%",
    height: 0,
    position: "relative",
  },

  [`& .${classes.btnRoot}`]: {
    pointerEvents: "all",
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    width: 60,

    backgroundColor: "transparent",
    backgroundImage: `linear-gradient(90deg, ${
      theme.palette.background.body
    }, ${alpha(theme.palette.background.body, 0.6)}, transparent)`,
    color: "rgba(255, 255, 255, 0.3)",

    "&:hover": {
      color: "#fff",
    },

    [theme.breakpoints.up("sm")]: {
      width: 90,
      left: -24,
    },
    [theme.breakpoints.up("md")]: {
      width: 80,
    },
  },

  [`& .${classes.icon}`]: {
    fontSize: 60,
  },
}));

export default function ContentCarouselPrevButton({ disabled, onClick, size }) {
  if (disabled) return null;

  return (
    <Root className={classes.next} size={size}>
      <Box className={classes.btnHolder}>
        <ButtonBase
          size="small"
          classes={{ root: classes.btnRoot }}
          onClick={onClick}
          aria-label="Prev"
        >
          <NavigateBeforeRoundedIcon color="inherit" className={classes.icon} />
        </ButtonBase>
      </Box>
    </Root>
  );
}

ContentCarouselPrevButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.number,
};
