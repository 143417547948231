import {
  responsiveFontSizes,
  createTheme as createMuiTheme,
} from "@mui/material/styles";

export function createTheme(customTheme) {
  const theme = {
    palette: {
      mode: "dark",

      primary: {
        main: customTheme?.palette?.primary || "#e91e63",
      },
      secondary: {
        main: customTheme?.palette?.secondary || "#3ea6ff",
      },

      background: {
        body: customTheme?.palette?.backgroundColor || "#0D0D0D",

        paper: "rgba(255, 255, 255, 0.03)",
      },

      text: {
        primary: "rgba(255, 255, 255, 0.9)",
        primaryInHex: "#ffffff", // if primary changes, change here too, for stripe
      },
    },

    typography: {
      // fontSize: 30,

      h1: {
        fontWeight: 500,
      },
      h2: {
        fontWeight: 500,
      },
      h3: {
        fontWeight: 500,
      },
      h4: {
        fontWeight: 500,
      },
      h5: {
        fontWeight: 500,
      },
      h6: {
        fontWeight: 500,
      },

      fontFamily:
        customTheme?.typography?.fontFamily ||
        [
          "-apple-system",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
    },

    components: {
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            height: "100%",
          },
          body: {
            height: "100%",
            backgroundColor: customTheme?.palette?.backgroundColor || "#0D0D0D",
            WebkitTextSizeAdjust: "none",
            // overscrollBehaviorY: "none",
            "& #root": {
              height: "100%",
            },
          },
          a: {
            color: "#3ea6ff",
            textDecoration: "none",
          },
        },
      },
      MuiPickersPopper: {
        styleOverrides: {
          paper: {
            backgroundColor: customTheme?.palette?.backgroundColor || "#0D0D0D",
          },
        },
      },

      // .css-1ww2xic-MuiPaper-root-MuiPickersPopper-paper

      MuiButton: {
        variants: [
          {
            props: { variant: "outlined-product" },
            style: {
              border: `4px solid`,
              borderColor: customTheme?.palette?.primary || "#e91e63",
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "transparent",
              },
            },
          },
        ],
      },

      MuiPaper: {
        defaultProps: {
          elevation: 0,
        },
      },
      MuiAppBar: {
        defaultProps: {
          // elevation: 1,
        },
        styleOverrides: {
          root: {
            backgroundImage: "none",
          },
          colorDefault: {
            // backgroundColor: "#191919",
            backgroundColor: "rgba(255, 255, 255, 0.03)",
          },
        },
      },
      MuiDialog: {
        defaultProps: {
          PaperProps: {
            elevation: 0,
          },
        },
        styleOverrides: {
          paper: {
            backgroundColor: customTheme?.palette?.backgroundColor || "#1f1f1f",
          },
        },
      },

      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: "#1f1f1f",
          },
        },
      },

      MuiTypography: {
        styleOverrides: {
          colorTextSecondary: {
            color: "rgb(170, 170, 170)",
          },
        },
      },

      MuiSnackbarContent: {
        styleOverrides: {
          root: {
            color: "white",
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            "&:focus": {
              background: "transparent",
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: "initial",
            minWidth: "0 !important",
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            touchAction: "none",
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: "rgba(0, 0, 0, 0.9)",
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            backgroundColor: customTheme?.palette?.backgroundColor,
          },
        },
      },
      MuiAutocomplete: {
        defaultProps: {
          componentsProps: {
            paper: {
              elevation: 2,
            },
          },
        },
        styleOverrides: {
          paper: {
            backgroundColor: customTheme?.palette?.backgroundColor, //"rgba(255, 255, 255, 0.1)",
            backdropFilter: "blur(5px)",
            boxShadow:
              "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
          },
        },
      },
    },
  };

  return responsiveFontSizes(createMuiTheme(theme));
}
