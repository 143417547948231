import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { useTranslation } from "react-i18next";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import SwitchVideoIcon from "@mui/icons-material/SwitchVideo";
import PlayerFooterButton from "core/components/PlayerFooterButton";

const PREFIX = "ChooseCameraButton";

const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  listItem: `${PREFIX}-listItem`,
  list: `${PREFIX}-list`,
};

const Root = styled("div")({
  [`& .${classes.root}`]: {
    position: "relative",
  },
  [`& .${classes.paper}`]: {
    position: "absolute",
    bottom: 60,
    left: -12,
    overflow: "hidden",
    backgroundColor: "rgba(31, 31, 31, 0.85)",
  },
  [`& .${classes.listItem}`]: {
    padding: "4px 2px",
    "&:first-of-type": {
      paddingLeft: 4,
    },
    "&:last-child": {
      paddingRight: 4,
    },
  },
  [`& .${classes.list}`]: {
    display: "flex",
  },
});

const DEFAULT = "DEFAULT";
const MEDIUM = "MEDIUM";
const HIGH = "HIGH";

function getThumbnail(camera) {
  const thumbnail =
    camera.thumbnails.find((t) => t.format === HIGH) ||
    camera.thumbnails.find((t) => t.format === MEDIUM) ||
    camera.thumbnails.find((t) => t.format === DEFAULT);

  if (thumbnail) {
    return thumbnail.url;
  }
}

function ChooseCameraButton({ cameras, cameraId, setCameraId }) {
  const { t } = useTranslation("core");

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((v) => !v);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const onClickItem = (event, cameraId) => {
    event.preventDefault();
    setCameraId(cameraId);
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Root>
          <PlayerFooterButton
            title={t("chooseCameraButton.title")}
            onClick={handleClick}
          >
            <SwitchVideoIcon />
          </PlayerFooterButton>

          {open && (
            <Paper className={classes.paper}>
              <List disablePadding dense className={classes.list}>
                {cameras.map((camera, index) => {
                  if (camera.id === cameraId) return null;
                  return (
                    <ListItem
                      key={camera.id}
                      className={classes.listItem}
                      dense
                      disableGutters
                      button
                      onClick={(event) => onClickItem(event, camera.id)}
                    >
                      <div>
                        <img
                          width="160"
                          height="90"
                          src={getThumbnail(camera)}
                        />
                        <Typography
                          variant="body2"
                          align="center"
                          style={{ lineHeight: "32px" }}
                        >
                          {camera.title ||
                            t("chooseCameraButton.label", { index: index + 1 })}
                        </Typography>
                      </div>
                    </ListItem>
                  );
                })}
              </List>
            </Paper>
          )}
        </Root>
      </ClickAwayListener>
    </>
  );
}

ChooseCameraButton.propTypes = {
  cameras: PropTypes.array.isRequired,
  cameraId: PropTypes.string,
  setCameraId: PropTypes.func.isRequired,
};

export default ChooseCameraButton;
