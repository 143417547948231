import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";

const StyledCard = styled(Card)({
  backgroundColor: "rgba(255, 255, 255, 0.03)",

  width: 320,
});

export default function ArenaReview({ review }) {
  return (
    <StyledCard>
      <CardHeader
        title={review.author}
        subheader={review.position}
        avatar={<Avatar src={review.src}>{review.author[0]}</Avatar>}
      />
      <CardContent sx={{ pt: 0 }}>
        <Typography
          variant="body1"
          component="div"
          dangerouslySetInnerHTML={{
            __html: review.text,
          }}
        />
      </CardContent>
    </StyledCard>
  );
}

ArenaReview.propTypes = {
  review: PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
  }),
};
