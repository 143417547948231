import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import formatCurrency from "core/utils/currency";
import { useTranslation } from "react-i18next";
import FormControlLabel from "@mui/material/FormControlLabel";
import Tooltip from "@mui/material/Tooltip";

export default function DonateButtons({ tab, product, onClickPriceButton }) {
  const { t } = useTranslation("arena");

  const donate = product.donates[tab];
  const recurrentUnavailable = donate?.currency === "USDT";

  const minAmount = Math.min(...donate.amounts);

  const [recurrent, setRecurrent] = useState(true);
  const [amount, setAmount] = useState("");

  const onChangeRecurrent = (event) => {
    setRecurrent(event.target.checked);
  };

  useEffect(() => {
    if (donate) {
      if (donate.currency === "USDT") {
        setRecurrent(false);
      }
    }
  }, [donate]);

  const onClickPay = (event) => {
    const price = {
      recurrent,
      amount,
      currency: donate.currency,
      providers: donate.providers,
    };
    onClickPriceButton(event, price);
  };

  const onChangeValue = (event) => {
    setAmount(event.target.value.replace(/[^0-9]/gi, ""));
  };

  const error =
    amount > 0 && amount < minAmount
      ? t("productPayment.donationMinAmountError", {
          amount: formatCurrency(minAmount, donate.currency),
        })
      : undefined;

  const isRub = donate.currency === "RUB";

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="subtitle2">{t("productPayment.title")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              value={amount}
              onChange={onChangeValue}
              label={t("productPayment.donationAmount")}
              error={error}
              helperText={error}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {donate.currency}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {donate.amounts.map((value, index) => {
            const label = formatCurrency(value, donate.currency);
            const onClick = () => {
              setAmount(value);
            };

            return (
              <Grid item key={index}>
                <Chip label={label} onClick={onClick} />
              </Grid>
            );
          })}
        </Grid>
      </Grid>

      {isRub && (
        <Grid item xs={12}>
          <Typography
            color="textSecondary"
            component="div"
            variant="caption"
            align="center"
          >
            {t("productPayment.onlyRussianCards")}
          </Typography>
        </Grid>
      )}

      <Grid item xs={12}>
        <Button
          color="primary"
          size="large"
          variant="contained"
          fullWidth
          sx={{ height: 56 }}
          type="submit"
          disabled={amount < minAmount}
          onClick={onClickPay}
        >
          {t("productPayment.donate")}
        </Button>
        <Tooltip
          title={
            recurrentUnavailable
              ? t("productPayment.recurrentUnavailableByCurrency")
              : ""
          }
        >
          <Box>
            <FormControlLabel
              label={t("productPayment.recurrentDonation")}
              control={
                <Checkbox
                  disabled={recurrentUnavailable}
                  checked={recurrent}
                  onChange={onChangeRecurrent}
                />
              }
            />
          </Box>
        </Tooltip>
      </Grid>
    </>
  );
}

DonateButtons.propTypes = {
  tab: PropTypes.number.isRequired,
  onClickPriceButton: PropTypes.func.isRequired,
  product: PropTypes.shape({
    donates: PropTypes.arrayOf(
      PropTypes.shape({
        amounts: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
        currency: PropTypes.string.isRequired,
        providers: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            token: PropTypes.string.isRequired,
          })
        ),
      })
    ),
  }),
};
