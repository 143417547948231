import React from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import ErrorScreen from "core/components/ErrorScreen";
import WarningIcon from "@mui/icons-material/Warning";

const Root = styled("div")({
  padding: 0,
  flex: 1,
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export default function InvalidOauth() {
  const { t } = useTranslation("arena");

  return (
    <Root data-testid="InvalidOauth">
      <ErrorScreen
        icon={WarningIcon}
        title={t("invalidOauth.title")}
        text={t("invalidOauth.text")}
      />
    </Root>
  );
}
