import LocalStorage from "./localstorage";

const ls = new LocalStorage(`utm`);

/**
 * Переложить utm метки из урла в локалсторадж
 */
export function setUtmToLocalStorage() {
  const utm = getUtmFromUrl(window.location.href);

  const has = Object.values(utm).some(Boolean);

  if (!has) return; // нет utm меток, ничего делать не надо

  ls.setItem("source", utm.source);
  ls.setItem("medium", utm.medium);
  ls.setItem("campaign", utm.campaign);
  ls.setItem("content", utm.content);
  ls.setItem("term", utm.term);
  ls.setItem("link", utm.link);
}

/**
 * Получить utm метки из LocalStorage
 *
 * @returns Object - Объект с utm метками
 */
export function getUtmFromLocalStorage() {
  return {
    source: ls.getItem("source") || undefined,
    medium: ls.getItem("medium") || undefined,
    campaign: ls.getItem("campaign") || undefined,
    content: ls.getItem("content") || undefined,
    term: ls.getItem("term") || undefined,
    link: ls.getItem("link") || undefined,
  };
}

/**
 * Получить utm метки из урла
 *
 * @param {String} url - URL адрес
 * @returns Object - Объект с utm метками
 */
export function getUtmFromUrl(url) {
  const uri = new URL(url);

  return {
    source: uri.searchParams.get("utm_source"),
    medium: uri.searchParams.get("utm_medium"),
    campaign: uri.searchParams.get("utm_campaign"),
    content: uri.searchParams.get("utm_content"),
    term: uri.searchParams.get("utm_term"),
    link: uri.searchParams.get("utm_link"),
  };
}

/**
 * Удалить utm метки из урла
 *
 * @param {String} url - URL адрес
 * @returns - ссылка без utm
 */
export function removeUtmFromUrl(url) {
  const uri = new URL(url);

  uri.searchParams.delete("utm_source");
  uri.searchParams.delete("utm_medium");
  uri.searchParams.delete("utm_campaign");
  uri.searchParams.delete("utm_content");
  uri.searchParams.delete("utm_term");
  uri.searchParams.delete("utm_link");

  return uri.href;
}

/**
 * Эта функция удаляет utm метки из браузерной строки
 */
export function removeUtmOnPageLoad() {
  const cleanUrl = removeUtmFromUrl(window.location.href);

  window?.history?.replaceState(null, "", cleanUrl);
}
