import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { truncate } from "core/utils/string";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Fab from "@mui/material/Fab";

const PREFIX = "PlayerContentTitle";

const classes = {
  title: `${PREFIX}-title`,
  hideOnSmallScreen: `${PREFIX}-hideOnSmallScreen`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.title}`]: {
    lineHeight: "1.1",
    fontSize: "0.7rem",
    fontWeight: 600,
    color: "white",
    ["@media (min-width: 320px)"]: {
      lineHeight: "1.3",
      fontSize: "0.975rem",
    },
    ["@media (min-width: 425px)"]: {
      fontSize: "1.1rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.3rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.6rem",
    },
  },

  [`& .${classes.hideOnSmallScreen}`]: {
    ["@media (max-width: 320px)"]: {
      display: "none",
    },
  },
}));

export default function PlayerContentTitle({
  title,
  contentUrl,
  onClickPlay,
  visible,
  hideLogo,
}) {
  const component = visible && !hideLogo ? "a" : "div";

  return (
    <StyledGrid
      container
      alignItems="center"
      justifyContent="center"
      spacing={4}
      wrap="nowrap"
    >
      <Grid item>
        <div>
          <Fab
            style={{ display: "inline-flex" }}
            size="large"
            color="primary"
            onClick={onClickPlay}
          >
            <PlayArrowIcon fontSize="large" />
          </Fab>
        </div>
      </Grid>

      <Grid item xs={12} className={classes.hideOnSmallScreen}>
        <Typography
          color="textPrimary"
          className={classes.title}
          component={component}
          href={contentUrl}
          target="_blank"
        >
          {truncate(title, 80)}
        </Typography>
      </Grid>
    </StyledGrid>
  );
}

PlayerContentTitle.propTypes = {
  title: PropTypes.string.isRequired,
  contentUrl: PropTypes.string.isRequired,
  onClickPlay: PropTypes.func,
  visible: PropTypes.bool,
  hideLogo: PropTypes.bool,
};
