import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import classNames from "classnames";
import CardActionArea from "@mui/material/CardActionArea";
import CardHeader from "@mui/material/CardHeader";
import Link from "@mui/material/Link";
import ThumbnailCardMedia from "arena/components/ThumbnailCardMedia";

const PREFIX = "ContentGridItem";

const classes = {
  avatar: `${PREFIX}-avatar`,
  mediaLink: `${PREFIX}-mediaLink`,
  media: `${PREFIX}-media`,
  cardHeader: `${PREFIX}-cardHeader`,
  cardHeaderContent: `${PREFIX}-cardHeaderContent`,
  link: `${PREFIX}-link`,
  linkSmall: `${PREFIX}-linkSmall`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.mediaLink}`]: {
    position: "relative",
    display: "block",
    color: "inherit",
    [theme.breakpoints.up("sm")]: {
      borderRadius: 4, // desktop
    },
  },

  [`& .${classes.cardHeader}`]: {
    width: "100%",
    alignItems: "flex-start",
    padding: "16px 0",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  },

  [`& .${classes.link}`]: {
    textDecoration: "none",
    fontSize: "1.1rem",
    fontWeight: 500,
    display: "block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.4rem",
    },
  },

  [`& .${classes.linkSmall}`]: {
    textDecoration: "none",
    fontSize: "1.1rem",
    fontWeight: 500,
    display: "block",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const DEFAULT = "DEFAULT";
const HIGH = "HIGH";
const MAXRES = "MAXRES";
const MEDIUM = "MEDIUM";

const StyledThumbnailCardMedia = styled(ThumbnailCardMedia)(({ theme }) => ({
  width: "100%",
  position: "relative",
  height: 0,
  paddingTop: "56.25%", // 16:9
  backgroundColor: "black",
  overflow: "hidden",
  borderRadius: 0, // mobile
  [theme.breakpoints.up("sm")]: {
    borderRadius: 4, // desktop
  },
}));

export default function GridItem({ item, smallTitle }) {
  return (
    <Root>
      <CardActionArea
        target={item.openInNewWindow ? "_blank" : ""}
        href={item.url}
        className={classNames(classes.mediaLink)}
      >
        <StyledThumbnailCardMedia thumbnails={item.thumbnails} />
      </CardActionArea>

      {item.title && (
        <CardHeader
          className={classes.cardHeader}
          title={item.title}
          classes={{
            content: classes.cardHeaderContent,
          }}
          titleTypographyProps={{
            color: "textPrimary",
            className: classNames({
              [classes.link]: !smallTitle,
              [classes.linkSmall]: smallTitle,
            }),
            component: Link,
            href: item.url,
            target: item.openInNewWindow ? "_blank" : "",
          }}
        />
      )}
    </Root>
  );
}

GridItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    thumbnails: PropTypes.arrayOf(
      PropTypes.shape({
        format: PropTypes.oneOf([DEFAULT, MEDIUM, HIGH, MAXRES]).isRequired,
        url: PropTypes.string.isRequired,
        webpUrl: PropTypes.string,
      })
    ),
    openInNewWindow: PropTypes.bool,
  }),
  smallTitle: PropTypes.bool,
};
