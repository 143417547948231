import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

export default function ArenaMarkdown(props) {
  const { variant, color, html, paddingTop, paddingBottom, maxWidth } = props;

  if (!html) return null;

  return (
    <Typography
      variant={variant}
      color={color}
      component="div"
      style={{ paddingTop, paddingBottom, maxWidth, margin: "auto" }}
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  );
}

ArenaMarkdown.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
  maxWidth: PropTypes.string,
  html: PropTypes.string,
  arena: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};
