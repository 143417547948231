import React from "react";
import PropTypes from "prop-types";
import Replay10Icon from "@mui/icons-material/Replay10";
import { useTranslation } from "react-i18next";
import PlayerFooterButton from "core/components/PlayerFooterButton";

function RewindButton({ videoState, onClick }) {
  const { t } = useTranslation("core");

  if (!videoState) return null;
  if (!videoState.live) return null;

  return (
    <PlayerFooterButton title={t("rewind")} onClick={onClick}>
      <Replay10Icon />
    </PlayerFooterButton>
  );
}

RewindButton.propTypes = {
  onClick: PropTypes.func,
  videoState: PropTypes.object,
};

export default RewindButton;
