import React from "react";
import PropTypes from "prop-types";
import PlayerPreview from "core/components/PlayerPreview";
import PlayerFooter from "core/components/PlayerFooter";
import LoadingScreen from "core/components/LoadingScreen";

export default function ContentLoading({
  content,
  hideLogo,
  hideShare,
  fullscreenTarget,
}) {
  return (
    <PlayerPreview preview={content.preview} overlay>
      <LoadingScreen />

      <PlayerFooter
        content={content}
        position="absolute"
        visible
        hideLogo={hideLogo}
        hideShare={hideShare}
        fullscreenTarget={fullscreenTarget}
      />
    </PlayerPreview>
  );
}

ContentLoading.propTypes = {
  fullscreenTarget: PropTypes.string,
  hideLogo: PropTypes.bool,
  hideShare: PropTypes.bool,
  content: PropTypes.shape({
    rightholder: PropTypes.shape({
      isAdvEnabled: PropTypes.bool,
    }),
    id: PropTypes.string.isRequired,
    preview: PropTypes.string,
  }).isRequired,
};
