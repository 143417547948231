export default function debounce(f, ms) {
  let isCooldown = false;

  return function () {
    if (isCooldown) return;

    const result = f.apply(this, arguments);

    isCooldown = true;

    setTimeout(() => (isCooldown = false), ms);

    return result;
  };
}
