import moment from "core/utils/moment";

export default function renderEventDate({ eventStartAt, eventFinishAt, t }) {
  if (eventStartAt) {
    if (eventFinishAt) {
      if (moment(eventStartAt).isSame(eventFinishAt, "day")) {
        return t("eventDate.sameDay", {
          eventStartAt,
          eventFinishAt,
        });
      }
    }
  }

  return [eventStartAt, eventFinishAt]
    .filter(Boolean)
    .map((date) => t("dateFull", { date }))
    .join(" – ");
}
