import React from "react";
import PropTypes from "prop-types";
import screenfull from "screenfull";

export default class Fullscreen extends React.Component {
  state = { isFullscreen: false };

  static propTypes = {
    target: PropTypes.string,
    children: PropTypes.func,
    onFullScreenChange: PropTypes.func,
  };

  handleFullscreenChange = () => {
    if (this.props.onFullScreenChange) {
      this.props.onFullScreenChange(screenfull.isFullscreen);
    }

    this.setState({
      isFullscreen: screenfull.isFullscreen,
    });
  };

  componentDidMount() {
    if (screenfull.isEnabled) {
      screenfull.on("change", this.handleFullscreenChange);
    }
  }

  componentWillUnmount() {
    if (screenfull.isEnabled) {
      screenfull.off("change", this.handleFullscreenChange);
    }
  }

  render() {
    return this.props.children({
      isFullscreen: this.state.isFullscreen,
      enabled: screenfull.isEnabled,
      toggleFullscreen: () => {
        const el = this.props.target
          ? document.getElementById(this.props.target)
          : window;

        screenfull.toggle(el);
      },
    });
  }
}
