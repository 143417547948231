import { init as initApm } from "@elastic/apm-rum";

const pageLoadTransactionName =
  typeof window === "undefined"
    ? "serverPageLoadTransactionName"
    : window.location.pathname;

const serverUrl = process.browser
  ? window.location.origin
  : process.env.RAZZLE_ELASTIC_APM_FRONTEND_SERVER_URL;

const apm = initApm({
  serviceName: process.env.RAZZLE_ELASTIC_APM_SERVICE_NAME,
  serviceVersion: process.env.RAZZLE_ELASTIC_APM_SERVICE_VERSION,
  environment: process.env.RAZZLE_ELASTIC_APM_ENVIRONMENT,
  pageLoadTransactionName,
  serverUrl,
  active: false, //process.env.RAZZLE_ELASTIC_APM_ACTIVE === "true",
  ignoreTransactions: [/hls*/],
  disableInstrumentations: [
    "page-load",
    "history",
    "eventtarget",
    "click",
    "xmlhttprequest",
    "fetch",
    "error",
  ],
  flushInterval: 2000,
});

export default apm;
