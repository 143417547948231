import React from "react";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { useMutation, useQuery } from "@apollo/client";
import {
  CreateFavoriteContentMutation,
  DeleteFavoriteContentMutation,
} from "arena/mutations";
import { FavoriteContentQuery } from "arena/queries";
import { useTranslation } from "react-i18next";

export default function FavoriteButton({ contentId, ...props }) {
  const { t } = useTranslation("arena");
  const [createFavoriteContent] = useMutation(CreateFavoriteContentMutation, {
    update(cache) {
      cache.modify({
        fields: {
          favoriteContent() {
            return true;
          },
        },
      });
    },
    variables: {
      contentId,
    },
  });

  const [deleteFavoriteContent] = useMutation(DeleteFavoriteContentMutation, {
    update(cache) {
      cache.modify({
        fields: {
          favoriteContent(favoriteContentRefs, { DELETE }) {
            return DELETE;
          },
        },
      });
    },
    variables: {
      contentId,
    },
  });

  const { data } = useQuery(FavoriteContentQuery, {
    variables: {
      contentId,
    },
  });

  const isFavorite = data?.favoriteContent;

  const onClick = () => {
    if (isFavorite) deleteFavoriteContent();
    else createFavoriteContent();
  };

  return (
    <Tooltip
      title={
        isFavorite
          ? t("favoriteButton.isFavorite")
          : t("favoritButton.isNotFavorite")
      }
    >
      <IconButton {...props} onClick={onClick}>
        {isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
      </IconButton>
    </Tooltip>
  );
}

FavoriteButton.propTypes = {
  contentId: PropTypes.string.isRequired,
};
