import React, { useState } from "react";
import PropTypes from "prop-types";
import ErrorScreen from "core/components/ErrorScreen";
import PlayerPreview from "core/components/PlayerPreview";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import DevicesIcon from "@mui/icons-material/Devices";
import ClosePremiumSessionDialog from "core/components/ClosePremiumSessionDialog";
import { useViewer } from "core/components/ViewerProvider";

export default function ContentIsNotAvailableBySessionError({ content }) {
  const { t } = useTranslation("core");
  const viewer = useViewer();

  const [open, setOpen] = useState(false);

  const title = t("premiumSessionsLimit.title");
  const text = t("premiumSessionsLimit.text");

  const onClose = () => {
    setOpen(false);
  };

  return (
    <PlayerPreview preview={content.preview} overlay>
      <ErrorScreen
        icon={DevicesIcon}
        title={title}
        text={text}
        button={
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpen(true)}
          >
            {t("premiumSessionsLimit.showSessions")}
          </Button>
        }
      />

      {viewer && <ClosePremiumSessionDialog open={open} onClose={onClose} />}
    </PlayerPreview>
  );
}

ContentIsNotAvailableBySessionError.propTypes = {
  content: PropTypes.shape({
    preview: PropTypes.string,
  }).isRequired,
};
