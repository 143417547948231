export default function jsonLdPreview(content, arena) {
  let image = content.preview;

  return JSON.stringify(
    {
      "@context": "http://schema.org",
      "@type": "ImageObject",
      author: arena.title,
      contentUrl: image,
      datePublished: content.publishAt,
      description: content.description,
      name: content.title,
    },
    null,
    2
  );
}
