import React from "react";
import PropTypes from "prop-types";
import ContentGrid from "arena/components/ContentGrid";
import ContentCarousel from "arena/components/ContentCarousel";
import ContentList from "arena/components/ContentList";
import HomepageTitle from "arena/components/HomepageTitle";
import LoadMore from "core/components/LoadMore";
import { useQuery } from "@apollo/client";
import { FavoriteContentsQuery } from "arena/queries";
import ReactGA from "react-ga4";

const components = {
  CAROUSEL: ContentCarousel,
  GRID: ContentGrid,
  LIST: ContentList,
};

export default function FavoriteContents({
  title,
  view = "CAROUSEL",
  skip,
  limit = 12,
  xs = 12,
  sm = 6,
  md = 4,
  lg,
  xl,
  onCompleted,
  loadMore,
  itemsPerRow,
}) {
  const variables = {};

  if (skip) {
    variables.skip = skip;
  }

  if (limit) {
    variables.limit = limit;
  }

  const { data, loading, fetchMore } = useQuery(FavoriteContentsQuery, {
    fetchPolicy: "cache-and-network",
    variables,
    onCompleted,
  });

  const Component = components[view];

  const count = data?.favoriteContents?.count || 0;
  const nodes = data?.favoriteContents?.nodes || [];

  if (nodes.length === 0) return null;

  const hasMore = nodes.length < count;
  const showLoadMore = loadMore && hasMore && !loading && view !== "CAROUSEL";
  const nextPage = Math.ceil(nodes.length / limit);

  const onLoadMore = () => {
    ReactGA.event({
      category: "FavoriteContents",
      action: "Fetch more contents",
      label: nextPage + " page",
    });

    fetchMore({
      variables: {
        skip: nextPage * limit,
      },
    });
  };

  if (itemsPerRow) {
    sm = Math.max(12 / itemsPerRow, 4);
    md = Math.max(12 / itemsPerRow, 3);
    lg = 12 / itemsPerRow;
    xl = lg;
  }

  return (
    <>
      {title && <HomepageTitle title={title} />}

      <Component nodes={nodes} xs={xs} sm={sm} md={md} lg={lg} xl={xl} />

      {showLoadMore && <LoadMore key={nextPage} onLoadMore={onLoadMore} />}
    </>
  );
}

FavoriteContents.propTypes = {
  itemsPerRow: PropTypes.oneOf([2, 4, 3, 6]),
  xs: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  sm: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  md: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  lg: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  xl: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  title: PropTypes.string,
  view: PropTypes.string,
  limit: PropTypes.number,
  skip: PropTypes.number,
  onCompleted: PropTypes.func,
  loadMore: PropTypes.bool,
};
