import React from "react";
import PropTypes from "prop-types";
import { useViewer } from "core/components/ViewerProvider";
import { useArena } from "core/components/ArenaProvider";

function RetargetPixels({
  rightholder,
  partner,
  viewSource,
  sport,
  content,
  contentType,
  eventType,
  source = "site",
}) {
  const viewer = useViewer();
  const arena = useArena();

  let fbPixelId = process.env.RAZZLE_PIXEL_FB_ID;

  if (arena?.facebookPixelId) {
    fbPixelId = arena.facebookPixelId;
  }

  const obj = {
    rightholder,
    partner,
    viewSource,
    sport,
    content,
    contentType,
    eventType,
    source,
    knownUser: viewer ? 1 : 0,
  };

  if (!fbPixelId) return null;

  const fbUrl = new URL("https://www.facebook.com/tr");

  fbUrl.searchParams.append("id", fbPixelId);
  fbUrl.searchParams.append("ev", eventType);

  Object.keys(obj).map((key) => {
    if (key === "eventType") {
      return;
    }
    if (obj[key]) {
      fbUrl.searchParams.append(`cd[${key}]`, obj[key]);
    }
  });

  const FB = <img src={fbUrl.href} />;

  return (
    <div
      style={{
        position: "fixed",
        bottom: -10,
        right: 0,
        width: 1,
        height: 1,
        zIndex: -1,
      }}
    >
      {FB}
    </div>
  );
}

RetargetPixels.propTypes = {
  rightholder: PropTypes.string,
  partner: PropTypes.string,
  viewSource: PropTypes.string,
  sport: PropTypes.string,
  content: PropTypes.string,
  contentType: PropTypes.string,
  eventType: PropTypes.string,
  source: PropTypes.string,
};

export default RetargetPixels;
