import { useState, useEffect } from "react";
import { wsLink } from "../utils/apollo";

/**
 * Хук следит за статусом веб сокет коннекта подписок
 */
export default function useWsLinkStatus() {
  const [online, setOnline] = useState(
    Boolean(wsLink?.subscriptionClient?.client?.readyState)
  );

  useEffect(() => {
    if (!process.browser) {
      return;
    }

    const onConnect = () => {
      setOnline(true);
    };
    const onDisconnect = () => {
      setOnline(false);
    };

    const offConnectedHandler = wsLink.subscriptionClient.on(
      "connected",
      onConnect
    );
    const offReconnectedHandler = wsLink.subscriptionClient.on(
      "reconnected",
      onConnect
    );
    const offDisconnectedHandler = wsLink.subscriptionClient.on(
      "disconnected",
      onDisconnect
    );

    return () => {
      offConnectedHandler();
      offReconnectedHandler();
      offDisconnectedHandler();
    };
  }, [process.browser]);

  return { online };
}
