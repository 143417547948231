import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import DialogContent from "@mui/material/DialogContent";
import { withSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import SettingsHeader from "arena/components/SettingsHeader";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useMutation, useApolloClient } from "@apollo/client";
import { DeleteAccountMutation } from "arena/mutations";
import LocalStorage from "core/utils/localstorage";

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  overflow: "hidden",
  padding: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(3),
  },
}));

const ls = new LocalStorage(`apollo`);

function SettingsAccount({ tabs, onClose, enqueueSnackbar }) {
  const { t } = useTranslation("arena");

  const client = useApolloClient();

  const [deleteAccount, { loading }] = useMutation(DeleteAccountMutation, {
    onCompleted: () => {
      ls.removeItem("token");
      client.resetStore();
      enqueueSnackbar(t("accountSettings.deleteAccount.success"), {
        variant: "success",
      });
    },
    onError: (error) => {
      console.error(error);
      enqueueSnackbar(t("accountSettings.deleteAccount.error"), {
        variant: "error",
      });
    },
  });

  const onClickDeleteAccount = () => {
    if (window.confirm(t("accountSettings.deleteAccount.confirmation"))) {
      // eslint-disable-next-line no-alert
      deleteAccount();
    }
  };

  return (
    <>
      <SettingsHeader
        onClose={onClose}
        title={t("accountSettings.title")}
        tabs={tabs}
      />

      <StyledDialogContent>
        <Stack spacing={2}>
          <Typography variant="h6">
            {t("accountSettings.downloadData.title")}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {t("accountSettings.downloadData.description")}
          </Typography>
          <Button
            href={"/api/user/download"}
            variant="contained"
            color="primary"
            target="_blank"
            fullWidth
          >
            {t("accountSettings.downloadData.button")}
          </Button>

          <Typography variant="h6">
            {t("accountSettings.deleteAccount.title")}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {t("accountSettings.deleteAccount.description")}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {t("accountSettings.deleteAccount.warning")}
          </Typography>

          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={onClickDeleteAccount}
            disabled={loading}
          >
            {t("accountSettings.deleteAccount.button")}
          </Button>
        </Stack>
      </StyledDialogContent>
    </>
  );
}

SettingsAccount.propTypes = {
  onClose: PropTypes.func.isRequired,
  tabs: PropTypes.node,
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(SettingsAccount);
