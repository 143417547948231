import React from "react";
import { styled } from "@mui/material/styles";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import ErrorScreen from "core/components/ErrorScreen";

const Root = styled("div")({
  minHeight: "60vh",
  height: "100%",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export default function NoMatchScreen() {
  const { t } = useTranslation("arena");

  return (
    <Root>
      <Helmet>
        <title>{t("noMatchScreen.title")}</title>
      </Helmet>

      <ErrorScreen
        code={404}
        icon={SentimentVeryDissatisfiedIcon}
        title={t("noMatchScreen.title")}
        text={t("noMatchScreen.text")}
      />
    </Root>
  );
}
