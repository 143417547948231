import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Slider from "@mui/material/Slider";
import SeekBarSliderRoot from "core/components/SeekBarSliderRoot";
import SeekBarPopup from "core/components/SeekBarPopup";
import moment from "core/utils/moment";

const PREFIX = "LiveSeekBar";

const classes = {
  sliderRoot: `${PREFIX}-sliderRoot`,
  sliderContainer: `${PREFIX}-sliderContainer`,
  sliderTrack: `${PREFIX}-sliderTrack`,
  sliderRail: `${PREFIX}-sliderRail`,
  sliderThumb: `${PREFIX}-sliderThumb`,
  progressRoot: `${PREFIX}-progressRoot`,
  progressColorPrimary: `${PREFIX}-progressColorPrimary`,
  progressBarColorPrimary: `${PREFIX}-progressBarColorPrimary`,
};

const Root = styled("div")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: -12,
  zIndex: 3,
  height: 24,

  [`& .${classes.sliderRoot}`]: {
    background: "transparent",
    position: "absolute",
    padding: "11px 0",
    top: 0,
    left: 0,
    right: 0,
  },

  [`& .${classes.sliderContainer}`]: {
    padding: "10px 0",
  },

  [`& .${classes.sliderTrack}`]: {
    height: 4,
    backgroundColor: theme.palette.primary.dark,
    border: 0,
  },

  [`& .${classes.sliderRail}`]: {
    backgroundColor: theme.palette.primary.dark,
    height: 4,
  },

  [`& .${classes.sliderThumb}`]: {
    background: "white",
    width: 10,
    height: 10,
  },

  [`& .${classes.progressRoot}`]: {
    height: 4,
    top: 11,
  },

  [`& .${classes.progressColorPrimary}`]: {
    background: "transparent",
  },

  [`& .${classes.progressBarColorPrimary}`]: {
    background: "rgba(255,255,255,0.2)",
    animation: "none",
  },
}));

const { RAZZLE_STREAM_SHIFT_LIMIT = 86400 } = process.env;

export function useLiveSeekBar(startAt, { onSetOffset, startOffset } = {}) {
  const [offset, setOffset] = useState(startOffset || 0); // Live position offseet in seconds
  const startAtLimited = moment.max(
    moment().subtract(RAZZLE_STREAM_SHIFT_LIMIT, "seconds"),
    moment(startAt)
  ); // Limit rewind to 1 day

  const startTime = moment(startAtLimited).unix(); // Unix Timestamp (seconds)
  const finishTime = moment().unix(); // Unix Timestamp (seconds)
  const duration = finishTime - startTime; // Timeline duration in seconds
  const currentPosition = duration - offset;

  useEffect(() => {
    if (startOffset) {
      setOffset(startOffset);
    }
  }, [startOffset]);

  return {
    offset,
    currentPosition,
    duration,
    startAtLimited: startAtLimited.unix(),
    setOffset(offset) {
      setOffset(Math.min(offset, duration));
      if (onSetOffset) {
        onSetOffset(Math.min(offset, duration));
      }
    },
  };
}

// function ThumbComponent(props) {
//   return <span {...props} onKeyDown={null}></span>;
// }

function getStep(duration) {
  if (duration < 60 * 60) return 5; // от 0 часов до 1 часа округляем до 5 секунд
  if (duration < 120 * 60) return 10; // от 1 часа до 2 часов округляем до 10 секунд
  if (duration < 240 * 60) return 30; // от 2 часов до 4 часов округаляем до 30 секунд
  // от 4 часов и дальше округляем до 60 секунд
  return 60;
}

function LiveSeekBar(props) {
  const { videoState, visible, liveSeekBar } = props;
  const [isPopupFixed, setIsPopupFixed] = React.useState(false);

  if (!visible) return null;
  if (!videoState) return null;
  if (!liveSeekBar) return null;
  if (!videoState.live) return null;

  const { duration, currentPosition, setOffset } = liveSeekBar;
  const step = getStep(duration);

  function handleChange(event, value) {
    let newOffset = duration - value;

    newOffset = Math.floor(newOffset / step) * step;

    setOffset(newOffset);
    setIsPopupFixed(true);
  }

  function handleChangeCommitted() {
    setIsPopupFixed(false);
  }

  const popupMargin = Math.round(SeekBarPopup.MIN_WIDTH / 2);

  return (
    <Root>
      <Slider
        component={SeekBarSliderRoot}
        // ThumbComponent={ThumbComponent}
        value={currentPosition}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
        classes={{
          root: classes.sliderRoot,
          track: classes.sliderTrack,
          thumb: classes.sliderThumb,
          rail: classes.sliderRail,
        }}
        max={duration}
        min={0}
        step={step}
        PopupComponent={SeekBarPopup}
        isPopupFixed={isPopupFixed}
        popupMarginLeft={popupMargin}
        popupMarginRight={popupMargin}
        popupProps={{
          duration,
          reverse: true,
        }}
      />
    </Root>
  );
}

LiveSeekBar.propTypes = {
  liveSeekBar: PropTypes.shape({
    duration: PropTypes.number,
    currentPosition: PropTypes.number,
    setOffset: PropTypes.func,
  }),
  visible: PropTypes.bool,
  videoActions: PropTypes.object,
  videoState: PropTypes.object,
};

export default LiveSeekBar;
