import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MuiLink from "@mui/material/Link";
import { useTranslation } from "react-i18next";

export default function PaymentTerms({ arena, product }) {
  const { t } = useTranslation("arena");

  if (!arena.termsOfServiceUrl) return null;
  if (!arena.refundPolicyUrl) return null;

  const texts = {
    PPV: t("productPayment.accept"),
    SUBSCRIPTION: t("productPayment.accept"),
    DONATION: t("productPayment.acceptDonation"),
  };

  return (
    <Grid item xs={12}>
      <Typography color="textSecondary" variant="caption" display="block">
        {texts[product.type]}

        <MuiLink
          href={arena.termsOfServiceUrl}
          color="textPrimary"
          target="_blank"
          underline="hover"
        >
          {t("productPayment.termsOfService")}
        </MuiLink>

        {t("productPayment.and")}

        <MuiLink
          href={arena.refundPolicyUrl}
          color="textPrimary"
          target="_blank"
          underline="hover"
        >
          {t("productPayment.refundPolicy")}
        </MuiLink>

        {t("productPayment.finish")}
      </Typography>
    </Grid>
  );
}

PaymentTerms.propTypes = {
  product: PropTypes.shape({
    type: PropTypes.oneOf(["PPV", "SUBSCRIPTION", "DONATION"]).isRequired,
  }).isRequired,
  arena: PropTypes.shape({
    termsOfServiceUrl: PropTypes.string,
    refundPolicyUrl: PropTypes.string,
  }).isRequired,
};
