import React from "react";
import PropTypes from "prop-types";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import formatCurrency from "core/utils/currency";
import copy from "copy-to-clipboard";
import { withSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import AppBar from "@mui/material/AppBar";

import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

const SUBSCRIPTION = "SUBSCRIPTION";
const PPV = "PPV";
const DONATION = "DONATION";

function ReferalPromocode({ product, enqueueSnackbar, classes }) {
  const { t } = useTranslation("arena");

  const promocode = product?.viewerPurchase?.promocode;

  if (!promocode) return null;
  if (product.type === DONATION) return null;

  const { currency } = product.viewerPurchase.amount;
  const { code, amount } = promocode;
  const promocodeUrl = product.shareUrl + "?promocode=" + code;
  const promocodeAmount = formatCurrency(amount, currency);

  const onClickCopyPromocode = () => {
    copy(promocodeUrl);
    enqueueSnackbar(t("productPurchase.urlCopied"), {
      variant: "success",
    });
  };

  return (
    <>
      <AppBar
        color="default"
        elevation={0}
        position="static"
        className={classes.appBar}
      >
        <CardHeader
          className={classes.header}
          title={t("referalPromocode.title")} // "Get discount for next purchases"
          subheader={t("referalPromocode.subtitle", {
            amount: promocodeAmount,
          })}
          subheaderTypographyProps={{
            variant: "caption",
          }}
        />
      </AppBar>
      <CardContent>
        <TextField
          fullWidth
          value={promocodeUrl}
          variant="outlined"
          label={t("referalPromocode.shareUrl")}
          // label="Discount link"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  color="primary"
                  onClick={onClickCopyPromocode}
                  variant="contained"
                >
                  {t("referalPromocode.copy")}
                </Button>
              </InputAdornment>
            ),
          }}
        />
      </CardContent>
    </>
  );
}

ReferalPromocode.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    appBar: PropTypes.string,
    header: PropTypes.string,
  }),
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    shareUrl: PropTypes.string.isRequired,
    finished: PropTypes.bool,
    startAt: PropTypes.string.isRequired,
    finishAt: PropTypes.string.isRequired,
    composition: PropTypes.arrayOf(PropTypes.string).isRequired,
    type: PropTypes.oneOf([SUBSCRIPTION, PPV, DONATION]).isRequired,
    rightholder: PropTypes.shape({
      id: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      sellerInfo: PropTypes.string,
    }).isRequired,
    preview: PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
    viewerPurchase: PropTypes.shape({
      id: PropTypes.string.isRequired,
      renewSubscription: PropTypes.bool,
      type: PropTypes.oneOf([SUBSCRIPTION, PPV, DONATION]).isRequired,
      recurrent: PropTypes.bool,
      started: PropTypes.bool,
      expired: PropTypes.bool,
      startAt: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      expiredAt: PropTypes.string.isRequired,
      period: PropTypes.number.isRequired,
      daysLeft: PropTypes.number.isRequired,
      promocode: PropTypes.shape({
        code: PropTypes.string.isRequired,
      }),
      amount: PropTypes.shape({
        currency: PropTypes.string.isRequired,
      }),
    }),
  }),
};

export default withSnackbar(ReferalPromocode);
