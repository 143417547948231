import React from "react";
import PropTypes from "prop-types";
import HomepageTitle from "arena/components/HomepageTitle";
import ProductList from "arena/components/ProductList";

export default function ArenaProducts({
  arena,
  limit = 12,
  title,
  loadMore,
  type,
  rightholderIds,
}) {
  return (
    <ProductList
      title={title && <HomepageTitle title={title} />}
      rowsPerPage={limit}
      arenaId={arena.id}
      rightholderIds={rightholderIds}
      type={type}
      loadMore={loadMore}
    />
  );
}

ArenaProducts.propTypes = {
  arena: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  type: PropTypes.string,
  limit: PropTypes.number,
  title: PropTypes.string,
  rightholderIds: PropTypes.arrayOf(PropTypes.string),
  loadMore: PropTypes.bool,
};
