import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
import { useMutation } from "@apollo/client";
import { UploadAvatarMutation } from "arena/mutations";
import CircularProgress from "@mui/material/CircularProgress";

const PREFIX = "AvatarField";

const classes = {
  root: `${PREFIX}-root`,
  avatar: `${PREFIX}-avatar`,
  photoIcon: `${PREFIX}-photoIcon`,
  input: `${PREFIX}-input`,
  loading: `${PREFIX}-loading`,
};

const Root = styled("div")({
  [`&.${classes.root}`]: {
    width: 100,
    height: 100,
    borderRadius: 100,
    overflow: "hidden",
    position: "relative",
    cursor: "pointer",
  },
  [`& .${classes.avatar}`]: {
    width: 100,
    height: 100,
    backgroundColor: "rgba(255, 255, 255, 0.03)",
    color: "white",
  },
  [`& .${classes.photoIcon}`]: {
    position: "absolute",
    left: 26,
    top: 26,
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  },
  [`& .${classes.input}`]: {
    display: "none",
  },
  [`& .${classes.loading}`]: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
});

export default function AvatarField({ image, onChange, onError }) {
  const [src, setImage] = useState(image);
  const [uploadAvatar, { loading }] = useMutation(UploadAvatarMutation, {
    onError,
    onCompleted(data) {
      onChange(data.uploadAvatar.id);
    },
  });

  let inputRef = null;

  const onClickUpload = (event) => {
    event.preventDefault();
    event.stopPropagation();
    inputRef.click();
  };

  const onChangeFileInput = (event) => {
    const file = event.target.files[0];
    const image = window.URL.createObjectURL(file);
    setImage(image);
    uploadAvatar({ variables: { file } });
    event.target.value = null;
  };

  return (
    <Root className={classes.root}>
      <Avatar className={classes.avatar} src={src} onClick={onClickUpload} />

      {loading && (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      )}

      <input
        accept="image/*"
        type="file"
        className={classes.input}
        ref={(n) => {
          inputRef = n;
        }}
        onChange={onChangeFileInput}
      />
    </Root>
  );
}

AvatarField.propTypes = {
  image: PropTypes.string,
  onChange: PropTypes.func,
  onError: PropTypes.func,
};
