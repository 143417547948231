import React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useQuery } from "@apollo/client";
import { TimeZonesQuery } from "arena/queries";
import { getTimeZone } from "core/utils/moment";

export default function TimeZoneField(props) {
  const { country, onChange, value, label, required } = props;

  const { loading, data } = useQuery(TimeZonesQuery, {
    fetchPolicy: "cache-and-network",
    variables: {
      country,
    },
  });

  if (loading) return null;

  let options = [...data?.timeZones];

  const currentTimeZone = getTimeZone();
  if (!options.includes(currentTimeZone)) {
    options.unshift(currentTimeZone);
  }

  return (
    <Autocomplete
      options={options}
      fullWidth
      value={value}
      renderInput={(params) => (
        <TextField {...params} label={label} required={required} />
      )}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
    />
  );
}

TimeZoneField.propTypes = {
  country: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
};
