/**
 * Возвращает
 * "Неделя"
 * "Месяц"
 * "Год"
 */
export default function getPricePeriod({ t, period }) {
  const periodTitles = {
    7: t("productPayment.periodWeek"),
    30: t("productPayment.periodMonth"),
    90: t("productPayment.period3Months"),
    180: t("productPayment.period6Months"),
    365: t("productPayment.periodYear"),
  };

  return periodTitles[period];
}
