import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { SponsorPlayerButtonQuery } from "core/queries";
import { useQuery } from "@apollo/client";
import {
  createSponsorImpression,
  createSponsorClick,
  trackAdPixel,
  postMessage,
} from "core/utils/tracker";
import classNames from "classnames";
import Button from "@mui/material/Button";
import { nanoid } from "nanoid";

const PREFIX = "SponsorPlayerButton";

const classes = {
  root: `${PREFIX}-root`,
  topLeft: `${PREFIX}-topLeft`,
  topRight: `${PREFIX}-topRight`,
  bottomLeft: `${PREFIX}-bottomLeft`,
  bottomRight: `${PREFIX}-bottomRight`,
  visible: `${PREFIX}-visible`,
  footerVisible: `${PREFIX}-footerVisible`,
};

const StyledButton = styled(Button)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "none",
    position: "absolute",
    zIndex: 1000,
  },

  [`& .${classes.topLeft}`]: {
    top: theme.spacing(1),
    left: theme.spacing(1),
    ["@media (min-width: 375px)"]: {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      top: theme.spacing(3),
      left: theme.spacing(3),
    },
  },

  [`&.${classes.topRight}`]: {
    top: theme.spacing(1),
    right: theme.spacing(1),
    ["@media (min-width: 375px)"]: {
      top: theme.spacing(2),
      right: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      top: theme.spacing(3),
      right: theme.spacing(3),
    },
  },

  [`& .${classes.bottomLeft}`]: {
    bottom: theme.spacing(1),
    left: theme.spacing(1),
    ["@media (min-width: 375px)"]: {
      bottom: theme.spacing(2),
      left: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      bottom: theme.spacing(3),
      left: theme.spacing(3),
    },

    [`&.${classes.footerVisible}`]: {
      bottom: 66,
      ["@media (min-width: 375px)"]: {
        bottom: 76,
      },
    },
  },

  [`& .${classes.bottomRight}`]: {
    bottom: theme.spacing(1),
    right: theme.spacing(1),

    ["@media (min-width: 375px)"]: {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },

    [theme.breakpoints.up("md")]: {
      bottom: theme.spacing(3),
      right: theme.spacing(3),
    },

    [`&.${classes.footerVisible}`]: {
      bottom: 66,
      ["@media (min-width: 375px)"]: {
        bottom: 76,
      },
    },
  },

  [`&.${classes.visible}`]: {
    display: "flex",
  },
}));

const CLICK = "CLICK";
const VIDEO_IN_EMBED = "VIDEO_IN_EMBED";
const STREAM_IN_EMBED = "STREAM_IN_EMBED";
const VIDEO_ON_SITE = "VIDEO_ON_SITE";
const STREAM_ON_SITE = "STREAM_ON_SITE";
const IMPRESSION = "IMPRESSION";
const TOP_LEFT = "TOP_LEFT";
const TOP_RIGHT = "TOP_RIGHT";
const BOTTOM_LEFT = "BOTTOM_LEFT";
const BOTTOM_RIGHT = "BOTTOM_RIGHT";

export default function SponsorPlayerButton({
  place,
  contentId,
  rightholderId,
  visible,
  footerVisible,
}) {
  const { data, loading } = useQuery(SponsorPlayerButtonQuery, {
    skip: !process.browser,
    fetchPolicy: "cache-and-network",
    variables: {
      contentId,
      rightholderId,
      place,
    },
    onCompleted() {
      if (data?.sponsorCampaign?.playerButton) {
        const { token, impressionPixel } = data.sponsorCampaign.playerButton;

        createSponsorImpression(token, "Player Button", IMPRESSION);

        if (impressionPixel) {
          trackAdPixel(impressionPixel);
        }

        postMessage({
          eventName: IMPRESSION,
          creative: "SponsorPlayerButton",
          place,
        });
      }
    },
  });

  if (loading) return null;
  if (!data?.sponsorCampaign) return null;

  const { id, link, text, color, textColor, position, token, clickPixel } =
    data.sponsorCampaign.playerButton;

  const onClick = (event) => {
    event.preventDefault();

    if (clickPixel) {
      trackAdPixel(clickPixel);
    }

    postMessage({
      eventName: CLICK,
      creative: "SponsorPlayerButton",
      place,
    });

    const clickId = nanoid();
    const url = link.replace("{clickId}", clickId);

    window.open(url, "_blank");

    createSponsorClick(token, clickId);
  };

  return (
    <StyledButton
      onClick={onClick}
      variant="contained"
      color="primary"
      className={classNames({
        [classes.root]: true,
        [classes.topLeft]: position === TOP_LEFT,
        [classes.topRight]: position === TOP_RIGHT,
        [classes.bottomLeft]: position === BOTTOM_LEFT,
        [classes.bottomRight]: position === BOTTOM_RIGHT,
        [classes.visible]: visible,
        [classes.footerVisible]: footerVisible,
      })}
      style={{ backgroundColor: color, color: textColor }}
      key={id}
    >
      {text}
    </StyledButton>
  );
}

SponsorPlayerButton.propTypes = {
  place: PropTypes.oneOf([
    VIDEO_IN_EMBED,
    STREAM_IN_EMBED,
    VIDEO_ON_SITE,
    STREAM_ON_SITE,
  ]).isRequired,
  contentId: PropTypes.string,
  rightholderId: PropTypes.string,
  visible: PropTypes.bool,
  footerVisible: PropTypes.bool,
};
