import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogContent from "@mui/material/DialogContent";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import DialogHeader from "core/components/DialogHeader";
import CardActionArea from "@mui/material/CardActionArea";
import getMinPrice from "core/utils/getMinPrice";
import getOldPriceButtonText from "core/utils/getOldPriceButtonText";
import getPriceButtonText from "core/utils/getPriceButtonText";

const PREFIX = "ChooseProductDialog";

const classes = {
  title: `${PREFIX}-title`,
  avatar: `${PREFIX}-avatar`,
  content: `${PREFIX}-content`,
  cardContent: `${PREFIX}-cardContent`,
  button: `${PREFIX}-button`,
  oldPrice: `${PREFIX}-oldPrice`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.title}`]: {
    flex: 1,
    marginLeft: 4,
  },

  [`& .${classes.avatar}`]: {
    borderRadius: 0,
    width: 80,
    height: 45,
    marginRight: 10,
  },

  [`& .${classes.content}`]: {
    padding: theme.spacing(1),
    backgroundColor: "#101010",
  },

  [`& .${classes.cardContent}`]: {
    paddingTop: 0,
  },

  [`& .${classes.button}`]: {
    whiteSpace: "nowrap",
    color: "white",
    backgroundColor: "transparent",
    border: `4px solid ${theme.palette.primary.main}`,
    fontSize: "1.4rem",
    fontWeight: 600,
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.8rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "2.4rem",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  [`& .${classes.oldPrice}`]: {
    color: "rgba(255,255,255,0.5)",
    position: "relative",
    display: "inline-block",
    fontSize: "1.2rem",
    marginRight: 10,
    fontWeight: 600,
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.4rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.6rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "2rem",
    },
    "&:after": {
      content: '""',
      position: "absolute",
      left: 0,
      right: 0,
      top: "calc(50% - 2px)",
      backgroundColor: "white",
      height: "2px",
    },
  },
}));

export default function ChooseProductDialog({ open, onClose, products }) {
  const { t } = useTranslation("core");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <StyledDialog
      scroll="paper"
      fullWidth
      fullScreen={fullScreen}
      maxWidth="sm"
      open={open}
    >
      <DialogHeader onClose={onClose} title={t("chooseProductDialog.title")} />

      <DialogContent className={classes.content}>
        <Grid container spacing={1}>
          {products.map((product) => {
            const minPrice = getMinPrice(product);
            const text = getPriceButtonText(product, minPrice, t);
            const oldPrice = getOldPriceButtonText(minPrice);

            return (
              <Grid item xs={12} key={product.id}>
                <Card>
                  <CardActionArea
                    href={product.shareUrl}
                    target="_blank"
                    component="a"
                  >
                    <CardHeader title={product.title} />
                    <CardContent className={classes.cardContent}>
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        disableRipple
                        fullWidth
                        size="large"
                        className={classes.button}
                      >
                        {oldPrice && (
                          <span className={classes.oldPrice}>{oldPrice}</span>
                        )}
                        {text}
                      </Button>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </DialogContent>
    </StyledDialog>
  );
}

ChooseProductDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      shareUrl: PropTypes.string.isRequired,
      prices: PropTypes.array.isRequired,
    })
  ),
};
