import React, { useState } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { VerifyEmailMutation } from "core/mutations";

export default function VerifyEmailForm({
  email,
  token,
  onCompleted,
  onError,
}) {
  const { t } = useTranslation("core");
  const [code, setCode] = useState("");
  const [verifyEmail, { loading }] = useMutation(VerifyEmailMutation, {
    variables: { code, token },
    onCompleted,
    onError,
  });

  const onSubmit = (event) => {
    event.preventDefault();
    verifyEmail();
  };

  const onChange = (event) => {
    const code = event.target.value.trim();
    setCode(code);

    if (code.length === 4) {
      verifyEmail({
        variables: {
          code,
          token,
        },
      });
    }
  };

  return (
    <Grid
      container
      spacing={4}
      name="verifyEmail"
      component="form"
      onSubmit={onSubmit}
    >
      <Grid item xs={12}>
        <TextField
          autoFocus
          required
          fullWidth
          variant="outlined"
          label={t("signIn.code")}
          helperText={t("signIn.codeText", { email })}
          placeholder="****"
          disabled={loading}
          onChange={onChange}
        />
      </Grid>
    </Grid>
  );
}

VerifyEmailForm.propTypes = {
  email: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onCompleted: PropTypes.func.isRequired,
};
