import React, { useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { TagsQuery } from "arena/queries";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";

export default function TagsAutocomplete({
  tagType,
  tagIds,
  filterTagsBySelectedTags,
  onChangeAutocomplete,
  sort,
}) {
  const [opened, setOpened] = useState(false);
  const variables = {
    tagTypeId: tagType.id,
    sort,
  };

  if (filterTagsBySelectedTags) {
    variables.contentTagIds = tagIds;
  }

  const { data, loading, previousData } = useQuery(TagsQuery, {
    variables,
    skip: !opened && !tagIds?.length,
  });

  const tags = data?.tags || previousData?.tags || [];

  return (
    <Autocomplete
      fullWidth
      filterSelectedOptions
      onChange={(event, newValue) => {
        const ids = tags.map((t) => t.id); // ID тегов которые надо исключить из выбранных
        onChangeAutocomplete(ids, newValue);
      }}
      onOpen={() => {
        setOpened(true);
      }}
      isOptionEqualToValue={(option, value) => {
        return option?.value === value?.value;
      }}
      options={tags.map((t) => ({
        label: t.title,
        value: t.id,
      }))}
      value={
        tags
          .map((t) => ({
            label: t.title,
            value: t.id,
          }))
          .find((tag) => tagIds.includes(tag.value)) || null
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={tagType.title}
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {opened && loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}

TagsAutocomplete.propTypes = {
  onChangeAutocomplete: PropTypes.func.isRequired,
  tagIds: PropTypes.array,
  sort: PropTypes.string,
  tagType: PropTypes.object,
  filterTagsBySelectedTags: PropTypes.bool,
  rightholderIds: PropTypes.array,
};
