export default async function getClientIp(url) {
  if (!url) {
    return;
  }

  const res = await fetch(url);

  if (res.ok) {
    return res.text();
  }
}
