import React from "react";
import PropTypes from "prop-types";
import InView from "./InView";
import Hover from "./Hover";

export default function Previewable({ children, isMobile }) {
  const Component = isMobile ? InView : Hover;

  return <Component>{children}</Component>;
}

Previewable.propTypes = {
  children: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};
