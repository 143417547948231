import React from "react";
import PropTypes from "prop-types";
import PlayerPreview from "core/components/PlayerPreview";
import PlayerFooter from "core/components/PlayerFooter";
import PlayerPause from "core/components/PlayerPause";
import useAutoPlay from "core/hooks/useAutoPlay";
import useIdle from "core/hooks/useIdle";

export default function PreviewScreen({
  content,
  onClickPlay,
  hideTitle,
  hideShare,
  hideLogo,
  autoPlay,
  fullscreenTarget,
}) {
  const ref = useAutoPlay(onClickPlay, autoPlay);
  const idle = useIdle({ target: "preview" });

  return (
    <PlayerPreview
      preview={content.preview}
      ref={ref}
      overlay={!idle}
      id="preview"
    >
      {!hideTitle && (
        <PlayerPause
          title={content.title}
          contentUrl={content.shareUrl}
          onClickPlay={onClickPlay}
          visible={!autoPlay && !idle}
          transparent
          hideLogo={hideLogo || content.hideLogo}
        />
      )}

      <PlayerFooter
        content={content}
        position="absolute"
        visible
        hideShare={hideShare}
        hideLogo={hideLogo}
        fullscreenTarget={fullscreenTarget}
      />
    </PlayerPreview>
  );
}

PreviewScreen.propTypes = {
  fullscreenTarget: PropTypes.string,
  hideShare: PropTypes.bool,
  hideLogo: PropTypes.bool,
  autoPlay: PropTypes.bool,
  hideTitle: PropTypes.bool,
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    shareUrl: PropTypes.string.isRequired,
    preview: PropTypes.string,
    hideLogo: PropTypes.bool,
    rightholder: PropTypes.shape({
      isAdvEnabled: PropTypes.bool,
    }),
  }).isRequired,
  onClickPlay: PropTypes.func.isRequired,
};
