import React from "react";
import { styled } from "@mui/material/styles";
import HomepageTitle from "arena/components/HomepageTitle";
import ProductPurchaseList from "arena/components/ProductPurchaseList";
import usePageview from "core/hooks/usePageview";
import useScrollToTop from "core/hooks/useScrollToTop";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const Root = styled("div")(({ theme }) => ({
  overflow: "hidden",
  padding: theme.spacing(0, 0, 2),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(0, 2, 2),
  },
}));

export default function ProductPurchases() {
  const { t } = useTranslation("arena");
  const location = useLocation();

  usePageview(location.pathname, "Purchases");
  useScrollToTop();

  return (
    <Root>
      <HomepageTitle title={t("productPurchases.title")} />

      <ProductPurchaseList rowsPerPage={12} />
    </Root>
  );
}
