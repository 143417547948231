import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import QiwiIcon from "arena/components/QiwiIcon";
import YandexMoneyIcon from "arena/components/YandexMoneyIcon";
import SberIcon from "arena/components/SberIcon";
import ApplepayIcon from "arena/components/ApplepayIcon";
import GooglepayIcon from "arena/components/GooglepayIcon";
import CreditCardIcon from "arena/components/CreditCardIcon";
import SamsungpayIcon from "arena/components/SamsungpayIcon";
import PayPalIcon from "arena/components/PayPalIcon";
import { paymentProviderNames } from "core/utils/paymentProviders";

const paymentProviderIcons = {
  YANDEX: [
    <CreditCardIcon width="40" key={1} />,
    <ApplepayIcon width="40" key={2} />,
    <GooglepayIcon width="40" key={3} />,
    <SberIcon height="20" key={4} />,
    <YandexMoneyIcon height="20" key={5} />,
  ],
  ROBOKASSA: [
    <CreditCardIcon width="40" key={1} />,
    <ApplepayIcon width="40" key={2} />,
    <QiwiIcon height="20" key={3} />,
    <SamsungpayIcon height="20" key={4} />,
  ],
  STRIPE: [
    <CreditCardIcon width="40" key={1} />,
    <ApplepayIcon width="40" key={2} />,
    <GooglepayIcon width="40" key={3} />,
  ],
  PAYPAL: [
    <PayPalIcon width="40" key={1} />,
    <CreditCardIcon width="40" key={2} />,
  ],
};

export default function PaymentProvidersList({ providers, onSelect }) {
  const onClick = (event, provider) => {
    event.preventDefault();

    onSelect(provider);
  };

  return (
    <Grid container spacing={1}>
      {providers.map((provider) => (
        <Grid
          item
          xs={12}
          sm={providers.length % 2 === 0 ? 6 : 4}
          key={provider.name}
          container
        >
          <Card
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <CardActionArea onClick={(event) => onClick(event, provider)}>
              <div style={{ padding: 16, height: 200 }}>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Grid item xs={12}>
                    <Typography align="left" variant="h6">
                      {paymentProviderNames[provider.name]}
                    </Typography>
                  </Grid>
                  {paymentProviderIcons[provider.name].map((icon, index) => (
                    <Grid item key={index}>
                      {icon}
                    </Grid>
                  ))}
                </Grid>
              </div>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

PaymentProvidersList.propTypes = {
  onSelect: PropTypes.func.isRequired,
  providers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      token: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};
