import React from "react";
import PropTypes from "prop-types";
import ArenaHelmet from "arena/components/ArenaHelmet";

export default function PostHelmet({ post }) {
  const imageUrl = post?.image?.url;

  return (
    <ArenaHelmet
      title={post.title}
      description={post.description}
      imageUrl={imageUrl}
    />
  );
}

PostHelmet.propTypes = {
  post: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
  }),
};
