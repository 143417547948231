import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ReactGA from "react-ga4";
import { styled } from "@mui/material/styles";
import classNames from "classnames";

const PREFIX = "ArenaCoverButton";

const classes = {
  small: `${PREFIX}-small`,
  medium: `${PREFIX}-medium`,
  large: `${PREFIX}-large`,
};

const StyledButton = styled(Button)(({ theme }) => ({
  [`&.${classes.small}`]: {
    fontWeight: 600,
  },

  [`&.${classes.medium}`]: {
    fontWeight: 600,
    fontSize: "1em",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.1rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.3rem",
    },
  },

  [`&.${classes.large}`]: {
    fontWeight: 600,
    fontSize: "1em",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.3rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.4rem",
    },
  },
}));

export default function Buttons({ buttons }) {
  const onClickButton = (event, button) => {
    ReactGA.event({
      category: "ArenaCover",
      action: "Click to button",
      label: button.text,
    });
  };

  return buttons
    .filter((button) => button.text)
    .map((button, i) => {
      const component = button?.url?.startsWith("/") ? Link : "a";

      return (
        <Grid item key={i}>
          <StyledButton
            component={component}
            to={button.url}
            href={button.url}
            variant={button.variant}
            color={button.color}
            size={button.size}
            onClick={(event) => onClickButton(event, button)}
            className={classNames({
              [classes.small]: button.size === "small",
              [classes.medium]: button.size === "medium",
              [classes.large]: button.size === "large",
            })}
          >
            {button.text}
          </StyledButton>
        </Grid>
      );
    });
}

Buttons.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
      variant: PropTypes.string,
      color: PropTypes.string,
      size: PropTypes.string,
    })
  ),
};
