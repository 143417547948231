import React from "react";
import PropTypes from "prop-types";
import SponsorVideo from "core/components/SponsorVideo";

export default function SponsorPostAdv({
  videoState,
  content,
  rightholderId,
  sponsorVideoPlace,
  sponsorChatMessagePlace,
  fullscreenTarget,
  onFinish,
  onChatAdv,
  muted,
}) {
  if (!videoState.isEnded) return null;
  if (!process.browser) return null;

  const onStart = () => {
    //
  };

  const _onFinish = () => {
    onFinish();
  };

  const onNotFound = () => {
    onFinish(); // Если видео не приехало, все равно вызовем onFinish. Чтобы в VideoScreen сработало onFinishPostroll
  };

  const videoPlace = "POSTROLL_IN_" + sponsorVideoPlace;
  const chatMessagePlace = sponsorChatMessagePlace
    ? "POSTROLL_IN_" + sponsorChatMessagePlace
    : null;

  return (
    <SponsorVideo
      shareUrl={content?.shareUrl}
      chatMessagePlace={chatMessagePlace}
      videoPlace={videoPlace}
      contentId={content.id}
      sportId={content?.sport?.id}
      rightholderId={rightholderId}
      fullscreenTarget={fullscreenTarget}
      onStart={onStart}
      onFinish={_onFinish}
      onChatAdv={onChatAdv}
      onNotFound={onNotFound}
      muted={muted}
    />
  );
}

SponsorPostAdv.propTypes = {
  muted: PropTypes.bool,
  onChatAdv: PropTypes.func,
  videoState: PropTypes.shape({
    isEnded: PropTypes.bool,
  }).isRequired,
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    shareUrl: PropTypes.string.isRequired,
    sport: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
  rightholderId: PropTypes.string.isRequired,
  sponsorChatMessagePlace: PropTypes.string,
  sponsorVideoPlace: PropTypes.string.isRequired,
  fullscreenTarget: PropTypes.string.isRequired,
  onFinish: PropTypes.func.isRequired,
};
