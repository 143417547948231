import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import ArenaContents from "arena/components/ArenaContents";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import { useQuery } from "@apollo/client";
import { ContentPlaylistQuery } from "arena/queries";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";

const StyledCard = styled(Card)(() => ({}));

const StyledCardContent = styled(CardContent)(() => ({
  padding: 0,
  margin: 0,
}));

export default function ContentPlaylist({
  className,
  productId,
  contentId: selectedContentId,
  collectionId,
  type,
}) {
  const { data } = useQuery(ContentPlaylistQuery, {
    skip: !productId && !collectionId,
    variables: {
      productId: productId || selectedContentId,
      contentId: collectionId || selectedContentId,
      loadContent: !!collectionId,
      loadProduct: !!productId,
    },
  });

  const title = (data?.product || data?.content)?.title;
  const link = (data?.product || data?.content)?.siteUrl;
  const sort = data?.content?.collectionContentSort || "LATEST";

  return (
    <StyledCard className={className} square>
      <CardHeader
        title={title}
        titleTypographyProps={{ sx: { fontWeight: 800 } }}
        avatar={
          link && (
            <IconButton component={Link} to={link}>
              <ArrowBackIcon />
            </IconButton>
          )
        }
      />
      <StyledCardContent>
        <ArenaContents
          productId={productId}
          collectionId={collectionId}
          selectedContentId={selectedContentId}
          relatedContentId={collectionId}
          view="LIST"
          sort={sort}
          type={type}
          limit={6}
          loadMore
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          inView
        />
      </StyledCardContent>
    </StyledCard>
  );
}

ContentPlaylist.propTypes = {
  className: PropTypes.string,
  productId: PropTypes.string,
  collectionId: PropTypes.string,
  contentId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};
