import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogHeader from "core/components/DialogHeader";
import { PaymentQuery } from "arena/queries";
import { useQuery } from "@apollo/client";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { useTranslation } from "react-i18next";
import ErrorScreen from "core/components/ErrorScreen";
import ReactGA from "react-ga4";
import CircularProgress from "@mui/material/CircularProgress";
import RetargetPixels from "core/components/RetargetPixels";
import trackEvent from "arena/utils/trackEvent";
import { useArena } from "core/components/ArenaProvider";
import { useViewer } from "core/components/ViewerProvider";

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(1),
}));

const CANCELED = "CANCELED";
const PENDING = "PENDING";
const SUCCEEDED = "SUCCEEDED";

export default function Payment({ onClose, state }) {
  const { t } = useTranslation("arena");
  const arena = useArena();
  const viewer = useViewer();

  const { data, loading, error, refetch } = useQuery(PaymentQuery, {
    skip: !state.paymentId,
    fetchPolicy: "cache-and-network",
    variables: {
      id: state.paymentId,
    },
    onCompleted(data) {
      ReactGA.event({
        category: "Payment",
        action: `Payment ${data.payment.status}`,
      });

      if (data.payment.status === SUCCEEDED) {
        trackEvent("paymentComplete", { arena, payment: data.payment, viewer });
      }
    },
  });

  const payment = data?.payment;
  const status = payment?.status;

  useEffect(() => {
    if (status !== PENDING) return;
    if (!process.browser) return;

    const interval = setInterval(refetch, 5000); // Раз в 5 секунд

    return () => clearInterval(interval);
  }, [status]);

  if (payment?.status === SUCCEEDED) {
    return (
      <>
        <DialogHeader
          onClose={onClose}
          title={t("productDialog.payment.title")}
        />
        <StyledDialogContent>
          <CardHeader
            title={t("productPayment.succeeded.title")}
            subheader={t("productPayment.succeeded.subheader")}
            titleTypographyProps={{
              variant: "h6",
            }}
            subheaderTypographyProps={{
              variant: "body2",
            }}
          />
          <CardContent>
            <Button
              disableElevation
              fullWidth
              variant="contained"
              color="primary"
              // component={Link}
              // to={product.siteUrl}
              onClick={onClose}
            >
              {t("productPayment.succeeded.button")}
            </Button>
          </CardContent>
        </StyledDialogContent>
        <RetargetPixels eventType="paymentSucceeded" />
      </>
    );
  }

  if (payment?.status === CANCELED) {
    return (
      <>
        <DialogHeader
          onClose={onClose}
          title={t("productDialog.payment.title")}
        />

        <StyledDialogContent>
          <Card elevation={0}>
            <CardHeader
              title={t("productPayment.failed.title")}
              subheader={t("productPayment.failed.subheader")}
              titleTypographyProps={{
                variant: "h6",
              }}
              subheaderTypographyProps={{
                variant: "body2",
              }}
            />
            <CardContent>
              <Button
                disableElevation
                fullWidth
                variant="contained"
                color="primary"
                // component={Link}
                // to={product.siteUrl}
                onClick={onClose}
              >
                {t("productPayment.failed.button")}
              </Button>
            </CardContent>
          </Card>
        </StyledDialogContent>
        <RetargetPixels eventType="paymentCanceled" />
      </>
    );
  }

  if (payment?.status === PENDING) {
    return (
      <>
        <DialogHeader
          onClose={onClose}
          title={t("productDialog.payment.title")}
        />

        <StyledDialogContent>
          <Card elevation={0}>
            <CardHeader
              title={t("productPayment.pending.title")}
              subheader={t("productPayment.pending.subheader")}
              titleTypographyProps={{
                variant: "h6",
              }}
              subheaderTypographyProps={{
                variant: "body2",
              }}
            />
          </Card>
        </StyledDialogContent>
        <RetargetPixels eventType="paymentPending" />
      </>
    );
  }

  if (error) {
    return (
      <>
        <DialogHeader
          onClose={onClose}
          title={t("productDialog.payment.title")}
        />
        <StyledDialogContent>
          <ErrorScreen error={error} />
        </StyledDialogContent>
        <RetargetPixels eventType="paymentError" />
      </>
    );
  }

  if (loading) {
    return (
      <>
        <DialogHeader
          onClose={onClose}
          title={t("productDialog.payment.title")}
        />
        <StyledDialogContent>
          <div style={{ textAlign: "center", padding: 16 }}>
            <CircularProgress size={50} />
          </div>
        </StyledDialogContent>
      </>
    );
  }

  return (
    <>
      <DialogHeader
        onClose={onClose}
        title={t("productDialog.payment.title")}
      />
      <StyledDialogContent>
        <ErrorScreen title="Not Found" />
      </StyledDialogContent>
    </>
  );
}

Payment.propTypes = {
  content: PropTypes.object,
  state: PropTypes.object,
  onClose: PropTypes.func,
};
