import { useState } from "react";
import { useQuery, useSubscription } from "@apollo/client";
import { LiveEventsQuery } from "core/queries";
import { LiveEventCreatedSubscription } from "core/subscriptions";
import moment from "core/utils/moment";

export default function useLiveEvents({ contentId, onEvent, currentTime }) {
  const [called, setCalled] = useState(false);
  const [liveEvents, setLiveEvents] = useState([]);
  const [lastEventIndex, setLastEventIndex] = useState(-1);

  const variables = {
    contentId,
  };

  useQuery(LiveEventsQuery, {
    skip: !process.browser,
    fetchPolicy: "no-cache",
    variables,
    onCompleted: (data) => {
      if (data?.liveEvents?.length) {
        let index = -1;

        data.liveEvents.find((e, i) => {
          const createdAt = moment(e.createdAt);
          const isBefore = createdAt.isBefore(currentTime);

          if (isBefore) {
            index = i;
          } else {
            return true;
          }
        });

        setLastEventIndex(index);
        setLiveEvents(data.liveEvents);
      }

      setCalled(true);
    },
  });

  useSubscription(LiveEventCreatedSubscription, {
    variables,
    skip: !called,
    onData: ({ data }) => {
      setLiveEvents((liveEvents) =>
        liveEvents.concat(data.data.liveEventCreated)
      );
    },
  });

  return {
    update(currentTime) {
      if (liveEvents.length === 0) return;

      const nextIndex = lastEventIndex + 1;
      const nextEvent = liveEvents[nextIndex];

      if (nextEvent) {
        const createdAt = moment(nextEvent.createdAt);
        const isBefore = createdAt.isBefore(currentTime);

        if (isBefore) {
          setLastEventIndex(nextIndex);
          onEvent(nextEvent);
        }
      }
    },
  };
}
