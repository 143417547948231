import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Item from "./Item";

export default function PartnersGrid({ xs, sm, md, lg, xl, items }) {
  return (
    <Grid container spacing={3}>
      {items.map((item, index) => (
        <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} key={index}>
          <Item item={item} smallTitle={12 / md > 3} />
        </Grid>
      ))}
    </Grid>
  );
}

PartnersGrid.propTypes = {
  xs: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  sm: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  md: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  lg: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  xl: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      thumbnails: PropTypes.array,
      openInNewWindow: PropTypes.bool,
    })
  ),
};
