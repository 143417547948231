import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import CountryField from "arena/components/CountryField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Autocomplete from "@mui/material/Autocomplete";

const TEXT = "TEXT";
const NUMBER = "NUMBER";
const BOOLEAN = "BOOLEAN";
const DATE = "DATE";
const SELECT = "SELECT";
const COUNTRY = "COUNTRY";

export default function CustomField({
  type,
  label,
  required,
  value,
  options,
  onChange,
}) {
  switch (type) {
    case DATE:
      return (
        <DatePicker
          label={label}
          required={required}
          value={value || null}
          onChange={onChange}
          renderInput={(params) => <TextField {...params} fullWidth />}
        />
      );

    case SELECT:
      return (
        <Autocomplete
          options={options}
          fullWidth
          value={options?.find((o) => o.value === value)}
          renderInput={(params) => (
            <TextField {...params} label={label} required={required} />
          )}
          onChange={(event, newValue) => {
            onChange(newValue?.value);
          }}
        />
      );

    case COUNTRY:
      return (
        <CountryField
          onChange={onChange}
          value={value}
          label={label}
          required={required}
        />
      );

    case TEXT:
    case NUMBER:
      return (
        <TextField
          fullWidth
          label={label}
          type={type}
          value={value || ""}
          onChange={(event) => onChange(event.target.value)}
          required={required}
        />
      );
    case BOOLEAN:
      return (
        <FormControlLabel
          control={
            <Checkbox
              color="default"
              checked={value}
              onChange={() => onChange(!value)}
            />
          }
          label={
            <Typography
              display="inline"
              dangerouslySetInnerHTML={{
                __html: label,
              }}
            />
          }
        />
      );
    default:
      return null;
  }
}

CustomField.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};
