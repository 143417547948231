import { useState, useEffect } from "react";
import getClientIp from "core/utils/getClientIp";
import apm from "core/utils/apm";

export default function useClientIp({ url }) {
  const [clientIp, setClientIp] = useState();
  const waitIp = url && !clientIp;

  useEffect(() => {
    if (!url) {
      return;
    }

    getClientIp(url)
      .then(setClientIp)
      .catch((err) => {
        apm.captureError(err);

        console.error("get ip error", err);
      });
  }, [url]);

  return { waitIp, clientIp };
}
