import { useReducer } from "react";
import { useSearchParams } from "react-router-dom";

const initialState = ({ paymentId }) => {
  if (paymentId) {
    return {
      open: true,
      screen: "Payment",
      paymentId,
    };
  }

  return {
    open: false,
  };
};

/**
 * Редьюсер который предназначен для вычисления состояния диалога <ProductDialog />
 */
function reducer(state, action) {
  switch (action.type) {
    case "close_dialog":
      return {
        open: false,
      };

    case "choose_subscriptions":
      return {
        open: true,
        screen: "ChooseProduct",
        type: "SUBSCRIPTION",
      };

    case "choose_ppv":
      return {
        open: true,
        screen: "ChooseProduct",
        type: "PPV",
      };

    case "choose_donates":
      return {
        open: true,
        screen: "ChooseProduct",
        type: "DONATION",
      };

    case "product":
      return {
        open: true,
        screen: "Product",
        productId: action.productId,
      };

    // этот экран сейчас не используется
    case "choose_provider":
      return {
        open: true,
        screen: "ChooseProvider",
        providers: action.providers,
      };

    case "create_payment":
      return {
        open: true,
        screen: "CreatePayment",
        productType: action.productType,
        provider: action.provider,
        providers: action.providers,
        priceId: action.priceId, // PPV, Subscription
        amount: action.amount, // Donate
        recurrent: action.recurrent, // Donate
      };

    case "payment":
      return {
        open: true,
        screen: "Payment",
        paymentId: action.paymentId,
      };

    default:
      return state;
  }
}

export default function useProductDialog({ skipPaymentId } = {}) {
  const [searchParams] = useSearchParams();
  const paymentId = skipPaymentId ? null : searchParams.get("paymentId");

  const [state, dispatch] = useReducer(reducer, initialState({ paymentId }));

  return { state, dispatch };
}
