import React from "react";

export default function QiwiIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 29.574 13.434"
      {...props}
    >
      <g fill="none">
        <path
          fill="#fff"
          d="M14.493 7.598h.46v1.03h.332l.596-1.03h.502l-.715 1.209v.008l.758 1.32h-.52l-.613-1.107h-.332v1.106h-.46V7.598h-.008zm2.963.672c.553 0 1.004.4 1.004.954 0 .562-.443.953-.996.953-.553 0-.996-.391-.996-.953-.009-.562.434-.954.988-.954zm1.361.043h.435v1.43h.57v-1.43h.434v1.43h.57v-1.43h.435v1.821h-2.427V8.313zm3.73-.043c.518 0 .808.383.808.86a1.27 1.27 0 01-.017.179h-1.252c.026.315.273.485.545.485a.889.889 0 00.52-.196l.178.324a1.169 1.169 0 01-.732.264c-.604 0-.979-.435-.979-.954 0-.57.383-.962.928-.962zm1.02 1.481c.222-.034.384-.22.384-.868v-.57h1.328v1.821h-.452V8.68h-.442v.204c0 .791-.213 1.251-.818 1.268v-.4zm3.006-1.48c.519 0 .808.382.808.86a1.27 1.27 0 01-.017.178h-1.251c.025.315.272.485.545.485a.889.889 0 00.519-.196l.179.324a1.169 1.169 0 01-.732.264c-.605 0-.98-.435-.98-.954 0-.57.384-.962.929-.962zm1.183.042h.451v.698h.221l.443-.698h.502l-.579.868v.009l.647.944h-.527l-.477-.74h-.238v.74h-.452V8.313h.009zm-10.3 1.473c.298 0 .544-.23.544-.57a.55.55 0 00-.545-.571c-.297 0-.536.23-.536.57 0 .34.238.57.537.57zm5.44-.792c0-.238-.163-.383-.367-.383-.23 0-.4.153-.443.383zm4.026 0c0-.238-.162-.383-.366-.383-.23 0-.4.153-.443.383z"
        />
        <path
          fill="#ff8c00"
          d="M20.852 2.073v4.299a.116.116 0 01-.119.119h-.877a.116.116 0 01-.119-.119V2.073a.116.116 0 01.12-.119h.876a.116.116 0 01.12.119zm6.844-.119c.034 0 .077.017.12.051.025.025.025.068.017.102l-1.448 4.308a.121.121 0 01-.11.076h-.783c-.052 0-.094-.025-.111-.076l-.843-2.512-.843 2.511a.121.121 0 01-.11.077H22.8c-.05 0-.093-.025-.11-.077l-1.448-4.307c-.008-.034-.008-.068.017-.102.018-.034.06-.051.094-.051h.979c.051 0 .094.034.11.085l.775 2.545.852-2.545a.121.121 0 01.11-.077h.69c.05 0 .093.026.11.077l.852 2.545.775-2.545a.117.117 0 01.11-.085h.98zm1.481 0c.06 0 .111.051.12.119v4.299a.116.116 0 01-.12.119h-.876a.116.116 0 01-.12-.119V2.073a.116.116 0 01.12-.119zm-9.95 4.384c.05.06.008.145-.069.145h-1.073a.137.137 0 01-.11-.052l-.179-.22a2.4 2.4 0 01-1.285.382 2.376 2.376 0 010-4.75 2.376 2.376 0 012 3.66l.715.835zm-8.965 2.086c.222.085.307.417.324.561.034.264-.043.366-.128.366s-.204-.102-.332-.306c-.128-.204-.179-.434-.11-.553.042-.077.136-.111.246-.068zm-1.294.962c.137 0 .29.06.426.178.264.222.34.477.204.664a.448.448 0 01-.349.153.607.607 0 01-.4-.144c-.238-.205-.306-.545-.153-.732a.33.33 0 01.272-.12zm-2.979 2.46a5.856 5.856 0 115.857-5.857 5.893 5.893 0 01-.826 3.005c-.017.025-.06.017-.068-.017-.204-1.439-1.081-2.23-2.358-2.469-.11-.017-.128-.085.017-.102.391-.034.945-.026 1.234.025a3.877 3.877 0 00-3.848-4.325A3.877 3.877 0 002.124 5.98a3.877 3.877 0 003.874 3.874h.178A5.225 5.225 0 016.1 8.807c.01-.239.06-.273.162-.086.537.928 1.303 1.763 2.801 2.095 1.226.272 2.452.587 3.771 2.264.12.145-.06.298-.196.179-1.345-1.192-2.57-1.584-3.686-1.584-1.251.009-2.102.17-2.962.17zm11.109-6.461l-.468-.57c-.051-.06-.009-.154.068-.154h.987c.051-.136.077-.29.077-.443 0-.706-.528-1.32-1.251-1.32-.724 0-1.252.614-1.252 1.32 0 .707.528 1.311 1.252 1.311.204.009.408-.05.587-.144z"
        />
      </g>
    </svg>
  );
}
