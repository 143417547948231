export function createAdVerification() {
  return {
    resource: null,
    vendor: null,
    browserOptional: false,
    apiFramework: null,
    type: null,
    parameters: null,
    trackingEvents: {},
  };
}
