export function getBannerSrcSet(banner) {
  const formatToWidth = {
    DEFAULT: "600w",
    MEDIUM: "960w",
    HIGH: "1280w",
    MAXRES: "1920w",
  };

  return banner
    .map((t) => {
      const set = [];

      if (t.webpUrl) {
        set.push(`${t.webpUrl} ${formatToWidth[t.format]}`);
      }

      set.push(`${t.url} ${formatToWidth[t.format]}`);

      return set;
    })
    .flat()
    .join(" ,");
}

export function getThumbnailSrcSet(thumbnails) {
  const formatToWidth = {
    DEFAULT: "600w",
    MEDIUM: "960w",
    HIGH: "1280w",
    MAXRES: "1920w",
  };

  return thumbnails
    .map((t) => `${t.url} ${formatToWidth[t.format]}`)
    .join(" ,");
}

export function getAvatarSrcSet(thumbnails) {
  if (!thumbnails) {
    return null;
  }

  const formatToWidth = {
    DEFAULT: "30w",
    MEDIUM: "60w",
    HIGH: "120w",
    MAXRES: "240w",
  };

  return thumbnails
    .map((t) => {
      const set = [];

      if (t.webpUrl) {
        set.push(`${t.webpUrl} ${formatToWidth[t.format]}`);
      }

      set.push(`${t.url} ${formatToWidth[t.format]}`);

      return set;
    })
    .flat()
    .join(" ,");
}

export function getLogoSrcSet(thumbnails) {
  const formatToWidth = {
    DEFAULT: "30w",
    MEDIUM: "60w",
    HIGH: "120w",
    MAXRES: "240w",
  };

  return thumbnails
    .map((t) => {
      const set = [];

      if (t.webpUrl) {
        set.push(`${t.webpUrl} ${formatToWidth[t.format]}`);
      }

      set.push(`${t.url} ${formatToWidth[t.format]}`);

      return set;
    })
    .flat()
    .join(" ,");
}
