import React from "react";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import DialogContent from "@mui/material/DialogContent";
import { withSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { DeletePaymentMethodMutation } from "arena/mutations";
import { PaymentMethodsQuery } from "arena/queries";
import SettingsHeader from "arena/components/SettingsHeader";
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorScreen from "core/components/ErrorScreen";
import PaymentIcon from "@mui/icons-material/Payment";
import Tooltip from "@mui/material/Tooltip";

function SettingsPayment({ enqueueSnackbar, tabs, onClose }) {
  const { t } = useTranslation("arena");

  const onError = (error) => {
    enqueueSnackbar(error.message, {
      variant: "error",
    });
  };

  const [deletePaymentMethod] = useMutation(DeletePaymentMethodMutation, {
    refetchQueries: ["PaymentMethods"],
    onError,
  });

  const { loading, data } = useQuery(PaymentMethodsQuery, {
    fetchPolicy: "cache-and-network",
    onError,
  });

  const onClickDelete = (event, id) => {
    event.preventDefault();
    deletePaymentMethod({
      variables: { id },
    });
  };

  const paymentMethods = data?.paymentMethods?.nodes || [];

  return (
    <>
      <SettingsHeader
        onClose={onClose}
        title={t("settingsPayment.title")}
        tabs={tabs}
      />

      {paymentMethods.length === 0 && (
        <DialogContent style={{ minHeight: 317 + 64, display: "flex" }}>
          <ErrorScreen
            icon={PaymentIcon}
            title={t("settingsPayment.empty.title")}
            text={t("settingsPayment.empty.text")}
          />
        </DialogContent>
      )}

      {paymentMethods.length > 0 && (
        <List style={{ minHeight: 317 + 64 }}>
          {paymentMethods.map((paymentMethod, index) => {
            return (
              <ListItem
                key={paymentMethod.id}
                divider={index + 1 < paymentMethods.length}
              >
                <ListItemAvatar>
                  <Avatar>
                    <PaymentIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={paymentMethod.title} />
                <ListItemSecondaryAction>
                  <Tooltip
                    title={
                      !paymentMethod.canDelete
                        ? t("settingsPayment.deleteUnavailable", {
                            date: paymentMethod.deleteBlockUntil,
                          })
                        : ""
                    }
                  >
                    <div>
                      <IconButton
                        edge="end"
                        onClick={(event) =>
                          onClickDelete(event, paymentMethod.id)
                        }
                        disabled={loading || !paymentMethod.canDelete}
                        size="large"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      )}
    </>
  );
}

SettingsPayment.propTypes = {
  onClose: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  tabs: PropTypes.node,
};

export default withSnackbar(SettingsPayment);
