import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";

const StyledImage = styled("img")({
  display: "block",
  maxWidth: "100%",
});

export default function Image({ imageSrc, imageSrcSet }) {
  return <StyledImage src={imageSrc} srcSet={imageSrcSet} />;
}

Image.propTypes = {
  imageSrc: PropTypes.string,
  imageSrcSet: PropTypes.string,
};
