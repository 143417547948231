import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { durationFormat } from "core/utils/string";
import { parseFps, computeSpriteIndex } from "core/utils/previews";

const CSS_TIME_BOX_HEIGHT = 32;
const CSS_TIME_BOX_MARGIN = 5;
const CSS_POPUP_MARGIN = -5;
const CSS_POPUP_MIN_WIDTH = 80;
const CSS_BORDER_WIDTH = 4;

const PREFIX = "SeekBarPopup";

const classes = {
  container: `${PREFIX}-container`,
  previewRoot: `${PREFIX}-previewRoot`,
  preview: `${PREFIX}-preview`,
  previewHQ: `${PREFIX}-previewHQ`,
  time: `${PREFIX}-time`,
};

const Root = styled("div")({
  position: "relative",
  background: "rgba(31, 31, 31, 0.85)",
  borderRadius: 4,
  overflow: "hidden",

  [`& .${classes.container}`]: {
    position: "absolute",
    width: "100%",
    height: "100%",
    padding: CSS_BORDER_WIDTH,
  },

  [`& .${classes.previewRoot}`]: {
    position: "relative",
    width: "100%",
    marginBottom: `${CSS_TIME_BOX_MARGIN}px`,
  },

  [`& .${classes.preview}`]: {
    position: "absolute",
    zIndex: "1",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
  },

  [`&.${classes.previewHQ}`]: {
    position: "absolute",
    zIndex: "2",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
  },

  [`& .${classes.time}`]: {
    width: "100%",
    height: `${CSS_TIME_BOX_HEIGHT}px`,
    lineHeight: `${CSS_TIME_BOX_HEIGHT}px`,
    textAlign: "center",
    color: "#fff",
  },
});

function formatBackgroundProp(previewSpriteIndex, videoPreview, scale = 1) {
  const url = videoPreview.files[previewSpriteIndex.file];
  const y = -Math.round(videoPreview.height * previewSpriteIndex.pic * scale);

  return `no-repeat 0 ${y}px / cover url(${url})`;
}

export default function SeekBarPopup({
  duration,
  position,
  reverse,
  videoPreviews,
  disableSeekBarPopup,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (isMobile || disableSeekBarPopup) return null;

  const secs = duration * position;
  const secsReverse = duration * (1 - position);

  if (videoPreviews && videoPreviews.low) {
    const previewSpriteIndexLow = computeSpriteIndex(
      secs,
      parseFps(videoPreviews.low.fps),
      videoPreviews.low.spriteSize
    );

    const rootWidth =
      Math.max(videoPreviews.low.width, CSS_POPUP_MIN_WIDTH) +
      CSS_BORDER_WIDTH * 2;
    const rootHeight =
      videoPreviews.low.height +
      CSS_TIME_BOX_HEIGHT +
      CSS_TIME_BOX_MARGIN +
      CSS_BORDER_WIDTH * 2;

    const rootStyle = {
      width: `${rootWidth}px`,
      height: `${rootHeight}px`,
      left: `-${Math.round(rootWidth / 2)}px`,
      top: `-${rootHeight}px`,
    };

    const previewRootStyle = {
      height: `${videoPreviews.low.height}px`,
    };

    const previewStyle = {
      background: formatBackgroundProp(
        previewSpriteIndexLow,
        videoPreviews.low
      ),
    };

    let previewHQ = null;

    if (videoPreviews.high) {
      const previewSpriteIndexHigh = computeSpriteIndex(
        secs,
        parseFps(videoPreviews.high.fps),
        videoPreviews.high.spriteSize
      );

      const previewHQStyle = {
        background: formatBackgroundProp(
          previewSpriteIndexHigh,
          videoPreviews.high,
          videoPreviews.low.height / videoPreviews.high.height
        ),
      };

      previewHQ = <div className={classes.previewHQ} style={previewHQStyle} />;
    }

    const time = reverse
      ? "-" + durationFormat(secsReverse)
      : durationFormat(secs);

    return (
      <Root style={rootStyle}>
        <div className={classes.container}>
          <div className={classes.previewRoot} style={previewRootStyle}>
            <div className={classes.preview} style={previewStyle}></div>
            {previewHQ}
          </div>

          <div className={classes.time}>{time}</div>
        </div>
      </Root>
    );
  } else {
    const rootStyle = {
      width: `${CSS_POPUP_MIN_WIDTH}px`,
      height: `${CSS_TIME_BOX_HEIGHT}px`,
      left: `-${Math.round(CSS_POPUP_MIN_WIDTH / 2)}px`,
      top: `-${CSS_TIME_BOX_HEIGHT + CSS_TIME_BOX_MARGIN + CSS_POPUP_MARGIN}px`,
    };

    return (
      <Root style={rootStyle}>
        <div className={classes.time}>
          {reverse ? `-${durationFormat(secsReverse)}` : durationFormat(secs)}
        </div>
      </Root>
    );
  }
}

const VideoPreviewShape = PropTypes.shape({
  width: PropTypes.number,
  height: PropTypes.number,
  spriteSize: PropTypes.number,
  fps: PropTypes.string,
  files: PropTypes.arrayOf(PropTypes.string),
});

SeekBarPopup.propTypes = {
  position: PropTypes.number,
  duration: PropTypes.number,
  reverse: PropTypes.bool,
  videoPreviews: PropTypes.shape({
    low: VideoPreviewShape,
    high: VideoPreviewShape,
  }),
  disableSeekBarPopup: PropTypes.bool,
};

SeekBarPopup.MIN_WIDTH = CSS_POPUP_MIN_WIDTH;
