import React from "react";
import { useQuery } from "@apollo/client";
import { LanguageOptionsQuery } from "arena/queries";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

export default React.forwardRef(function LanguageField(props, ref) {
  const { loading, data } = useQuery(LanguageOptionsQuery, {
    variables: {
      translation: true,
    },
  });

  if (loading) return null;

  return (
    <TextField {...props} ref={ref} select fullWidth disabled={loading}>
      {data?.languageOptions.map((language) => (
        <MenuItem key={language.value} value={language.value}>
          {language.label}
        </MenuItem>
      ))}
    </TextField>
  );
});
