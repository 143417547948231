import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

export default function useAutoPlay(onAutoPlay, autoPlay) {
  if (process.env.BUILD_TARGET === "server") return null;

  const [ref, inView] = useInView({
    threshold: 0.4,
    triggerOnce: true,
  });

  const play = inView && autoPlay;

  useEffect(() => {
    if (play) {
      onAutoPlay();
    }
  }, [play]);

  return ref;
}
