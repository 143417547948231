import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import {
  createSponsorImpression,
  createSponsorClick,
  trackAdPixel,
} from "core/utils/tracker";
import { getThumbnailSrcSet } from "core/utils/getSrcSet";

const PREFIX = "ChatMessageListItemAdv";

const classes = {
  img: `${PREFIX}-img`,
  text: `${PREFIX}-text`,
};

const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: 0,
  display: "block",

  [`& .${classes.img}`]: {
    display: "block",
    border: 0,
    margin: theme.spacing(2, 0),
    width: "100%",
  },

  [`& .${classes.text}`]: {
    margin: theme.spacing(2, 1.5),
    width: "100%",
  },
}));

const StyledDivider = styled(Divider)({
  borderColor: "#101010",
});

const DEFAULT = "DEFAULT";
const MEDIUM = "MEDIUM";
const HIGH = "HIGH";
const MAXRES = "MAXRES";

const IMPRESSION = "IMPRESSION";

export default function ChatMessageListItemAdv({ sponsorChatMessage }) {
  const {
    link,
    text,
    token,
    thumbnails,
    isClickable,
    impressionPixel,
    clickPixel,
  } = sponsorChatMessage;

  const onClick = () => {
    if (isClickable) {
      if (clickPixel) {
        trackAdPixel(clickPixel);
      }

      createSponsorClick(token);
    }
  };

  const imageSrcSet = getThumbnailSrcSet(thumbnails);

  const imageSrc = thumbnails?.find((t) => t.format === MAXRES)?.url;

  useEffect(() => {
    createSponsorImpression(token, "ChatMessage", IMPRESSION);

    if (impressionPixel) {
      trackAdPixel(impressionPixel);
    }
  }, [token]);

  return (
    <>
      <StyledDivider />
      <StyledListItem
        button={isClickable}
        href={isClickable ? link : null}
        onClick={onClick}
        component={isClickable ? "a" : "div"}
        target="_blank"
      >
        {imageSrc && (
          <img
            src={imageSrc}
            srcSet={imageSrcSet}
            className={classes.img}
            alt={text}
          />
        )}

        {text && (
          <Typography variant="body1" className={classes.text}>
            {text}
          </Typography>
        )}
      </StyledListItem>
      <StyledDivider />
    </>
  );
}

ChatMessageListItemAdv.propTypes = {
  sponsorChatMessage: PropTypes.shape({
    id: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    isClickable: PropTypes.bool,
    impressionPixel: PropTypes.string,
    clickPixel: PropTypes.string,
    thumbnails: PropTypes.arrayOf(
      PropTypes.shape({
        format: PropTypes.oneOf([DEFAULT, MEDIUM, HIGH, MAXRES]).isRequired,
        url: PropTypes.string.isRequired,
      })
    ),
  }),
};
