import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import HeaderMenuItem from "./HeaderMenuItem";

const Root = styled("div")(({ theme }) => ({
  marginLeft: theme.spacing(4),
  minHeight: 64,
  flex: 1,
  display: "flex",
  flexWrap: "nowrap",
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

export default function HeaderMenu({ arena, onClickMenuItem }) {
  const { pathname } = useLocation();

  return (
    <Root>
      {arena.menu.map((menuItem, index) => {
        const selected = pathname === menuItem.path;

        return (
          <HeaderMenuItem
            key={index}
            item={menuItem}
            selected={selected}
            onClick={onClickMenuItem}
          />
        );
      })}
    </Root>
  );
}

HeaderMenu.propTypes = {
  onClickMenuItem: PropTypes.func.isRequired,
  arena: PropTypes.shape({
    menu: PropTypes.arrayOf(
      PropTypes.shape({
        path: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      })
    ).isRequired,
  }),
};
