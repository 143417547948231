import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { UnsubscribeMutation } from "arena/mutations";
import ErrorScreen from "core/components/ErrorScreen";
import { useParams } from "react-router-dom";

const Root = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
});

export default function Unsubscribe() {
  const { token } = useParams();

  const { t } = useTranslation("arena");

  const [unsubscribe, { error, data }] = useMutation(UnsubscribeMutation, {
    variables: { token },
  });

  useEffect(unsubscribe, []);

  if (error) {
    return (
      <Root>
        <ErrorScreen error={error} />
      </Root>
    );
  }

  if (data) {
    return (
      <Root>
        <Typography variant="h6">{t("unsubscribeEmail.success")}</Typography>
      </Root>
    );
  }

  return (
    <Root>
      <CircularProgress />
    </Root>
  );
}
