import React, { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import LoadMore from "core/components/LoadMore";
import ErrorScreen from "core/components/ErrorScreen";
import { ProductsQuery } from "arena/queries";
import { useQuery } from "@apollo/client";
import ReactGA from "react-ga4";
import ProductCarouselItem from "arena/components/ProductCarouselItem";
import ProductPromoVideoDialog from "arena/components/ProductPromoVideoDialog";

export default function ProductList({
  arenaId,
  type,
  rowsPerPage,
  xs,
  sm,
  md,
  lg,
  xl,
  empty,
  fetchPolicy,
  title,
  rightholderIds,
  loadMore,
}) {
  const [product, setProduct] = useState();

  const variables = {
    arenaId,
    rowsPerPage,
    type,
  };

  if (rightholderIds?.length) {
    variables.rightholderIds = rightholderIds;
  }

  const { data, loading, error, fetchMore } = useQuery(ProductsQuery, {
    fetchPolicy,
    variables,
  });

  if (error) return <ErrorScreen error={error} />;

  const nodes = data?.products?.nodes || [];
  const count = data?.products?.count || 0;
  const hasMore = nodes.length < count;
  const showLoadMore = loadMore && hasMore && !loading;
  const nextPage = Math.ceil(nodes.length / rowsPerPage);

  if (loading && !data) {
    return null;
  }

  if (!nodes || nodes.length === 0) {
    return empty;
  }

  const onClosePromoVideoDialog = () => setProduct();
  const onClickPromoVideoButton = (product) => setProduct(product);

  const onLoadMore = () => {
    ReactGA.event({
      category: "User",
      action: "Load more products",
      label: nextPage + " page",
    });

    fetchMore({
      variables: {
        page: nextPage,
      },
    });
  };

  return (
    <>
      {title}

      {product && (
        <ProductPromoVideoDialog
          onClose={onClosePromoVideoDialog}
          open={!!product}
          product={product}
        />
      )}

      <Grid
        container
        spacing={3}
        alignItems="stretch"
        data-testid="ProductList"
      >
        {nodes.map((product) => (
          <Grid
            item
            xs={xs}
            sm={sm}
            md={md}
            lg={lg}
            xl={xl}
            key={product.id}
            style={{ display: "flex" }}
            data-testid="ProductListItem"
          >
            <ProductCarouselItem
              product={product}
              onClickPromoVideoButton={onClickPromoVideoButton}
            />
          </Grid>
        ))}
      </Grid>

      {showLoadMore && <LoadMore key={nextPage} onLoadMore={onLoadMore} />}
    </>
  );
}

ProductList.propTypes = {
  title: PropTypes.node,
  xs: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  sm: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  md: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  lg: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  xl: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  rowsPerPage: PropTypes.number,
  fetchPolicy: PropTypes.string,
  empty: PropTypes.node,
  type: PropTypes.string,
  arenaId: PropTypes.string,
  rightholderIds: PropTypes.arrayOf(PropTypes.string),
  loadMore: PropTypes.bool,
};

ProductList.defaultProps = {
  empty: null,
  fetchPolicy: "cache-and-network",
  rowsPerPage: 10,
  xs: 12,
  sm: 6,
  md: 4,
  // lg: 3,
  // xl: 2,
};
