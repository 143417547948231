import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import ErrorIcon from "@mui/icons-material/Error";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Status from "core/components/Status";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import Stack from "@mui/material/Stack";

const GRAPHQL_VALIDATION_FAILED = "GRAPHQL_VALIDATION_FAILED";
const INVALID_ID_ENCRYPTED_MESSAGE = "Invalid ID (encrypted)";

const Root = styled("div")(() => ({
  zIndex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  flexGrow: 1,
  height: "inherit",
}));

const Title = styled(Typography)(({ theme }) => ({
  lineHeight: "1.3",
  fontSize: "1rem",
  fontWeight: 600,
  ["@media (min-width: 425px)"]: {
    fontSize: "1.4rem",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.6rem",
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  fontSize: "0.775rem",
  ["@media (min-width: 425px)"]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.1rem",
  },
}));

const Icon = styled(ErrorIcon)(({ theme }) => ({
  display: "none",

  ["@media (min-height: 300px)"]: {
    display: "block",
    fontSize: "3rem",
  },

  [theme.breakpoints.up("sm")]: {
    fontSize: "4rem",
  },
}));

export default function ErrorScreen(props) {
  const { t } = useTranslation("core");

  let { error, code, button, icon, title, text } = props;

  if (error) {
    if (error?.networkError?.result?.errors?.length) {
      if (
        error.networkError.result.errors.some((error) =>
          error.message.includes(INVALID_ID_ENCRYPTED_MESSAGE)
        )
      ) {
        title = t("noMatchScreen.title");
        text = t("noMatchScreen.text");
        code = 404;
        icon = SentimentVeryDissatisfiedIcon;
      } else if (
        error.networkError.result.errors.some(
          (error) => error.extensions.code === GRAPHQL_VALIDATION_FAILED
        )
      ) {
        title = "Validation Error";
        text = error.networkError.result.errors[0].message;
        code = 400;
        icon = SentimentVeryDissatisfiedIcon;
      } else {
        title = error.networkError.name;
        text = error.networkError.message;
        code = error.networkError.statusCode;
      }
    } else if (error.graphQLErrors.length) {
      title = "Internal Server Error";
      text = error.graphQLErrors[0].message;
      icon = SentimentVeryDissatisfiedIcon;
      code = 500;
    } else {
      title = "Internal Server Error";
      text = error.message;
      icon = SentimentVeryDissatisfiedIcon;
      code = 500;
    }
  }

  return (
    <Status code={code}>
      <Root>
        <Stack spacing={1} alignItems="center">
          <Icon component={icon} />

          {title && (
            <Title variant="h6" color="textPrimary" align="center">
              {title}
            </Title>
          )}

          {text && (
            <Text variant="body1" color="textSecondary" align="center">
              {text}
            </Text>
          )}

          {button}
        </Stack>
      </Root>
    </Status>
  );
}

ErrorScreen.propTypes = {
  button: PropTypes.node,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  title: PropTypes.string,
  text: PropTypes.string,
  code: PropTypes.number,
  error: PropTypes.object,
};
