export function createMezzanine() {
  return {
    id: null,
    fileURL: null,
    delivery: null,
    codec: null,
    type: null,
    width: 0,
    height: 0,
    fileSize: 0,
    mediaType: '2D',
  };
}
