import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import DialogContent from "@mui/material/DialogContent";
import DialogHeader from "core/components/DialogHeader";
import SignInForm from "core/components/SignInForm";
import VerifyEmailForm from "core/components/VerifyEmailForm";
import { withSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useApolloClient } from "@apollo/client";

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(3),
}));

function SignIn({ onClose, arena, enqueueSnackbar }) {
  const { t } = useTranslation("core");

  const [token, setToken] = useState();
  const [email, setEmail] = useState();
  const client = useApolloClient();

  const onError = (error) => {
    if (error.networkError) {
      if (error.networkError.result) {
        if (error.networkError.result.errors) {
          if (error.networkError.result.errors[0]) {
            if (
              error.networkError.result.errors[0].message.startsWith(
                'Variable "$email" got invalid value'
              )
            ) {
              return enqueueSnackbar(t("signIn.invalidEmail"), {
                variant: "error",
              });
            }
          }
        }
      }
    }

    enqueueSnackbar(error.message, {
      variant: "error",
    });
  };

  const onSignInSuccess = (data) => {
    if (data.signIn.authToken) {
      client.resetStore();
    } else {
      setToken(data.signIn.token);
      enqueueSnackbar(t("signIn.codeSent"), {
        variant: "success",
      });
    }
  };

  const onVerifyEmailSuccess = () => {
    client.resetStore();
  };

  const handleClose = () => {
    setToken(null);
    onClose();
  };

  return (
    <>
      <DialogHeader onClose={handleClose} title={t("signIn.title")} />
      <StyledDialogContent>
        {token ? (
          <VerifyEmailForm
            onCompleted={onVerifyEmailSuccess}
            onError={onError}
            token={token}
            email={email}
          />
        ) : (
          <SignInForm
            onCompleted={onSignInSuccess}
            onError={onError}
            email={email}
            setEmail={setEmail}
            arena={arena}
          />
        )}
      </StyledDialogContent>
    </>
  );
}

SignIn.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  arena: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withSnackbar(SignIn);
