/**
 * Функция для разбивки массива на чанки по size штук
 *
 * @param {Array} array
 * @param {Number} size
 *
 * @example
 *
 * chunk([1,2,3,4], 2)
 *
 * [
 *   [1, 2],
 *   [3, 4]
 * ]
 *
 */
export default function chunk(array, size) {
  const result = [];
  const arr = [...array];

  while (arr.length) {
    result.push(arr.splice(0, size));
  }

  return result;
}
