import React from "react";
import PropTypes from "prop-types";
import LoadingFullScreen from "core/components/LoadingScreen";

export default function VideoLoading({ videoState }) {
  if (!videoState) return null;
  if (!videoState.isLoading) return null;

  return <LoadingFullScreen />;
}

VideoLoading.propTypes = {
  videoState: PropTypes.shape({
    isLoading: PropTypes.bool,
  }),
};
