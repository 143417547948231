import React, { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Slide from "@mui/material/Slide";
import DialogHeader from "core/components/DialogHeader";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ConnectDeviceDialog({ open, code, onClose, onConnectDevice }) {
  const { t } = useTranslation("arena");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [values, setValues] = useState({ code });

  const onSubmit = (event) => {
    event.preventDefault();

    onConnectDevice(values);
  };

  return (
    <Dialog
      fullWidth
      disableScrollLock
      fullScreen={fullScreen}
      TransitionComponent={Transition}
      maxWidth="sm"
      open={open}
      onClose={onClose}
      scroll="body"
    >
      <form onSubmit={onSubmit}>
        <DialogHeader
          onClose={onClose}
          title={t("connectDeviceDialog.title")}
          action={
            <Button
              disableElevation
              variant="contained"
              color="primary"
              type="submit"
              // disabled={loading}
            >
              {t("connectDeviceDialog.connect")}
            </Button>
          }
          position="static"
        />

        <DialogContent>
          <Grid container spacing={3} alignItems="flex-start">
            <Grid item xs={4}>
              <TextField
                fullWidth
                label={t("connectDeviceDialog.form.code")}
                required
                value={values.code || ""}
                onChange={(event) =>
                  setValues({ ...values, code: event.target.value })
                }
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                label={t("connectDeviceDialog.form.title")}
                required
                value={values.title || ""}
                onChange={(event) =>
                  setValues({ ...values, title: event.target.value })
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
      </form>
    </Dialog>
  );
}

ConnectDeviceDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConnectDevice: PropTypes.func.isRequired,
  code: PropTypes.string,
};

export default ConnectDeviceDialog;
