import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import DialogContent from "@mui/material/DialogContent";
import { withSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useMutation, useApolloClient } from "@apollo/client";
import { UpdateSettingsMutation } from "arena/mutations";
import GoogleIcon from "core/components/GoogleIcon";
import SettingsHeader from "arena/components/SettingsHeader";
import { useViewer } from "core/components/ViewerProvider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import AppleIcon from "@mui/icons-material/Apple";
import Avatar from "@mui/material/Avatar";
import Alert from "@mui/material/Alert";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  overflow: "hidden",
  padding: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(3),
  },
}));

function SettingsAuth({
  tabs,
  onClose,
  arenaAuth,
  oauthConnect,
  enqueueSnackbar,
}) {
  const { t } = useTranslation("arena");

  const viewer = useViewer();
  const client = useApolloClient();

  const oauthProviders = [];

  if (arenaAuth?.google?.enabled) {
    oauthProviders.push({
      Icon: <GoogleIcon />,
      title: "Google",
      url: "/oauth/login/google",
      connected: viewer.oauth.google,
      provider: "google",
    });
  }

  if (arenaAuth?.apple?.enabled) {
    oauthProviders.push({
      Icon: <AppleIcon />,
      url: "/oauth/login/apple",
      title: "Apple ID",
      connected: viewer.oauth.apple,
      provider: "apple",
    });
  }

  let notify = null;
  const notifyText = {
    google: t("oauthSettings.googleConnected"),
    apple: t("oauthSettings.appleConnected"),
  };

  if (oauthConnect && notifyText[oauthConnect]) {
    notify = (
      <Alert icon={false} severity="success">
        {notifyText[oauthConnect]}
      </Alert>
    );
  }

  const onError = (error) => {
    enqueueSnackbar(error.message, {
      variant: "error",
    });
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.data?.oauthLogin || event.data?.oauthConnect) {
        client.resetStore();
      }
    };

    window.addEventListener("message", listener, false);

    return () => {
      window.removeEventListener("message", listener);
    };
  }, []);

  const openAuthWindow = (event, url) => {
    event.preventDefault();
    event.stopPropagation();
    window.open(url, "Auth", "width=800, height=600");
  };

  const [updateSettings, { loading }] = useMutation(UpdateSettingsMutation, {
    onError,
    refetchQueries: ["Viewer"],
  });

  const onDisconnect = (provider) => {
    updateSettings({
      variables: {
        input: {
          oauth: {
            [provider]: false,
          },
        },
      },
    });
  };

  return (
    <>
      <SettingsHeader
        onClose={onClose}
        title={t("oauthSettings.title")}
        tabs={tabs}
      />

      <StyledDialogContent>
        <Grid container spacing={3} alignItems="flex-start">
          {notify && (
            <Grid item xs={12}>
              {notify}
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography>{t("oauthSettings.oauthDesc")}</Typography>
          </Grid>

          <Grid item xs={12}>
            {oauthProviders.length > 0 && (
              <List>
                {oauthProviders.map(
                  ({ Icon, url, title, connected, provider }, key) => {
                    return (
                      <ListItem disableGutters key={key}>
                        <ListItemAvatar>
                          <Avatar>{Icon}</Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={title} />
                        <ListItemSecondaryAction>
                          {connected ? (
                            <Button
                              disabled={loading}
                              onClick={() => onDisconnect(provider)}
                            >
                              {t("oauthSettings.disconnect")}
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={(event) => openAuthWindow(event, url)}
                              href={url}
                              disabled={loading}
                            >
                              {t("oauthSettings.connect")}
                            </Button>
                          )}
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  }
                )}
              </List>
            )}
          </Grid>
        </Grid>
      </StyledDialogContent>
    </>
  );
}

SettingsAuth.propTypes = {
  oauthConnect: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  tabs: PropTypes.node,
  arenaAuth: PropTypes.shape({
    google: PropTypes.shape({
      enabled: PropTypes.bool,
    }),
    apple: PropTypes.shape({
      enabled: PropTypes.bool,
    }),
  }),
};

export default withSnackbar(SettingsAuth);
