import React from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import ErrorScreen from "core/components/ErrorScreen";
import DoneIcon from "@mui/icons-material/Done";

const Root = styled("div")({
  padding: 0,
  minHeight: "100vh",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export default function SuccessAuth() {
  const { t } = useTranslation("arena");

  return (
    <Root data-testid="SuccessAuth">
      <ErrorScreen
        icon={DoneIcon}
        title={t("successAuth.title")}
        text={t("successAuth.text")}
      />
    </Root>
  );
}
