import React, { useState } from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import { withSnackbar } from "notistack";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Slide from "@mui/material/Slide";
import { useMutation, useQuery } from "@apollo/client";
import { UpdateSettingsMutation } from "arena/mutations";
import { ArenaOnboardingQuery } from "arena/queries";
import CreateProfile from "./CreateProfile";
import OnboardingSteps from "./OnboardingSteps";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CREATE_PROFILE = "CREATE_PROFILE";
const ONBOARDING_STEPS = "ONBOARDING_STEPS";

function OnboardingDialog({ viewer, enqueueSnackbar }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [step, setStep] = useState(CREATE_PROFILE);

  const open = !viewer.isOnboarded;

  const onError = (error) => {
    enqueueSnackbar(error.message, {
      variant: "error",
    });
  };

  const { data } = useQuery(ArenaOnboardingQuery, {
    skip: !open,
  });

  const onboardingSteps = data?.arenaOnboarding?.steps || [];

  const [updateSettings, { loading }] = useMutation(UpdateSettingsMutation, {
    onError,
  });

  const onUpdateSettings = (input) => {
    if (onboardingSteps.length === 0) {
      input.isOnboarded = true;
    }

    return updateSettings({
      variables: {
        input,
      },
    }).then(() => {
      return setStep(ONBOARDING_STEPS);
    });
  };

  const completeOnboarding = () => {
    updateSettings({
      variables: {
        input: {
          isOnboarded: true,
        },
      },
    });
  };

  const onCloseDialog = () => {
    if (step === CREATE_PROFILE) return;
    completeOnboarding();
  };

  const onCompleted = () => {
    completeOnboarding();
  };

  return (
    <Dialog
      fullWidth
      // disableScrollLock
      disablePortal
      fullScreen={fullScreen}
      TransitionComponent={Transition}
      maxWidth="sm"
      open={open}
      onClose={onCloseDialog}
      scroll="body"
      disableAutoFocus
      disableEnforceFocus
      disableRestoreFocus
    >
      {open && (
        <>
          {step === ONBOARDING_STEPS && (
            <OnboardingSteps
              steps={onboardingSteps}
              onCompleted={onCompleted}
            />
          )}

          {step === CREATE_PROFILE && (
            // open && !called &&
            <CreateProfile
              arena={data?.arena}
              onUpdateSettings={onUpdateSettings}
              loading={loading}
              onError={onError}
              viewer={viewer}
            />
          )}
        </>
      )}
    </Dialog>
  );
}

OnboardingDialog.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  arena: PropTypes.shape({
    id: PropTypes.string.isRequired,
    multilingual: PropTypes.bool,
    userFields: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        required: PropTypes.bool,
      })
    ),
  }),
  viewer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    country: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    isOnboarded: PropTypes.bool,
    login: PropTypes.string,
    email: PropTypes.string.isRequired,
    photo: PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string,
    }),
    language: PropTypes.string,
    timeZone: PropTypes.string,
  }),
};

export default withSnackbar(OnboardingDialog);
