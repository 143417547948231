import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import LaptopIcon from "@mui/icons-material/Laptop";
import { useQuery, useMutation } from "@apollo/client";
import { ViewSessionsQuery } from "core/queries";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorScreen from "core/components/ErrorScreen";
import { useViewer } from "core/components/ViewerProvider";
import { CreateViewSessionMutation } from "core/mutations";
import DialogHeader from "core/components/DialogHeader";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Checkbox from "@mui/material/Checkbox";

const PREFIX = "CloseViewSessionDialog";

const classes = {
  title: `${PREFIX}-title`,
  primary: `${PREFIX}-primary`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.title}`]: {
    flex: 1,
    marginLeft: 4,
  },

  [`& .${classes.primary}`]: {
    color: "white",
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function CloseViewSessionDialog({ contentId, open, onClose }) {
  const { t } = useTranslation("core");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [selected, setSelected] = useState([]);
  const viewer = useViewer();

  const { data, loading, error } = useQuery(ViewSessionsQuery, {
    variables: {
      active: true,
      contentId,
    },
    skip: !open || !viewer,
  });

  const [createSession, { loading: closeLoading }] = useMutation(
    CreateViewSessionMutation,
    {
      refetchQueries: ["Content"],
      onCompleted: () => {
        onClose();
      },
    }
  );

  const toggleSelectSession = (session) => {
    if (selected.includes(session.id)) {
      const newSelected = selected.filter((sid) => sid !== session.id);
      setSelected(newSelected);
    } else {
      setSelected([session.id, ...selected]);
    }
  };

  const onClickCloseSession = () => {
    createSession({ variables: { contentId, closeIds: selected } });
  };

  const next = selected.length > 0;
  const disabled = !next || loading || closeLoading;
  const sessions = data?.viewSessions?.nodes || [];

  if (!viewer) {
    return;
  }

  return (
    <StyledDialog
      scroll="paper"
      fullWidth
      fullScreen={fullScreen}
      maxWidth="sm"
      open={open}
    >
      <DialogHeader
        onClose={onClose}
        title={t("closeViewSessionsDialog.title")}
        action={
          <Button
            onClick={onClickCloseSession}
            variant="contained"
            color="primary"
            disabled={disabled}
          >
            {t("closeViewSessionsDialog.closeSessions")}
          </Button>
        }
      />

      <DialogContent style={{ minHeight: "300px", padding: "0px 0px 8px 0px" }}>
        {error && <ErrorScreen error={error} />}

        {loading && !data && (
          <div align="center" style={{ marginTop: theme.spacing(2) }}>
            <CircularProgress color="secondary" />
          </div>
        )}

        <List style={{ padding: "0" }}>
          {sessions.map((session) => (
            <ListItem
              key={session.id}
              button
              disabled={viewer.currentSession.id === session.viewerSession.id}
              selected={selected.includes(session.id)}
              onClick={() => toggleSelectSession(session)}
            >
              <ListItemAvatar>
                <Avatar className={classes.primary}>
                  {session.viewerSession.userDeviceType === "Mobile" ? (
                    <SmartphoneIcon />
                  ) : (
                    <LaptopIcon />
                  )}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={[
                  session.viewerSession.userDeviceType,
                  session.viewerSession.userDeviceOs,
                ]
                  .filter(Boolean)
                  .join(" ")}
                secondary={
                  <>
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                      style={{ marginRight: theme.spacing(0.5) }}
                    >
                      {session.viewerSession.latestIp}
                    </Typography>

                    {viewer.currentSession.id === session.viewerSession.id
                      ? t("closeViewSessionsDialog.currentDevice")
                      : t("closeViewSessionsDialog.latestActivity", {
                          date: session.updatedAt,
                        })}
                  </>
                }
              />
              <ListItemSecondaryAction>
                <Checkbox
                  color="primary"
                  edge="end"
                  onChange={() => toggleSelectSession(session)}
                  checked={selected.includes(session.id)}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </StyledDialog>
  );
}

CloseViewSessionDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  contentId: PropTypes.string.isRequired,
};
