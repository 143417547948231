import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import ErrorScreen from "core/components/ErrorScreen";
import BadgeIcon from "@mui/icons-material/Badge";
import useScrollToTop from "core/hooks/useScrollToTop";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { useViewer } from "core/components/ViewerProvider";

const Root = styled("div")(({ theme }) => ({
  overflow: "hidden",
  padding: theme.spacing(0, 0, 2),
  flexGrow: 1,
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(0, 2, 2),
  },
}));

export default function AuthRequired() {
  const { t } = useTranslation("arena");
  const viewer = useViewer();

  const onClick = () => {
    return document.querySelector(".js-sign-in").click();
  };

  useScrollToTop();
  useEffect(() => {
    if (viewer === null) onClick();
  }, [viewer]);

  return (
    <Root>
      <ErrorScreen
        icon={BadgeIcon}
        title={t("authRequired.title")}
        text={t("authRequired.text")}
        button={
          <Button variant="contained" color="primary" onClick={onClick}>
            {t("authRequired.button")}
          </Button>
        }
      />
    </Root>
  );
}
