import React from "react";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export default function HeaderMoreLink({ url }) {
  const { t } = useTranslation("arena");

  return (
    <Tooltip title={t("headerMoreLink.showAll")}>
      <IconButton
        style={{ padding: "11px" }}
        component={Link}
        to={url}
        size="large"
      >
        <ArrowForwardIcon />
      </IconButton>
    </Tooltip>
  );
}

HeaderMoreLink.propTypes = {
  url: PropTypes.string,
};
