import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import jsonLd from "./jsonLd";
import jsonLdPreview from "./jsonLdPreview";
import moment from "core/utils/moment";
import { removeLinks } from "core/utils/string";

const COLLECTION = "COLLECTION";
const MEDIUM = "MEDIUM";
const HIGH = "HIGH";
const MAXRES = "MAXRES";

export default function ContentHelmet({ content, arena }) {
  if (!content) return null;

  const desc = content.description || "";

  let image = content.preview;

  if (content.shareImages.length) {
    const shareImage =
      content.shareImages.find((i) => i.format === MAXRES) ||
      content.shareImages.find((i) => i.format === HIGH) ||
      content.shareImages.find((i) => i.format === MEDIUM);

    if (shareImage) {
      image = shareImage.url;
    }
  }

  const allowEmbed = JSON.stringify(
    content.type !== COLLECTION && !content.access.error
  );

  const title = removeLinks(content.title);
  const description = removeLinks(desc);

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="twitter:title" content={title} />
      <meta property="og:title" content={title} />

      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta name="twitter:description" content={description} />

      <meta property="og:site_name" content={arena.host} />
      <meta property="og:type" content="video.other" />
      <meta property="og:url" content={content.shareUrl} />

      {allowEmbed && <meta name="twitter:player" content={content.url} />}
      {allowEmbed && <meta name="twitter:player:width" content="1280" />}
      {allowEmbed && <meta name="twitter:player:height" content="720" />}
      {allowEmbed && <meta property="og:video" content={content.url} />}
      {allowEmbed && (
        <meta
          property="og:video:type"
          content="application/vnd.apple.mpegurl"
        />
      )}
      {allowEmbed && (
        <meta property="og:video:secure_url" content={content.url} />
      )}
      {allowEmbed && <meta property="og:video:width" content="1280" />}
      {allowEmbed && <meta property="og:video:height" content="720" />}
      {allowEmbed && <meta property="og:video:iframe" content={content.url} />}

      <meta name="twitter:card" content="summary_large_image" />

      {image && <meta property="og:image:width" content="1280" />}
      {image && <meta property="og:image:height" content="720" />}
      {image && <meta property="og:image" content={image} />}
      {image && <link rel="image_src" href={image} />}
      {image && <meta name="twitter:image" content={image} />}
      {image && <meta property="vk:image" content={image} />}

      {content.duration && (
        <meta property="video:duration" content={content.duration.toFixed()} />
      )}
      {content.duration && (
        <meta
          property="og:video:duration"
          content={content.duration.toFixed()}
        />
      )}

      <meta
        property="ya:ovs:upload_date"
        content={moment(content.publishAt).format()}
      />

      <meta property="ya:ovs:allow_embed" content={allowEmbed} />
      {/* <meta property="ya:ovs:views_total" content={content.totalViews} /> */}
      {/* <meta property="ya:ovs:comments" content="23" /> */}
      <meta property="ya:ovs:rating" content="10" />

      <meta property="ya:ovs:adult" content="false" />
      <meta property="ya:ovs:status" content="published" />
      <meta property="ya:ovs:is_official" content="true" />
      <meta property="ya:ovs:license" content="cc" />

      {content.access.country.include.length > 0 && (
        <meta
          property="og:restrictions:country:allowed"
          content={content.access.country.include.join(", ")}
        />
      )}

      {content.access.country.exclude.length > 0 && (
        <meta
          property="og:restrictions:country:disallowed"
          content={content.access.country.exclude.join(", ")}
        />
      )}

      {content.sport && (
        <meta property="ya:ovs:category" content={content.sport.title} />
      )}

      <link rel="canonical" href={content.shareUrl} />
      <link rel="video_src" href={content.url} />
      <meta name="video_width" content="1280" />
      <meta name="video_height" content="720" />

      <script type="application/ld+json">{jsonLd(content, arena)}</script>

      <script type="application/ld+json">
        {jsonLdPreview(content, arena)}
      </script>
    </Helmet>
  );
}

ContentHelmet.propTypes = {
  arena: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    host: PropTypes.string,
  }),
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    duration: PropTypes.number,
    // totalViews: PropTypes.number,
    access: PropTypes.object,
    sport: PropTypes.shape({
      title: PropTypes.string,
    }),
    publishAt: PropTypes.string,
    url: PropTypes.string.isRequired,
    shareUrl: PropTypes.string.isRequired,
    description: PropTypes.string,
    preview: PropTypes.string,
    shareImages: PropTypes.array,
  }),
};
