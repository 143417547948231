/**
 const ls = new LocalStorage(`channel-settings`);

 ls.getItem('key')

 ls.setItem('key', 'value')
 */
export default function LocalStorage(namespace) {
  return {
    getItem(key) {
      try {
        return JSON.parse(window.localStorage.getItem(`${namespace}:${key}`));
      } catch (error) {
        return null;
      }
    },

    setItem(key, value) {
      try {
        window.localStorage.setItem(
          `${namespace}:${key}`,
          JSON.stringify(value)
        );
      } catch (error) {
        //
      }
    },

    removeItem(key) {
      try {
        window.localStorage.removeItem(`${namespace}:${key}`);
      } catch (error) {
        //
      }
    },
  };
}
