import React from "react";
import PropTypes from "prop-types";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import ErrorScreen from "core/components/ErrorScreen";
import PlayerPreview from "core/components/PlayerPreview";
import { useTranslation } from "react-i18next";

export default function StreamPendingScreen({ content, subscribeButton }) {
  const { t } = useTranslation("core");

  return (
    <PlayerPreview preview={content.preview} overlay>
      <ErrorScreen
        icon={LiveTvIcon}
        title={content.title}
        text={
          content.startAt
            ? t("streamPending.textWithDate", { date: content.startAt })
            : t("streamPending.text")
        }
        button={subscribeButton}
      />
    </PlayerPreview>
  );
}

StreamPendingScreen.propTypes = {
  subscribeButton: PropTypes.node,
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    preview: PropTypes.string,
    startAt: PropTypes.string,
  }).isRequired,
};
