import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";

const StyledLinearProgress = styled(LinearProgress)({
  position: "absolute",
  height: "5px",
  bottom: 0,
  left: 0,
  width: "100%",
  borderRadius: "0 0 4px 4px",
});

const normalize = (value, max, min = 0) => ((value - min) * 100) / (max - min);

const VIDEO = "VIDEO";

export default function ThumbnailProgress({ content }) {
  if (content.type !== VIDEO) return null;
  if (!content.viewerVideoPosition) return null;
  if (!content.duration) return null;

  return (
    <StyledLinearProgress
      variant="determinate"
      value={normalize(content.viewerVideoPosition, content.duration)}
    />
  );
}

ThumbnailProgress.propTypes = {
  content: PropTypes.shape({
    type: PropTypes.string.isRequired,
    viewerVideoPosition: PropTypes.number,
    duration: PropTypes.number,
  }),
};
