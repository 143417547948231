import React, { Fragment, useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ChatMessageMenu from "core/components/ChatMessageMenu";
import { useMutation } from "@apollo/client";
import { CreateBanMutation, DeleteChatMessageMutation } from "core/mutations";
import { withSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const PREFIX = "ChatMessageListItem";

const classes = {
  avatar: `${PREFIX}-avatar`,
  alignItemsFlexStart: `${PREFIX}-alignItemsFlexStart`,
  listItemAvatar: `${PREFIX}-listItemAvatar`,
  userDisplayName: `${PREFIX}-userDisplayName`,
  message: `${PREFIX}-message`,
  action: `${PREFIX}-action`,
  item: `${PREFIX}-item`,
  itemText: `${PREFIX}-itemText`,
};

const StyledListItem = styled(ListItem)({
  [`& .${classes.avatar}`]: {
    margin: "auto",
    width: 24,
    height: 24,
  },
  [`& .${classes.alignItemsFlexStart}`]: {
    marginTop: 4,
  },
  [`& .${classes.listItemAvatar}`]: {
    minWidth: 40,
    paddingLeft: 12,
    paddingRight: 10,
  },
  [`& .${classes.userDisplayName}`]: {
    fontWeight: 500,
    fontSize: 13,
    marginRight: 10,
    whiteSpace: "nowrap",
  },
  [`& .${classes.message}`]: {
    fontSize: 13,
  },
  [`& .${classes.action}`]: {
    visibility: "hidden",
  },
  [`& .${classes.item}`]: {
    "&:hover $action": {
      visibility: "visible",
    },
  },
  [`& .${classes.itemText}`]: {
    width: "100%",
    wordBreak: "break-word",
  },
});

const PERMANENT = "PERMANENT";
const TEMPORARY = "TEMPORARY";

function ChatMessageListItem({
  message,
  viewerIsAdmin,
  viewerIsCreator,
  enqueueSnackbar,
  updateCacheAfterDelete,
}) {
  const { t } = useTranslation("core");

  const [chatMessageMenuAnchorEl, setChatMessageMenuAnchorEl] = useState(null);

  const onError = (error) => {
    enqueueSnackbar(error.message, {
      variant: "error",
    });
  };

  const [createBan, { loading: createBanLoading }] = useMutation(
    CreateBanMutation,
    {
      refetchQueries: ["Bans"],
      onError,
      onCompleted: (data) => {
        setChatMessageMenuAnchorEl(null);
        if (data.createBan.type === PERMANENT) {
          enqueueSnackbar(t("ban.created_permanent"), {
            variant: "success",
          });
        }
        if (data.createBan.type === TEMPORARY) {
          enqueueSnackbar(t("ban.created_temprorary"), {
            variant: "success",
          });
        }
      },
    }
  );

  const [deleteChatMessage, { loading: deleteChatMessageLoading }] =
    useMutation(DeleteChatMessageMutation, {
      variables: {
        id: message.id,
      },
      onError,
      onCompleted: () => {
        enqueueSnackbar(t("chat.message_deleted"), {
          variant: "success",
        });
      },
      update: updateCacheAfterDelete,
    });

  const onCloseChatMessageMenu = () => setChatMessageMenuAnchorEl(null);
  const onClickChatMessageMenu = (event) => {
    setChatMessageMenuAnchorEl(event.currentTarget);
  };

  const onClickBanPermanent = () => {
    createBan({
      variables: {
        input: {
          messageId: message.id,
          type: PERMANENT,
        },
      },
    });
  };

  const onClickBanTemprorary = () => {
    createBan({
      variables: {
        input: {
          messageId: message.id,
          type: TEMPORARY,
        },
      },
    });
  };

  const onClickDelete = () => {
    deleteChatMessage();
  };

  const alignItemsFlexStart = message.message.length > 30;
  const alignItems = alignItemsFlexStart ? "flex-start" : "center";

  return (
    <>
      <StyledListItem
        alignItems={alignItems}
        dense
        disableGutters
        classes={{ container: classes.item }}
      >
        <ListItemAvatar
          classes={{
            root: classes.listItemAvatar,
            alignItemsFlexStart:
              alignItemsFlexStart && classes.alignItemsFlexStart,
          }}
        >
          <Avatar src={message.userAvatar} className={classes.avatar} />
        </ListItemAvatar>
        <div className={classes.itemText}>
          <Typography
            component="span"
            variant="body2"
            color={
              message.userIsAdmin
                ? "primary"
                : message.userIsRightholder
                ? "secondary"
                : "textSecondary"
            }
            className={classes.userDisplayName}
          >
            {message.userDisplayName}
          </Typography>
          <Typography
            component="span"
            variant="body2"
            color="textPrimary"
            className={classes.message}
          >
            {message.message}
          </Typography>
        </div>

        {(viewerIsAdmin || viewerIsCreator) && (
          <ListItemSecondaryAction className={classes.action}>
            <IconButton
              edge="end"
              size="small"
              onClick={onClickChatMessageMenu}
              disabled={createBanLoading || deleteChatMessageLoading}
            >
              <MoreVertIcon color="disabled" />
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </StyledListItem>
      {(viewerIsAdmin || viewerIsCreator) && (
        <ChatMessageMenu
          anchorEl={chatMessageMenuAnchorEl}
          open={Boolean(chatMessageMenuAnchorEl)}
          onClose={onCloseChatMessageMenu}
          onClickBanPermanent={onClickBanPermanent}
          onClickBanTemprorary={onClickBanTemprorary}
          onClickDelete={onClickDelete}
          viewerIsCreator={viewerIsCreator}
        />
      )}
    </>
  );
}

ChatMessageListItem.propTypes = {
  updateCacheAfterDelete: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  viewerIsAdmin: PropTypes.bool,
  viewerIsCreator: PropTypes.bool,
  message: PropTypes.shape({
    id: PropTypes.string.isRequired,
    userAvatar: PropTypes.string,
    userId: PropTypes.string.isRequired,
    userDisplayName: PropTypes.string.isRequired,
    userIsRightholder: PropTypes.bool,
    userIsAdmin: PropTypes.bool,
    message: PropTypes.string.isRequired,
  }),
};

export default withSnackbar(ChatMessageListItem);
