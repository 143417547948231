import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import PlayerContentTitle from "core/components/PlayerContentTitle";

const PREFIX = "PlayerPause";

const classes = {
  root: `${PREFIX}-root`,
  inner: `${PREFIX}-inner`,
  visible: `${PREFIX}-visible`,
  transparent: `${PREFIX}-transparent`,
};

const Root = styled("div", {
  shouldForwardProp: (prop) => !["visible", "transparent"].includes(prop),
})(({ theme, visible, transparent }) => ({
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 1000,
  width: "100%",
  height: "100%",
  backgroundColor: transparent
    ? "transparent"
    : visible
    ? "rgba(0, 0, 0, 0.6)"
    : "rgba(0, 0, 0, 0)",
  display: "flex",
  alignItems: "stretch",
  justifyContent: "stretch",
  padding: "46px 8px",
  transition: "background-color .3s ease",

  ["@media (min-width: 375px)"]: {
    padding: "72px 16px",
  },

  [theme.breakpoints.up("md")]: {
    padding: "72px 24px",
  },

  [`& .${classes.inner}`]: {
    width: "100%",
    margin: "auto",
    opacity: visible ? 1 : 0,
    transition: "opacity .3s ease",

    maxWidth: 700,
    [theme.breakpoints.up("lg")]: {
      maxWidth: 800,
    },
  },
}));

const SHOW_TIMEOUT = 2000; // Кол-во миллисекунд, через которое покажется информация о контенте

export default function PlayerPause({
  title,
  contentUrl,
  relatedContent,
  onClickPlay,
  setVisible,
  visible,
  transparent,
  hideLogo,
}) {
  useEffect(() => {
    if (setVisible) {
      const show = () => {
        setVisible(true);
      };

      const timer = setTimeout(show, SHOW_TIMEOUT);

      return () => {
        clearInterval(timer);
      };
    }
  }, [setVisible]);

  return (
    <Root visible={visible} transparent={transparent} onClick={onClickPlay}>
      <div className={classes.inner}>
        <PlayerContentTitle
          onClickPlay={onClickPlay}
          title={title}
          contentUrl={contentUrl}
          visible={visible}
          hideLogo={hideLogo}
        />

        {relatedContent}
      </div>
    </Root>
  );
}

PlayerPause.propTypes = {
  hideLogo: PropTypes.bool,
  visible: PropTypes.bool,
  transparent: PropTypes.bool,
  title: PropTypes.string.isRequired,
  contentUrl: PropTypes.string.isRequired,
  relatedContent: PropTypes.node,
  onClickPlay: PropTypes.func,
  setVisible: PropTypes.func,
};
