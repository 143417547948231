import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";
import { BansQuery } from "core/queries";
import { DeleteBanMutation } from "core/mutations";
import LinearProgress from "@mui/material/LinearProgress";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import LoadMore from "core/components/LoadMore";
import ReactGA from "react-ga4";
import { withSnackbar } from "notistack";

const PREFIX = "ChatBans";

const classes = {
  root: `${PREFIX}-root`,
  empty: `${PREFIX}-empty`,
};

const Root = styled("div")({
  [`&.${classes.root}`]: {
    width: "100%",
    // background: "blue",
    position: "absolute",
    top: 48,
    bottom: 0,
    left: 0,
    right: 0,
    overflowX: "hidden",
    overflowY: "auto",
    zIndex: 1,
  },
  [`& .${classes.empty}`]: {
    padding: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

function ChatBans({ enqueueSnackbar, rowsPerPage }) {
  const { t } = useTranslation("core");

  const onError = (error) => {
    enqueueSnackbar(error.message, {
      variant: "error",
    });
  };

  const [deleteBan] = useMutation(DeleteBanMutation, {
    refetchQueries: ["Bans"],
    onError,
    onCompleted: () => {
      enqueueSnackbar(t("ban.deleted"), {
        variant: "success",
      });
    },
  });

  const { data, loading, error, fetchMore } = useQuery(BansQuery, {
    // ssr: false,
    fetchPolicy: "cache-and-network",
    onError,
    variables: {
      rowsPerPage,
    },
  });

  if (loading && !data) return <LinearProgress />;
  if (error) return null;

  const { nodes, count } = data.bans;
  const hasMore = nodes.length < count;
  const showLoadMore = hasMore && !loading;
  const nextPage = Math.ceil(nodes.length / rowsPerPage);

  const onLoadMore = () => {
    ReactGA.event({
      category: "User",
      action: "Load more bans",
      label: nextPage + " page",
    });

    fetchMore({
      variables: {
        page: nextPage,
      },
    });
  };

  return (
    <Root className={classes.root}>
      <Grid container spacing={1}>
        {count === 0 ? (
          <Grid item xs={12}>
            <Typography variant="body1" style={{ padding: 16 }}>
              {t("ban.empty")}
            </Typography>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <List disablePadding>
              {nodes.map((ban, index) => (
                <ListItem
                  key={ban.id}
                  alignItems="flex-start"
                  divider={index !== nodes.length - 1}
                >
                  <ListItemAvatar>
                    {ban.user.photo ? (
                      <Avatar
                        src={ban.user.photo.url}
                        alt={ban.user.fullName}
                      />
                    ) : (
                      <Avatar>{ban.user.fullName[0]}</Avatar>
                    )}
                  </ListItemAvatar>
                  <ListItemText
                    primary={ban.user.fullName}
                    secondary={t("ban.expiredAt", {
                      date: ban.expiredAt,
                    })}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      size="small"
                      onClick={() => {
                        deleteBan({ variables: { id: ban.id } });
                      }}
                    >
                      <DeleteIcon color="disabled" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Grid>
        )}
      </Grid>

      {showLoadMore && <LoadMore key={nextPage} onLoadMore={onLoadMore} />}
    </Root>
  );
}

ChatBans.defaultProps = {
  rowsPerPage: 10,
};

ChatBans.propTypes = {
  rowsPerPage: PropTypes.number,
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(ChatBans);
