export default function getReferrer() {
  if (typeof window === "undefined") return "";

  if (window.location !== window.parent.location) {
    if (document.referrer) {
      return document.referrer;
    }
  }

  return document.location.href;
}
