export default function getReferer() {
  if (typeof window !== "undefined") {
    if (window.location !== window.parent.location) {
      if (document.referrer) {
        return document.referrer;
      }
    }

    return document.location.href;
  }
}
