import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import CardActionArea from "@mui/material/CardActionArea";
import CardHeader from "@mui/material/CardHeader";
import contentUrl from "core/utils/contentUrl";
import Thumbnail from "core/components/Thumbnail";
import ThumbnailProgress from "core/components/ThumbnailProgress";

const PREFIX = "ContentGridItem";

const classes = {
  avatar: `${PREFIX}-avatar`,
  mediaLink: `${PREFIX}-mediaLink`,
  body: `${PREFIX}-body`,
  cardHeader: `${PREFIX}-cardHeader`,
  cardHeaderContent: `${PREFIX}-cardHeaderContent`,
  link: `${PREFIX}-link`,
  linkSmall: `${PREFIX}-linkSmall`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.avatar}`]: {
    backgroundColor: "#151515",
  },

  [`& .${classes.mediaLink}`]: {
    position: "relative",
    display: "block",
    color: "inherit",
    [theme.breakpoints.up("sm")]: {
      borderRadius: 4, // desktop
    },
  },

  [`& .${classes.body}`]: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: theme.spacing(2),
  },

  [`& .${classes.cardHeader}`]: {
    width: "100%",
    alignItems: "flex-start",
    padding: "16px 0",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  },

  [`& .${classes.cardHeaderContent}`]: {
    width: "100%",
  },

  [`& .${classes.link}`]: {
    textDecoration: "none",
    fontSize: "1.1rem",
    fontWeight: 500,
    display: "block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // "-webkit-line-clamp": 2,
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.4rem",
    },
  },

  [`& .${classes.linkSmall}`]: {
    textDecoration: "none",
    fontSize: "1.1rem",
    fontWeight: 500,
    display: "block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // "-webkit-line-clamp": 2,
    // [theme.breakpoints.up("sm")]: {
    //   fontSize: "1.2rem",
    // },
  },
}));

export default function ContentGridItem({
  start,
  finish,
  content,
  play,
  className,
  productId,
  collectionId,
  smallTitle,
}) {
  const to = contentUrl(content.siteUrl, {
    start,
    finish,
    productId,
    collectionId,
  });

  return (
    <Root className={className}>
      <CardActionArea
        component={Link}
        to={to}
        className={classNames(classes.mediaLink)}
      >
        <Thumbnail
          content={content}
          play={play}
          start={start}
          finish={finish}
        />

        <ThumbnailProgress content={content} />
      </CardActionArea>

      <CardHeader
        className={classes.cardHeader}
        title={content.title}
        classes={{
          content: classes.cardHeaderContent,
        }}
        titleTypographyProps={{
          color: "textPrimary",
          className: classNames({
            [classes.link]: !smallTitle,
            [classes.linkSmall]: smallTitle,
          }),
          component: Link,
          to,
        }}
      />
    </Root>
  );
}

ContentGridItem.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    siteUrl: PropTypes.string.isRequired,
  }),
  smallTitle: PropTypes.bool,
  play: PropTypes.bool,
  className: PropTypes.string,
  productId: PropTypes.string,
  collectionId: PropTypes.string,
  start: PropTypes.number,
  finish: PropTypes.number,
};
