import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { ViewerQuery } from "core/queries";
import { useQuery, useSubscription } from "@apollo/client";
import { useTranslation } from "react-i18next";
import ErrorScreen from "core/components/ErrorScreen";
import { SessionClosedSubscription } from "core/subscriptions";
import LocalStorage from "core/utils/localstorage";
import { setTimeZone } from "core/utils/moment";

const ls = new LocalStorage(`apollo`);

const ViewerContext = React.createContext();

export function useViewer() {
  return useContext(ViewerContext);
}

export default function ViewerProvider({ children, useViewerLanguage }) {
  const { i18n } = useTranslation("core");

  const { error, data } = useQuery(ViewerQuery);

  const authToken = data?.viewer?.authToken;

  useEffect(() => {
    ls.setItem("token", authToken);
  }, [authToken]);

  /**
   * подписываемся на закрытие сеанса только если
   * она помечена как для просмотра премиум контента
   */
  const session = data?.viewer?.currentSession;

  useSubscription(SessionClosedSubscription, {
    skip: !process.browser || !session?.premiumContent,
    variables: {
      id: session?.id,
    },
    onData: ({ data }) => {
      if (data?.data?.sessionClosed?.closedAt) {
        location.reload();
      }
    },
  });

  const language = data?.viewer?.language?.toLowerCase();

  // делаем это здесь, чтобы все дате пикеры точно сработали
  if (data?.viewer?.timeZone) {
    setTimeZone(data.viewer.timeZone);
  }

  useEffect(() => {
    if (language) {
      if (useViewerLanguage) {
        i18n.changeLanguage(language);
      }
    }
  }, [language, useViewerLanguage]);

  if (error) return <ErrorScreen error={error} />;

  return (
    <ViewerContext.Provider value={data?.viewer}>
      {children}
    </ViewerContext.Provider>
  );
}

ViewerProvider.propTypes = {
  children: PropTypes.node,
  useViewerLanguage: PropTypes.bool,
};
