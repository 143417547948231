import React from "react";
import PropTypes from "prop-types";
import CastIcon from "@mui/icons-material/Cast";
import { useCast } from "core/components/CastProvider";
import CastConnectedIcon from "@mui/icons-material/CastConnected";
import PlayerFooterButton from "core/components/PlayerFooterButton";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";

function CastButton({ hlsUrl, content, liveSeekBar }) {
  const { t } = useTranslation("core");

  const { currentContentId, startCast, isSupported, requestSession } =
    useCast();

  if (!isSupported) {
    return null;
  }

  const sameContent = content.id && currentContentId === content.id;

  if (!sameContent && !hlsUrl) {
    return null;
  }

  const handleClick = () => {
    ReactGA.event({
      category: "User",
      action: "Start cast",
    });
    startCast({
      hlsUrl,
      contentId: content.id,
      title: content.title,
      subtitle: content.rightholder.name,
      preview: content.preview,
      startAt: liveSeekBar?.startAtLimited,
      offset: liveSeekBar?.offset,
      live: !!liveSeekBar,
    });
  };

  const handleShowCastMenu = () => {
    requestSession();
  };

  return (
    <>
      {sameContent && (
        <PlayerFooterButton
          title={t("castButton.showCastMenu")}
          onClick={handleShowCastMenu}
        >
          <CastConnectedIcon />
        </PlayerFooterButton>
      )}

      {hlsUrl && (
        <PlayerFooterButton title={t("castButton.cast")} onClick={handleClick}>
          <CastIcon />
        </PlayerFooterButton>
      )}
    </>
  );
}

CastButton.propTypes = {
  hlsUrl: PropTypes.string,
  content: PropTypes.object,
  live: PropTypes.bool,
  liveSeekBar: PropTypes.object,
};

export default CastButton;
