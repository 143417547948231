import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Zoom from "@mui/material/Zoom";
import { createEvent } from "core/utils/tracker";
import { useTranslation } from "react-i18next";
import { RelatedVideoQuery } from "core/queries";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import AutorenewIcon from "@mui/icons-material/Autorenew";

const PREFIX = "RelatedVideo";

const classes = {
  root: `${PREFIX}-root`,
  item: `${PREFIX}-item`,
  itemTitle: `${PREFIX}-itemTitle`,
};

const Root = styled("div")(({ theme }) => ({
  margin: "auto",
  width: "100%",
  display: "flex",
  alignItems: "center",
  jusifyContent: "center",
  paddingTop: theme.spacing(6),

  ["@media (max-width: 600px)"]: {
    display: "none",
  },
  ["@media (max-height: 375px)"]: {
    display: "none",
  },

  [`& .${classes.item}`]: {
    width: "100%",
    height: 0,
    paddingTop: "56.25%",
    display: "block",
    position: "relative",
    borderRadius: 4,
    overflow: "hidden",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundColor: "#000",

    "&:before": {
      content: '""',
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      height: 64,
      pointerEvents: "none",
      backgroundImage:
        "linear-gradient(transparent, rgba(0,0,0,0.6), rgba(0,0,0,0.9))",
    },
  },

  [`& .${classes.itemTitle}`]: {
    padding: theme.spacing(1),
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    fontSize: "0.75rem",

    ["@media (min-width: 425px)"]: {
      fontSize: "0.875rem",
    },
  },
}));

export default React.memo(RelatedVideo);

function RelatedVideo() {
  const { t } = useTranslation("core");

  const { contentId } = useParams();
  const [page, setPage] = useState(0);

  const { data } = useQuery(RelatedVideoQuery, {
    variables: { contentId },
  });

  if (!data) return null;
  if (!data.content) return null;

  const { relatedContent } = data.content;
  const rowsPerPage = 3;
  const from = page * rowsPerPage;
  const to = from + rowsPerPage;
  const items = relatedContent.slice(from, to);

  if (items.length === 0) return null;

  const onClickRefresh = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setPage((page) => {
      const newPage = page + 1;
      const newFrom = newPage * rowsPerPage;

      if (relatedContent[newFrom]) {
        return newPage;
      }

      return 0;
    });
  };

  return (
    <Root className={classes.root}>
      <Grid container spacing={2} justifyContent="center">
        {items.map(({ id, title, preview, shareUrl }, i) => {
          const style = preview
            ? {
                backgroundImage: `url(${preview})`,
              }
            : {};

          const onClick = (event) => {
            event.stopPropagation();
            createEvent("relatedvideo", "click", title);
          };

          const n = i + 1;
          const timeout = 300 * n;

          return (
            <Grid item xs={12 / rowsPerPage} key={id} container>
              <Zoom in timeout={timeout}>
                <Link
                  href={shareUrl + "?utm_source=playerrelatedvideo"}
                  className={classes.item}
                  style={style}
                  onClick={onClick}
                  target="_blank"
                  underline="hover"
                >
                  <div className={classes.overlay} />
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                    component="div"
                    noWrap
                    className={classes.itemTitle}
                  >
                    {title}
                  </Typography>
                </Link>
              </Zoom>
            </Grid>
          );
        })}
        <Grid item xs={12} container justifyContent="center">
          <Button
            onClick={onClickRefresh}
            startIcon={<AutorenewIcon />}
            color="inherit"
          >
            {t("relatedVideo.refresh")}
          </Button>
        </Grid>
      </Grid>

      <Helmet>
        {data.content.relatedContent.map((c) => (
          <link rel="preload" href={c.preview} as="image" key={c.id} />
        ))}
      </Helmet>
    </Root>
  );
}

RelatedVideo.propTypes = {
  onCompleted: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      preview: PropTypes.string,
      duration: PropTypes.number.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
};
