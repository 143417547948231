import { loader } from "graphql.macro";

export const CreateViewMutation = loader("./CreateViewMutation.graphql");

export const CreateEventMutation = loader("./CreateEventMutation.graphql");
export const TrackWidgetMutation = loader("./TrackWidgetMutation.graphql");
export const SubmitFormWidgetMutation = loader(
  "./SubmitFormWidgetMutation.graphql"
);
export const CreateBanMutation = loader("./CreateBanMutation.graphql");
export const DeleteBanMutation = loader("./DeleteBanMutation.graphql");
export const CreateChatMessageMutation = loader(
  "./CreateChatMessageMutation.graphql"
);
export const DeleteChatMessageMutation = loader(
  "./DeleteChatMessageMutation.graphql"
);
export const SignInMutation = loader("./SignInMutation.graphql");
export const VerifyEmailMutation = loader("./VerifyEmailMutation.graphql");

export const UpdateSubscriptionMutation = loader(
  "./UpdateSubscriptionMutation.graphql"
);

export const CloseSessionsMutation = loader("./CloseSessionsMutation.graphql");

export const CreateSponsorImpressionMutation = loader(
  "./CreateSponsorImpressionMutation.graphql"
);
export const CreateSponsorClickMutation = loader(
  "./CreateSponsorClickMutation.graphql"
);
export const CreateViewSessionMutation = loader(
  "./CreateViewSessionMutation.graphql"
);

export const UpdateViewDataMutation = loader(
  "./UpdateViewDataMutation.graphql"
);

export const SkipEmailErrorMutation = loader(
  "./SkipEmailErrorMutation.graphql"
);
