import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";

const Root = styled("div", {
  shouldForwardProp: (prop) => !["overlay", "preview"].includes(prop),
})(({ overlay, preview }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  bottom: 0,
  top: 0,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  flex: 1,
  display: "flex",
  flexDirection: "column",
  backgroundImage: preview ? `url("${preview}")` : "none",
  "&:before": {
    zIndex: 1,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    content: "''",
    position: "absolute",
    background: overlay ? "rgba(0,0,0,0.75)" : "rgba(0,0,0,0)",
    transition: "all .15s linear",
  },
}));

const PlayerPreview = React.forwardRef(function PlayerPreview(props, ref) {
  return <Root ref={ref} {...props} />;
});

PlayerPreview.propTypes = {
  preview: PropTypes.string,
  overlay: PropTypes.bool,
};

export default PlayerPreview;
