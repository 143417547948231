import React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { CountriesQuery } from "arena/queries";
import { useQuery } from "@apollo/client";

export default function CountryField(props) {
  const { onChange, value, label, required } = props;

  const { data } = useQuery(CountriesQuery);

  if (!data) return null;

  const options =
    data?.countries?.map((c) => ({
      value: c.code,
      label: c.name,
    })) || [];

  return (
    <Autocomplete
      options={options}
      fullWidth
      value={options.find((o) => o.value === value)}
      renderInput={(params) => (
        <TextField {...params} label={label} required={required} />
      )}
      onChange={(event, newValue) => {
        onChange(newValue?.value);
      }}
    />
  );
}

CountryField.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
};
