import React, { useState } from "react";
import PropTypes from "prop-types";
import Video from "core/components/Video";
import VideoPreview from "core/components/VideoPreview";
import VideoLoading from "core/components/VideoLoading";
import PlayerFooter from "core/components/PlayerFooter";
import BigPlayButton from "core/components/BigPlayButton";
import Shortcut from "core/components/Shortcut";
import { InView } from "react-intersection-observer";
import { ProductVideoQuery } from "arena/queries";
import { useQuery } from "@apollo/client";
import useClientIp from "core/utils/useClientIp";
import useIdle from "core/hooks/useIdle";

export default function ProductPlayer({
  productId,
  getIpUrl,
  fullscreenTarget,
}) {
  const idle = useIdle({ target: fullscreenTarget });
  const [isSeeking, setIsSeeking] = useState(false);
  const [wasPlaying, setWasPlaying] = useState(false);
  const [seekValue, setSeekValue] = useState(0);
  const { waitIp, clientIp } = useClientIp({ url: getIpUrl });

  const { data } = useQuery(ProductVideoQuery, {
    skip: waitIp,
    variables: {
      productId,
      clientIp,
    },
  });

  const video = data?.product?.video;
  const promoPreview = data?.product?.promoPreview;

  if (!video) return null;

  const visible = !idle;

  function handleSeekStart(value, videoState, videoActions) {
    setIsSeeking(true);
    setSeekValue(value);
    setWasPlaying(!videoState.paused);
    videoActions.pause();
  }

  function handleSeek(value) {
    setSeekValue(value);
  }

  function handleSeekEnd(value, _, videoActions) {
    videoActions.navigate(value);

    if (wasPlaying) {
      videoActions.play();
    }

    setIsSeeking(false);
  }

  const preview = promoPreview?.url;

  return (
    <Video hlsUrl={video.playlist} videoElementId={productId} poster={preview}>
      {(videoElement, videoState, videoActions) => {
        const onClickBigPlay = () => {
          videoActions.togglePlayUser();
        };

        const onClickPlay = () => {
          videoActions.togglePlayUser();
        };

        const onClickPause = () => {
          videoActions.togglePlayUser();
        };

        return (
          <InView
            as="div"
            className="inview"
            onChange={(inView) => {
              if (inView) {
                // if (!videoState.isUserPause) {
                //   videoActions.play();
                // }
              } else {
                videoActions.pause();
              }
            }}
            // rootMargin="-20% 0px 0px 0px"
            threshold={0.5}
          >
            {videoElement}

            <VideoPreview
              visible={isSeeking}
              videoPreviews={video.videoPreviews}
              seekValue={seekValue}
            />

            <VideoLoading videoState={videoState} />

            <BigPlayButton onClick={onClickBigPlay} videoState={videoState} />

            <Shortcut videoActions={videoActions} videoState={videoState} />

            <PlayerFooter
              position="absolute"
              videoPreviews={video.videoPreviews}
              visible={visible || videoState.isUserPause || isSeeking}
              videoElement={videoElement}
              videoState={videoState}
              videoActions={videoActions}
              onClickPlay={onClickPlay}
              onClickPause={onClickPause}
              onSeekStart={handleSeekStart}
              onSeek={handleSeek}
              onSeekEnd={handleSeekEnd}
              hideShare
              hideLogo
              fullscreenTarget={fullscreenTarget}
              disableSeekBarPopup={videoState.isEnded}
            />
          </InView>
        );
      }}
    </Video>
  );
}

ProductPlayer.propTypes = {
  fullscreenTarget: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired,
  getIpUrl: PropTypes.string,
};
