import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { LinearProgress } from "@mui/material";
import apm from "core/utils/apm";
import loadSDK from "core/utils/loadSDK";
import { useTheme } from "@mui/material/styles";

const SRC = "https://yookassa.ru/checkout-widget/v1/checkout-widget.js";
const ID = "yookassa-widget";

export default function YandexPaymentForm({
  confirmationToken,
  returnUrl,
  setError,
}) {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  const customization = {
    colors: {
      controlPrimary: theme.palette.primary.main,
      controlPrimaryContent: theme.palette.primary.contrastText,
      background: theme.palette.background.body,
      border: theme.palette.background.body,
      text: theme.palette.text.primary,
      controlSecondary: theme.palette.secondary.main,
    },
  };

  useEffect(() => {
    if (!process.browser) return;

    setLoading(true);

    let checkout = null;

    loadSDK(SRC, ID)
      .then(() => {
        checkout = new window.YooMoneyCheckoutWidget({
          confirmation_token: confirmationToken, // eslint-disable-line camelcase
          return_url: returnUrl, // eslint-disable-line camelcase
          customization,
          // eslint-disable-next-line camelcase
          error_callback(error) {
            apm.captureError(error);
            // Обработка ошибок инициализации
            console.log(error);
            setError(error);
          },
        });

        return checkout.render("payment-form");
      })
      .then(() => {
        return setLoading(false);
      })
      .catch((error) => {
        apm.captureError(error);
        // Обработка ошибок инициализации
        console.log(error);
        setError(error);
        setLoading(false);
      });

    return () => {
      if (checkout?.destroy) {
        checkout.destroy();
      }
    };
  }, [confirmationToken, returnUrl]);

  return (
    <>
      {loading && <LinearProgress />}
      <div id="payment-form" style={{ marginTop: 20 }}></div>
    </>
  );
}

YandexPaymentForm.propTypes = {
  confirmationToken: PropTypes.string.isRequired,
  returnUrl: PropTypes.string.isRequired,
  setError: PropTypes.func,
};
