import React from "react";
import PropTypes from "prop-types";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

export default function Checkbox({ checked }) {
  if (checked) {
    return <CheckBoxIcon />;
  }

  return <CheckBoxOutlineBlankIcon />;
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
};
