// import "react-app-polyfill/ie11"; // For Safari and IE 11
import "core-js/features/array/find";
import "core-js/features/array/includes";
import "core-js/features/string/includes";
import "core-js/features/object/entries";
import "core-js/features/object/values";
import "core-js/features/set";
import "core-js/features/map";
import "intersection-observer";
import "url-search-params-polyfill";
