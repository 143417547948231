import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import ProductQA from "arena/components/ProductQA";
import ProductFeedbackForm from "arena/components/ProductFeedbackForm";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(3, 2, 6),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(3, 3, 6),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ProductHelpDialog({ product, open, onClose }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation("arena");

  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen}
      TransitionComponent={Transition}
      maxWidth="md"
      open={open}
      onClose={onClose}
    >
      <AppBar position="static" color="default">
        <Toolbar>
          <Typography variant="h5" style={{ flex: 1 }}>
            {t("productQA.title")}
          </Typography>
          <IconButton edge="end" onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <StyledDialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ProductQA product={product} />
          </Grid>
          <Grid item xs={12}>
            <ProductFeedbackForm productId={product.id} />
          </Grid>
        </Grid>
      </StyledDialogContent>
    </Dialog>
  );
}

ProductHelpDialog.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};
