import React from "react";
import { styled } from "@mui/material/styles";
import usePageview from "core/hooks/usePageview";
import useScrollToTop from "core/hooks/useScrollToTop";
import ArenaContents from "arena/components/ArenaContents";
import ArenaHelmet from "arena/components/ArenaHelmet";
import { useTranslation } from "react-i18next";
import { useArena } from "core/components/ArenaProvider";
import { useQuery } from "@apollo/client";
import { ProductContentsQuery } from "arena/queries";
import { Link, useLocation } from "react-router-dom";
import MuiLink from "@mui/material/Link";

const Root = styled("div")(({ theme }) => ({
  overflow: "hidden",
  padding: theme.spacing(0),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(0, 3),
  },
}));

const VIDEO = "VIDEO";
const STREAM = "STREAM";
const COLLECTION = "COLLECTION";
const LIMIT = 12; // кол-во контентов на страницу

function renderTitle(product, title) {
  return (
    <>
      <MuiLink
        component={Link}
        to={product.siteUrl}
        color="textPrimary"
        underline="hover"
      >
        {product.title}
      </MuiLink>
      {title && " / " + title}
    </>
  );
}

function getTitle(title, type, t) {
  if (title) {
    return title;
  }

  switch (type) {
    case VIDEO:
      return t("contents.videos");
    case STREAM:
      return t("contents.streams");
    case COLLECTION:
      return t("contents.collections");
    default:
      return t("contents.all");
  }
}

export default function Contents() {
  const arena = useArena();
  const location = useLocation();

  const { t } = useTranslation("arena");

  usePageview(location.pathname, "Contents");
  useScrollToTop();

  const searchParams = new URLSearchParams(location.search);

  const status = searchParams.get("status")?.toUpperCase();
  const type = searchParams.get("type")?.toUpperCase();
  const sort = searchParams.get("sort")?.toUpperCase();

  const blockTitle = searchParams.get("blockTitle");
  const period = searchParams.get("period")
    ? parseInt(searchParams.get("period"), 10)
    : null;

  const productId = searchParams.get("productId");
  const tagIds = searchParams.getAll("tagId");
  const rightholderIds = searchParams.getAll("rightholderId");
  const collectionIds = searchParams.getAll("collectionId");
  const productIds = searchParams.getAll("productIds");
  const anyTagIds = searchParams.getAll("anyTagIds");

  const { data } = useQuery(ProductContentsQuery, {
    skip: !productId,
    variables: {
      id: productId,
    },
  });

  let title = getTitle(blockTitle, type, t); // здесь может быть JSX
  let titleHelmet = title; // Здесь всегда должна быть ТОЛЬКО строка

  if (data?.product) {
    title = renderTitle(data.product, title);
    titleHelmet = data.product.title + " / " + titleHelmet;
  }

  return (
    <Root>
      <ArenaHelmet title={titleHelmet} />
      <ArenaContents
        arena={arena}
        view="GRID"
        type={type}
        sort={sort || "LATEST"}
        limit={LIMIT}
        title={title}
        tagIds={tagIds}
        anyTagIds={anyTagIds}
        rightholderIds={rightholderIds}
        collectionIds={collectionIds}
        productId={productId}
        productIds={productIds}
        period={period}
        status={status}
        noMore
        loadMore
        inView
      />
    </Root>
  );
}
