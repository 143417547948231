import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SponsorVideo from "core/components/SponsorVideo";

export default function SponsorPauseAdv({
  videoState,
  content,
  rightholderId,
  sponsorVideoPlace,
  sponsorChatMessagePlace,
  fullscreenTarget,
  onChatAdv,
  muted,
}) {
  const [played, setPlayed] = useState(false);

  useEffect(() => {
    if (played) {
      if (!videoState.isUserPause) {
        setPlayed(false);
      }
    }
  }, [played, videoState.isUserPause]);

  if (played) return null;
  if (videoState.isEnded) return null;
  if (!videoState.isUserPause) return null;
  if (!process.browser) return null;

  const onStart = () => {
    //
  };

  const onFinish = () => {
    setPlayed(true);
  };

  const onNotFound = () => {
    setPlayed(true);
  };

  const videoPlace = "PAUSEROLL_IN_" + sponsorVideoPlace;
  const chatMessagePlace = sponsorChatMessagePlace
    ? "PAUSEROLL_IN_" + sponsorChatMessagePlace
    : null;

  return (
    <SponsorVideo
      shareUrl={content?.shareUrl}
      chatMessagePlace={chatMessagePlace}
      videoPlace={videoPlace}
      contentId={content.id}
      sportId={content?.sport?.id}
      rightholderId={rightholderId}
      fullscreenTarget={fullscreenTarget}
      onStart={onStart}
      onFinish={onFinish}
      onNotFound={onNotFound}
      onChatAdv={onChatAdv}
      muted={muted}
    />
  );
}

SponsorPauseAdv.propTypes = {
  onChatAdv: PropTypes.func,
  videoState: PropTypes.shape({
    isEnded: PropTypes.bool,
    isUserPause: PropTypes.bool,
  }).isRequired,
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    shareUrl: PropTypes.string,
    sport: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
  rightholderId: PropTypes.string.isRequired,
  sponsorChatMessagePlace: PropTypes.string,
  sponsorVideoPlace: PropTypes.string.isRequired,
  chatMessagePlace: PropTypes.string,
  fullscreenTarget: PropTypes.string.isRequired,
  muted: PropTypes.bool,
};
