import React from "react";
import PropTypes from "prop-types";
import PlayIcon from "@mui/icons-material/PlayArrow";
import { useTranslation } from "react-i18next";
import PlayerFooterButton from "core/components/PlayerFooterButton";
function PlayButton({ videoState, onClick }) {
  const { t } = useTranslation("core");

  if (!videoState) return null;
  if (videoState.status === "playing") return null;

  return (
    <PlayerFooterButton title={t("play")} onClick={onClick}>
      <PlayIcon />
    </PlayerFooterButton>
  );
}

PlayButton.propTypes = {
  onClick: PropTypes.func,
  videoState: PropTypes.object,
};

export default PlayButton;
