import React from "react";
import PropTypes from "prop-types";
import VpnLockIcon from "@mui/icons-material/VpnLock";
import ErrorScreen from "core/components/ErrorScreen";
import PlayerPreview from "core/components/PlayerPreview";

export default function ContentIsNotAvailableError({
  content,
  button,
  text,
  title,
  icon,
}) {
  return (
    <PlayerPreview preview={content.preview} overlay>
      <ErrorScreen icon={icon} title={title} text={text} button={button} />
    </PlayerPreview>
  );
}

ContentIsNotAvailableError.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  button: PropTypes.node,
  icon: PropTypes.object,
  content: PropTypes.shape({
    preview: PropTypes.string,
  }).isRequired,
};

ContentIsNotAvailableError.defaultProps = {
  icon: VpnLockIcon,
};
