import { Component } from "react";
import PropTypes from "prop-types";

export default class Shortcut extends Component {
  static propTypes = {
    videoActions: PropTypes.object.isRequired,
    videoState: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.shortcuts = [
      {
        keyCode: 32, // spacebar
        handle: this.togglePlay,
      },
      {
        keyCode: 75, // k
        handle: this.togglePlay,
      },
      {
        keyCode: 37, // Left arrow
        handle: this.replay,
      },
      {
        keyCode: 74, // j
        handle: this.replay,
      },
      {
        keyCode: 39, // Right arrow
        handle: this.forward,
      },
      {
        keyCode: 76, // l
        handle: this.forward,
      },
    ];
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPress);
  }

  replay = () => {
    this.props.videoActions.navigate(this.props.videoState.currentTime - 5);
  };

  forward = () => {
    this.props.videoActions.navigate(this.props.videoState.currentTime + 5);
  };

  togglePlay = () => {
    this.props.videoActions.togglePlayUser();
  };

  handleKeyPress = (e) => {
    if (document.activeElement && document.activeElement.nodeName !== "BODY") {
      return false;
    }

    const keyCode = e.keyCode || e.which;
    const ctrl = e.ctrlKey || e.metaKey;
    const shift = e.shiftKey;
    const alt = e.altKey;

    const shortcut = this.shortcuts.filter((s) => {
      if (s.keyCode !== keyCode) {
        return false;
      }
      if (
        (s.ctrl !== undefined && s.ctrl !== ctrl) ||
        (s.shift !== undefined && s.shift !== shift) ||
        (s.alt !== undefined && s.alt !== alt)
      ) {
        return false;
      }
      return true;
    })[0];

    if (shortcut) {
      shortcut.handle();
      e.preventDefault();
    }
  };

  render() {
    return null;
  }
}
