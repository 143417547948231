import React, { Fragment } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useViewer } from "core/components/ViewerProvider";
import UserMenu from "./UserMenu";
import HeaderLogo from "./HeaderLogo";
import HeaderMenu from "./HeaderMenu";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import classNames from "classnames";

const PREFIX = "Header";

const classes = {
  avatarButton: `${PREFIX}-avatarButton`,
  avatar: `${PREFIX}-avatar`,
  menuButton: `${PREFIX}-menuButton`,
  title: `${PREFIX}-title`,
  search: `${PREFIX}-search`,
  signInDesktop: `${PREFIX}-signInDesktop`,
  signInMobile: `${PREFIX}-signInMobile`,
  buttonLabel: `${PREFIX}-buttonLabel`,
  searchIcon: `${PREFIX}-searchIcon`,
  arenaIcon: `${PREFIX}-arenaIcon`,
  appBar: `${PREFIX}-appBar`,
  appBarElevate: `${PREFIX}-appBarElevate`,
  rightMenu: `${PREFIX}-rightMenu`,
};

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  transition: "all ease 0.5s",
  backgroundColor: "rgba(255, 255, 255, 0.03)",
  backdropFilter: "blur(5px)",

  "&:before": {
    content: '""',
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    height: 100,
    pointerEvents: "none",
    transition: "all ease 1s",
    backgroundImage:
      "linear-gradient(rgba(16,16,16,0.8), rgba(16,16,16,0.4), rgba(16,16,16,0.1), transparent)",
  },

  [`&.${classes.appBarElevate}`]: {
    backgroundColor: "rgba(255, 255, 255, 0)",
    backdropFilter: "blur(0px)",
    boxShadow: "none",
  },

  [`& .${classes.avatarButton}`]: {
    padding: 9,
    [theme.breakpoints.up("sm")]: {
      padding: 4,
    },
  },

  [`& .${classes.avatar}`]: {
    width: 30,
    height: 30,
    borderRadius: 30,

    [theme.breakpoints.up("sm")]: {
      width: 40,
      height: 40,
      borderRadius: 40,
    },
  },

  [`& .${classes.menuButton}`]: {
    marginRight: 10,

    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  [`& .${classes.title}`]: {
    marginLeft: 10,
  },

  [`& .${classes.search}`]: {
    flex: 1,
    margin: theme.spacing(0, 4),
    marginRight: "auto",
    background: "#101010",
    maxWidth: 240,
    padding: "4px 10px",
    borderRadius: 4,
  },

  [`& .${classes.signInDesktop}`]: {
    display: "none",

    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },

  [`& .${classes.signInMobile}`]: {
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },

  [`& .${classes.buttonLabel}`]: {
    whiteSpace: "nowrap",
  },

  [`& .${classes.searchIcon}`]: {
    // marginLeft: "auto",
    [theme.breakpoints.up("sm")]: {
      marginRight: 10,
    },
  },

  [`& .${classes.arenaIcon}`]: {
    [`@media (max-width: 350px)`]: {
      display: "none",
    },
  },

  [`& .${classes.rightMenu}`]: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
  },
}));

export default function Header({
  arena,
  menuItems,
  onClickMenuMobile,
  onClickSignIn,
  onClickSearch,
  onClickMenuItem,
}) {
  const { t } = useTranslation("arena");
  const viewer = useViewer();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return (
    <StyledAppBar
      color="default"
      position="fixed"
      className={classNames({
        [classes.appBarElevate]: !trigger,
      })}
    >
      <Toolbar>
        <Tooltip title={t("header.menu")}>
          <IconButton
            onClick={onClickMenuMobile}
            edge="start"
            className={classes.menuButton}
            size="large"
          >
            <MenuIcon />
          </IconButton>
        </Tooltip>

        <HeaderLogo arena={arena} />

        <HeaderMenu arena={arena} onClickMenuItem={onClickMenuItem} />

        <Box className={classes.rightMenu}>
          <Tooltip title={t("header.search")}>
            <IconButton
              component={Link}
              to="/search"
              className={classes.searchIcon}
              onClick={onClickSearch}
              size="large"
            >
              <SearchIcon />
            </IconButton>
          </Tooltip>

          {typeof viewer !== "undefined" && arena.isRegistrationEnabled && (
            <>
              {viewer ? (
                <UserMenu viewer={viewer} menuItems={menuItems} />
              ) : (
                <>
                  <Button
                    variant="outlined"
                    onClick={onClickSignIn}
                    className={classes.signInDesktop + " js-sign-in"}
                    classes={{
                      label: classes.buttonLabel,
                    }}
                  >
                    {t("header.signIn")}
                  </Button>

                  <IconButton
                    onClick={onClickSignIn}
                    className={classes.signInMobile + " js-sign-in"}
                    edge="end"
                    size="large"
                  >
                    <AccountCircleIcon />
                  </IconButton>
                </>
              )}
            </>
          )}
        </Box>
      </Toolbar>
    </StyledAppBar>
  );
}

Header.propTypes = {
  className: PropTypes.string,
  onClickMenuItem: PropTypes.func,
  onClickSearch: PropTypes.func,
  onClickMenuMobile: PropTypes.func,
  onClickSignIn: PropTypes.func,
  menuItems: PropTypes.array,
  arena: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    isRegistrationEnabled: PropTypes.bool,
    logo: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
};
