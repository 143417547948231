import React from "react";
import PropTypes from "prop-types";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

export default function RadioButton({ checked }) {
  if (checked) {
    return <RadioButtonCheckedIcon />;
  }

  return <RadioButtonUncheckedIcon />;
}

RadioButton.propTypes = {
  checked: PropTypes.bool,
};
