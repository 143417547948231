/**
 * Возвращает
 * "ежемесячно"
 * "каждые 3 месяца"
 * "ежегодно"
 */
export default function getPricePeriodPer({ t, period }) {
  const pricePeriods = {
    7: t("productPayment.periodPerWeek"),
    30: t("productPayment.periodPerMonth"),
    90: t("productPayment.periodPer3Months"),
    180: t("productPayment.periodPer6Months"),
    365: t("productPayment.periodPerYear"),
  };

  return pricePeriods[period];
}
