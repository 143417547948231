import React from "react";
import PropTypes from "prop-types";
import ProductContents from "arena/components/ProductContents";
// import HomepageTitle from "arena/components/HomepageTitle";
// import HeaderMoreLink from "arena/components/HeaderMoreLink";
import LoadMore from "core/components/LoadMore";
import { useQuery } from "@apollo/client";
import { ProductCollectionsQuery } from "arena/queries";
import ReactGA from "react-ga4";

const LATEST = "LATEST";

export default function ProductCollections({ productId, skip, limit }) {
  const { data, loading, fetchMore } = useQuery(ProductCollectionsQuery, {
    fetchPolicy: "cache-and-network",
    variables: {
      productId,
      skip,
      limit,
    },
  });

  const count = data?.contents?.count || 0;
  const nodes = data?.contents?.nodes || [];

  if (nodes.length === 0) return null;

  const hasMore = nodes.length < count;
  const showLoadMore = hasMore && !loading;
  const nextPage = Math.ceil(nodes.length / limit);

  const onLoadMore = () => {
    ReactGA.event({
      category: "ProductCollections",
      action: "Fetch more collections",
      label: nextPage + " page",
    });

    fetchMore({
      variables: {
        skip: nextPage * limit,
      },
    });
  };

  return (
    <>
      {nodes.map((collection) => {
        return (
          <ProductContents
            key={collection.id}
            sort={LATEST}
            limit={24}
            collectionId={collection.id}
            title={collection.title}
            moreUrl={collection.siteUrl}
            // productId={productId}
          />
        );
      })}

      {showLoadMore && <LoadMore key={nextPage} onLoadMore={onLoadMore} />}
    </>
  );
}

ProductCollections.propTypes = {
  productId: PropTypes.string.isRequired,
  skip: PropTypes.number,
  limit: PropTypes.number,
};
