import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function useOauthResult() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const oauthConnect = searchParams.get("oauthConnect");
  const oauthLogin = searchParams.get("oauthLogin");

  useEffect(() => {
    if (typeof window === "undefined") return;
    if (!oauthConnect && !oauthLogin) return;

    // Очистим oauthConnect в адресной строке
    window.history.replaceState(null, document.title, window.location.pathname);

    if (window.opener) {
      window.opener.postMessage({ oauthConnect, oauthLogin });
      window.close();
    }
  }, [oauthConnect, oauthLogin]);

  return process.browser && window.opener && (oauthConnect || oauthLogin);
}
