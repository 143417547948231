import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { InView } from "react-intersection-observer";
import PageVisibility from "react-page-visibility";
import loadSDK from "core/utils/loadSDK";
import {
  createSponsorClick,
  createSponsorImpression,
  trackAdPixel,
  postMessage,
} from "core/utils/tracker";
import apm from "core/utils/apm";
import getAdUrl from "core/utils/getAdUrl";

const Root = styled("div")({
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  height: "100%",
  width: "100%",
  position: "absolute",
});

const Slot = styled("div")({
  position: "absolute",
  width: "100%",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  flex: 1,
  zIndex: 111000,
  pointerEvents: "none",
  backgroundColor: "black",
});

const StyledVideo = styled("video")({
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  height: "100%",
  width: "100%",
  position: "absolute",
});

const IMPRESSION = "IMPRESSION";
// const CREATIVEVIEW = "CREATIVEVIEW";
// const FULLSCREEN = "FULLSCREEN";
// const EXIT_FULLSCREEN = "EXIT_FULLSCREEN";
const ERROR = "ERROR";
const COMPLETE = "COMPLETE";
const CLICKTHROUGH = "CLICKTHROUGH";
const CLOSE = "CLOSE";
// const UNMUTE = "UNMUTE";
// const MUTE = "MUTE";
const SKIP = "SKIP";
const FIRSTQUARTILE = "FIRSTQUARTILE";
const MIDPOINT = "MIDPOINT";
const THIRDQUARTILE = "THIRDQUARTILE";
const NOBANNER_ERROR = "NOBANNER_ERROR";
const NETWORK_ERROR = "NETWORK_ERROR";
const NOT_FOUND = "NOT_FOUND";

const SDK_URL = "https://yandex.ru/ads/system/adsdk.js";

const creativeType = "Yandex SDK";

let adLoaderConfig = {
  partnerId: 1367052,
  category: 0,
  impId: 1,
};

if (process.env.NODE_ENV !== "production") {
  adLoaderConfig = {
    partnerId: 291193,
    category: 0,
  };
}

if (process.browser) {
  // TODO надо добавить продакшеновый домен для них
  if (window.location.hostname === "sportsru.sprinttyott.com") {
    adLoaderConfig = {
      partnerId: 509705,
      category: 0,
    };
  }
}

/* global ya */

const nobannerErrors = [
  "YANDEX_NO_AD_CUSTOM_MARKER",
  "NO_AD_SECTION",
  "VMAP_LOAD_TIMEOUT",
  "YANDEX_VAST_LOAD_TIMEOUT",
  "YANDEX_INVALID_VAST_XML",
];

export default function Yandex({
  vast,
  vastPlace,
  //   fullscreenTarget,
  onFinish,
  onStart,
  onError,
  muted,
  sportId,
  shareUrl,
}) {
  const slotRef = useRef();
  const videoRef = useRef();
  const emptyRef = useRef();
  const [adPlaybackController, setAdPlaybackController] = useState();
  const [adStore, setAdStore] = useState();

  useEffect(() => {
    loadSDK(SDK_URL, "yandex-ad-sdk")
      .then(() => ya.videoAd.loadModule("AdLoader"))
      .then((module) => {
        if (vast.url) {
          const { current } = emptyRef;

          adLoaderConfig.vastUrl = getAdUrl({
            url: vast.url,
            puid: vast.puid,
            sportId: sportId,
            shareUrl,
            width: current?.offsetWidth,
            height: current?.offsetHeight,
          });
        }

        return module.AdLoader.create(adLoaderConfig);
      })
      .then((adLoader) => adLoader.loadAd())
      .then(setAdStore)
      .catch((error) => {
        console.error(error);
        apm.captureError(error);

        if (
          error.message &&
          nobannerErrors.some((errorCode) => error.message.includes(errorCode))
        ) {
          createSponsorImpression(vast.token, creativeType, NOBANNER_ERROR);
        } else {
          createSponsorImpression(vast.token, creativeType, NETWORK_ERROR);
        }

        postMessage({
          eventName: NOT_FOUND,
          creative: "SponsorVideo",
          place: vastPlace,
        });

        onError(error);
      });
  }, [vast]);

  useEffect(() => {
    if (!adStore) return;

    const videoSlot = videoRef.current;
    const slot = slotRef.current;

    const { token, clickPixel, impressionPixel } = vast;

    const adPlaybackController = adStore.createPlaybackController(
      videoSlot,
      slot
    );

    adPlaybackController.subscribe("AdClickThru", function () {
      createSponsorClick(token);
      createSponsorImpression(token, creativeType, CLICKTHROUGH);
      postMessage({
        eventName: CLICKTHROUGH,
        creative: "SponsorVideo",
        place: vastPlace,
      });

      if (clickPixel) {
        trackAdPixel(clickPixel);
      }
    });

    adPlaybackController.subscribe("AdPodVideoFirstQuartile", function () {
      createSponsorImpression(token, creativeType, FIRSTQUARTILE);
      postMessage({
        eventName: FIRSTQUARTILE,
        creative: "SponsorVideo",
        place: vastPlace,
      });
    });

    adPlaybackController.subscribe("AdPodVideoMidpoint", function () {
      createSponsorImpression(token, creativeType, MIDPOINT);
      postMessage({
        eventName: MIDPOINT,
        creative: "SponsorVideo",
        place: vastPlace,
      });
    });

    adPlaybackController.subscribe("AdPodVideoThirdQuartile", function () {
      createSponsorImpression(token, creativeType, THIRDQUARTILE);
      postMessage({
        eventName: THIRDQUARTILE,
        creative: "SponsorVideo",
        place: vastPlace,
      });
    });

    adPlaybackController.subscribe("AdPodComplete", function () {
      createSponsorImpression(token, creativeType, COMPLETE);
      postMessage({
        eventName: COMPLETE,
        creative: "SponsorVideo",
        place: vastPlace,
      });
    });

    adPlaybackController.subscribe("AdPodSkipped", function () {
      createSponsorImpression(token, creativeType, SKIP);
      postMessage({
        eventName: SKIP,
        creative: "SponsorVideo",
        place: vastPlace,
      });
    });

    adPlaybackController.subscribe("AdPodImpression", function () {
      createSponsorImpression(token, creativeType, IMPRESSION);
      postMessage({
        eventName: IMPRESSION,
        creative: "SponsorVideo",
        place: vastPlace,
      });
      if (impressionPixel) {
        trackAdPixel(impressionPixel);
      }
    });

    adPlaybackController.subscribe("AdPodError", function (error) {
      console.log("AdPodError", error);
      createSponsorImpression(token, creativeType, ERROR);
      onError(error);
    });

    adPlaybackController.subscribe("AdPodClose", function () {
      createSponsorImpression(token, creativeType, CLOSE);
      postMessage({
        eventName: CLOSE,
        creative: "SponsorVideo",
        place: vastPlace,
      });
    });

    adPlaybackController.subscribe("AdStarted", function () {
      // console.log("Ad started playing");
      onStart();

      if (muted) {
        if (adPlaybackController.getAdVolume() !== 0) {
          adPlaybackController.setAdVolume(0);
        }
      }
    });

    adPlaybackController.subscribe("AdStopped", function () {
      // console.log("Ad stopped playing");
      onFinish();
      setAdStore();
    });

    // Запускаем проигрывание рекламы
    adPlaybackController.playAd();

    if (muted) {
      adPlaybackController.setAdVolume(0);
    }

    setAdPlaybackController(adPlaybackController);
  }, [adStore]);

  if (!adStore) {
    return <div ref={emptyRef} style={{ width: "100%", height: "100%" }} />;
  }

  const onChangeVisibility = (isVisible) => {
    if (!adPlaybackController) return;
    if (isVisible) {
      adPlaybackController.resumeAd();
    } else {
      adPlaybackController.pauseAd();
    }
  };

  const onChangeInView = (inView) => {
    if (!adPlaybackController) return;
    if (inView) {
      adPlaybackController.resumeAd();
    } else {
      adPlaybackController.pauseAd();
    }
  };

  return (
    <PageVisibility onChange={onChangeVisibility}>
      <InView as={Root} threshold={0.5} onChange={onChangeInView}>
        <Slot ref={slotRef}>
          <StyledVideo ref={videoRef} />
        </Slot>
      </InView>
    </PageVisibility>
  );
}

Yandex.propTypes = {
  onStart: PropTypes.func,
  onFinish: PropTypes.func,
  onUpdate: PropTypes.func,
  onChangeMute: PropTypes.func,
  onError: PropTypes.func,
  fullscreenTarget: PropTypes.string,
  vastPlace: PropTypes.string,
  muted: PropTypes.bool,
  vast: PropTypes.shape({
    url: PropTypes.string,
    token: PropTypes.string.isRequired,
    clickPixel: PropTypes.string,
    impressionPixel: PropTypes.string,
    puid: PropTypes.object,
  }).isRequired,
  sportId: PropTypes.string,
  shareUrl: PropTypes.string,
};
