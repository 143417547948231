import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import DialogContent from "@mui/material/DialogContent";
import DialogHeader from "core/components/DialogHeader";
import PaymentProvidersList from "arena/components/PaymentProvidersList";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(1),
  backgroundColor: "#101010",
}));

/**
 * ВНИМАНИЕ - этот экран сейчас не достигается, но когда будет больше провайдеров, надо будет тут всё починить
 */
export default function ChooseProvider({ onClose, state, dispatch }) {
  const { t } = useTranslation("arena");

  const { providers } = state;
  const onSelect = (provider) => {
    dispatch({ type: "create_payment", provider, providers });

    ReactGA.event({
      category: "ChooseProvider",
      action: "Click to provider",
      label: provider.name,
    });
  };

  return (
    <>
      <DialogHeader
        onClose={onClose}
        title={t("productDialog.chooseProvider.title")}
      />
      <StyledDialogContent>
        <PaymentProvidersList providers={providers} onSelect={onSelect} />
      </StyledDialogContent>
    </>
  );
}

ChooseProvider.propTypes = {
  dispatch: PropTypes.func.isRequired,
  state: PropTypes.object,
  onClose: PropTypes.func,
};
