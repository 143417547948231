import React from "react";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { useTranslation } from "react-i18next";

export default function ToggleMuteButton({ muted, ...props }) {
  const { t } = useTranslation("core");

  return (
    <Tooltip title={muted ? t("unmute") : t("mute")}>
      <IconButton {...props} size="large">
        {muted ? <VolumeOffIcon /> : <VolumeUpIcon />}
      </IconButton>
    </Tooltip>
  );
}

ToggleMuteButton.propTypes = {
  muted: PropTypes.bool,
};
