import React, { useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  createSponsorClick,
  createSponsorImpression,
  trackAdPixel,
  postMessage,
} from "core/utils/tracker";
import { InView } from "react-intersection-observer";
import PageVisibility from "react-page-visibility";
import BigPlayButton from "core/components/BigPlayButton";
import FullscreenButton from "core/components/FullscreenButton";
import ToggleMuteButton from "core/components/ToggleMuteButton";
import SkipAdButton from "core/components/SkipAdButton";
import DisableAdButton from "core/components/DisableAdButton";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { nanoid } from "nanoid";

const StyledVideo = styled("video")({
  height: "100%",
  width: "100%",
  position: "absolute",
});

const CloseButton = styled(IconButton)({
  position: "absolute",
  right: 10,
  top: 10,
  zIndex: 100000,
});

const Controls = styled("div")({
  position: "absolute",
  right: 10,
  bottom: 10,
  left: 10,
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  zIndex: 100000,
});

const AdLabel = styled(Typography)({
  backgroundColor: "rgba(0, 0, 0, 0.2)",
  borderRadius: 4,
  padding: "5px 10px",
  position: "absolute",
  left: 10,
  top: 10,
  zIndex: 100000,
});

const Root = styled("div")(() => ({
  position: "absolute",
  height: "100%",
  width: "100%",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  flex: 1,
  zIndex: 111000,
  background: "black",
}));

const DEFAULT_SKIP_TIME = 5; // дефолтное время для скипа (в секундах)

const Q1080P = "Q1080P";
const Q720P = "Q720P";
const Q480P = "Q480P";
const Q360P = "Q360P";
const ORIGINAL = "ORIGINAL";

const PAUSED = "paused";
const PLAYING = "playing";

const START = "START";
const IMPRESSION = "IMPRESSION";
const CREATIVEVIEW = "CREATIVEVIEW";
const FULLSCREEN = "FULLSCREEN";
const EXIT_FULLSCREEN = "EXIT_FULLSCREEN";
const ERROR = "ERROR";
const COMPLETE = "COMPLETE";
const CLICKTHROUGH = "CLICKTHROUGH";
const CLOSE = "CLOSE";
const UNMUTE = "UNMUTE";
const MUTE = "MUTE";
const SKIP = "SKIP";
const FIRSTQUARTILE = "FIRSTQUARTILE";
const MIDPOINT = "MIDPOINT";
const THIRDQUARTILE = "THIRDQUARTILE";

const creativeType = "Video";

function findVideoUrl(formats, { smUp, mdUp, lgUp }) {
  const indexByFormat = formats.reduce((all, item) => {
    all[item.preset] = item.url;
    return all;
  }, {});

  if (lgUp) {
    if (indexByFormat[Q1080P]) {
      return indexByFormat[Q1080P];
    }
  }

  if (mdUp) {
    if (indexByFormat[Q720P]) {
      return indexByFormat[Q720P];
    }
  }

  if (smUp) {
    if (indexByFormat[Q480P]) {
      return indexByFormat[Q480P];
    }
  }

  if (indexByFormat[Q360P]) {
    return indexByFormat[Q360P];
  }

  return indexByFormat[ORIGINAL];
}

export default function SponsorVideo({
  isDisableable,
  videoPlace,
  fullscreenTarget,
  onError,
  onFinish,
  onStart,
  muted: defaultMuted,
  video,
}) {
  const { t } = useTranslation("core");

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));

  const videoRef = useRef();

  const [isVisible, setVisible] = useState(true);
  const [played, setPlayed] = useState(false);

  const [muted, setMuted] = useState(defaultMuted);
  const [status, setStatus] = useState(PAUSED);
  const [currentTime, setCurrentTime] = useState(0);
  const [trackedEvents, setTrackedEvents] = useState([]);

  if (!isVisible) return null;

  const {
    id,
    formats,
    link,
    token,
    adLabel,
    isCloseable,
    isSkippable,
    isClickable,
    clickPixel,
    impressionPixel,
  } = video;

  const triggerPostMessage = (eventName) => {
    return postMessage({
      eventName,
      creative: "SponsorVideo",
      place: videoPlace,
    });
  };

  const saveImpression = (event) => {
    triggerPostMessage(event);
    return createSponsorImpression(token, creativeType, event);
  };

  const muteVideo = (value) => {
    if (!videoRef.current) return;

    videoRef.current.muted = value;

    setMuted(value);
  };

  const playVideo = () => {
    if (!videoRef.current) return;

    const promise = videoRef.current.play();

    if (promise !== undefined) {
      promise.catch((error) => {
        console.dir(error);
        console.log("Не удалось воспроизвести видеорекламу со звуком");

        if (!videoRef.current) return;

        muteVideo(true);
        setStatus(PLAYING);

        videoRef.current.play();
      });
    }
  };

  const pauseVideo = () => {
    if (!videoRef.current) return;

    videoRef.current.pause();

    setStatus(PAUSED);
  };

  const onChangeVisibility = (isVisible) => {
    if (isVisible) {
      if (status !== PLAYING) {
        playVideo();
      }
    } else {
      if (status !== PAUSED) {
        pauseVideo();
      }
    }
  };

  const onChangeInView = (inView) => {
    if (inView) {
      if (status !== PLAYING) {
        playVideo();
      }
    } else {
      if (status !== PAUSED) {
        pauseVideo();
      }
    }
  };

  const onClickPlay = () => {
    playVideo();
  };

  const onClickToggleMute = () => {
    if (muted) {
      muteVideo(false);
      saveImpression(UNMUTE);
    } else {
      muteVideo(true);
      saveImpression(MUTE);
    }
  };

  const onFullScreenChange = (isFullscreen) => {
    if (isFullscreen) {
      saveImpression(FULLSCREEN);
    } else {
      saveImpression(EXIT_FULLSCREEN);
    }
  };

  const onClickClose = () => {
    saveImpression(CLOSE);
    onFinish();
    setVisible(false);
  };

  const onClickSkip = () => {
    saveImpression(SKIP);
    onFinish();
    setVisible(false);
  };

  const onVideoError = () => {
    saveImpression(ERROR);
    onError();
    setVisible(false);
  };

  const onVideoEnded = () => {
    saveImpression(COMPLETE);
    onFinish();
    setVisible(false);
  };

  const onVideoClick = async (event) => {
    event.preventDefault();

    if (!isClickable) return;

    saveImpression(CLICKTHROUGH);

    if (clickPixel) {
      trackAdPixel(clickPixel);
    }

    const clickId = nanoid();
    const url = link.replace("{clickId}", clickId);

    window.open(url, "_blank");

    await createSponsorClick(token, clickId);

    pauseVideo();
  };

  const onVideoDurationChange = () => {
    //
  };

  const onVideoVolumeChange = () => {
    //
  };

  const onVideoPause = () => {
    setStatus(PAUSED);
  };

  const onVideoTimeUpdate = (e) => {
    const { currentTime, duration } = e.target;

    setCurrentTime(currentTime);

    const percent = (100 / duration) * currentTime;

    if (percent > 75 && !trackedEvents.includes(THIRDQUARTILE)) {
      saveImpression(THIRDQUARTILE);
      setTrackedEvents((trackedEvents) => trackedEvents.concat(THIRDQUARTILE));
    } else if (percent > 50 && !trackedEvents.includes(MIDPOINT)) {
      saveImpression(MIDPOINT);
      setTrackedEvents((trackedEvents) => trackedEvents.concat(MIDPOINT));
    } else if (percent > 25 && !trackedEvents.includes(FIRSTQUARTILE)) {
      saveImpression(FIRSTQUARTILE);
      setTrackedEvents((trackedEvents) => trackedEvents.concat(FIRSTQUARTILE));
    }
  };

  const onVideoCanPlay = () => {
    if (status === PLAYING) {
      playVideo();
    } else {
      saveImpression(CREATIVEVIEW);
    }
  };

  const onPlaying = () => {
    if (status !== PLAYING) {
      setStatus(PLAYING);
    }

    if (!played) {
      setPlayed(true);
      saveImpression(START);

      saveImpression(IMPRESSION);

      if (impressionPixel) {
        trackAdPixel(impressionPixel);
      }

      onStart();
    }
  };

  const src = findVideoUrl(formats, { smUp, mdUp, lgUp });

  if (!src) return null;

  const videoElement = (
    <StyledVideo
      key={id}
      src={src}
      playsInline
      muted={muted}
      ref={videoRef}
      onError={onVideoError}
      onEnded={onVideoEnded}
      onClick={onVideoClick}
      onDurationChange={onVideoDurationChange}
      onVolumeChange={onVideoVolumeChange}
      onPause={onVideoPause}
      onTimeUpdate={onVideoTimeUpdate}
      onCanPlay={onVideoCanPlay}
      onPlaying={onPlaying}
    />
  );

  const skipTimeout = Math.trunc(DEFAULT_SKIP_TIME - currentTime); // кол-во секунд через которое можно будет скипнуть
  const canSkip = skipTimeout < 0;

  return (
    <PageVisibility onChange={onChangeVisibility}>
      <InView as={Root} threshold={0.5} onChange={onChangeInView}>
        {videoElement}

        {isCloseable && (
          <CloseButton onClick={onClickClose} size="large">
            <CloseIcon />
          </CloseButton>
        )}

        {status !== PLAYING && (
          <BigPlayButton
            onClick={onClickPlay}
            videoState={{
              status,
            }}
          />
        )}

        <Controls>
          {isDisableable ? (
            <DisableAdButton href="https://wrestlingtv.ru/to/dpwZYj" />
          ) : (
            isSkippable && (
              <SkipAdButton
                onClick={onClickSkip}
                canSkip={canSkip}
                skipTimeout={skipTimeout}
              />
            )
          )}

          <ToggleMuteButton muted={muted} onClick={onClickToggleMute} />

          <FullscreenButton
            target={fullscreenTarget}
            onFullScreenChange={onFullScreenChange}
            videoElement={videoElement}
          />
        </Controls>

        {adLabel && <AdLabel variant="body1">{t("adv.ad")}</AdLabel>}
      </InView>
    </PageVisibility>
  );
}

SponsorVideo.defaultProps = {
  onError() {},
  onFinish() {},
  onStart() {},
  onChatAdv() {},
  onCompleted() {},
};

SponsorVideo.propTypes = {
  isDisableable: PropTypes.bool,
  videoPlace: PropTypes.string,
  fullscreenTarget: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  onStart: PropTypes.func.isRequired,
  muted: PropTypes.bool,
  video: PropTypes.shape({
    id: PropTypes.string.isRequired,
    formats: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired,
      })
    ),
    link: PropTypes.string,
    token: PropTypes.string.isRequired,
    adLabel: PropTypes.bool,
    isCloseable: PropTypes.bool,
    isSkippable: PropTypes.bool,
    isClickable: PropTypes.bool,
    clickPixel: PropTypes.string,
    impressionPixel: PropTypes.string,
  }),
};
