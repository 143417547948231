import getUserAgent from "core/utils/getUserAgent";

const { userDeviceType, userDeviceOs, browser } = getUserAgent();

const isMobile = userDeviceType === "Mobile";
const isAndroid = userDeviceOs === "Android";
const isFirefox = browser?.name === "Firefox";
const isSafari = browser?.name === "Mobile Safari";
const isIOS = userDeviceOs === "iOS";
const isOpera = browser?.name === "Opera";
const browserVersion = browser?.version;

export const platforms = {
  NATIVE: "native", // currently: Chrome, Edge mobile, Samsung internet, Yandex etc...
  FIREFOX: "firefox",
  FIREFOX_NEW: "firefox_new", // above version 79
  OPERA: "opera",
  IDEVICE: "idevice",
  OTHER: "other", // don't know, so will do nothing
};

export function getPlatform() {
  if (typeof window !== "undefined") {
    if ("BeforeInstallPromptEvent" in window) {
      return platforms.NATIVE;
    }
  }

  if (isMobile && isAndroid && isFirefox && +browserVersion >= 79) {
    return platforms.FIREFOX_NEW;
  }

  if (isMobile && isAndroid && isFirefox) {
    return platforms.FIREFOX;
  }

  if (isOpera && isAndroid && isMobile) {
    return platforms.OPERA;
  }

  if (isSafari && isIOS && isMobile) {
    return platforms.IDEVICE;
  }

  return platforms.OTHER;
}
