import React from "react";
import PropTypes from "prop-types";
import { InView } from "react-intersection-observer";
import { styled } from "@mui/material/styles";

const Root = styled("div", {
  shouldForwardProp: (prop) =>
    !["inStreamBannerHeight", "cornerBannerHeight"].includes(prop),
})(({ inStreamBannerHeight, cornerBannerHeight }) => ({
  display: "block",
  position: "absolute",
  left: cornerBannerHeight > 0 ? "10%" : 0,
  right: cornerBannerHeight > 0 ? "10%" : 0,
  top: cornerBannerHeight > 0 ? "20%" : 0,
  bottom: inStreamBannerHeight > 0 ? inStreamBannerHeight : 0,
  width: "auto",
  height: "auto",
  overflow: "hidden",
  zIndex: 2,
  transition: "all 1s linear",
}));

export default function PlayerInView({
  cornerBannerHeight,
  inStreamBannerHeight,
  onChange,
  children,
}) {
  return (
    <InView
      as={Root}
      onChange={onChange}
      threshold={0.5}
      inStreamBannerHeight={inStreamBannerHeight}
      cornerBannerHeight={cornerBannerHeight}
    >
      {children}
    </InView>
  );
}

PlayerInView.propTypes = {
  cornerBannerHeight: PropTypes.number,
  inStreamBannerHeight: PropTypes.number,
  onChange: PropTypes.func,
  children: PropTypes.node,
};
