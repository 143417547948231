import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

export default function SettingsHeader({ onClose, title, action, tabs }) {
  const { t } = useTranslation("arena");

  return (
    <AppBar position="static" color="default">
      <Toolbar>
        <Tooltip title={t("close")} arrow>
          <IconButton onClick={onClose} edge="start" size="large">
            <CloseIcon />
          </IconButton>
        </Tooltip>

        <Typography variant="h5" sx={{ flex: 1, marginLeft: 4 }}>
          {title}
        </Typography>

        {action}
      </Toolbar>

      {tabs}
    </AppBar>
  );
}

SettingsHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  action: PropTypes.node,
  tabs: PropTypes.node,
};
