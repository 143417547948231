import { useState, useCallback } from "react";

export default function useCarousel() {
  const [step, setStep] = useState(0);
  const [prev, setPrev] = useState(false);
  const [next, setNext] = useState(false);

  const rootRef = useCallback(
    (node) => {
      if (!node) return;

      const { scrollWidth, clientWidth } = node;

      const left = step * clientWidth;
      const right = left + clientWidth;

      if (node.scroll) {
        node.scroll({
          top: 0,
          left,
          behavior: "smooth",
        });
      } else {
        node.scrollTo(0, left);
      }

      if (left > 0) {
        setPrev(true);
      } else {
        setPrev(false);
      }

      if (right < scrollWidth) {
        setNext(true);
      } else {
        setNext(false);
      }
    },
    [step]
  );

  const onScroll = useCallback((event) => {
    const { scrollWidth, scrollLeft, clientWidth } = event.target;
    const scrollRight = scrollLeft + clientWidth;

    if (scrollLeft > 0) {
      setPrev(true);
    } else {
      setPrev(false);
    }

    if (scrollRight === scrollWidth) {
      setNext(false);
    } else {
      setNext(true);
    }
  }, []);

  const onClickPrev = () => {
    setStep((step) => step - 1);
  };

  const onClickNext = () => {
    setStep((step) => step + 1);
  };

  return { step, prev, next, rootRef, onScroll, onClickPrev, onClickNext };
}
