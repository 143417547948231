import React, { useState } from "react";
import PropTypes from "prop-types";
import CardStep from "./CardStep";
import PollStep from "./PollStep";

const components = {
  CARD: CardStep,
  POLL: PollStep,
};

function OnboardingSteps({ steps, onCompleted }) {
  const [stepIndex, setStepIndex] = useState(0);

  const onNext = () => {
    setStepIndex((stepIndex) => {
      const newIndex = stepIndex + 1;
      if (steps[newIndex]) {
        return newIndex;
      }
      return onCompleted();
    });
  };

  const step = steps[stepIndex];

  if (!step) return null;

  const Component = components[step.type];

  return (
    <Component
      step={step}
      key={stepIndex}
      title={`${stepIndex + 1} / ${steps.length}`}
      onNext={onNext}
      last={steps.length === stepIndex + 1}
    />
  );
}

OnboardingSteps.propTypes = {
  steps: PropTypes.array.isRequired,
  onCompleted: PropTypes.func.isRequired,
};

export default OnboardingSteps;
