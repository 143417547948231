import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";

export default function PageHelmet({ arena, page }) {
  const title = page.title;
  const description = page.description || arena.description;
  const image = page?.image?.url || arena?.logo?.url;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="twitter:title" content={title} />
      <meta property="og:title" content={title} />

      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:card" content="summary_large_image" />

      {image && <meta property="og:image" content={image} />}
      {image && <link rel="image_src" href={image} />}
      {image && <meta name="twitter:image" content={image} />}
      {image && <meta property="vk:image" content={image} />}
    </Helmet>
  );
}

PageHelmet.propTypes = {
  page: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    image: PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
    description: PropTypes.string,
  }),
  arena: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    logo: PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  }).isRequired,
};
