import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";

export default function ProductHelmet({ product }) {
  const image = product?.preview?.url;

  return (
    <Helmet>
      <title>{product.title}</title>

      <meta name="twitter:title" content={product.title} />
      <meta property="og:title" content={product.title} />

      {product.description && (
        <meta name="description" content={product.description} />
      )}

      {product.description && (
        <meta property="og:description" content={product.description} />
      )}
      {product.description && (
        <meta name="twitter:description" content={product.description} />
      )}
      <meta name="twitter:card" content="summary_large_image" />
      {image && <meta property="og:image" content={image} />}
      {image && <link rel="image_src" href={image} />}
      {image && <meta name="twitter:image" content={image} />}
      {image && <meta property="vk:image" content={image} />}
    </Helmet>
  );
}

ProductHelmet.propTypes = {
  product: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    shareUrl: PropTypes.string.isRequired,
    preview: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
  }),
};
