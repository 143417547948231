import { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import HttpContext from "core/utils/httpContext";

export default function ArenaRedirect({ url }) {
  let context = useContext(HttpContext);

  useEffect(() => {
    if (!process.browser) return;

    document.location.href = url;
  }, [url]);

  if (!url) return null;

  context.url = url;

  return null;
}

ArenaRedirect.propTypes = {
  url: PropTypes.string,
};
