import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { CardActionArea } from "@mui/material";
import ReactGA from "react-ga4";
import { getLogoSrcSet } from "core/utils/getSrcSet";

const PREFIX = "Item";

const classes = {
  root: `${PREFIX}-root`,
  actionArea: `${PREFIX}-actionArea`,
  avatar: `${PREFIX}-avatar`,
  img: `${PREFIX}-img`,
  title: `${PREFIX}-title`,
};

const DEFAULT = "DEFAULT";
const HIGH = "HIGH";
const MAXRES = "MAXRES";
const MEDIUM = "MEDIUM";

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: "rgba(255, 255, 255, 0.03)",

  [`&.${classes.root}`]: {
    width: "100%",
  },

  [`& .${classes.actionArea}`]: {
    // padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  [`& .${classes.avatar}`]: {
    width: 160,
    height: 90,
    [theme.breakpoints.up("sm")]: {
      width: 240,
      height: 135,
    },
    [theme.breakpoints.up("md")]: {
      width: 320,
      height: 180,
    },
  },

  [`& .${classes.img}`]: {
    objectFit: "contain",
  },
}));

const Title = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(1),

  width: 160,

  [theme.breakpoints.up("sm")]: {
    width: 240,
    paddingTop: theme.spacing(2),
  },

  [theme.breakpoints.up("md")]: {
    width: 320,
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: "1.1rem",
  fontWeight: 500,
  width: "100%",
  color: "inherit",
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.4rem",
  },
}));

export default function Item({ logo }) {
  const onClick = () => {
    ReactGA.event({
      category: "ArenaPartners",
      action: "Click to logo",
      label: logo.title,
    });
  };

  return (
    <>
      <StyledCard className={classes.root}>
        <CardActionArea
          className={classes.actionArea}
          href={logo.url}
          disabled={!logo.url}
          target={logo.openInNewWindow ? "_blank" : ""}
          rel="noopener noreferrer"
          onClick={onClick}
        >
          <Avatar
            variant="rounded"
            src={logo.src}
            srcSet={getLogoSrcSet(logo.thumbnails)}
            alt={logo.title || "partner logo"}
            classes={{ root: classes.avatar, img: classes.img }}
          />
        </CardActionArea>
      </StyledCard>
      {logo.title && (
        <Title>
          <StyledTypography
            variant="h6"
            align={logo.align}
            component="a"
            href={logo.url}
            disabled={!logo.url}
            target={logo.openInNewWindow ? "_blank" : ""}
            rel="noopener noreferrer"
            onClick={onClick}
          >
            {logo.title}
          </StyledTypography>
        </Title>
      )}
    </>
  );
}

Item.propTypes = {
  logo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    align: PropTypes.string,
    url: PropTypes.string,
    src: PropTypes.string.isRequired,
    thumbnails: PropTypes.arrayOf(
      PropTypes.shape({
        format: PropTypes.oneOf([DEFAULT, MEDIUM, HIGH, MAXRES]).isRequired,
        url: PropTypes.string.isRequired,
        webpUrl: PropTypes.string,
      })
    ),
    openInNewWindow: PropTypes.bool,
  }),
};
