import React from "react";
import PropTypes from "prop-types";
import PauseIcon from "@mui/icons-material/Pause";
import { useTranslation } from "react-i18next";
import PlayerFooterButton from "core/components/PlayerFooterButton";

function PauseButton({ onClick, videoState }) {
  const { t } = useTranslation("core");

  if (!videoState) return null;
  if (videoState.status === "paused") return null;

  return (
    <PlayerFooterButton title={t("pause")} onClick={onClick}>
      <PauseIcon fontSize="inherit" />
    </PlayerFooterButton>
  );
}

PauseButton.propTypes = {
  onClick: PropTypes.func,
  videoState: PropTypes.object,
};

export default PauseButton;
