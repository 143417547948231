import React, { Fragment, useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import { useMutation } from "@apollo/client";
import { SubmitFormWidgetMutation } from "core/mutations";
import { useTranslation } from "react-i18next";

const PREFIX = "FormWidget";

const classes = {
  content: `${PREFIX}-content`,
  form: `${PREFIX}-form`,
  close: `${PREFIX}-close`,
  title: `${PREFIX}-title`,
  subtitle: `${PREFIX}-subtitle`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.content}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  [`& .${classes.form}`]: {
    maxWidth: 420,
  },

  [`& .${classes.close}`]: {
    zIndex: 1,
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },

  [`& .${classes.title}`]: {
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.subtitle}`]: {
    marginBottom: theme.spacing(2),
  },
}));

export default function FormWidget({
  open,
  onClose,
  color,
  textColor,
  title,
  subtitle,
  inputLabel,
  submitText,
  inputType,
  widgetId,
  preview,
  termsOfServiceUrl,
  privacyPolicyUrl,
}) {
  const { t } = useTranslation("core");
  const [value, setValue] = useState(true);
  const [submitForm, submitFormResult] = useMutation(SubmitFormWidgetMutation, {
    variables: { value, widgetId },
  });

  const helperText = (
    <Fragment>
      {t("formWidget.accept1")}
      <Link
        href={termsOfServiceUrl}
        target="_blank"
        color="textPrimary"
        underline="hover"
      >
        {t("formWidget.accept2")}
      </Link>
      {t("formWidget.accept3")}
      <Link
        href={privacyPolicyUrl}
        target="_blank"
        color="textPrimary"
        underline="hover"
      >
        {t("formWidget.accept4")}
      </Link>
    </Fragment>
  );

  const onSubmit = (event) => {
    event.preventDefault();
    submitForm();
  };

  return (
    <StyledDialog open={open} onClose={onClose} fullScreen>
      <Tooltip title={t("close")} arrow>
        <IconButton onClick={onClose} className={classes.close} size="large">
          <CloseIcon />
        </IconButton>
      </Tooltip>
      <DialogContent className={classes.content}>
        {submitFormResult.data ? (
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <Typography variant="h6" align="center">
                {submitFormResult.data.submitFormWidget}
              </Typography>
            </Grid>
            <Grid item xs={12} container justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                style={{ backgroundColor: color, color: textColor }}
                onClick={onClose}
              >
                {t("close")}
              </Button>
            </Grid>
          </Grid>
        ) : (
          <form className={classes.form} onSubmit={onSubmit}>
            <Typography
              variant="h6"
              color="textPrimary"
              gutterBottom
              align="center"
              className={classes.title}
            >
              {title}
            </Typography>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              gutterBottom
              align="center"
              className={classes.subtitle}
            >
              {subtitle}
            </Typography>
            <TextField
              autoFocus
              required
              variant="outlined"
              label={inputLabel}
              helperText={helperText}
              type={inputType}
              fullWidth
              onChange={(e) => setValue(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      style={{ backgroundColor: color, color: textColor }}
                      disabled={submitFormResult.loading || preview}
                    >
                      {submitText}
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </form>
        )}
      </DialogContent>
    </StyledDialog>
  );
}

FormWidget.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  inputLabel: PropTypes.string.isRequired,
  submitText: PropTypes.string.isRequired,
  inputType: PropTypes.string.isRequired,
  widgetId: PropTypes.string.isRequired,
  preview: PropTypes.bool,
  termsOfServiceUrl: PropTypes.string.isRequired,
  privacyPolicyUrl: PropTypes.string.isRequired,
};
