import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";

function getListItemProps(subItem) {
  if (subItem.path.startsWith("/")) {
    return {
      component: Link,
      to: subItem.path,
    };
  }

  return {
    component: "a",
    href: subItem.path,
  };
}

export default function MenuItemDialogItem({ item, onClick }) {
  return (
    <Box sx={{ overflow: "hidden" }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ pl: 2 }}>
            {item.title}
          </Typography>
        </Grid>

        {item.subItems.map((subItem) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={subItem.id}>
            <ListItem disablePadding>
              <ListItemButton {...getListItemProps(subItem)} onClick={onClick}>
                <ListItemAvatar>
                  <Avatar src={subItem?.icon?.url}>{subItem?.title[0]}</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={subItem.title}
                  secondary={subItem.subtitle}
                />
              </ListItemButton>
            </ListItem>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

MenuItemDialogItem.propTypes = {
  onClick: PropTypes.func,
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    subItems: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string,
        subtitle: PropTypes.string,
        icon: PropTypes.shape({
          url: PropTypes.string,
        }),
      })
    ),
  }),
};
