import React from "react";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import ReactGA from "react-ga4";
import { createTheme } from "core/utils/theme";
import { useQuery } from "@apollo/client";
import { ArenaQuery } from "core/queries";
import LoadingScreen from "core/components/LoadingScreen";
import ErrorScreen from "core/components/ErrorScreen";
import ViewerProvider from "core/components/ViewerProvider";
import ArenaProvider from "core/components/ArenaProvider";
import AppRoutes from "arena/components/AppRoutes";
import AppHelmet from "arena/components/AppHelmet";
import CookieBanner from "arena/components/CookieBanner";
import CastProvider from "core/components/CastProvider";
import RetargetPixels from "core/components/RetargetPixels";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const anchorOrigin = {
  vertical: "top",
  horizontal: "center",
};

let initialized = false;

function initializeGa(arena) {
  if (!arena) return;
  if (!arena.gaId) return;
  if (initialized) return;
  if (!process.browser) return;

  ReactGA.initialize(arena.gaId);
  initialized = true;
}

export default function App() {
  const { data, loading, error } = useQuery(ArenaQuery);

  initializeGa(data?.arena);

  if (loading) return <LoadingScreen />;
  if (error) return <ErrorScreen error={error} />;

  const { arena } = data;

  const theme = createTheme(arena?.theme);

  if (!arena) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ErrorScreen title="Arena not found" code={404} />
      </ThemeProvider>
    );
  }

  if (!arena.available) {
    if (!arena.published) {
      return (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ErrorScreen icon={AccessTimeIcon} title="Coming soon" code={404} />
        </ThemeProvider>
      );
    }

    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ErrorScreen title="Arena not available" code={404} />
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppHelmet arena={arena} />
      <SnackbarProvider
        maxSnack={1}
        anchorOrigin={anchorOrigin}
        autoHideDuration={2000}
      >
        <ArenaProvider value={arena}>
          <ViewerProvider useViewerLanguage={arena.multilingual}>
            <CastProvider>
              <AppRoutes />
              <RetargetPixels eventType="PageView" />
            </CastProvider>
            <CookieBanner arena={arena} />
          </ViewerProvider>
        </ArenaProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}
