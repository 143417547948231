import React from "react";
import { useQuery, gql } from "@apollo/client";
import DocumentBody from "./DocumentBody";
import Box from "@mui/material/Box";
import { Helmet } from "react-helmet-async";
import LoadingScreen from "core/components/LoadingScreen";
import ErrorScreen from "core/components/ErrorScreen";
import NoMatchScreen from "arena/components/NoMatchScreen";
import { useParams } from "react-router-dom";

export default function Document() {
  const { slug } = useParams();
  const { data, loading, error } = useQuery(DocumentQuery, {
    variables: { slug },
  });

  if (loading) return <LoadingScreen />;
  if (error) return <ErrorScreen error={error} />;
  if (!data?.document) {
    return <NoMatchScreen />;
  }

  return (
    <Box sx={{ p: 2, pt: 5, pb: 10 }}>
      <Helmet>
        <title>{data.document.title}</title>
      </Helmet>
      <Box sx={{ maxWidth: 800, margin: "auto" }}>
        <DocumentBody document={data.document} />
      </Box>
    </Box>
  );
}

const DocumentQuery = gql`
  query DocumentQuery($slug: String!) {
    document(slug: $slug) {
      id
      slug
      title
      text
      version
      createdAt
      versions {
        id
        slug
        title
        version
        createdAt
      }
    }
  }
`;
