import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { parseFps, computeSpriteIndex } from "core/utils/previews";

const PREFIX = "VideoPreview";

const classes = {
  root: `${PREFIX}-root`,
  image: `${PREFIX}-image`,
};

const Root = styled("div")({
  position: "absolute",
  zIndex: "1",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
  overflow: "hidden",

  [`& .${classes.image}`]: {
    width: "100%",
  },
});

export default function VideoPreview({
  visible = false,
  videoPreviews = null,
  seekValue = 0,
}) {
  if (!visible) return null;
  if (!videoPreviews) return null;

  const videoPreview = videoPreviews.high || videoPreviews.low;

  if (!videoPreview) return null;

  const fps = parseFps(videoPreview.fps);

  if (!fps) return null;

  const spriteIndex = computeSpriteIndex(
    seekValue,
    fps,
    videoPreview.spriteSize
  );
  const aspectRatio = videoPreview.width / videoPreview.height;
  const marginPercent = (-100 / aspectRatio) * spriteIndex.pic;

  return (
    <Root className={classes.root}>
      {videoPreview.files.map((url, idx) => (
        <img
          key={url}
          src={url}
          className={classes.image}
          style={
            idx === spriteIndex.file
              ? {
                  marginTop: `${marginPercent}%`,
                }
              : {
                  display: "none",
                }
          }
        />
      ))}
    </Root>
  );
}

VideoPreview.propTypes = {
  visible: PropTypes.bool,
  videoPreviews: PropTypes.shape({
    low: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
      spriteSize: PropTypes.number.isRequired,
      fps: PropTypes.string.isRequired,
      files: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
    high: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
      spriteSize: PropTypes.number.isRequired,
      fps: PropTypes.string.isRequired,
      files: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
  }),
  seekValue: PropTypes.number,
};
