import getReferer from "core/utils/getReferer";
import LocalStorage from "core/utils/localstorage";

const ls = new LocalStorage(`apollo`);

function replacePlaceholder(str, placeholders) {
  if (str && placeholders) {
    return str.replace(/{\w+}/gim, (match) => placeholders[match] || match);
  }
}
export default function getAdUrl({
  url,
  duration,
  puid = {},
  sportId,
  shareUrl,
  width,
  height,
}) {
  const referer = getReferer();
  const playerDomain = new URL(referer).hostname;

  const cacheBuster =
    Math.floor(new Date().getTime() / 1000) +
    Math.floor(Math.random() * 214748364);

  const random = Math.floor(Math.random() * 100000000);
  const sessionId =
    Math.floor(new Date().getTime() / 1000) +
    Math.floor(Math.random() * 214748364);

  const viewerId = ls.getItem("viewerId");

  return replacePlaceholder(url, {
    "{viewerId}": viewerId,
    "{sessionId}": sessionId,
    "{playerDomain}": playerDomain,
    "{shareUrl}": encodeURIComponent(shareUrl),
    "{referer}": encodeURIComponent(referer),
    "{duration}": duration,
    "{pr}": cacheBuster,
    "{random}": random,
    "{puidSportId}": puid[sportId] || puid.default,
    "{width}": width,
    "{height}": height,
  });
}
