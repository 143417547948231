import formatCurrency from "core/utils/currency";

/**
 * Получить текст старой цены для кнопки в карточке продукта
 *
 * @param {*} price - Минимальная цена
 */
export default function getPriceButtonText(price) {
  if (!price?.oldValue) return null;

  return formatCurrency(price.oldValue, price.currency);
}
