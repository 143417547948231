import React, { useState } from "react";
import PropTypes from "prop-types";
import { LinearProgress } from "@mui/material";
import PaypalCheckout from "./PaypalCheckout";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

export default function PaypalPaymentForm({
  paymentData,
  returnUrl,
  paymentId,
  setError,
  descriptionComponent,
  paymentHasError,
  setPaymentHasError,
}) {
  const [loading, setLoading] = useState(false);

  return (
    <>
      {loading && <LinearProgress />}
      <PayPalScriptProvider
        deferLoading={false}
        options={paymentData.providerOptions}
      >
        <PaypalCheckout
          paymentId={paymentId}
          setLoading={setLoading}
          setError={setError}
          loading={loading}
          paymentData={paymentData}
          returnUrl={returnUrl}
          descriptionComponent={descriptionComponent}
          paymentHasError={paymentHasError}
          setPaymentHasError={setPaymentHasError}
        />
      </PayPalScriptProvider>
    </>
  );
}

PaypalPaymentForm.propTypes = {
  paymentHasError: PropTypes.bool,
  setPaymentHasError: PropTypes.func.isRequired,
  paymentId: PropTypes.string.isRequired,
  paymentData: PropTypes.shape({
    providerOptions: PropTypes.shape({
      currency: PropTypes.string.isRequired,
      "client-id": PropTypes.string.isRequired,
      vault: PropTypes.bool,
      intent: PropTypes.string.isRequired,
      components: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  descriptionComponent: PropTypes.object,
  setError: PropTypes.func,
  returnUrl: PropTypes.string.isRequired,
};
