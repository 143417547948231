import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Video from "core/components/Video";
import Box from "@mui/material/Box";

const PREFIX = "BackgroundVideo";

const classes = {
  video: `${PREFIX}-video`,
};

const StyledContainer = styled(Box)(({ background }) => {
  const style = {
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    opacity: 0,
    zIndex: -1,
    position: "absolute",
    transition: "1s all",
    [`& .${classes.video}`]: {
      width: "100%",
      height: "100%",
      position: "absolute",
      objectFit: "cover",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
  };

  if (background) {
    if ("brightness" in background) {
      style["filter"] = `brightness(${background?.brightness})`;
    }
  }

  return style;
});

export default function BackgroundVideo({ background, backgroundVideo }) {
  if (!backgroundVideo) return null;

  const onCanPlay = (event) => {
    event.target.parentNode.style.opacity = 1;
  };

  /**
   * If the video is not a HLS video, we use the video tag.
   */
  if (!backgroundVideo.playlistUrl) {
    return (
      <StyledContainer background={background}>
        <video
          className={classes.video}
          src={backgroundVideo.url}
          onCanPlay={onCanPlay}
          muted
          autoPlay
          playsInline
          loop
        />
      </StyledContainer>
    );
  }

  return (
    <StyledContainer background={background}>
      <Video
        hlsUrl={backgroundVideo.playlistUrl}
        autoPlay
        muted
        loop
        playsInline
        onCanPlay={onCanPlay}
        videoElementId={backgroundVideo.id}
        className={classes.video}
        maxBufferLength={10}
      >
        {(videoElement) => {
          return videoElement;
        }}
      </Video>
    </StyledContainer>
  );
}

BackgroundVideo.propTypes = {
  background: PropTypes.shape({
    brightness: PropTypes.number,
  }),
  backgroundVideo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    playlistUrl: PropTypes.string,
  }),
};
