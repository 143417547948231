import React from "react";
import PropTypes from "prop-types";

export default function SportrecsLogoIcon({ width }) {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 47 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="23.5" cy="23.5" r="23.2866" fill="#E91E63" />
      <path
        d="M29.676 19.292C28.956 18.86 28.212 18.476 27.444 18.14C26.796 17.852 26.064 17.588 25.248 17.348C24.456 17.084 23.676 16.952 22.908 16.952C22.284 16.952 21.78 17.048 21.396 17.24C21.036 17.432 20.856 17.756 20.856 18.212C20.856 18.548 20.964 18.824 21.18 19.04C21.396 19.256 21.708 19.46 22.116 19.652C22.524 19.82 23.016 19.988 23.592 20.156C24.192 20.324 24.864 20.528 25.608 20.768C26.784 21.128 27.84 21.524 28.776 21.956C29.736 22.388 30.552 22.904 31.224 23.504C31.896 24.08 32.412 24.788 32.772 25.628C33.132 26.468 33.312 27.488 33.312 28.688C33.312 30.224 33.024 31.52 32.448 32.576C31.896 33.608 31.152 34.436 30.216 35.06C29.304 35.684 28.26 36.14 27.084 36.428C25.932 36.692 24.768 36.824 23.592 36.824C22.656 36.824 21.696 36.752 20.712 36.608C19.728 36.464 18.744 36.26 17.76 35.996C16.8 35.732 15.864 35.42 14.952 35.06C14.064 34.7 13.236 34.292 12.468 33.836L15.492 27.68C16.332 28.208 17.208 28.676 18.12 29.084C18.888 29.444 19.752 29.768 20.712 30.056C21.696 30.344 22.692 30.488 23.7 30.488C24.468 30.488 24.996 30.392 25.284 30.2C25.596 29.984 25.752 29.708 25.752 29.372C25.752 29.012 25.596 28.712 25.284 28.472C24.996 28.208 24.588 27.98 24.06 27.788C23.532 27.596 22.92 27.404 22.224 27.212C21.552 27.02 20.832 26.792 20.064 26.528C18.936 26.144 17.964 25.736 17.148 25.304C16.332 24.848 15.66 24.344 15.132 23.792C14.604 23.216 14.208 22.568 13.944 21.848C13.704 21.128 13.584 20.3 13.584 19.364C13.584 17.948 13.836 16.7 14.34 15.62C14.868 14.54 15.576 13.64 16.464 12.92C17.352 12.2 18.36 11.66 19.488 11.3C20.64 10.916 21.852 10.724 23.124 10.724C24.06 10.724 24.972 10.82 25.86 11.012C26.748 11.18 27.6 11.408 28.416 11.696C29.256 11.96 30.036 12.26 30.756 12.596C31.476 12.908 32.124 13.208 32.7 13.496L29.676 19.292Z"
        fill="white"
      />
    </svg>
  );
}

SportrecsLogoIcon.defaultProps = {
  width: 24,
};

SportrecsLogoIcon.propTypes = {
  width: PropTypes.number,
};
