import React, { useRef } from "react";
import PropTypes from "prop-types";
import Watermark from "./Watermark";

export default function ViewerIdWatermark({
  watermarked,
  children,
  onVideoError,
  videoRef,
}) {
  const containerRef = useRef();

  if (!watermarked) {
    return children;
  }

  return (
    <div
      ref={containerRef}
      style={{ width: "100%", height: "100%", position: "relative" }}
    >
      {children}
      <Watermark
        onVideoError={onVideoError}
        containerRef={containerRef}
        videoRef={videoRef}
      />
    </div>
  );
}

ViewerIdWatermark.propTypes = {
  onVideoError: PropTypes.func,
  watermarked: PropTypes.bool,
  children: PropTypes.node,
  videoRef: PropTypes.object,
};
