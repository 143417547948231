import React, { useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import HomepageTitle from "arena/components/HomepageTitle";

const PREFIX = "ArenaFaq";

const classes = {
  title: `${PREFIX}-title`,
};

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  //   border: `1px solid ${theme.palette.divider}`,
  overflow: "hidden",
  borderRadius: 4,
  marginBottom: theme.spacing(1),

  "&:not(:last-child)": {
    // borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  // backgroundColor: theme.palette.background.paper,
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  // borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const Root = styled("div", {
  shouldForwardProp: (prop) => !["maxWidth"].includes(prop),
})(
  ({
    theme,
    //maxWidth
  }) => {
    return {
      margin: "auto",
      padding: theme.spacing(0, 3),

      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(0, 2),
      },
      [`& .${classes.title}`]: {
        padding: theme.spacing(5, 0, 1),
      },
    };
  }
);

export default function ArenaHtml({
  title,
  questions,

  paddingTop,
  paddingBottom,
  maxWidth,
}) {
  const [expanded, setExpanded] = useState();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <Root style={{ paddingTop, paddingBottom, maxWidth }} maxWidth={maxWidth}>
        {title && <HomepageTitle className={classes.title} title={title} />}

        {questions?.map((q, i) => {
          return (
            <Accordion
              key={q.id}
              expanded={expanded === i}
              onChange={handleChange(i)}
            >
              <AccordionSummary id={i}>
                <Typography component="span">{q.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body1"
                  component="div"
                  dangerouslySetInnerHTML={{
                    __html: q.answer,
                  }}
                />
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Root>
    </>
  );
}

ArenaHtml.propTypes = {
  title: PropTypes.string,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired,
    })
  ),

  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
  maxWidth: PropTypes.string,
  arena: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};
