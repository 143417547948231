import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Slide from "@mui/material/Slide";

const StyledAppBar = styled(AppBar)({
  top: "auto",
  bottom: 0,

  backgroundColor: "transparent",
  "&:before": {
    content: '""',
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    height: 64,
    pointerEvents: "none",
    backgroundImage:
      "linear-gradient(transparent, rgba(0,0,0,0.6), rgba(0,0,0,0.9))",
  },
});

function PlayerPanel({
  children,
  visible,
  disableGutters,
  position,
  ...props
}) {
  return (
    <Slide direction="up" in={visible} appear={false}>
      <StyledAppBar
        color="default"
        position={position}
        elevation={0}
        {...props}
      >
        <Toolbar disableGutters={disableGutters}>{children}</Toolbar>
      </StyledAppBar>
    </Slide>
  );
}

PlayerPanel.propTypes = {
  children: PropTypes.node,
  visible: PropTypes.bool,
  disableGutters: PropTypes.bool,
  position: PropTypes.oneOf([
    "absolute",
    "fixed",
    "relative",
    "static",
    "sticky",
  ]),
};

export default PlayerPanel;
