import React, { useState, useRef, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import {
  createSponsorImpression,
  createSponsorClick,
  trackAdPixel,
} from "core/utils/tracker";
import ButtonBase from "@mui/material/ButtonBase";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { nanoid } from "nanoid";

const StyledButtonBase = styled(ButtonBase)(() => ({
  display: "block",
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  height: "100%",
  width: "100%",
  zIndex: 1,
  backgroundPosition: "top center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
}));

const SHOW_BANNER_TIMEOUT = 15000; // Через сколько мс закрывать баннер
const ANIMATION_DURATION = 1000; // Длительность анимации

const IMPRESSION = "IMPRESSION";
const DEFAULT = "DEFAULT";
const MEDIUM = "MEDIUM";
const HIGH = "HIGH";
const MAXRES = "MAXRES";

function findImageUrl(formats, { smUp, mdUp, lgUp }) {
  const indexByFormat = formats.reduce((all, item) => {
    all[item.format] = item.url;
    return all;
  }, {});

  if (lgUp) {
    if (indexByFormat[MAXRES]) {
      return indexByFormat[MAXRES];
    }
  }

  if (mdUp) {
    if (indexByFormat[HIGH]) {
      return indexByFormat[HIGH];
    }
  }

  if (smUp) {
    if (indexByFormat[MEDIUM]) {
      return indexByFormat[MEDIUM];
    }
  }

  if (indexByFormat[DEFAULT]) {
    return indexByFormat[DEFAULT];
  }

  return indexByFormat[MAXRES];
}
export default function SponsorCornerBanner({
  onStart,
  onFinish,
  setCornerBannerHeight,
  cornerBanner,
}) {
  const [loaded, setLoaded] = useState(false);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const bannerRef = useRef();

  const onWindowResize = () => {
    if (bannerRef.current) {
      setCornerBannerHeight(bannerRef.current.offsetHeight);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, [bannerRef.current, setCornerBannerHeight]);

  useEffect(() => {
    if (loaded) return null;

    const { token, impressionPixel } = cornerBanner;

    setLoaded(true);

    createSponsorImpression(token, "In-Stream Branding", IMPRESSION);

    if (impressionPixel) {
      trackAdPixel(impressionPixel);
    }

    onStart();

    if (bannerRef.current) {
      setCornerBannerHeight(bannerRef.current.offsetHeight);
    }

    setTimeout(() => {
      setCornerBannerHeight(null);
    }, SHOW_BANNER_TIMEOUT - ANIMATION_DURATION);

    setTimeout(() => {
      setCornerBannerHeight(null);
      onFinish();
    }, SHOW_BANNER_TIMEOUT);
  }, [
    token,
    bannerRef.current,
    setCornerBannerHeight,
    onFinish,
    onStart,
    loaded,
  ]);

  const { id, token, thumbnails, link, isClickable, clickPixel } = cornerBanner;

  const onClick = async (event) => {
    event.preventDefault();

    if (isClickable) {
      if (clickPixel) {
        trackAdPixel(clickPixel);
      }

      const clickId = nanoid();
      const url = link.replace("{clickId}", clickId);

      window.open(url, "_blank");

      await createSponsorClick(token, clickId);
    }
  };

  const src = findImageUrl(thumbnails, { smUp, mdUp, lgUp });

  return (
    <StyledButtonBase
      ref={bannerRef}
      key={id}
      style={{ backgroundImage: `url('${src}')` }}
      onClick={onClick}
    />
  );
}

SponsorCornerBanner.propTypes = {
  onStart: PropTypes.func,
  onFinish: PropTypes.func,
  cornerBanner: PropTypes.shape({
    id: PropTypes.string.isRequired,
    thumbnails: PropTypes.arrayOf(
      PropTypes.shape({
        format: PropTypes.oneOf([DEFAULT, MEDIUM, HIGH, MAXRES]).isRequired,
        url: PropTypes.string.isRequired,
      })
    ).isRequired,
    link: PropTypes.string,
    token: PropTypes.string.isRequired,
    isClickable: PropTypes.bool,
    clickPixel: PropTypes.string,
    impressionPixel: PropTypes.string,
  }),
  setCornerBannerHeight: PropTypes.func,
};
