import React from "react";
import PropTypes from "prop-types";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import StopIcon from "@mui/icons-material/Stop";

export default function ChatMessageMenu({
  anchorEl,
  open,
  onClose,
  onClickBanPermanent,
  onClickBanTemprorary,
  onClickDelete,
  viewerIsCreator,
}) {
  const { t } = useTranslation("core");

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      MenuListProps={{
        dense: true,
      }}
    >
      {!viewerIsCreator && (
        <MenuItem dense onClick={onClickBanPermanent}>
          <ListItemIcon style={{ minWidth: 40, color: "#f44336" }}>
            <StopIcon color="inherit" />
          </ListItemIcon>
          <ListItemText primary={t("chat.ban_permanent")} />
        </MenuItem>
      )}
      {!viewerIsCreator && (
        <MenuItem dense onClick={onClickBanTemprorary}>
          <ListItemIcon style={{ minWidth: 40, color: "#ffeb3b" }}>
            <StopIcon color="inherit" />
          </ListItemIcon>
          <ListItemText primary={t("chat.ban_temporary")} />
        </MenuItem>
      )}
      <MenuItem dense onClick={onClickDelete}>
        <ListItemIcon style={{ minWidth: 40 }}>
          <DeleteIcon color="disabled" />
        </ListItemIcon>
        <ListItemText primary={t("chat.delete")} />
      </MenuItem>
    </Menu>
  );
}

ChatMessageMenu.propTypes = {
  anchorEl: PropTypes.any,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onClickBanPermanent: PropTypes.func,
  onClickBanTemprorary: PropTypes.func,
  onClickDelete: PropTypes.func,
  viewerIsCreator: PropTypes.bool,
};
