import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import ButtonBase from "@mui/material/ButtonBase";
import { Link } from "react-router-dom";

const LINK = "LINK";
const DIALOG = "DIALOG";

const StyledButton = styled(ButtonBase)(({ theme, selected }) => ({
  minHeight: 64,
  fontWeight: 500,
  fontSize: "1.1rem",
  padding: "6px 12px 2px 12px",
  whiteSpace: "nowrap",
  borderBottom: selected
    ? `4px solid ${theme.palette.primary.main}`
    : "4px solid transparent",
}));

function getMenuItemProps(item) {
  if (item.type === DIALOG) {
    return {
      component: "a",
    };
  }

  const isExternalLink = item.path.startsWith("http");

  if (isExternalLink) {
    return {
      href: item.path,
      component: "a",
      target: "_blank",
    };
  } else {
    return {
      to: item.path,
      component: Link,
    };
  }
}

export default function HeaderMenuItem({ item, selected, onClick }) {
  return (
    <StyledButton
      {...getMenuItemProps(item)}
      selected={selected}
      onClick={(event) => onClick(event, item)}
    >
      {item.label}
    </StyledButton>
  );
}

HeaderMenuItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    path: PropTypes.string,
    type: PropTypes.oneOf([LINK, DIALOG]).isRequired,
  }),
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};
