import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";

const Root = styled("div")({
  zIndex: 1,
  userSelect: "none",
  borderRadius: 0,
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  width: "100%",
  height: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

function BigPlayButton({ videoState, onClick }) {
  if (!videoState) return null;
  if (videoState.isLoading) return null;

  return <Root onClick={onClick} data-name="BigPlayButton" />;
}

BigPlayButton.propTypes = {
  videoState: PropTypes.shape({
    status: PropTypes.oneOf(["paused", "playing"]),
    isLoading: PropTypes.bool,
    isStarted: PropTypes.bool,
  }),
  onClick: PropTypes.func.isRequired,
};

export default BigPlayButton;
