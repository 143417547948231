import React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

export default function DialogHeader({ onClose, title, action, position }) {
  const { t } = useTranslation("core");

  return (
    <AppBar position={position} color="default">
      <Toolbar>
        <Tooltip title={t("close")} arrow>
          <IconButton onClick={onClose} edge="start" size="large">
            <CloseIcon />
          </IconButton>
        </Tooltip>

        <Typography variant="h5" sx={{ flex: 1 }} noWrap>
          {title}
        </Typography>

        {action}
      </Toolbar>
    </AppBar>
  );
}

DialogHeader.defaultProps = {
  position: "static",
};

DialogHeader.propTypes = {
  position: PropTypes.string,
  onClose: PropTypes.func,
  title: PropTypes.node,
  action: PropTypes.node,
};
