import React, { useState } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import RadioButton from "./RadioButton";
import Checkbox from "./Checkbox";
import { CreateOnboardingAnswerMutation } from "arena/mutations";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";

const components = {
  RADIO: RadioButton,
  CHECKBOX: Checkbox,
};

export default function PollStep({ step, title, onNext, last }) {
  const { t } = useTranslation("arena");
  const [checked, setChecked] = useState([]);
  const [text, setText] = useState("");

  const [createOnboardingAnswer, { loading }] = useMutation(
    CreateOnboardingAnswerMutation
  );

  const onClick = (event, index) => {
    setChecked((checked) => {
      if (step.props.type === "RADIO") {
        return [index];
      }

      if (checked.includes(index)) {
        return checked.filter((value) => value !== index);
      }

      return checked.concat(index);
    });
  };

  const onChange = (event) => {
    setText(event.target.value);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    onNext();
    let answers = [];

    if (step.props.type === "TEXT") {
      answers = [text];
    }

    if (["CHECKBOX", "RADIO"].includes(step.props.type)) {
      answers = step.props.questions
        .filter((question, index) => checked.includes(index))
        .map((q) => q.label);
    }
    createOnboardingAnswer({
      variables: {
        input: {
          question: step.props.title,
          answers,
        },
      },
    });
  };

  const Component = components[step.props.type];
  const { type, questions } = step.props;

  return (
    <form onSubmit={onSubmit}>
      <AppBar position="static" color="default">
        <Toolbar>
          <Typography variant="h5" sx={{ flex: 1 }}>
            {title}
          </Typography>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            {last ? t("close") : t("next")}
          </Button>
        </Toolbar>
      </AppBar>

      <DialogContent>
        <Card>
          <CardHeader title={step.props.title} />
          {type === "TEXT" && (
            <TextField
              label={step.props.title}
              multiline
              fullWidth
              required
              onChange={onChange}
              value={text}
            />
          )}

          {["CHECKBOX", "RADIO"].includes(type) && (
            <List>
              {questions.map((question, index) => (
                <ListItem
                  key={index}
                  button
                  onClick={(event) => onClick(event, index)}
                >
                  <ListItemIcon>
                    <Component checked={checked.includes(index)} />
                  </ListItemIcon>
                  <ListItemText>{question.label}</ListItemText>
                </ListItem>
              ))}
            </List>
          )}
        </Card>
      </DialogContent>
    </form>
  );
}

PollStep.propTypes = {
  last: PropTypes.bool,
  onNext: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  step: PropTypes.shape({
    type: PropTypes.string.isRequired,
    props: PropTypes.object,
  }),
};
