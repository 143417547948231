import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { loadStripe } from "@stripe/stripe-js/pure";
import { LinearProgress } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import StripeCheckout from "./StripeCheckout";
import ErrorScreen from "core/components/ErrorScreen";
import { useTheme } from "@mui/material/styles";
import { useArena } from "core/components/ArenaProvider";

function getGoogleFontLinks(family) {
  const linkElements = Array.from(
    document.querySelectorAll('link[href^="https://fonts.googleapis.com"]')
  );

  const fontLink = linkElements
    .map((link) => link.href)
    .find((href) => {
      return href.includes(`?family=${family}`);
    });

  return fontLink;
}

export default function StripePaymentForm({
  paymentData,
  returnUrl,
  setError,
  descriptionComponent,
  paymentHasError,
  setPaymentHasError,
}) {
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const arena = useArena();
  const fontFamily = arena?.theme?.typography?.fontFamily;

  const fontLink = fontFamily ? getGoogleFontLinks(fontFamily) : null;

  const { stripeAccount, publishableKey } = paymentData;

  if (!publishableKey) {
    return <ErrorScreen title="Publishable Key Not Found" />;
  }

  const stripePromise = useMemo(() => {
    const headers = stripeAccount ? { stripeAccount } : {};

    return loadStripe(paymentData.publishableKey, headers)
      .then((stripe) => {
        return stripe;
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      });
  }, [publishableKey, stripeAccount]);

  const options = {
    clientSecret: paymentData.clientSecret,
    appearance: {
      theme: "stripe",
      variables: {
        colorPrimary: theme.palette.primary.main,
        colorBackground: theme.palette.background.body,
        colorText: theme.palette.text.primaryInHex,
        colorDanger: theme.palette.error.dark,
        fontFamily: theme.typography.fontFamily,
        borderRadius: `${theme.shape.borderRadius}px`,

        // See all possible variables below
      },
      rules: {
        ".Input:focus": {
          borderColor: theme.palette.primary.main,
          boxShadow: "none",
        },
      },
    },
  };

  if (fontLink) {
    options.fonts = [
      {
        cssSrc: fontLink,
      },
    ];
  }

  return (
    <Elements stripe={stripePromise} options={options}>
      {loading && <LinearProgress />}
      <StripeCheckout
        setError={setError}
        setLoading={setLoading}
        paymentData={paymentData}
        loading={loading}
        returnUrl={returnUrl}
        descriptionComponent={descriptionComponent}
        paymentHasError={paymentHasError}
        setPaymentHasError={setPaymentHasError}
      />
    </Elements>
  );
}

StripePaymentForm.propTypes = {
  paymentHasError: PropTypes.bool,
  setPaymentHasError: PropTypes.func.isRequired,
  paymentData: PropTypes.shape({
    clientSecret: PropTypes.string.isRequired,
    publishableKey: PropTypes.string.isRequired,
    stripeAccount: PropTypes.string,
  }).isRequired,
  descriptionComponent: PropTypes.object,
  setError: PropTypes.func,
  returnUrl: PropTypes.string.isRequired,
};
