import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { UpdateProductPurchaseMutation } from "arena/mutations";
import { useMutation } from "@apollo/client";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

export default function ToggleNotificationsProductPurchase({
  productPurchase,
}) {
  const { t } = useTranslation("arena");

  const [updateProductPurchase, { loading }] = useMutation(
    UpdateProductPurchaseMutation,
    {
      refetchQueries: ["Product"],
    }
  );

  const onChangeSendNotifications = (event) => {
    event.preventDefault();

    updateProductPurchase({
      variables: {
        id: productPurchase.id,
        input: {
          sendNotifications: !productPurchase.sendNotifications,
        },
      },
    });
  };

  return (
    <FormControlLabel
      control={
        <Switch
          color="primary"
          disabled={loading}
          checked={productPurchase.sendNotifications}
          onChange={onChangeSendNotifications}
        />
      }
      label={t("productPurchase.sendNotifications")}
    />
  );
}

ToggleNotificationsProductPurchase.propTypes = {
  productPurchase: PropTypes.shape({
    id: PropTypes.string.isRequired,
    sendNotifications: PropTypes.bool,
  }),
};
