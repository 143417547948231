import React, { useState } from "react";
import PropTypes from "prop-types";

const Hover = ({ children }) => {
  const [focused, setFocused] = useState(false);

  const handleMouseEnter = () => {
    setFocused(true);
  };

  const handleMouseLeave = () => {
    setFocused && setFocused(false);
  };

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {children(focused)}
    </div>
  );
};

Hover.propTypes = {
  children: PropTypes.func.isRequired,
};

export default Hover;
