import { loader } from "graphql.macro";

export const ArenaQuery = loader("./ArenaQuery.graphql");

export const RelatedVideoQuery = loader("./RelatedVideoQuery.graphql");

export const PromocodeQuery = loader("./PromocodeQuery.graphql");
export const BansQuery = loader("./BansQuery.graphql");
export const ChatMessagesQuery = loader("./ChatMessagesQuery.graphql");
export const ViewerQuery = loader("./ViewerQuery.graphql");

export const LiveEventsQuery = loader("./LiveEventsQuery.graphql");

export const ViewerSessionsQuery = loader("./ViewerSessionsQuery.graphql");

export const SponsorChatTopBannerQuery = loader(
  "./SponsorChatTopBannerQuery.graphql"
);
export const SponsorPlayerLogoQuery = loader(
  "./SponsorPlayerLogoQuery.graphql"
);
export const SponsorPlayerButtonQuery = loader(
  "./SponsorPlayerButtonQuery.graphql"
);
export const SponsorVideoQuery = loader("./SponsorVideoQuery.graphql");
export const SponsorInStreamBannerQuery = loader(
  "./SponsorInStreamBannerQuery.graphql"
);
export const SponsorCornerBannerQuery = loader(
  "./SponsorCornerBannerQuery.graphql"
);
export const SponsorMultiAdvQuery = loader("./SponsorMultiAdvQuery.graphql");

export const ViewSessionsQuery = loader("./ViewSessionsQuery.graphql");
