import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import loadSDK from "core/utils/loadSDK";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import {
  createSponsorImpression,
  trackAdPixel,
  postMessage,
} from "core/utils/tracker";
import { useTranslation } from "react-i18next";

const Root = styled("div", {
  shouldForwardProp: (prop) => prop !== "visible",
})(({ visible }) => ({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.65)",
  zIndex: visible ? 10000 : 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const Container = styled("div")({
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const AdLabel = styled(Typography)({
  backgroundColor: "rgba(0, 0, 0, 0.2)",
  borderRadius: 4,
  padding: "5px 10px",
  position: "absolute",
  left: 10,
  top: 10,
  zIndex: 100000,
});

const CloseButton = styled(IconButton)({
  backgroundColor: "rgba(0, 0, 0, 0.2)",
  position: "absolute",
  right: 10,
  top: 10,
  zIndex: 100000,
});

const SDK_URL = "https://yandex.ru/ads/system/context.js";
const TIMEOUT = 20000; // сколько мс должен показываться баннер прежде чем закроется
const IMPRESSION = "IMPRESSION";
const ERROR = "ERROR";
const CLOSE = "CLOSE";
const NOBANNER_ERROR = "NOBANNER_ERROR";

const creativeType = "Adfox Banner";

export default function Adfox({
  onStart,
  onFinish,
  onError,
  place,
  adfoxBanner,
}) {
  const { t } = useTranslation("core");
  const [visible, setVisible] = useState(false);
  const { id, token, impressionPixel, ownerId, adLabel, isCloseable } =
    adfoxBanner;

  const containerId = `adfox_${id}`;

  const params = adfoxBanner.params.reduce((all, item) => {
    all[item.key] = item.value;
    return all;
  }, {});

  let timer = null;

  // Вызывается, когда получен ответ за запрос и в ответе присутствует объект window.loadAdFoxBundle. Вызывается до onRender.
  const onLoad = () => {
    setVisible(true);
  };

  // Вызывается, когда код баннера или баннера по умолчанию (заданный пользователем) был вставлен в контейнер.
  const onRender = () => {
    onStart();

    createSponsorImpression(token, creativeType, IMPRESSION);
    postMessage({
      eventName: IMPRESSION,
      creative: "SponsorAdfoxBanner",
      place,
    });

    if (impressionPixel) {
      trackAdPixel(impressionPixel);
    }

    timer = setTimeout(onFinish, TIMEOUT);
  };

  // Вызывается, когда сервер вернул заглушку (системный код), контейнер остался пустым.
  const onStub = () => {
    createSponsorImpression(token, creativeType, NOBANNER_ERROR);
    postMessage({
      eventName: NOBANNER_ERROR,
      creative: "SponsorVideo",
      place,
    });
    onError();
  };

  // Вызывается, если в ходе выполнения запроса была получена сетевая ошибка (например, 404) или же код баннера содержит синтаксическую ошибку.
  const _onError = (error) => {
    console.log("onError", error);
    createSponsorImpression(token, creativeType, ERROR);
    postMessage({
      eventName: ERROR,
      creative: "SponsorVideo",
      place,
    });
    onError();
  };

  useEffect(() => {
    window.yaContextCb = window.yaContextCb || [];

    let obj = null;

    loadSDK(SDK_URL, "yandex-ad-system-sdk")
      .then(() => {
        return window.yaContextCb.push(() => {
          console.log("Ya.adfoxCode.create", { ownerId, containerId, params });

          obj = window.Ya.adfoxCode.create({
            ownerId,
            containerId,
            params,
            onLoad,
            onRender,
            onStub,
            onError: _onError,
          });
        });
      })
      .catch(onError);

    return () => {
      obj?.destroy();
      clearTimeout(timer);
    };
  }, [adfoxBanner]);

  const onClickClose = () => {
    // close
    createSponsorImpression(token, creativeType, CLOSE);
    postMessage({
      eventName: CLOSE,
      creative: "SponsorVideo",
      place,
    });
    onFinish();
  };

  return (
    <Root visible={visible} test-id="AdfoxBannerRoot">
      <Container id={containerId} test-id="AdfoxBannerContainer" />
      {adLabel && <AdLabel variant="body1">{t("adv.ad")}</AdLabel>}
      {isCloseable && (
        <CloseButton onClick={onClickClose} size="large">
          <CloseIcon />
        </CloseButton>
      )}
    </Root>
  );
}

Adfox.propTypes = {
  onStart: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  place: PropTypes.string.isRequired,
  adfoxBanner: PropTypes.shape({
    id: PropTypes.string.isRequired,
    ownerId: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    impressionPixel: PropTypes.string,
    params: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ),
    adLabel: PropTypes.bool,
    isCloseable: PropTypes.bool,
  }),
};
