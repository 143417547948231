import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import PlayerFooterButton from "core/components/PlayerFooterButton";
import SportrecsLogoIcon from "core/components/SportrecsLogoIcon";

function LogoButton({ url }) {
  const { t } = useTranslation("core");

  return (
    <PlayerFooterButton
      title={t("logoButton")}
      component="a"
      href={url}
      target="_blank"
      rel="noopener"
    >
      <SportrecsLogoIcon />
    </PlayerFooterButton>
  );
}

LogoButton.propTypes = {
  url: PropTypes.string.isRequired,
};

export default LogoButton;
